
<script setup lang="ts">
import {onMounted, ref, onUnmounted} from "vue";
import router from "@/router";
import {MiniAppsEventListener, on, off, postEvent} from "@telegram-apps/sdk";
import { useI18n } from 'vue-i18n';
import lottie from 'lottie-web';

import Soon from '@/assets/wallet/soon.json'

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/profile');
};

const image_soon = ref();

let sticker: any = null;

onMounted(() => {
  on('back_button_pressed', listener);
  postEvent('web_app_setup_back_button', { is_visible: true});
  sticker = lottie.loadAnimation({
    container: image_soon.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: Soon,
  });
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})



</script>

<template>
  <div class="soon_wrapper">
    <div class="soon">{{ t(`tournaments.soon`) }}</div>
    <div class="image" ref="image_soon"></div>
  </div>
</template>



<style scoped>

.soon_wrapper {
  height: 78vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 39px;
}

.soon {
  font-size: 40px;
  font-weight: 500;
  color: #B9B9B9;
}

.image {
  max-width: 200px;
}

</style>
