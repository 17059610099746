import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo/background_logo_partner.svg'


const _hoisted_1 = {
  class: "table-cards",
  id: "tablecards"
}
const _hoisted_2 = { class: "absolut-cards" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src"]
const _hoisted_18 = ["src"]
const _hoisted_19 = ["src"]
const _hoisted_20 = { class: "bank-bet" }
const _hoisted_21 = { class: "bank" }
const _hoisted_22 = { class: "bank-label" }
const _hoisted_23 = { class: "bank-sum" }
const _hoisted_24 = { class: "bet" }
const _hoisted_25 = { class: "bet-label" }
const _hoisted_26 = { class: "bet-sum" }
const _hoisted_27 = { class: "cards" }
const _hoisted_28 = { class: "cartas" }
const _hoisted_29 = ["src"]
const _hoisted_30 = ["src"]
const _hoisted_31 = ["src"]
const _hoisted_32 = ["src"]
const _hoisted_33 = ["src"]

import store from "@/store";
import GameCard from "@/models/gameCard";
import Game from "@/models/game";
import Player from "@/models/player";
import { onMounted, watch, inject, KeepAlive, ref } from "vue";
import { useI18n } from 'vue-i18n';
import NotificationModal from "@/components/game/NotificationModal.vue";

type MoveUser = {
  user_id: string;
  username: string | null;
  time: string;
}

type Winner = {
  user_id: string;
  username: string | null;
  sum: number,
  created_at: string
}

type Action = {
  user_id: string,
  username: string | null,
  action_name: string,
  action_sum?: number
}

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'TableCards',
  props: {
    firstFlopCard: {},
    secondFlopCard: {},
    thirdFlopCard: {},
    ternCard: {},
    riverCard: {},
    game: {},
    players: {},
    playersCount: {},
    hand: {},
    notificationType: {},
    moveUser: {},
    winners: {},
    action: {},
    messageData: {},
    firstFlopCardHanled: { type: Boolean },
    nahdNotif: {}
  },
  emits: ['firstFlopCardHanled'],
  setup(__props: any, { emit: __emit }) {

const playSound = inject<() => void>("playSound");

const playFlopSound = inject<() => void>("playFlopSound");

const { t } = useI18n();

const props = __props;

// watch(() => props.notificationType, () => {
//   console.log("notificationType:", props.notificationType);
//   console.log("winners");
//   console.log(props.winners)
// });

const preloadImage = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.src = url;
  });
};

const flipCard = async (elementId: string, card: GameCard) => {
  const element = document.getElementById(elementId);
  if (!element) return;
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const imageUrl = require(`@/assets/cards/open/${store.getters.USER.vip_status}/${card.card_rank.toLowerCase()}_${card.card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`);

  await preloadImage(imageUrl);

  element.style.animation = 'moveCard 0.7s ease forwards, flipCard 0.5s ease forwards';
  if (card == props.secondFlopCard) {
    element.style.animationDelay = '0.2s, 1.2s';
    setTimeout(() => {
      element.setAttribute('src', imageUrl);
    }, 1450);
  } else if (card == props.thirdFlopCard) {
    element.style.animationDelay = '0.4s, 1.4s';
    setTimeout(() => {
      element.setAttribute('src', imageUrl);
    }, 1650);
  } else {
    element.style.animationDelay = '0s, 1s';
    setTimeout(() => {
      element.setAttribute('src', imageUrl);
    }, 1250);
  }
};

function checkHandCards() {
  const cardsToCheck = [
    props.firstFlopCard,
    props.secondFlopCard,
    props.thirdFlopCard,
    props.ternCard,
    props.riverCard,
  ];

  cardsToCheck.forEach((card, index) => {
    if (!card) return;

    const isCardInCombination = props.hand.cards.some(
      (comboCard) =>
        comboCard.card_rank === card.card_rank &&
        comboCard.card_suit === card.card_suit
    );

    if (isCardInCombination) {
      const elementId = getCardElementId(index);
      const element = document.getElementById(elementId);

      if (element) {
        setTimeout(() => {
          element.style.animation = "highlight-card-table .4s ease forwards";
          setTimeout(() => {
            element.style.animation = "outhighlight-card-table .4s ease forwards";
          }, 2000);
        }, 1800);
      }
    }
  });
}

function getCardElementId(index: number) {
  switch (index) {
    case 0:
      return "flop-1";
    case 1:
      return "flop-2";
    case 2:
      return "flop-3";
    case 3:
      return "tern";
    case 4:
      return "river";
    default:
      return "";
  }
}

watch(
  () => props.hand,
  (newHand, oldHand) => {
    if (JSON.stringify(newHand) !== JSON.stringify(oldHand)) {
      checkHandCards(); 
    }
  },
  { deep: true, immediate: true }
);

const emit = __emit;

// function setPositionPreflopCard() {
//   const tableCardsElement = document.getElementById('tablecards');
  
//   if (tableCardsElement) {
//     const rect = tableCardsElement.getBoundingClientRect();
//     const centerX = rect.width / 2;
//     const centerY = rect.height / 2;

//     const preflopCards = document.getElementsByClassName('preflop-card');

//     for (let i = 0; i < preflopCards.length; i++) {
//       const element = preflopCards[i] as HTMLElement; 
//       element.style.left = `${centerX - element.offsetWidth / 2}px`;
//       element.style.top = `${centerY - element.offsetHeight / 2}px`;
//     }
//   }
// }


onMounted(() => {
  if (props.firstFlopCard.is_opened) flipCard('flop-1', props.firstFlopCard);
  if (props.secondFlopCard.is_opened) flipCard('flop-2', props.secondFlopCard);
  if (props.thirdFlopCard.is_opened) flipCard('flop-3', props.thirdFlopCard);
  if (props.ternCard.is_opened) flipCard('tern', props.ternCard);
  if (props.riverCard.is_opened) flipCard('river', props.riverCard);
});


watch(() => props.firstFlopCard.is_opened, (newValue) => {
  if (newValue && newValue != undefined) {
    console.log('firstFlopCard');
    console.log(props.firstFlopCard);
      flipCard('flop-1', props.firstFlopCard);
      if (playSound) {
        playSound(); 
      }    
  } 
  else {
    console.log('undefined у карты firstFlopCard')
  }
}, { deep: true });

watch(() => props.secondFlopCard.is_opened, (newValue) => {
  if (newValue && newValue != undefined) {
    console.log('secondFlopCard');
    console.log(props.secondFlopCard);
    flipCard('flop-2', props.secondFlopCard);
  if (playSound) {
    playSound(); 
  }
  setTimeout(() => {
    if (playFlopSound) {
    playFlopSound(); 
  }  
  },800)
  }
  else {
    console.log('undefined у карты secondFlopCard')
  }
}, { deep: true });

watch(() => props.thirdFlopCard.is_opened, (newValue) => {
  if (newValue && newValue != undefined) {
    console.log('thirdFlopCard');
    console.log(props.thirdFlopCard);
    flipCard('flop-3', props.thirdFlopCard);
    if (playSound) {
    playSound(); 
  }  
  } 
  else {
    console.log('undefined у карты thirdFlopCard')
  }
}, { deep: true });

watch(() => props.ternCard.is_opened, (newValue) => {
  console.log('ternCard');
  console.log(props.ternCard);
  if (newValue && newValue != undefined) {
    flipCard('tern', props.ternCard);
    if (playSound) {
    playSound(); 
  } 
  setTimeout(() => {
    if (playFlopSound) {
    playFlopSound(); 
  }  
  },800)
  }
  else {
    console.log('undefined у карты ternCard')
  }
}, { deep: true });

watch(() => props.riverCard.is_opened, (newValue) => {
  console.log('riverCard');
  console.log(props.riverCard);
  if (newValue && newValue != undefined) {
    flipCard('river', props.riverCard);
    if (playSound) {
    playSound(); 
  }  
  setTimeout(() => {
    if (playFlopSound) {
    playFlopSound(); 
  }  
  },800)
  }
  else {
    console.log('undefined у карты riverCard')
  }
}, { deep: true });



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo-background-wrapper" }, [
      _createElementVNode("img", {
        src: _imports_0,
        class: "logo-background"
      })
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-1",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_3),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-2",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_4),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-3",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_5),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-4",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_6),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-5",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_7),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-6",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_8),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-7",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_9),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-8",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_10),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-9",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_11),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-10",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_12),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-11",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_13),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-12",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_14),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-13",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_15),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-14",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_16),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-15",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_17),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-16",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_18),
      _createElementVNode("img", {
        class: _normalizeClass(['preflop-card']),
        id: "preflop-base",
        src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
      }, null, 8, _hoisted_19)
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("div", _hoisted_21, [
        _createElementVNode("p", _hoisted_22, _toDisplayString(_unref(t)(`game.bank`)), 1),
        _createElementVNode("p", _hoisted_23, _toDisplayString(_ctx.game?.bank ?? 0), 1)
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("p", _hoisted_25, _toDisplayString(_unref(t)(`game.bet`)), 1),
        _createElementVNode("p", _hoisted_26, _toDisplayString(props.players.length!=0 ? Math.max(..._ctx.players.map(x => x.current_round_bet)) : 0), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_27, [
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("img", {
          class: _normalizeClass(['card']),
          id: "flop-1",
          src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
        }, null, 8, _hoisted_29),
        _createElementVNode("img", {
          class: _normalizeClass(['card']),
          id: "flop-2",
          src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
        }, null, 8, _hoisted_30),
        _createElementVNode("img", {
          class: _normalizeClass(['card']),
          id: "flop-3",
          src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
        }, null, 8, _hoisted_31),
        _createElementVNode("img", {
          class: _normalizeClass(['card']),
          id: "tern",
          src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
        }, null, 8, _hoisted_32),
        _createElementVNode("img", {
          class: _normalizeClass(['card']),
          id: "river",
          src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
        }, null, 8, _hoisted_33)
      ])
    ]),
    (_ctx.notificationType == 'move' && _ctx.moveUser)
      ? (_openBlock(), _createBlock(NotificationModal, {
          "notification-type": 'move',
          "move-user": _ctx.moveUser,
          action: _ctx.action,
          card_open: props.firstFlopCard.is_opened,
          key: _ctx.moveUser ? _ctx.moveUser.time : ''
        }, null, 8, ["move-user", "action", "card_open"]))
      : _createCommentVNode("", true),
    (_ctx.notificationType && _ctx.winners !== undefined)
      ? (_openBlock(), _createBlock(NotificationModal, {
          "notification-type": 'winner',
          winners: _ctx.winners,
          hand: _ctx.nahdNotif,
          card_open: props.firstFlopCard.is_opened,
          key: _ctx.winners.map(element => element.created_at).join(', ')
        }, null, 8, ["winners", "hand", "card_open"]))
      : _createCommentVNode("", true)
  ]))
}
}

})