<script setup lang="ts">
import {onMounted, ref, defineProps} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import {ApiService} from "@/api/apiService";

const { t } = useI18n();

type MoveUser = {
  user_id: string;
  username: string | null;
}

type Winner = {
  user_id: string;
  username: string | null;
  sum: number,
  created_at: string
}

type Action = {
  user_id: string,
  username: string | null,
  action_name: string,
  action_sum?: number
}

const props = defineProps<{
  notificationType: "winner" | "move" | "new_msg",
  moveUser?: MoveUser,
  winners?: Winner[],
  hand?: string,
  action?: Action,
  messageData?: any,
  card_open: boolean
}>();
const whoWinnerString = ref("");
const sum = ref(false);

onMounted(() => {
  const currentUserId = store.getters.USER.id;
  if (props.winners) {
    // console.log('props.winners notif type:', props.winners)
    if (props.winners.length == 1) {
      if (props.winners[0].user_id == currentUserId) {
        whoWinnerString.value = `${t('notification.youve_won')} ${props.winners[0].sum}`;
      } else {
        whoWinnerString.value = `${t('notification.won')} <span style="color: #B698EF;">${props.winners[0].username ?? 'noname'}</span> ${props.winners[0].sum}`;
      }
      sum.value = true;
    } else {
      const currentUserWinner = props.winners.find((element) => element.user_id == currentUserId);
      if (currentUserWinner) {
        whoWinnerString.value = `${t('notification.youve_won')} ${currentUserWinner.sum}`;
        sum.value = true;
      } else {
        whoWinnerString.value = `${props.winners.length} ${t('notification.slice_bank')}`;
      }
    }
  }
});

</script>

<template>
  <div v-if="notificationType != 'new_msg'" class="notification_mov_winner" :style="{ 'z-index': notificationType == 'move' ? 1 : 1 }">
    <div :class="{'mov_winner': !card_open, 'mov_winner_open': card_open}" v-if="notificationType === 'move'">
      <div v-if="action" class="winners-sum">
        <div class="winners-sum-who">
          <p class="player">{{ action.username ?? 'noname' }}</p>
          <p>{{ action.action_name }}</p>
          <p v-if="action.action_sum">{{ action.action_sum }}</p>
          <img v-if="action.action_sum" src="@/assets/logo/money_logo.svg" class="balance-logo">
        </div>
      </div>
      <div v-if="moveUser">
        <p class="move-label" style="text-align: center" v-if="moveUser?.user_id == store.getters.USER.id">{{ t(`notification.you_turn`) }}</p>
        <p class="move-label" style="text-align: center" v-else>{{ t(`notification.action`) }} {{ moveUser?.username ?? 'noname' }}</p>
      </div>
    </div>
    <div :class="{'mov_winner': !card_open, 'mov_winner_open': card_open}" v-else :style="{'text-align': 'center', 'z-index': notificationType == 'winner' ? 1 : 1000}">
      <div class="winners-sum">
        <div class="winners-sum-who">
          <p class="winners-label" v-html="whoWinnerString"></p>
          <img v-if="sum" src="@/assets/logo/money_logo.svg" class="balance-logo">
        </div>
        <p class="winners-label" v-if="hand != 'нет'">{{ t(`notification.the_winning_hand`) }} {{ hand }}</p>
      </div>
    </div>
  </div>
  <div v-else-if="notificationType === 'new_msg' && messageData.user_id!==store.getters.USER.id" class="notification-message" :style="{ 'z-index': notificationType == 'new_msg' ?1 : 1000 }">
    <div class="container">
      <img class="user-img" :src="messageData.user.photo_url ? ApiService.url + messageData.user.photo_url : require('@/assets/mock_user/user.svg')">
      <div class="wrapper">
        <div class="user-name">
          <p class="user-name-lable">{{ messageData.user_username }}</p>
          <!-- <img class="crown" :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)"> -->
        </div>
        <p class="message">{{ messageData.body }}</p>
      </div>
    </div>
    
  </div>
</template>

<style scoped>

.winners-sum-who {
  display: flex;
  gap: 6px;
  justify-content: center;
  align-items: center;
}

.notification_mov_winner {
  position: absolute;
  z-index: 1;
  top: 56%;
  animation: notification-transform 0.3s ease-out forwards;
  animation-delay: 0s, 2.3s;
}

.mov_winner_open {
  text-align: center;
  background-color: #2A2836;
  border-radius: 100px;
  padding-top: 6.98vw;
  padding-bottom: 2.6vw;
  padding-left: 8.37vw;
  padding-right: 8.37vw;
} 

.mov_winner {
  text-align: center;
  background-color: #2A2836;
  border-radius: 100px;
  padding-top: 2.6vw;
  padding-bottom: 2.6vw;
  padding-left: 8.37vw;
  padding-right: 8.37vw;
} 

.notification {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 56px;
  animation: notification-transform 0.3s ease-out forwards;
  animation-delay: 0s;
  background: #423757 url("@/assets/background/background-notification.svg") no-repeat;
  background-size: 100% 100%;
  border-radius: 0 0 20px 20px;

}

.notification-message {
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 56px;
  animation: notification-transform 0.3s ease-out forwards, notification-transform-hide 0.3s ease-out forwards;
  animation-delay: 0s, 20s; 
  background: #423757 url("@/assets/background/background-notification.svg") no-repeat;
  background-size: 100% 100%;
  border-radius: 0 0 20px 20px;

}

.user-img{
  width:42px;
  height: 42px;
  margin-right: 14px;
  border-radius: 50%;
}

.container{
  display: flex;
  align-items: center;
}

.wrapper{
  display: flex;
  flex-direction: column;
  gap: 4px;

}
.user-name{
  display:flex;
  align-items: center;
  gap: 4px;
}
.user-name-lable{
  font-size: 14px;
}
.crown{
  width: 14px;
}
.message{
  text-align: left;
  width: 220px;
  white-space: nowrap;        
  overflow: hidden;          
  text-overflow: ellipsis;      
  font-size: 14px;    
}

.move-label {
  font-size: 15px;
  font-weight: 500;
  width: 100%;
}
.player{
  color: #B698EF;
}
.winners-label {
  font-size: 15px;
}

.winners-sum {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  flex-direction: column;
}

@keyframes notification-transform {
  0% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes notification-transform-hide {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
    display: none;
  }
}

.balance-logo {
  width: 3.49vw;
  height: 3.4vw;
  filter: drop-shadow(0px 1px 1px #000);
}
</style>