import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "new-bb-room" }
const _hoisted_2 = { class: "new-bb-wrapper" }
const _hoisted_3 = { class: "new-bb-wrapper-card" }
const _hoisted_4 = { class: "new-bb-up" }
const _hoisted_5 = { class: "new-bb-up-up" }
const _hoisted_6 = { class: "new-bb-text1" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = { class: "new-bb-text2" }
const _hoisted_10 = { key: 0 }
const _hoisted_11 = { key: 1 }
const _hoisted_12 = { style: {"font-weight":"600"} }
const _hoisted_13 = {
  class: "new-bb-сhips",
  width: "15px",
  height: "15px",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_14 = ["fill"]

import {ref, onMounted, computed} from "vue";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";


export default /*@__PURE__*/_defineComponent({
  __name: 'NewBbNotification',
  props: {
    room: {},
    myroomBigBlindBet: {},
    showPlayer: { type: Boolean },
    deleteRoom: { type: Boolean }
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {


const { t } = useI18n();

const props = __props

const emit = __emit; 

const tg = window.Telegram?.WebApp;

const timer = ref<number>(3);

onMounted (async ()=> {
  timer.value = 3;
  countTimer();
})

function countTimer(){
  const interval = setInterval(() => {
    if (timer.value > 1) {
      timer.value--;  
    } else {
      emit('close');
      clearInterval(interval);
    }
  }, 1000);
}

const colorChips = computed(() => {
  if(props.room.coin_type == 'go_coin') {
    return "#7861A5";
  }
  if(props.room.coin_type == 'red_coin') {
    return "#D14A4A";
  }
  else {
    return "#FFFFFF";
  }
  
});

const computedCoinTypeText = computed(() => {
  if (props.room.coin_type === 'go_coin') {
    return t('notification.go_coins');
  } else if (props.room.coin_type === 'red_coin') {
    return t('notification.red_coins');
  }
  return '';
});



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              (_ctx.deleteRoom)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_unref(t)(`notification.admin_del_room`)), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_unref(t)(`notification.change_settings_room`)), 1))
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_ctx.deleteRoom)
              ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_unref(t)(`notification.admin_del_room2`)), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_11, [
                  _createTextVNode(_toDisplayString(_unref(t)(`notification.game`)) + " ", 1),
                  _createElementVNode("span", _hoisted_12, _toDisplayString(computedCoinTypeText.value), 1),
                  _createTextVNode(" " + _toDisplayString(_unref(t)(`notification.bb`)) + " " + _toDisplayString(_ctx.myroomBigBlindBet), 1)
                ])),
            (_openBlock(), _createElementBlock("svg", _hoisted_13, [
              _createElementVNode("path", {
                d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                fill: colorChips.value
              }, null, 8, _hoisted_14)
            ]))
          ])
        ])
      ])
    ])
  ]))
}
}

})