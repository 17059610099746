import {ApiService} from "@/api/apiService";
import store from "@/store";

export class TournamentServise {

    public async register(type: string): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/register?type=${type}`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    type: type
                })
            })
    }

    public async unRegister(type: string): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/cancel-participation?type=${type}`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    type: type
                })
            })
    }

    public async joinTournament(type: string): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/joinRoom`,
            {
                method: 'POST',
                headers: headers,
                body: JSON.stringify({
                    type: type
                })
            })
    }

    public async getTournament(type: string): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/participant-count?type=${type}`, { headers: headers })
    }

    public async getTourPlayer(type: string): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/tournament/participant-exists?type=${type}`, {
            method: 'GET',
            headers: headers
        });
    }
    

}