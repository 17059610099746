import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "third-section" }
const _hoisted_2 = { class: "top-player-filter-wrapper" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "top-player-card-mock" }
const _hoisted_6 = { class: "top-player-card-mock" }
const _hoisted_7 = { class: "top-player-card-mock" }
const _hoisted_8 = { class: "top-player-card-mock" }
const _hoisted_9 = { class: "top-player-card-mock" }

import {UserService} from "@/api/userService";
import {onMounted, ref} from "vue";
import AnimatedPlaceholder from "@/components/tools/AnimatedPlaceholder.vue";
import TopPlayer from "@/models/topPlayer";
import TopPlayerListItem from "@/components/lists/top_players/TopPlayerListItem.vue";
import { useI18n } from 'vue-i18n';
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'TopPlayersList',
  setup(__props) {

const { t } = useI18n();

const topPlayers = ref<TopPlayer[]>([]);
const isLoaded = ref(false);
const filter = ref<'hour' | 'day'>('hour');

function getTopPlayers(interval: "hour" | "day") {
  isLoaded.value = false;
  const userService = new UserService()
  filter.value = interval;
  userService.topPlayers(interval)
      .then(response => response.json())
      .then(data => {
        topPlayers.value = data;
        isLoaded.value = true;
      });
}

onMounted(() => {
  getTopPlayers("hour");
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => {triggerHapticFeedback('light'); getTopPlayers('hour')}),
        class: _normalizeClass({ 'top-player-filter': true, 'top-player-filter-active': filter.value == 'hour' })
      }, _toDisplayString(_unref(t)(`main.top_of_hour`)), 3),
      _createElementVNode("div", {
        onClick: _cache[1] || (_cache[1] = ($event: any) => {triggerHapticFeedback('light'); getTopPlayers('day')}),
        class: _normalizeClass({ 'top-player-filter': true, 'top-player-filter-active': filter.value == 'day' })
      }, _toDisplayString(_unref(t)(`main.top_of_day`)), 3)
    ]),
    (isLoaded.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(topPlayers.value, (item, i) => {
            return (_openBlock(), _createBlock(TopPlayerListItem, {
              key: i,
              item: item
            }, null, 8, ["item"]))
          }), 128))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(AnimatedPlaceholder, {
              "border-radius": "var(--border-mixed)",
              width: "100%",
              height: "70px"
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(AnimatedPlaceholder, {
              "border-radius": "var(--border-mixed)",
              width: "100%",
              height: "70px"
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(AnimatedPlaceholder, {
              "border-radius": "var(--border-mixed)",
              width: "100%",
              height: "70px"
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(AnimatedPlaceholder, {
              "border-radius": "var(--border-mixed)",
              width: "100%",
              height: "70px"
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(AnimatedPlaceholder, {
              "border-radius": "var(--border-mixed)",
              width: "100%",
              height: "70px"
            })
          ])
        ]))
  ]))
}
}

})