import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/friends/gift.png'
import _imports_1 from '@/assets/logo/money_logo.svg'
import _imports_2 from '@/assets/friends/link.svg'
import _imports_3 from '@/assets/friends/copy.svg'
import _imports_4 from '@/assets/friends/open-red-box.svg'
import _imports_5 from '@/assets/friends/friend.svg'
import _imports_6 from '@/assets/friends/copy-white.svg'


const _hoisted_1 = { class: "invite-friends" }
const _hoisted_2 = { class: "invite-friends-additional" }
const _hoisted_3 = { class: "invite-friend-gift-wrapper" }
const _hoisted_4 = { class: "invite-friend-gift-info" }
const _hoisted_5 = { class: "invite-friend-label" }
const _hoisted_6 = { class: "invite-friend-gift-bonus-label" }
const _hoisted_7 = { class: "invite-friend-gift-bonus" }
const _hoisted_8 = { class: "invite-friend-link-wrapper" }
const _hoisted_9 = { class: "friends-count-wrapper" }
const _hoisted_10 = { class: "friends-count" }
const _hoisted_11 = { class: "friends-count-label" }
const _hoisted_12 = { class: "friend-get-label" }
const _hoisted_13 = { class: "friends-count" }
const _hoisted_14 = { class: "friends-count-label" }
const _hoisted_15 = { class: "friend-get-label" }
const _hoisted_16 = { class: "referral-link" }
const _hoisted_17 = { class: "rules-wrapper" }
const _hoisted_18 = { class: "rules-label" }
const _hoisted_19 = { class: "rules-img-wrapper" }
const _hoisted_20 = { class: "rules-img-label" }
const _hoisted_21 = { class: "rules-list-label" }
const _hoisted_22 = { class: "rules-list" }
const _hoisted_23 = { class: "rules-item" }
const _hoisted_24 = { class: "rules-item-label" }
const _hoisted_25 = { class: "rules-item" }
const _hoisted_26 = { class: "rules-item-label" }
const _hoisted_27 = { class: "rules-list-label" }
const _hoisted_28 = { class: "rules-list" }
const _hoisted_29 = { class: "rules-item" }
const _hoisted_30 = { class: "rules-item-label" }
const _hoisted_31 = { class: "rules-item" }
const _hoisted_32 = { class: "rules-item-label" }
const _hoisted_33 = { class: "rules-item" }
const _hoisted_34 = { class: "rules-item-label" }
const _hoisted_35 = {
  key: 0,
  class: "rules-list-label"
}
const _hoisted_36 = {
  key: 1,
  class: "friends-list"
}
const _hoisted_37 = { class: "friend-info" }
const _hoisted_38 = ["src"]
const _hoisted_39 = { class: "friend-username" }
const _hoisted_40 = { class: "friend-bonus" }
const _hoisted_41 = { class: "friend-bonus-sum" }
const _hoisted_42 = { class: "invite-friend-buttons" }

import HeaderMini from "@/components/header/HeaderMini.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, ref, watch} from "vue";
import store from "../store";
import {ApiService} from "@/api/apiService";
import { useI18n } from 'vue-i18n';
import { UserService } from "@/api/userService";
import {formatNumber} from "@/tools/tools";


export default /*@__PURE__*/_defineComponent({
  __name: 'FriendsView',
  setup(__props) {


const { t } = useI18n();

const userService = new UserService();

const referals = ref(0);

const balance = ref(store.getters.USER.balance);

const isOpenPop = ref(false);

// watch(() => balance.value, (newValue) => {
//   if (newValue>balance.value) {
//     userService.getReferrals()
//     .then(response => response.json())
//     .then(data => {
//       referals.value = data;
//     });
//   }
// });

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1);
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
  userService.getReferrals()
    .then(response => response.json())
    .then(data => {
      referals.value = data;
    });
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
  isOpenPop.value = true;
  setTimeout(() => {
    isOpenPop.value = false;
  }, 700);
}

function openShare() {
  triggerHapticFeedback('soft');
  postEvent('web_app_open_tg_link', {
    path_full: `/share/url?text=${encodeURI(t('friends.invite_message'))}&url=https://t.me/okgo_poker_bot/open_app?startapp=${store.getters.TELEGRAM_USER.user.id}`
  });
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderMini),
    _createElementVNode("div", {
      class: "friends-wrapper",
      style: _normalizeStyle({'margin-top': _unref(tg).isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + 20px)': 72})
    }, [
      _createElementVNode("div", {
        class: "popap-copy",
        style: _normalizeStyle({'opacity': isOpenPop.value ? '1' : '0'})
      }, _cache[3] || (_cache[3] = [
        _createElementVNode("p", null, "Ссылка скопирована", -1)
      ]), 4),
      _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)(`friends.invite_friends`)), 1),
      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)(`friends.invite_friends_additional`)), 1),
      _createElementVNode("div", _hoisted_3, [
        _cache[5] || (_cache[5] = _createElementVNode("img", {
          class: "invite-friends-gift",
          src: _imports_0
        }, null, -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(`friends.invite_friend_label`)), 1),
          _createElementVNode("div", _hoisted_6, [
            _cache[4] || (_cache[4] = _createElementVNode("img", {
              class: "money-logo-friends",
              src: _imports_1
            }, null, -1)),
            _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(t)(`friends.invite_friend_gift_bonus`)), 1)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("p", _hoisted_11, _toDisplayString(referals.value), 1),
            _createElementVNode("p", _hoisted_12, [
              _createTextVNode(_toDisplayString(_unref(t)(`friends.friend_get_label1`)), 1),
              _cache[6] || (_cache[6] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(_toDisplayString(_unref(t)(`friends.friend_get_label2`)), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(formatNumber)(referals.value * 50000)), 1),
            _createElementVNode("p", _hoisted_15, [
              _createTextVNode(_toDisplayString(_unref(t)(`friends.friend_get_label_bonus1`)), 1),
              _cache[7] || (_cache[7] = _createElementVNode("br", null, null, -1)),
              _createTextVNode(_toDisplayString(_unref(t)(`friends.friend_get_label_bonus2`)), 1)
            ])
          ])
        ]),
        _createElementVNode("div", {
          class: "referral-link-wrapper",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (copyToClipboard(`https://t.me/okgo_poker_bot/open_app?startapp=${_unref(store).getters.TELEGRAM_USER.user.id}`)))
        }, [
          _cache[8] || (_cache[8] = _createElementVNode("img", {
            src: _imports_2,
            class: "referral-link-img"
          }, null, -1)),
          _createElementVNode("p", _hoisted_16, "https://t.me/okgo_poker_bot/open_app?startapp=" + _toDisplayString(_unref(store).getters.TELEGRAM_USER.user.id), 1),
          _cache[9] || (_cache[9] = _createElementVNode("img", {
            src: _imports_3,
            class: "referral-link-copy no-image-animation"
          }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("p", _hoisted_18, _toDisplayString(_unref(t)(`friends.rules_label`)), 1),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("p", _hoisted_20, _toDisplayString(_unref(t)(`friends.rules_img_label`)), 1),
          _cache[10] || (_cache[10] = _createElementVNode("img", {
            src: _imports_4,
            class: "rules-img"
          }, null, -1))
        ])
      ]),
      _createElementVNode("p", _hoisted_21, _toDisplayString(_unref(t)(`friends.you_are_required`)), 1),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, [
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "rules-item-number" }, [
            _createElementVNode("p", { class: "rules-item-number-text" }, "1")
          ], -1)),
          _createElementVNode("p", _hoisted_24, _toDisplayString(_unref(t)(`friends.register`)), 1)
        ]),
        _createElementVNode("div", _hoisted_25, [
          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "rules-item-number" }, [
            _createElementVNode("p", { class: "rules-item-number-text" }, "2")
          ], -1)),
          _createElementVNode("p", _hoisted_26, _toDisplayString(_unref(t)(`friends.win_10_games_and`)), 1)
        ])
      ]),
      _createElementVNode("p", _hoisted_27, _toDisplayString(_unref(t)(`friends.a_friend_id_required_to`)), 1),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("div", _hoisted_29, [
          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "rules-item-number" }, [
            _createElementVNode("p", { class: "rules-item-number-text" }, "1")
          ], -1)),
          _createElementVNode("p", _hoisted_30, _toDisplayString(_unref(t)(`friends.follow_your_link`)), 1)
        ]),
        _createElementVNode("div", _hoisted_31, [
          _cache[14] || (_cache[14] = _createElementVNode("div", { class: "rules-item-number" }, [
            _createElementVNode("p", { class: "rules-item-number-text" }, "2")
          ], -1)),
          _createElementVNode("p", _hoisted_32, _toDisplayString(_unref(t)(`friends.register`)), 1)
        ]),
        _createElementVNode("div", _hoisted_33, [
          _cache[15] || (_cache[15] = _createElementVNode("div", { class: "rules-item-number" }, [
            _createElementVNode("p", { class: "rules-item-number-text" }, "3")
          ], -1)),
          _createElementVNode("p", _hoisted_34, _toDisplayString(_unref(t)(`friends.win_10_games`)), 1)
        ])
      ]),
      (_unref(store).getters.USER_REFERRALS.length > 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_35, _toDisplayString(_unref(t)(`friends.list_of_your_friends`)), 1))
        : _createCommentVNode("", true),
      (_unref(store).getters.USER_REFERRALS.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_36, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(store).getters.USER_REFERRALS, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "friends-list-item",
                key: index
              }, [
                _createElementVNode("div", _hoisted_37, [
                  _createElementVNode("img", {
                    src: item.referral_photo_url ? _unref(ApiService).url + item.referral_photo_url : require('@/assets/mock_user/user.svg'),
                    class: "friend-info-img"
                  }, null, 8, _hoisted_38),
                  _createElementVNode("p", _hoisted_39, _toDisplayString(item.referral_username ?? 'noname'), 1)
                ]),
                _createElementVNode("div", _hoisted_40, [
                  _cache[16] || (_cache[16] = _createElementVNode("img", {
                    src: _imports_1,
                    class: "friend-bonus-img"
                  }, null, -1)),
                  _createElementVNode("p", _hoisted_41, "+" + _toDisplayString(item.sum), 1)
                ])
              ]))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_42, [
        _createElementVNode("div", {
          class: "invite-friend-invite",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (openShare()))
        }, [
          _createElementVNode("p", null, _toDisplayString(_unref(t)(`friends.button_invete`)), 1),
          _cache[17] || (_cache[17] = _createElementVNode("img", { src: _imports_5 }, null, -1))
        ]),
        _createElementVNode("div", {
          class: "invite-friend-copy",
          onClick: _cache[2] || (_cache[2] = ($event: any) => {triggerHapticFeedback('soft'); copyToClipboard(`https://t.me/okgo_poker_bot/open_app?startapp=${_unref(store).getters.TELEGRAM_USER.user.id}`)})
        }, _cache[18] || (_cache[18] = [
          _createElementVNode("img", { src: _imports_6 }, null, -1)
        ]))
      ])
    ], 4)
  ], 64))
}
}

})