import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, vModelText as _vModelText, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo/money_logo_gc.svg'
import _imports_1 from '@/assets/tools/divider.svg'
import _imports_2 from '@/assets/logo/money_logo.svg'


const _hoisted_1 = { class: "wrapper" }
const _hoisted_2 = { class: "go-coins" }
const _hoisted_3 = { class: "first-line" }
const _hoisted_4 = { class: "text-wrapper" }
const _hoisted_5 = { class: "text" }
const _hoisted_6 = { class: "min-wrapper" }
const _hoisted_7 = { class: "min" }
const _hoisted_8 = { class: "second-line" }
const _hoisted_9 = { class: "red-coins" }
const _hoisted_10 = { class: "first-line" }
const _hoisted_11 = { class: "text-wrapper" }
const _hoisted_12 = { class: "text" }
const _hoisted_13 = { class: "second-line" }
const _hoisted_14 = { class: "amount" }

import {onMounted, onUnmounted, ref} from "vue";
import router from "@/router";
import {UserService} from "@/api/userService";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import store from "@/store";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangeView',
  setup(__props) {


const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/profile')
};

const userService = new UserService();
const inputData = ref("1");
const inputRef = ref<HTMLInputElement | null>(null);

onMounted(()=>{
  inputRef.value!.focus();

  store.commit('SET_FOCUSED', true)
  postEvent('web_app_setup_back_button', { is_visible: true});
  postEvent('web_app_setup_main_button', {
    is_visible: true,
    is_active: true,
    is_progress_visible: false,
    text: 'Обменять',
    color: '#32A1E6',
    text_color: '#FFFFFF'
  })
  on('back_button_pressed', listener);
  on('main_button_pressed', mainButtonPressed);
})

onUnmounted(() => {
  store.commit('SET_FOCUSED', false)
  postEvent('web_app_setup_back_button', { is_visible: false});
  postEvent('web_app_setup_main_button', {
    is_visible: false,
    is_active: false
  })
  off('back_button_pressed', listener);
  off('main_button_pressed', mainButtonPressed);
})


const mainButtonPressed: MiniAppsEventListener<'main_button_pressed'> = payload => {
  if (inputData.value <= store.getters.USER.balance) {
    userService.change(parseInt(inputData.value));
    router.push('/profile');
    postEvent('web_app_setup_main_button', {
    is_progress_visible: true,
  })
  }
}


function onInput(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value.replace(/\D/g, '');
  if (value === '') {
    value = '0'
  }
  else if (value.charAt(0) ===  '0') {
    input.value = value.substring(1)
  }
  inputData.value = value;
}


const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: "coin"
          }, null, -1)),
          _createElementVNode("div", _hoisted_5, "Вы платите " + _toDisplayString(_unref(t)(`change.you_deposit`)), 1)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(_unref(t)(`change.min`)) + " 1", 1),
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            src: _imports_0,
            alt: "",
            class: "coin-min"
          }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _withDirectives(_createElementVNode("input", {
          pattern: "\\\\d*",
          inputmode: "numeric",
          onInput: onInput,
          min: "0",
          maxlength: "8",
          style: _normalizeStyle({ width: inputData.value.toString().length + 'ch', color: inputData.value === '0' || inputData.value > _unref(store).getters.USER.balance ? 'red' : 'white' }),
          class: "balance-input",
          type: "number",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputData).value = $event)),
          ref_key: "inputRef",
          ref: inputRef
        }, null, 36), [
          [_vModelText, inputData.value]
        ]),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "coin-type" }, "GoCoin", -1))
      ])
    ]),
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "divider" }, [
      _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      })
    ], -1)),
    _createElementVNode("div", _hoisted_9, [
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            src: _imports_2,
            alt: "",
            class: "coin"
          }, null, -1)),
          _createElementVNode("div", _hoisted_12, _toDisplayString(_unref(t)(`change.you_take`)), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, _toDisplayString((parseInt(inputData.value) * 150)), 1),
        _cache[5] || (_cache[5] = _createElementVNode("div", { class: "coin-type" }, "RedCoin", -1))
      ])
    ])
  ]))
}
}

})