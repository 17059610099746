<script setup lang="ts">

import HeaderMini from "@/components/header/HeaderMini.vue";
import {computed, nextTick, onMounted, onUnmounted, ref, watch, inject} from "vue";
import store from "@/store";
import {useRoute} from "vue-router";
import {RoomService} from "@/api/roomService";
import Room from "@/models/room";
import { MiniAppsEventListener, off, on, postEvent } from "@telegram-apps/sdk";
import router from "@/router";
import Player from "@/models/player";
import Game from "@/models/game";
import GameCard from "@/models/gameCard";
import PlayerCard from "@/models/playerCard";
import {UserService} from "@/api/userService";
import BetSlider from "@/components/game/BetSlider.vue";
import GameActions from "@/components/game/GameActions.vue";
import PlayerItem from "@/components/game/PlayerItem.vue";
import TableCards from "@/components/game/TableCards.vue";
import Message from "@/models/message";
import NotificationModal from "@/components/game/NotificationModal.vue";
import EmojiPlayer from "@/components/game/EmojiPlayer.vue"
import GameUserPlayer from "@/components/game/GameUserAction.vue"
import ChatWrapper from "@/components/game/ChatWrapper.vue";
import ChipsPopup from "@/components/game/ChipsPopup.vue";
import SettingsRoom from '@/components/lists/rooms/SettingsPrivateRoom.vue'
import ExitPopup from '@/components/game/ExitRoomPop.vue'
import NewBbRoom from '@/components/game/NewBbNotification.vue'
import { Hand } from 'pokersolver';

// const playCheckSound = inject<() => void>("playCheckSound");

// const playSound = inject<() => void>("playSound");

const playNewCheck = inject<() => void>("playNewCheck");
const playNewPreflop = inject<() => void>("playNewPreflop");
const playNewFold = inject<() => void>("playNewFold");
const playNewMessage = inject<() => void>("playNewMessage");
const playNewRaise = inject<() => void>("playNewRaise");
const playNewSeat = inject<() => void>("playNewSeat");



const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

type Seat = {
  player: Player | null,
  seat: number
}

const messages = ref<Message[]>([]);

let selectedEmoji = ref<any>();
let selectedEmojiPlayerID = ref<number>();
let messageData = ref<any>();
let newMessage = ref<boolean>(false);

let coinType = useRoute().query.coin_type;

const room = ref<Room>();
const players = ref<Player[]>([]);
const currentPlayer = ref<Player | null>(null);
const seats = ref<Seat[]>([]);
const game = ref<Game | null>(null);
const gameCards = ref<GameCard[]>(getClearedGameCards());

const playerCards = ref<PlayerCard[]>([]);
const sliderValue = ref(1);
const isLoaded = ref(false);
const roomService = new RoomService();
const userService = new UserService();

const moveUser = ref<any | undefined>(undefined)
const winners = ref<any[] | undefined>(undefined);
const hand = ref<string>('');
const action = ref<any | undefined>(undefined);
const waitForJoin = ref(false);
const waitForAction = ref(false);

const newBlind = ref<number>(0);

const showChipsPopup = ref(false);

const notificationType = ref<"move" | "winner" | "new_msg" | null>(null);

const showChatComponent = ref(false); 
const middletGame = ref(false);

const openChangeRoomPop = ref(false);
const deleteRoom = ref(false);

const handleChatClick = () => {
  showChatComponent.value = true; 
};

const handleCloseChat = () => {
  showChatComponent.value = false; 
};

const handleCloseChipsPopup = () => {
  showChipsPopup.value = false;
  middletGame.value = false;
  timeToUp.value = false;
}

const handleOpenChipsPop = () => {
  showChipsPopup.value = true;
  middletGame.value = true;
}

const listener: MiniAppsEventListener<'back_button_pressed'> = () => {
  triggerHapticFeedback('soft');
  openExitpopup();
};

const joinRoom = () => {
  if (!showChipsPopup.value) {
    showChipsPopup.value = true;
  } else {
    waitForJoin.value = true;
    roomService.joinRoom(room.value!.id)
        .then(response => {
          waitForJoin.value = false;
          if (response.status === 403) {
            router.push('/rooms');
            return;
          }
          if (response.status === 402) {
            router.push('/rooms?withBalancePopup=true');
            return;
          }
          return response.json();
        })
        .then(data => {
          currentPlayer.value = data.player;
          if (!data["game"]) {
            players.value = data["players"];
            fillSeats();
            if (playNewSeat) playNewSeat();
          }
          if(data["room"]){
            room.value = data["room"];
          }
          isLoaded.value = true;
        });
        showChipsPopup.value = false;
    }
}

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
  store.commit('SET_FOCUSED', true);
  let small_blind_bet = useRoute().query.small_blind_bet;
  if (!small_blind_bet) {
    return
  }
  let id = useRoute().query.id?.toString();
  if (id) {
    roomService.joinPrivateRoom(Number(id))
      .then(response => {
        if (response.status == 402) {
          router.push({
            path: '/rooms',
            query: { withBalancePopup: 'true' }
          });
          return;
        }
        return response.json()
      })
      .then(data => {
        room.value = data["room"];
        sliderValue.value = room.value?.small_blind_bet ?? 0;
        listenWebSockets();
        players.value = data["players"];
        currentPlayer.value = data["player"];
        messages.value = data["messages"];
        fillSeats();
        game.value = data["game"] ?? null;
        if (data["cards"]) {
          for (let i = 0; i < data["cards"].length; i++) {
            gameCards.value.splice(i, 1, data["cards"][i]);
          }
        }
        if (!room.value?.is_available && !currentPlayer.value) {
          router.push('/rooms');
          return;
        }
        if (game.value && currentPlayer.value?.status == 'active' && room.value) {
          roomService.getPlayerCards(room.value.id)
              .then(response => response.json())
              .then(data => {
                playerCards.value = data;
                isLoaded.value = true;
              });
        } else {
          isLoaded.value = true;
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 402) {
        router.push({
          path: '/rooms',
          query: { withBalancePopup: 'true' }
        });
        return;
        }
        else console.log(error.message);
        
      });
  }
  else {
    const withPlayers = store.getters['WITH_PLAYERS'];
    if (withPlayers) {
      store.commit('SET_WITH_PLAYERS',false);
    }
    roomService.room(parseInt(small_blind_bet.toString()), getFirstAvailableSeat.value!, coinType as string, true)
      .then(response => {
        if (response.status == 402) {
          router.push({
            path: '/rooms',
            query: { withBalancePopup: 'true' }
          });
          return;
        }
        return response.json()
      })
      .then(data => {
        currentPlayer.value = data.player;
        room.value = data["room"];
        sliderValue.value = room.value?.small_blind_bet ?? 0
        listenWebSockets();
        players.value = data["players"];
        messages.value = data["messages"];
        fillSeats();
        game.value = data["game"] ?? null;
        if (data["cards"]) {
          for (let i = 0; i < data["cards"].length; i++) {
            gameCards.value.splice(i, 1, data["cards"][i]);
          }
        }
        if (!room.value?.is_available && !currentPlayer.value) {
          router.push('/rooms');
          return;
        }
        if (game.value && currentPlayer.value?.status == 'active' && room.value) {
          roomService.getPlayerCards(room.value.id)
              .then(response => response.json())
              .then(data => {
                playerCards.value = data;
                isLoaded.value = true;
              });
        } else {
          isLoaded.value = true;
        }
      })
      .catch((error) => {
        if (error.response?.status == 402) {
          router.push({
            path: '/rooms',
            query: { withBalancePopup: 'true' }
          });
          return;
        }
        else console.log(error.message);
      });
  }
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
  stopListeningWebSockets();
  store.commit('SET_FOCUSED', false)
});


function preflopAnim() {
  let preflopCards = [
    document.getElementById('preflop-1'), 
    document.getElementById('preflop-2'), 
    document.getElementById('preflop-3'), 
    document.getElementById('preflop-4'), 
    document.getElementById('preflop-5'), 
    document.getElementById('preflop-6'), 
    document.getElementById('preflop-7'), 
    document.getElementById('preflop-8'), 
    document.getElementById('preflop-9'), 
    document.getElementById('preflop-10'),
    document.getElementById('preflop-11'),
    document.getElementById('preflop-12'),   
    document.getElementById('preflop-13'), 
    document.getElementById('preflop-14'), 
    document.getElementById('preflop-15'), 
    document.getElementById('preflop-16'), 
  ];

  let baseCard = document.getElementById('preflop-base');

  nextTick(() => {
    const activePlayers = document.getElementsByClassName('proflop');

    if (activePlayers && baseCard) {
      for (let index = 0; index < activePlayers.length; index++) {
        const element = activePlayers[index] as HTMLElement;

        // Установить карту в центр стола
        const centerX = baseCard.offsetLeft + baseCard.offsetWidth / 2;
        const centerY = baseCard.offsetTop + baseCard.offsetHeight / 2;

        const card1 = preflopCards[index * 2] as HTMLElement;
        const card2 = preflopCards[index * 2 + 1] as HTMLElement;

        positionCardAtCenter(card1, centerX, centerY);
        positionCardAtCenter(card2, centerX, centerY);

        // Карты остаются на месте 300мс
        setTimeout(() => {
          // Анимация перемещения первой карты
          animateCardToPlayer(card1, element);
          setTimeout(() => {
            // if (playSound) playSound();
            if (playNewPreflop) playNewPreflop();

            // Анимация перемещения второй карты
            animateCardToPlayer(card2, element);
          }, 50); // Задержка между первой и второй картой
        }, 300); // Пауза на 300мс
      }
    }
  });
}

function positionCardAtCenter(card: HTMLElement, centerX: number, centerY: number) {
  card.style.position = 'absolute';
  card.style.left = `${centerX - card.offsetWidth / 2}px`;
  card.style.top = `${centerY - card.offsetHeight / 2}px`;
  card.style.opacity = '1'; // Делает карту видимой
  card.style.transform = 'rotate(0deg) scale(1)'; // Сброс начального состояния
}

function animateCardToPlayer(card: HTMLElement, playerElement: HTMLElement) {
  const targetCenter = getElementCenter(playerElement);
  const cardCenter = getElementCenter(card);

  const deltaX = targetCenter.x - cardCenter.x;
  const deltaY = targetCenter.y - cardCenter.y;
  const angle = Math.atan2(deltaY, deltaX) * (180 / Math.PI) + 90;

  // Устанавливаем анимацию перемещения, вращения и масштаба
  card.style.transition = 'transform 0.7s ease, opacity 0.7s ease';
  card.style.transform = `translate(${deltaX}px, ${deltaY}px) rotate(${angle}deg) scale(0.3)`;

  // Оставляем карту видимой после перемещения
  setTimeout(() => {
    card.style.opacity = '0';
  }, 1000); // Длительность анимации
}





function getElementCenter(element: HTMLElement): { x: number, y: number } {
  const rect = element.getBoundingClientRect();
  return {
    x: rect.left + rect.width / 2,
    y: rect.top + rect.height / 2,
  };
}

// WEBSOCKETS
const currentCase = ref<string | null>(null);

function stopListeningWebSockets() {
  if (room.value?.id) {
    (window as any).Echo.leave('game-updated-test.' + room.value!.id);
  }
}

function listenWebSockets() {
  (window as any).Echo.channel('game-updated-test.' + room.value!.id).listen('NotifyRoomTest', (e: any) => {

    if (e.message.action == 'roomDeleted'){
      stopListeningWebSockets();
      router.push('/rooms');
      return;
    }
    if (e.room.id != room.value!.id) {
      return;
    }
    console.log(e.message);
    switch (e.message.action) {
      case 'players_update':
        currentCase.value = 'players_update';
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        fillSeats();
        room.value = e.room;
        break;
      case 'new_game':
        currentCase.value = 'new_game';
        foldCards.value = false;
        game.value = e.message.game;
        players.value.forEach((player: Player) => {
          if (currentPlayer.value?.user_id == player.id
              && player.game_deposit < room.value!.big_blind_bet
              && store.getters.USER.balance < room.value!.game_deposit) {
            router.push('/rooms?withBalancePopup=true')
          }
        });
        if (room.value){
          if (room.value.id == e.message.game.room_id){
            console.log("room_id = "+room.value.id);
            foldCards.value = false;
            getPlayerCards(room.value.id);
          }
        }
        gameCards.value = getClearedGameCards();
        waitForAction.value = false;
        fillSeats();
        break;
      case 'roomUpdated':
        newBlind.value = e.message.newBlind*2;
        roomService.getPrivateRoom(room.value!.id)
          .then(response => response.json())
          .then(data => {
            room.value = data;
          })
        openChangeRoomPop.value = true;
        break;  
        case 'roomWillDelet':
          console.log(e.message);
          deleteRoom.value = true;
          openChangeRoomPop.value = true;
          openSettingsConmponent.value = false;
          //Твой код, который покажет попап, что типо после этой игры комната удалится
          break;
      case 'show_down':
        moveUser.value = undefined;
        currentCase.value = 'show_down';
        players.value = e.message.players;
        if (game.value){
          game.value.current_player_id=undefined;
        }
        hand.value = e.message.winnerHand ?? 'нет';
        handlePlayerCards(e.message.playerCards);
        winners.value = undefined;
        winners.value = e.message.winners.map((element: any) => {
          return {
            user_id: element.user_id,
            username: element.user_username,
            sum: element.sum,
            handRanking: element.handRanking,
            created_at: element.created_at
          }
        });
        notificationType.value = 'winner';
        break;
      case 'preflop':
        winners.value = undefined;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        fillSeats();
        preflopAnim();
        break;
      case 'finish':
        currentCase.value = 'finish';
        gameCards.value = getClearedGameCards();
        playerCards.value = [];
        game.value = null;
        moveUser.value = undefined;
        hand.value = e.message.winnerHand ?? 'нет';
        waitForAction.value = false;
        break;
      case 'game_stopped':
        currentCase.value = 'game_stopped';
        game.value = null;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        fillSeats();
        playerCards.value = [];
        if (!currentPlayer.value) {
          router.push('/rooms');
        }
        waitForAction.value = false;
        break;
      case 'check':
        currentCase.value = 'check';
        game.value = e.message.game;
        notificationType.value = 'move';
        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'чек',
        }
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        if (playNewCheck) {
          playNewCheck(); 
        }
        fillSeats();
        
        waitForAction.value = false;
        break;
      case 'bet':
        currentCase.value = 'bet';
        game.value = e.message.game;
        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'бет',
          action_sum: e.message.bet,
        }
        notificationType.value = 'move';
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        fillSeats();
        waitForAction.value = false;
        break;
      case 'raise':
        currentCase.value = 'raise';
        game.value = e.message.game;
        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'рейз',
          action_sum: e.message.bet,
        }
        notificationType.value = 'move';
        if (playNewRaise) playNewRaise();
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        fillSeats();

        waitForAction.value = false;
        break;
      case 'pre_flop_all_in':
        currentCase.value = 'pre_flop_all_in';
        game.value = e.message.game;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        gameCards.value.splice(0, 1, e.message.cards[0]);
        gameCards.value.splice(1, 1, e.message.cards[1]);
        gameCards.value.splice(2, 1, e.message.cards[2]);
        gameCards.value.splice(3, 1, e.message.cards[3]);
        gameCards.value.splice(4, 1, e.message.cards[4]);
        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'ва-банк',
          action_sum: e.message.bet,
        }
        notificationType.value = 'move';
        fillSeats();

        waitForAction.value = false;
        break;
      case 'post_flop_all_in':
        currentCase.value = 'post_flop_all_in';
        game.value = e.message.game;
        players.value = e.message.players;
        gameCards.value.splice(0, 1, e.message.cards[0]);
        gameCards.value.splice(1, 1, e.message.cards[1]);
        gameCards.value.splice(2, 1, e.message.cards[2]);
        gameCards.value.splice(3, 1, e.message.cards[3]);
        gameCards.value.splice(4, 1, e.message.cards[4]);
        // if (players.value.find(item => item.id === currentPlayer.value!.id)) {
        //   currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        // }

        action.value = undefined;
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'ва-банк',
          action_sum: e.message.bet,
        }
        notificationType.value = 'move';
        fillSeats();

        waitForAction.value = false;
        break;
      case 'fold':
        currentCase.value = 'fold';
        game.value = e.message.game;
        action.value = undefined;
        notificationType.value = 'move';
        if (playNewFold) playNewFold();
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'фолд',
        }
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        fillSeats();

        waitForAction.value = false;
        break;
      case 'call':
        currentCase.value = 'call';
        game.value = e.message.game;

        action.value = undefined;
        notificationType.value = 'move';
        action.value = {
          user_id: e.message.user_id,
          username: e.message.username,
          action_name: 'колл'
        }
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        fillSeats();

        waitForAction.value = false;
        break;
      case 'post_flop':
        currentCase.value = 'post_flop';
        game.value = e.message.game;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        gameCards.value.splice(0, 1, e.message.cards[0]);
        gameCards.value.splice(1, 1, e.message.cards[1]);
        gameCards.value.splice(2, 1, e.message.cards[2]);
        fillSeats();
        break;
      case 'post_turn':
        currentCase.value = 'post_turn';
        game.value = e.message.game;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        gameCards.value.splice(3, 1, e.message.cards[0]);
        fillSeats();
        break;
      case 'post_river':
      currentCase.value = 'post_river';
        game.value = e.message.game;
        players.value = e.message.players;
        if (players.value.find(item => item.id === currentPlayer.value!.id)) {
          currentPlayer.value = players.value.find(item => item.id === currentPlayer.value!.id) || null;
        }
        gameCards.value.splice(4, 1, e.message.cards[0]);
        fillSeats();
        break;
      case 'new_message':{
        if (e.message.messageData.type ==='message'){
          messageData = e.message.messageData;
          nextTick(() =>{
            notificationType.value = 'new_msg';
            if (playNewMessage) playNewMessage();
          })
          const roomID = room.value?.id;
          if (roomID){
            roomService.messages(roomID)
            .then(response => response.json())
              .then(data => {
                messages.value = data;
              });
          }
        }
        else {
          selectedEmoji.value = e.message.messageData.body;
          selectedEmojiPlayerID.value = e.message.messageData.user_id;
        }
        break;
      }
    }
  });
}

const dealerSeatNumber = ref<number | null>(null); 

function fillSeats() {
  seats.value = [];
  for (let i = 0; i < room.value!.max_players; i++) {
    seats.value.push({
      'player': getPlayerBySeat(i + 1) ?? null,
      'seat': i + 1
    });
  }
  if (game.value && game.value.dealer_id) {
    const dealerId = game.value.dealer_id;
    const dealerSeat = seats.value.find(seat => seat.player?.id === dealerId);
    if (dealerSeat) {
      dealerSeatNumber.value = dealerSeat.seat; 
    } else {
      dealerSeatNumber.value = null;  
    }
  } else {
    dealerSeatNumber.value = null;
  }
}

function getClearedGameCards() {
  return [
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    },
    {
      id: 0,
      created_at: "",
      updated_at: "",
      game_id: 0,
      card_id: 0,
      card_rank: "",
      card_suit: "",
      is_opened: false,
    }
  ];
}

// GETTERS

function getPlayerBySeat(seat: number) {
  return players.value.find((player: Player) => player && player.seat == seat);
}

function getPlayerCards(roomID: number) {
  roomService.getPlayerCards(roomID)
      .then(response => response.json())
      .then(data => {
        playerCards.value = data;
      });
}

// SETTERS

const getMaxBet = computed(() => {
  return currentPlayer.value!.game_deposit ?? room.value!.big_blind_bet;
});

const getMaxRaise = computed(() => {
  return currentPlayer.value!.game_deposit ?? room.value!.big_blind_bet;
});

function setSliderValue(value: any) {
  showBtnRaise.value = false;
  if (value < minSliderValue.value) {
    return;
  }
  if (value > getMaxBet.value) {
      return;
  }
  else {
    if (value > getMaxRaise.value) {
      return;
    }
  }
  if (value == currentPlayer.value?.game_deposit){
    sliderValue.value = currentPlayer.value!.game_deposit;
  } else {
    sliderValue.value = value;
  }
  return sliderValue.value;
}


// GAME LOGIC

const check = async () => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  await roomService.check(room.value!.id);
}

const call = async () => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  await roomService.call(room.value!.id)
}

const foldCards = ref(false)

const fold = async () => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  foldCards.value = true;
  await roomService.fold(room.value!.id);
}

const bet = async (bet: number) => {
  game.value!.current_player_id = undefined;
  waitForAction.value = true;
  showBtnRaise.value = false;
  await roomService.bet(bet, room.value!.id)
}

const raise = async (raise: number) => {
  waitForAction.value = true;
  showBtnRaise.value = false;
  game.value!.current_player_id = undefined;
  if ((currentPlayer.value!.game_deposit) >= Math.max(...players.value.map(x => x.current_round_bet)) && (currentPlayer.value!.game_deposit) <= Math.max(...players.value.map(x => x.current_round_bet))*2){
    raise = currentPlayer.value!.game_deposit;
  }
  await roomService.raise(raise, room.value!.id)
}


function updateUser() {
  userService.get()
      .then((response: any) => response.json())
      .then((data: any) => {
        store.commit('SET_USER', data);
      });
}

// function resetTimer() {
//   timer.value = 30;
// }

// COMPUTED

const getSortedPlayersForDisplay = computed(() => {
  const currentUserId = store.getters.USER.id;
  
  const currentUserIndex = seats.value.findIndex(seat => seat.player?.user_id === currentUserId);
  
  if (currentUserIndex === -1) {
    return seats.value;
  }
  
  const beforeCurrentUser = seats.value.slice(0, currentUserIndex);
  const afterCurrentUser = seats.value.slice(currentUserIndex + 1);

  return [...afterCurrentUser, seats.value[currentUserIndex], ...beforeCurrentUser];
});

const getFilteredPlayersForDisplay = computed(() => {
  const currentUserId = store.getters.USER.id;
  return getSortedPlayersForDisplay.value.filter(seat => seat.player?.user_id !== currentUserId);
});

const minSliderValue = computed(() => {
  if (!room.value) {
    return 0;
  }
  if (!Array.isArray(players.value)) {
    console.error('players.value должен быть массивом', players.value);
    return room.value.small_blind_bet;  
  }

  if (currentPlayer.value!.current_round_bet >= Math.max(...players.value.map(x => x.current_round_bet))) {
    return Math.max(...players.value.map(x => x.current_round_bet), room.value.small_blind_bet) ?? room.value.small_blind_bet;
  }
  return room.value.small_blind_bet;
});

const getHand = computed(() => {
  if (playerCards.value.length === 0) {
    return { combination: '', cards: [] };
  }

  // Преобразование карт игрока
  const filteredPlayerCards = playerCards.value.map((playerCard) => {
    let rank = playerCard.card_rank;
    let suit = playerCard.card_suit;

    if (rank === '10') {
      rank = 'T';
    }
    return rank + suit.charAt(0).toLowerCase();
  });

  // Преобразование открытых карт на столе
  const filteredGameCards = gameCards.value
    .filter((gameCard) => gameCard.is_opened)
    .map((gameCard) => {
      let rank = gameCard.card_rank;
      let suit = gameCard.card_suit;
      if (rank === '10') {
        rank = 'T';
      }
      return rank + suit.charAt(0).toLowerCase();
    });

  // console.log("filteredPlayerCards:", filteredPlayerCards)
  // console.log("filteredGameCards", filteredGameCards)

  // Объединение карт игрока и стола
  const cards = filteredPlayerCards.concat(filteredGameCards);
  // console.log("cards:", cards)

  // Удаление дубликатов
  const cardsArr = Array.from(new Set(cards));
  // console.log("cardsArr:", cardsArr)

  // Проверка формата карт
  if (cardsArr.some(card => !/^[2-9TJQKA][shdc]$/.test(card))) {
    console.error('Invalid card format in cardsArr:', cardsArr);
    return { combination: 'Unknown', cards: [] };
  }

  // Решение комбинации с обработкой ошибок
  let hand;
  try {
    hand = Hand.solve(cardsArr);
  } catch (error) {
    console.error('Error solving hand:', error);
    return { combination: 'Unknown', cards: [] };
  }

  // Перевод названия комбинации
  const translate = {
    'Royal Flush': 'Роял Флеш',
    'Straight Flush': 'Стрит Флеш',
    'Four of a Kind': 'Каре',
    'Full House': 'Фулл Хаус',
    'Flush': 'Флеш',
    'Straight': 'Стрит',
    'Three of a Kind': 'Тройка',
    'Two Pair': 'Две Пары',
    'Pair': 'Пара',
    'High Card': 'Старшая Карта',
  };

  const combinationName = translate[hand.name] || 'Unknown';

  // Получение карт лучшей комбинации
  const getBestCombinationCards = (hand) => {
  try {
    const cards = hand.cards;

    // Группируем карты по значению и масти
    const groupedByValue = cards.reduce((acc, card) => {
      acc[card.value] = acc[card.value] || [];
      acc[card.value].push(card);
      return acc;
    }, {});

    const groupedBySuit = cards.reduce((acc, card) => {
      acc[card.suit] = acc[card.suit] || [];
      acc[card.suit].push(card);
      return acc;
    }, {});

    // Упорядочиваем карты по рангу
    const sortedCards = [...cards].sort((a, b) => a.rank - b.rank);

    // Функция поиска стрита
    const findStraight = (cards) => {
      const uniqueRanks = [...new Set(cards.map((card) => card.rank))];
      for (let i = 0; i <= uniqueRanks.length - 5; i++) {
        const straightSlice = uniqueRanks.slice(i, i + 5);
        if (straightSlice[4] - straightSlice[0] === 4) {
          return cards.filter((card) => straightSlice.includes(card.rank));
        }
      }
      return null;
    };

    // Проверяем комбинации

    // 1. Роял флеш
    const flushSuit = Object.keys(groupedBySuit).find((suit) => groupedBySuit[suit].length >= 5);
    if (flushSuit) {
      const flushCards = groupedBySuit[flushSuit].sort((a, b) => b.rank - a.rank);
      const straightFlush = findStraight(flushCards);
      if (straightFlush && straightFlush[0].rank === 14) { // Старшая карта "A"
        // console.log("Combination: Royal Flush");
        return straightFlush;
      }
    }

    // 2. Стрит флеш
    if (flushSuit) {
      const flushCards = groupedBySuit[flushSuit].sort((a, b) => b.rank - a.rank);
      const straightFlush = findStraight(flushCards);
      if (straightFlush) {
        // console.log("Combination: Straight Flush");
        return straightFlush;
      }
    }

    // 3. Каре
    const fourOfAKind = Object.values(groupedByValue).find((group) => group.length === 4);
    if (fourOfAKind) {
      // console.log("Combination: Four of a Kind");
      return [...fourOfAKind];
    }
    // 4. Фулл хаус
    const threeOfAKind = Object.values(groupedByValue).find((group) => group.length === 3);
    const anotherPair = Object.values(groupedByValue).find(
      (group) => group.length >= 2 && group[0].value !== threeOfAKind?.[0]?.value
    );
    if (threeOfAKind && anotherPair) {
      // console.log("Combination: Full House");
      return [...threeOfAKind, ...anotherPair.slice(0, 2)];
    }

    // 5. Флеш
    if (flushSuit) {
      const flushCards = groupedBySuit[flushSuit].sort((a, b) => b.rank - a.rank).slice(0, 5);
      // console.log("Combination: Flush");
      return flushCards;
    }

    // 6. Стрит
    const straight = findStraight(sortedCards);
    if (straight) {
      // console.log("Combination: Straight");
      return straight;
    }

    // 7. Тройка
    if (threeOfAKind) {
      // console.log("Combination: Three of a Kind");
      return [...threeOfAKind];
    }

    // 8. Две пары
    const pairs = Object.values(groupedByValue).filter((group) => group.length === 2);
    if (pairs.length >= 2) {
      const highestPairs = pairs.sort((a, b) => b[0].rank - a[0].rank).slice(0, 2);
      // console.log("Combination: Two Pair");
      return [...highestPairs.flat()];
    }

    // 9. Пара
    if (pairs.length === 1) {
      const pair = pairs[0];
      // console.log("Combination: Pair");
      return [...pair];
    }

    // 10. Старшая карта
    if (sortedCards.length > 0) {
      const highCard = sortedCards[sortedCards.length - 1]; // Берем только одну старшую карту
      // console.log("Combination: High Card");
      // console.log("Highest card:", highCard); 
      return [highCard]; // Возвращаем комбинацию, состоящую только из одной карты
    }

  } catch (error) {
    console.error(`Error determining best combination cards:`, error);
    return [];
  }
};

// Вспомогательная функция для поиска стрита
const findStraight = (cards) => {
  let straight = [];
  for (let i = 0; i < cards.length; i++) {
    if (
      straight.length === 0 ||
      cards[i].rank === straight[straight.length - 1].rank - 1
    ) {
      straight.push(cards[i]);
      if (straight.length === 5) {
        return straight;
      }
    } else if (cards[i].rank !== straight[straight.length - 1].rank) {
      straight = [cards[i]];
    }
  }

  // Специальный случай для "Колеса" (5-4-3-2-A)
  const wheel = [14, 5, 4, 3, 2]; // Ранги для "Колеса"
  if (cards.some((card) => card.rank === 14)) { // Есть ли туз
    const wheelCards = cards.filter((card) => wheel.includes(card.rank));
    if (wheelCards.length === 5) {
      return wheelCards.sort((a, b) => b.rank - a.rank);
    }
  }

  return null;
};

// Пример использования
const bestCombinationCards = getBestCombinationCards(hand);
// console.log(`Best combination cards: ${JSON.stringify(bestCombinationCards)}`);

  // Удаляем дубли, если они есть (на случай ошибок в данных)
  const uniqueBestCombinationCards = Array.from(
    new Set(bestCombinationCards.map((card) => JSON.stringify(card)))
  ).map((card) => JSON.parse(card));

  // console.log(`Best combination cards: ${JSON.stringify(uniqueBestCombinationCards)}`);

  // Преобразование формата карт
  const originalFormatCards = bestCombinationCards.map((card) => {
    const rank = card.value === 'T' ? '10' : card.value;
    const suitMap = {
      's': 'spades',
      'h': 'hearts',
      'd': 'diamonds',
      'c': 'clubs'
    };
    const suit = suitMap[card.suit.toLowerCase()];
    return { card_rank: rank, card_suit: suit };
  });

  // console.log(originalFormatCards)

  return {
    combination: combinationName,
    cards: originalFormatCards,
  };
});

const getFirstAvailableSeat = computed(() => {
  return seats.value.find(seat => !seat.player)?.seat ?? null;
});

// WATCHERS

watch(minSliderValue, (newMin: number) => {
  if (sliderValue.value < newMin) {
    sliderValue.value = newMin;
  }
});

watch(() => game.value?.current_player_id, (newVal) => {
  const currentPlayer = players.value.find((el) => el.id == newVal);
  if (newVal && currentPlayer) {
    notificationType.value = 'move';
    moveUser.value = undefined;
    moveUser.value = {
      user_id: currentPlayer.user_id,
      username: currentPlayer.user_username,
      time: new Date().toString()
    }
  }
});


const showGameUserPlayer = computed(() => {
  if (!players.value.some(item => item.id === currentPlayer.value!.id)) handleCloseChat();
  return (players.value.some(item => item.id === currentPlayer.value!.id)) || waitForJoin.value;
});


const canRaise = computed(() => {
  if(game.value && game.value.current_player_id === currentPlayer.value?.id) handleCloseChat();
  return game.value && game.value.current_player_id === currentPlayer.value?.id;
});

const isBalanceEnoughForSlider = computed(() => {
  
  const myPlayer = currentPlayer.value;
  const currentMaxBet = maxBet.value;

  const result = currentMaxBet <= myPlayer!.game_deposit ;

  return result;
});


const shouldRenderBetSlider = computed(() => {
  return canRaise.value && isBalanceEnoughForSlider.value;
});

const maxBet =  computed(() => {

  let result = Math.max(...players.value.map(player => player.current_round_bet))
  if (result === 0) {
    return room.value!.big_blind_bet;
  }
  return Math.max(...players.value.map(player => player.current_round_bet))*2;
});


let playerCardsState = ref<PlayerCard[]>([]);

function handlePlayerCards(playerCards: any) {
  playerCardsState = playerCards;
}

const openSettingsConmponent = ref(false);

const showSettings = () => {
  openSettingsConmponent.value = true;
}

const handleCloseSettingsPopup = () => {
  openSettingsConmponent.value = false;
}

const openExitPop = ref(false);

const openExitpopup = () => {
  if (players.value.find(item => item.id === currentPlayer.value!.id)) {
    openExitPop.value = true;
  }
  else {
    openExitPop.value = false;
    if (room.value)
    {
      roomService.leaveRoom(room.value.id);
      router.push('/rooms');
    }
    else router.push('/rooms');
  }
}

const closeExitpop = () => {
  openExitPop.value = false;
}

async function editRoom() {
  setTimeout(async () => {
    openChangeRoomPop.value = true;
  }, 500);
}

const closeChangeRoomPop = () => {
  openChangeRoomPop.value = false;
}

const timeToUp = ref(false);

// watch(() => currentPlayer.value?.game_deposit, () => {
//   if (game.value) {
//     if (currentPlayer.value!.game_deposit <= room.value!.big_blind_bet * 5) {
//       timeToUp.value = true;
//       showChipsPopup.value = true;
//     }
//   }
// });

const addMyDeposit = ref(false);
const result = ref(0);

function handleUpdateValues(data: { result: number; addMyDeposit: boolean }) {
  result.value = data.result;
  addMyDeposit.value = data.addMyDeposit;
}

watch(() => currentCase.value, () => {
  if (currentCase.value === 'show_down' && addMyDeposit.value) {
    roomService.setDeposit(result.value, currentPlayer.value!.user_id, room.value!.id)
      .then(response => {
        if (response.status === 402) {
          return;
        }
      });
  }
});

const currentUserId = store.getters.USER.id;

const exitShowDown = ref(false);

const exithandle_showDown = () => {
  exitShowDown.value = true;
}

watch(() => currentCase.value, (newCase) => {
  if (newCase === 'finish' && exitShowDown.value) {
    if (room.value)
    {
      roomService.leaveRoom(room.value.id);
      router.push('/rooms');
    }
    else router.push('/rooms');
  }
});


const autoFoldCheck = ref(false);
const autoCall = ref(false);

const autoFoldCheck_handle = () => {
  autoFoldCheck.value = true;
};

const autoCall_handle = () => {
  autoCall.value = true;
};

watch(() => game.value?.current_player_id, () => {
  if (game.value?.current_player_id == currentPlayer.value!.id) {
    if (autoFoldCheck.value){
      if (Math.max(...players.value.map(x => x.current_round_bet)) == 0) {
        check();
        autoFoldCheck.value = false;
      }
      else {
        fold();
        autoFoldCheck.value = false;
      }
    }
    if (autoCall.value){
      call();
      autoCall.value = false;
    }
  }
});

const showBtnRaise = ref(false);

const showHandleBtnRaise = () => {
  showBtnRaise.value = true;
};

const hideHandleBtnRaise = () => {
  showBtnRaise.value = false;
};

</script>

<template>
  <KeepAlive>
  <div>
    <header-mini 
    style="z-index: 10002;"
    @openSettings="showSettings"
    @openBalance="handleOpenChipsPop"
    :room="room!"
    :player="currentPlayer!"
    :game="game!"
    />
    <div class="game-g-wrapper" :style="{'margin-top': tg.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + (var(--tg-safe-area-inset-top)/2))': 0}">
      <notification-modal v-if="notificationType== 'new_msg'" 
        :notification-type="'new_msg'"
        :message-data="messageData"
      /> 
      <div class="game-wrapper" v-if="room && players && isLoaded" :key="game?.id">
        <new-bb-room v-if="openChangeRoomPop"
        @close="closeChangeRoomPop"
        :room="room"
        :myroomBigBlindBet="newBlind!"
        :show-player="showGameUserPlayer"
        :deleteRoom = "deleteRoom"
        />
        <div class="game-table-wrapper">
          <div class="first-players-line">
            <div v-for="seat in getFilteredPlayersForDisplay.slice(0, room.max_players / 2)" :key="seat.seat">
              <player-item 
                :seat="seat"
                :game="game!"
                :dealer-seat="dealerSeatNumber"
                :is-second-line="false"
                :player-cards="playerCardsState"
                :current-case="currentCase"
                :selected-emoji="selectedEmoji"
                :selectedEmojiPlayerID="selectedEmojiPlayerID"
                :winners="winners"
                :room="room!"
                :players="players"
                :cerruntPlayer="currentPlayer!"
                />
            </div>
          </div>
          <table-cards
            :first-flop-card="gameCards[0]"
            :second-flop-card="gameCards[1]"
            :third-flop-card="gameCards[2]"
            :tern-card="gameCards[3]"
            :river-card="gameCards[4]"
            :game="game!"
            :player="currentPlayer!"
            :players="players"
            :players-count="room.max_players"
            :hand="getHand"
            :nahdNotif="hand"
            :notification-type="moveUser ? 'move' : 'winner'"
            :move-user="moveUser" 
            :winners="winners"
            :action="action"
          />  
          <div class="second-players-line">
            <div v-if="showGameUserPlayer" class="opac">
              <player-item
              :itsmyplayer="true"
              />
            </div>
            <div v-for="(seat, index) in getFilteredPlayersForDisplay.slice(room.max_players / 2).reverse()" :key="seat.seat">
              <player-item 
                :seat="seat" 
                :game="game!"
                :index="index"
                :dealer-seat="dealerSeatNumber"
                :is-second-line="true"
                :player-cards="playerCardsState"
                :current-case="currentCase"
                :selected-emoji="selectedEmoji"
                :selectedEmojiPlayerID="selectedEmojiPlayerID"
                :winners="winners"
                :room="room!"
                :players="players"
                :cerruntPlayer="currentPlayer!"
              />
            </div>
          </div>
        </div>
          <div class="elements-player">
            <div class="elements-player-lft">
              <div class="user-player-action" >
                <game-user-player
                  :class="{ 'proflop': showGameUserPlayer}"
                  :player="currentPlayer!"
                  :game="game!"
                  :wait-for-join="waitForJoin"
                  :selected-emoji="selectedEmoji"
                  :player-cards="playerCards"
                  :dealer-seat="dealerSeatNumber"
                  :show-player="showGameUserPlayer"
                  :selectedEmojiPlayerID="selectedEmojiPlayerID!"
                  :current-case="currentCase"
                  :winners="winners"
                  />
              </div> 
              <div class="back"></div>
              <game-actions
                @bet="() => bet(sliderValue)"
                @raise="() => raise(sliderValue)"
                @bet_btn="(value) => bet(value)"
                @raise_btn="(value) => raise(value)"
                @call="() => call()"
                @check="() => check()"
                @fold="() => fold()"
                @autoFoldCheck_handle = "autoFoldCheck_handle"
                @autoCall_handle = "autoCall_handle"
                @showHandleBtnRaise="showHandleBtnRaise"
                @hideHandleBtnRaise="hideHandleBtnRaise"
                :player-cards="playerCards"
                :game="game!"
                :room="room"
                :slider-value="sliderValue"
                :players="players"
                :player="currentPlayer!"
                :hand="getHand"
                :key="playerCards.length"
                :join-room="joinRoom"
                :wait-for-join="waitForJoin"
                :wait-for-action="waitForAction"
                :selectedEmojiPlayerID="selectedEmojiPlayerID!"
                :selected-emoji="selectedEmoji"
                :show-player="showGameUserPlayer"
                :current-case="currentCase"
                :winners="winners"
                :min="maxBet"
                :foldCards="foldCards"
                :autoFoldCheck ="autoFoldCheck"
                :autoCall="autoCall"
                :showBtnRaise="showBtnRaise"
                />
            </div>
            <div class="elements-player-rght" :style="{ opacity: showGameUserPlayer ? 1 : 0.4 }">
              <bet-slider 
                @update-slider-value="(value) => setSliderValue(value)"
                :game="game!" 
                :players="players" 
                :room="room"
                :player="currentPlayer!" 
                :min="maxBet"
                :value="sliderValue"/>
              <!-- <emoji-player v-else-if="isLoaded" 
                @chatClicked="handleChatClick"
                :room="room"
                :showPlayer="showGameUserPlayer"
                :message-data="messageData"
              /> -->
              <chat-wrapper v-if="showChatComponent"
                @closeChat="handleCloseChat"
                :myPlayer="currentPlayer!"
                :room="room"
                :messages="messages"
              />
            </div>
          </div>
      </div>
    </div>
  </div>
  <chips-popup v-if="showChipsPopup"
  @close="handleCloseChipsPopup"
  @updateValues="handleUpdateValues"
  :room="room!"
  :join-room="joinRoom"
  :player="currentPlayer!"
  :middletGame="middletGame"
  :timeToUp="timeToUp"
  :current-case="currentCase"
  />
  <settings-room v-if="openSettingsConmponent"
  @close="handleCloseSettingsPopup"
  @editRoom="editRoom"
  :currentPlayer = "currentPlayer"
  :room="room!"
  />  
  <exit-popup v-if="openExitPop"
  @close="closeExitpop"
  @exithandle_showDown="exithandle_showDown"
  :room="room!"
  :player="currentPlayer!"
  :current-case="currentCase"
  />
</KeepAlive>
</template>

<style scoped>

.back {
  box-shadow: 0 6px 10px rgba(0,0,0,.45);
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), rgba(0, 0, 0, 0.3);
  border-top-right-radius: 147px;
  border-bottom-right-radius: 147px;
  padding-bottom: 42px;
  /* display: flex; */
  position: absolute;
  top: 0;
  left: -100vw;
  right: 0;
  padding-bottom: 11.85vh;
  height: 74%;
}

.elements-player-lft {
  /* box-shadow: 0 6px 10px rgba(0,0,0,.45);
  background: linear-gradient(45deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), rgba(0, 0, 0, 0.3);
  border-top-right-radius: 147px;
  border-bottom-right-radius: 147px;
  padding-bottom: 42px; */
  position: relative;
}


.opac {
  opacity: 0;
}

.elements-player-rght {
  z-index: 1000;
  margin-left: 11.28vw;
  position: relative;
  height: 100%;
  align-items: center;
}

.elements-player {
  /* display: flex;
  margin-left: -25px;
  margin-top: 54px; */

  z-index: 102;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
}

.user-player-action {
  display: flex;
  margin-top: -120px;
  z-index: 11;
  position: relative;
  width: 30%;
}

.game-table-wrapper {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  padding-top: 32px;
  position: fixed;
  right: 4%;
  left: 4%;
  bottom: 38.6vh;
}

.first-players-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 10px;
  background: #00000030;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  padding-bottom: 91px;
  max-width: 349px;
  width: 81.16vw;
}

.second-players-line {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background: #00000030;
  max-width: 349px;
  width: 81.16vw;
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-top: -107px;
  max-height: 159px;
  height: 159px;
  display: flex;
  align-items: end;
}

/* .second-players-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8vw;
  padding-bottom: 15px;
  padding-top: 120px;
  margin-top: -105px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  background: rgba(181, 152, 239, 0.2);
  max-width: 349px;
  width: 75.58vw;
  padding-right: 5.58vw;
} */

.game-wrapper {
  position: relative;
}
</style>