import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/tools/close-modal.svg'


const _hoisted_1 = {
  key: 0,
  class: "delete-modal-wrapper"
}
const _hoisted_2 = { class: "delete-modal" }
const _hoisted_3 = { class: "warning-main" }
const _hoisted_4 = { class: "warning-full" }

import store from "@/store";
import {UserService} from "@/api/userService";
import router from "@/router";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'DeleteAccountModal',
  setup(__props) {


const { t } = useI18n();

const closeModal = () => {
  store.commit('SET_FOCUSED', false);
  triggerHapticFeedback('light');
  store.commit('SET_OPEN_MODAL', "");
}

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1)
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

const deleteAccount = () => {
  const userService = new UserService();
  userService.deleteAccount()
      .then(response => {
        if (response.status == 204) {
          closeModal();
          triggerHapticFeedback('light');
          router.go(0);
        }
      });
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}



return (_ctx: any,_cache: any) => {
  return (_unref(store).getters.OPEN_MODAL === 'delete-account')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "delete-account-background",
          onClick: closeModal
        }),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", { class: "close-modal-wrapper" }, [
            _createElementVNode("img", {
              src: _imports_0,
              onClick: closeModal
            })
          ]),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`delete_account.warning_main`)), 1),
          _createElementVNode("p", _hoisted_4, [
            _createTextVNode(_toDisplayString(_unref(t)(`delete_account.warning_ful`)), 1),
            _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
            _createTextVNode(_toDisplayString(_unref(t)(`delete_account.warning_full`)), 1)
          ]),
          _createElementVNode("div", {
            class: "delete-button",
            onClick: deleteAccount
          }, _toDisplayString(_unref(t)(`delete_account.delete_button`)), 1),
          _createElementVNode("div", {
            class: "cancel-button",
            onClick: closeModal
          }, _toDisplayString(_unref(t)(`delete_account.cancel_button`)), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})