import {Ref, ref, computed} from "vue";
import router from "@/router";
import store from "@/store";



type Setting = {
    on?: () => void,
    off?: () => void,
    click?: () => void,
    type: "link" | "switch",
    label: string,
    defaultState?: boolean,
}

const settings: Ref<Setting[]> = ref([
    {
        on: () => {
            store.commit('ENABLE_VIBRATION');
        },
        off: () => {
            store.commit('DISABLE_VIBRATION');
        },
        type: "switch",
        label: "settings.vibration",
        defaultState: computed(() => store.getters.isVibrationOn).value,
    },
    {
        on: () => {
            store.commit('ENABLE_MUSIC');  
        },
        off: () => {
            store.commit('DISABLE_MUSIC');  
        },
        type: "switch",
        label: "settings.music",  
        defaultState: computed(() => store.getters.isMusicOn).value,
    },
    {
        on: () => {
            store.commit('ENABLE_SOUND');  
        },
        off: () => {
            store.commit('DISABLE_SOUND');  
        },
        type: "switch",
        label: "settings.sounds",  
        defaultState: computed(() => store.getters.isSoundOn).value,
    },
    {
        type: "link",
        label: "settings.language",  
        click: () => {
            window.scrollTo(0, 0)
            router.push('/settings/language')
        }
    },
    {
        type: "link",
        label: "settings.deleteAccount",  
        click: () => {
            store.commit('SET_OPEN_MODAL', 'delete-account');
            store.commit('SET_FOCUSED', true)
        }
    },
    {
        type: "link",
        label: "settings.userAgreement",  
        click: () => {
            store.commit('SET_OPEN_MODAL', 'user-agreement');
            store.commit('SET_FOCUSED', true)
        }
    },
    {
        type: "link",
        label: "settings.privacyPolicy",  
        click: () => {
            store.commit('SET_OPEN_MODAL', 'privacy-policy');
            store.commit('SET_FOCUSED', true)
        }
    }
]);

export default settings;