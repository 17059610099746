import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo/logo.svg'


const _hoisted_1 = { class: "transactions_wrapper" }
const _hoisted_2 = { class: "transaction-labels-wrapper" }
const _hoisted_3 = { class: "transaction-label" }
const _hoisted_4 = { class: "transaction-list-wrapper" }

import TransactionsList from "@/components/lists/transactions/TransactionsList.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionHistoryView',
  setup(__props) {


const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/profile')
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`profile.history`)) + " " + _toDisplayString(_unref(t)(`profile.transaction`)), 1),
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "transaction-hr" }, null, -1)),
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: _imports_0,
        class: "no-image-animation"
      }, null, -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(TransactionsList)
    ])
  ]))
}
}

})