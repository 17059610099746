<script setup lang="ts">
import lottie from 'lottie-web';
import { onMounted, ref, watch, defineProps, onUnmounted, defineEmits } from 'vue';

import like_Data from '@/assets/game/emoji/Positive/Like.json';
import fire_Data from '@/assets/game/emoji/Positive/Fire.json';
import boomstick_Data from '@/assets/game/emoji/Positive/Boomstick.json';
import star_Data from '@/assets/game/emoji/Positive/Star.json';
import flexed_bicep_Data from '@/assets/game/emoji/Positive/Flexed_biceps.json';

import thumb_down_Data from '@/assets/game/emoji/Negative/Thumb_down.json';
import angry_Data from '@/assets/game/emoji/Negative/Angry.json';
import skull_Data from '@/assets/game/emoji/Negative/Skull.json';
import water_face_Data from '@/assets/game/emoji/Negative/Water_face.json';
import super_angry_Data from '@/assets/game/emoji/Negative/Super_angry.json';

import ok_Data from '@/assets/game/emoji/Neutral/Ok.json';
import thinking_face_Data from '@/assets/game/emoji/Neutral/Thinking_face.json';
import pokerface_Data from '@/assets/game/emoji/Neutral/pokerface.json';
import monokle_Data from '@/assets/game/emoji/Neutral/Monokle.json';
import mda_Data from '@/assets/game/emoji/Neutral/Mda.json';

import bye_Data from '@/assets/game/emoji/Closing/Bye.json';
import folded_hands_Data from '@/assets/game/emoji/Closing/Folded_hands.json';
import call_me_Data from '@/assets/game/emoji/Closing/Call_me.json';
import bye_mars_Data from '@/assets/game/emoji/Closing/Bye_mars.json';
import peace_Data from '@/assets/game/emoji/Closing/Peace.json';
import store from "@/store";

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const props = defineProps({
  category: {
    type: String,
    required: true,
  },
  position: {
    type: Object,
    required: true,
  },
});

const emojiRefs = ref<HTMLElement[]>([]);
const emojis = ref<any[]>([]);
const animationInstances = ref<any[]>([]);
const isVisible = ref(true);
const isClosing = ref(false);

const emojiSets = {
  positive: [like_Data, fire_Data, boomstick_Data, star_Data, flexed_bicep_Data],
  negative: [thumb_down_Data, angry_Data, skull_Data, water_face_Data, super_angry_Data],
  neutral: [ok_Data, thinking_face_Data, pokerface_Data, monokle_Data, mda_Data],
  closing: [bye_Data, folded_hands_Data, call_me_Data, bye_mars_Data, peace_Data],
};

watch(() => props.category, (newCategory) => {
  emojis.value = emojiSets[newCategory] || [];
  loadEmojis();
}, { immediate: true });

function loadEmojis() {
  animationInstances.value.forEach(instance => instance.destroy());
  animationInstances.value = [];
  emojis.value.forEach((emojiData, index) => {
    const container = emojiRefs.value[index];
    if (container) {
      const animationInstance = lottie.loadAnimation({
        container,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: emojiData,
      });
      animationInstances.value.push(animationInstance);
    }
  });
}

const emit = defineEmits(['emojiSelected', 'closeEmojiBar']);

function selectEmoji(index: number) {
  const selectedEmoji = emojis.value[index];
  triggerHapticFeedback('soft');
  emit('emojiSelected', selectedEmoji);
  closeEmojiBar();
}

function closeEmojiBar() {
  isClosing.value = true;
  setTimeout(() => {
    isVisible.value = false;
    emit('closeEmojiBar');
  }, 200); 
}

onMounted(() => {
  loadEmojis();
});

onUnmounted(() => {
  animationInstances.value.forEach(instance => instance.destroy());
});
</script>

<template>
  <div class="emoji-bar" :style="{ top: `${position.top}px`, left: '107px' }" v-if="isVisible">
      <div :class="['up-bar', isClosing ? 'scale-out-up' : 'scale-in-up']">
      <div
          v-for="(emoji, index) in emojis"
          :key="index"
          ref="emojiRefs"
          :data-index="index"
          class="emoji"
          @click="selectEmoji(index)"
      ></div>
      </div>
      <div class="down-bar">
      <div :class="['first-circle', isClosing ? 'scale-out-first' : 'scale-in-first']"></div>
      <div :class="['second-circle', isClosing ? 'scale-out-second' : 'scale-in-second']"></div>
      </div>
  </div>
  </template>


<style scoped>
.emoji-bar {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  z-index: 100;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

.emoji {
  width: 8.14vw;
  height: 8.14vw;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  &:active, &:focus {
    background-color: transparent;
    }
}

.up-bar {
  display: flex;
  justify-content: center;
  background-color: #5c4e73;
  gap: 13px;
  padding: 7px 20px;
  border-radius: 100px;
  z-index: 10;
}

.scale-in-up {
  animation: scaleInUp 0.2s ease forwards;
  animation-delay: 0.02s;
  opacity: 0;
}

.scale-out-up {
  animation: scaleOutUp 0.2s ease forwards;
  animation-delay: 0.02s;
}

@keyframes scaleInUp {
  from {
    transform: scale(0.9, 0.9);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes scaleOutUp {
  from {
    transform: scale(1, 1);
    opacity: 1;
  }
  to {
    transform: scale(0.9, 0.9);
    opacity: 0;
  }
}

.first-circle, .second-circle {
  background-color: #5C4E73;
  border-radius: 100px;
}

.first-circle {
  width: 20px;
  height: 20px;
  margin-left: -60px;
  margin-top: -10px;
}

.second-circle {
  width: 10px;
  height: 10px;
  margin-left: -40px;
  margin-top: 0px;
}

.scale-in-first {
  animation: scaleInFirst 0.2s ease forwards;
  animation-delay: 0.01s;
}

.scale-out-first {
  animation: scaleOutFirst 0.2s ease forwards;
  animation-delay: 0s;
}

@keyframes scaleInFirst {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes scaleOutFirst {
  from {
    transform: scale(1, 1);
    opacity: 1;
  }
  to {
    transform: scale(0.5, 0.5);
    opacity: 0;
  }
}

.scale-in-second {
  animation: scaleInSecond 0.2s ease forwards;
  animation-delay: 0s;
}

.scale-out-second {
  animation: scaleOutSecond 0.2s ease forwards;
  animation-delay: 0s;
}

@keyframes scaleInSecond {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

@keyframes scaleOutSecond {
  from {
    transform: scale(1, 1);
    opacity: 1;
  }
  to {
    transform: scale(0.5, 0.5);
    opacity: 0;
  }
}
</style>
