<script setup lang="ts">

import RoomsList from "@/components/lists/rooms/RoomsList.vue";
import HeaderMini from "@/components/header/HeaderMini.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, ref} from "vue";
import {useRoute} from "vue-router";
import store from "@/store";

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/');
};

const route = useRoute();

const showSettingsPop = ref(route.query.showSettingsPop === 'true');

onMounted(() => {
  if (route.query.withBalancePopup) {
    store.commit('SET_FOCUSED', true);
    store.commit('SET_OPEN_MODAL', 'balance-top-up');
  }
  postEvent('web_app_setup_back_button', { is_visible: true});
  triggerHapticFeedback('soft');
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  triggerHapticFeedback('soft');
  off('back_button_pressed', listener);
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <header-mini/>
  <div class="rooms-wrapper" :style="{'margin-top': tg.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top))': 45}">
    <rooms-list :max_len="100" :all="true" :myroom="true" :show-settings-pop="showSettingsPop"/>
  </div>
</template>

<style scoped>
.rooms-wrapper {
  margin-top: 45px;
  margin-bottom: 113px;
}
</style>