import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/friends/Sub_tg_icon.svg'
import _imports_1 from '@/assets/friends/Profile_arrow.svg'


const _hoisted_1 = { class: "soon_wrapper" }
const _hoisted_2 = { class: "sub-tg-wrapper" }
const _hoisted_3 = { class: "sub-tg-text" }
const _hoisted_4 = { class: "sub-tg-text-text" }

import {onMounted, ref, onUnmounted} from "vue";
import router from "@/router";
import {MiniAppsEventListener, on, off, postEvent} from "@telegram-apps/sdk";
import { useI18n } from 'vue-i18n';
import lottie from 'lottie-web';

import Soon from '@/assets/wallet/soon.json'


export default /*@__PURE__*/_defineComponent({
  __name: 'TechnicalMaintenance',
  setup(__props) {

const { t } = useI18n();

const image_soon = ref();

let sticker: any = null;

onMounted(() => {
  sticker = lottie.loadAnimation({
    container: image_soon.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: Soon,
  });
});

function navigateToChannel(){
  const url = 'https://t.me/okgo_club';
  window.open(url, '_blank');
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "soon" }, "Закрыты на неделю", -1)),
    _createElementVNode("div", {
      class: "image",
      ref_key: "image_soon",
      ref: image_soon
    }, null, 512),
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "soon-3" }, "Вносим корректировки и добавляем новые фичи", -1)),
    _cache[4] || (_cache[4] = _createElementVNode("div", { class: "soon-3" }, "Подпишитесь на наш канал, чтобы следить за новостями", -1)),
    _createElementVNode("div", {
      class: "sub-tg",
      onClick: navigateToChannel
    }, [
      _createElementVNode("div", _hoisted_2, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "sub-tg-icon-wrapper" }, [
          _createElementVNode("img", {
            class: "sub-tg-icon",
            src: _imports_0
          })
        ], -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`profile.sub_tg`)), 1)
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "all-transaction-arrow" }, [
          _createElementVNode("img", { src: _imports_1 })
        ], -1))
      ])
    ])
  ]))
}
}

})