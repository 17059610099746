/**
 * @param value 
 * @param numbers
 * @returns 
 */
export function formatNumber(value: number, numbers = 0): string {
    if (value >= 1_000_000_000_000) {
        return formatWithSuffix(value, 1_000_000_000_000, 'T', numbers); 
    } else if (value >= 1_000_000_000) {
        return formatWithSuffix(value, 1_000_000_000, 'B', numbers); 
    } else if (value >= 1_000_000) {
        return formatWithSuffix(value, 1_000_000, 'M', numbers); 
    } else if (value >= 1_000) {
        return formatWithSuffix(value, 1_000, 'K', numbers); 
    } else if (value){
        return value.toString();
    }
    else return '0';
}

function formatWithSuffix(value: number, divisor: number, suffix: string, numbers: number): string {
    const formattedValue = value / divisor;
    return formattedValue % 1 === 0
        ? formattedValue.toFixed(0) + suffix
        : formattedValue.toFixed(numbers || 1) + suffix;
}

/**
 * @param value 
 * @returns 
 */
export function formatNumberByZiro(value: number): string {
    return Math.floor(value).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export function formatLocalDate(utcPlus3DateString: string, withMinutes = false): string {
    const serverDate = new Date(utcPlus3DateString);
    const serverTimezoneOffset = 3 * 60 * 60000;
    const utcDate = new Date(serverDate.getTime() - serverTimezoneOffset);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60000);

    const day = String(localDate.getDate()).padStart(2, '0');
    const month = String(localDate.getMonth() + 1).padStart(2, '0');
    const year = localDate.getFullYear();
    const hours = String(localDate.getHours()).padStart(2, '0');
    const minutes = String(localDate.getMinutes()).padStart(2, '0');
    const seconds = String(localDate.getSeconds()).padStart(2, '0');

    let returnString = `${day}.${month}.${year}`;
    if (withMinutes) {
        returnString += ` ${hours}:${minutes}:${seconds}`
    }

    return returnString;
}

export function shortenNickname(nickname: string | undefined): string {
    if (!nickname) return '';
    if (nickname.length > 9) {
        return nickname.slice(0, 6) + '...';
    }
    return nickname;
}

export function shortenNicknameMain(nickname: string | undefined): string {
    if (!nickname) return '';
    if (nickname.length > 11) {
        return nickname.slice(0, 9) + '...';
    }
    return nickname;
}