import {ApiService} from "@/api/apiService";
import store from "@/store";

export class RoomService {
    public async rooms(limit: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/rooms?limit=${limit}`, { headers: headers })
    }

    public async room(small_blind_bet: number, seat: number, type: string, withPlayers: boolean): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/rooms/${small_blind_bet}?seat=${seat}&coin_type=${type}&withPlayers=${withPlayers}`, { headers: headers })
    }

    public async joinRoom(roomId: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/rooms/${roomId}/joinRoom`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            })
    }

    public async leaveRoom(roomId: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/rooms/${roomId}/leaveRoom`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                })
            })
    }

    public async getPlayerCards(): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/game/playerCards`, { headers: headers })
    }

    public async check(roomID: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/check`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
            })
    }

    public async call(roomID: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/call`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
            })
    }

    public async fold(roomID: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/fold`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
            })
    }

    public async bet(bet: number, roomID: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/bet`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    bet: bet
                })
            })
    }

    public async raise(bet: number, roomID: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/raise`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    bet: bet
                })
            })
    }

    public async messages(roomId: number): Promise<Response> {
        const headers: HeadersInit = ApiService.withAuth();
        return await fetch(`${ApiService.baseUrl}/rooms/${roomId}/messages`, { headers: headers })
    }

    public async sendMessage(roomId: number, message: string, type: string): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/rooms/${roomId}/messages`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    body: message,
                    type: type
                })
            })
    }

    public async setDeposit(amount:number, user_id:number, roomID: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/game/top-up?room_id=${roomID}`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    amount: amount,
                    user_id: user_id
                })
            })
    }

    public async getPrivateRooms(limit: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/private-rooms?limit=${limit}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            });
            
    }

    public async getPrivateRoom(Id: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/private-rooms/${Id}`,
            {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            });
            
    }

    public async CreatePrivateRooms(small_blind_bet: number, fee: number, coin_type: string): Promise<any> {
        const auth = ApiService.getToken()!;
        
            return await fetch(`${ApiService.baseUrl}/private-rooms`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    small_blind_bet: small_blind_bet, 
                    fee: fee, 
                    coin_type: coin_type
                })
            });
    }

    public async deletePrivateRooms(id: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/private-rooms/${id}`,
            {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            });
            
    }

    public async joinPrivateRoom(roomId: number): Promise<Response> {
        const auth = ApiService.getToken()!;
        return await fetch(`${ApiService.baseUrl}/private-rooms/joinRoom/${roomId}`,
            {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                }
            })
    }

    public async changePrivateRooms(Id: number ,small_blind_bet: number, fee: number, coin_type: string): Promise<any> {
        const auth = ApiService.getToken()!;
        
            return await fetch(`${ApiService.baseUrl}/private-rooms/${Id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `tma ${auth}`
                },
                body: JSON.stringify({
                    id: Id,
                    small_blind_bet: small_blind_bet, 
                    fee: fee, 
                    coin_type: coin_type
                })
            });
    }

}