import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "available-card" }
const _hoisted_2 = { class: "players-wrapper" }
const _hoisted_3 = { class: "blind-wrapper" }
const _hoisted_4 = { class: "blind-number" }
const _hoisted_5 = { class: "blind-label" }
const _hoisted_6 = { class: "players-wrapper" }
const _hoisted_7 = { class: "blind-wrapper" }
const _hoisted_8 = { class: "blind-number" }
const _hoisted_9 = { class: "blind-label" }
const _hoisted_10 = {
  key: 0,
  class: "players-wrapper"
}
const _hoisted_11 = { class: "blind-wrapper" }
const _hoisted_12 = { class: "blind-number" }
const _hoisted_13 = { class: "blind-label" }

import { formatNumber } from "@/tools/tools";
import RoomsListItem from "@/models/roomsListItem";
import { useI18n } from 'vue-i18n';



export default /*@__PURE__*/_defineComponent({
  __name: 'RoomsListItem',
  props: {
    room: {},
    myroom: { type: Boolean },
    isCheckedDown: { type: Boolean },
    isCheckedTop: { type: Boolean }
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

// const iconColor = computed(() => {
//   if (props.isCheckedTop) return "#7861A5"; 
//   if (props.isCheckedDown) return "#7861A5";
//   return "#D14A4A";
// });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_3, [
          _cache[0] || (_cache[0] = _createElementVNode("svg", {
            class: "blind-balance-image no-image-animation",
            width: "14",
            height: "14",
            viewBox: "0 0 24 24",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
              fill: "#D14A4A"
            })
          ], -1)),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(formatNumber)(_ctx.room.big_blind_bet/2)) + "/" + _toDisplayString(_unref(formatNumber)(_ctx.room.big_blind_bet)), 1)
        ]),
        _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(`main.blind`)), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_7, [
          _cache[1] || (_cache[1] = _createElementVNode("svg", {
            class: "blind-balance-image no-image-animation",
            width: "14",
            height: "14",
            viewBox: "0 0 24 24",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
              fill: "#D14A4A"
            })
          ], -1)),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.room.game_deposit >= 1_000_000 ? _unref(formatNumber)(_ctx.room.game_deposit, 1) : _unref(formatNumber)(_ctx.room.game_deposit)), 1)
        ]),
        _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(t)(`main.buy_in`)), 1)
      ])
    ]),
    (_ctx.myroom)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("div", null, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.room.players_count) + "/" + _toDisplayString(_ctx.room.max_players), 1)
            ]),
            _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)(`main.players`)), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _cache[2] || (_cache[2] = _createElementVNode("div", null, [
      _createElementVNode("p", { class: "go" }, "GO")
    ], -1))
  ]))
}
}

})