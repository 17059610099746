<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import HeaderMain from "@/components/header/HeaderMain.vue";
import RoomsList from "@/components/lists/rooms/RoomsList.vue";
import TopPlayersList from "@/components/lists/top_players/TopPlayersList.vue";
import HeaderMini from "@/components/header/HeaderMini.vue";
import DailyBonus from '@/components/modals/DailyBonus.vue';
import store from '@/store';


export default defineComponent({
  name: 'HomeView',
  components: {
    HeaderMini,
    TopPlayersList,
    RoomsList,
    HeaderMain,
    DailyBonus,
  },
  setup() {
    const isCollapsed = ref(false);
    const showPopup = ref(false); 
    const last_bonus_at = ref(store.getters.USER.last_bonus_at);

    const handleScroll = () => {
      isCollapsed.value = window.scrollY > 200;
    };

    const handlePopupClose = () => {
      showPopup.value = false; 
    };

    const isMoreThan24HoursAgo = (dateString: string): boolean => {
      const targetDate = new Date(dateString);
      const currentDate = new Date();
      const diffInMilliseconds = currentDate.getTime() - targetDate.getTime();
      const hours24InMilliseconds = 24 * 60 * 60 * 1000;

      return diffInMilliseconds > hours24InMilliseconds;
    };

    onMounted(() => {
      showPopup.value = isMoreThan24HoursAgo(last_bonus_at.value);
    });

    window.addEventListener('scroll', handleScroll);

    return {
      isCollapsed,
      showPopup,
      handlePopupClose,
      isMoreThan24HoursAgo
    };
  },
});

</script>

<template>
  <daily-bonus v-if="showPopup" @close="handlePopupClose"/>
  <header-mini :animation="true" v-if="isCollapsed"/>
  <header-main/>
  <rooms-list :max_len="3" :all="false" :random="false" :myroom="true"/>
  <top-players-list/>
</template>