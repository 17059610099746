<script setup lang="ts">

import store from "@/store";
import {UserService} from "@/api/userService";
import router from "@/router";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const closeModal = () => {
  store.commit('SET_FOCUSED', false);
  triggerHapticFeedback('light');
  store.commit('SET_OPEN_MODAL', "");
}

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1)
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

const deleteAccount = () => {
  const userService = new UserService();
  userService.deleteAccount()
      .then(response => {
        if (response.status == 204) {
          closeModal();
          triggerHapticFeedback('light');
          router.go(0);
        }
      });
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


</script>

<template>
  <div class="delete-modal-wrapper" v-if="store.getters.OPEN_MODAL === 'delete-account'">
    <div class="delete-account-background" @click="closeModal"></div>
    <div class="delete-modal">
      <div class="close-modal-wrapper">
        <img src="@/assets/tools/close-modal.svg" @click="closeModal">
      </div>
      <p class="warning-main">{{ t(`delete_account.warning_main`) }}</p>
      <p class="warning-full">{{ t(`delete_account.warning_ful`) }}<br>{{ t(`delete_account.warning_full`) }}</p>
      <div class="delete-button" @click="deleteAccount">{{ t(`delete_account.delete_button`) }}</div>
      <div class="cancel-button" @click="closeModal">{{ t(`delete_account.cancel_button`) }}</div>
    </div>
  </div>
</template>

<style scoped>
.delete-modal-wrapper {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.delete-account-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.1s ease-out;
}

.delete-modal {
  background-color: #292936;
  border-top-left-radius: var(--border-mixed);
  border-top-right-radius: var(--border-mixed);
  border-top: #5C4E73 4px solid;
  padding: 12px 12px 57px;
  animation: translateUp 0.3s ease-out;
  z-index: 1;
}

.warning-main {
  font-weight: 500;
  font-size: 18px;
}

.warning-full {
  font-weight: 500;
  font-size: 14px;
  margin-top: 25px;
}

.delete-button {
  background: #FD3838;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: center;
  width: 100%;
  margin-top: 18px;
  margin-right: 7px;
}

.cancel-button {
  background: #5C4E73;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: center;
  width: 100%;
  margin-top: 14px;
  margin-right: 7px;
}

.close-modal-wrapper {
  width: 100%;
  display: flex;
  justify-content: right;
}
</style>