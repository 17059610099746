import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/tools/close-modal.svg'


const _hoisted_1 = { class: "level-up" }
const _hoisted_2 = { class: "up-pop" }
const _hoisted_3 = { class: "center-pop" }
const _hoisted_4 = { class: "user-info" }
const _hoisted_5 = { class: "user-container" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = { class: "text-pop" }
const _hoisted_9 = {
  key: 0,
  class: "text-pop-text",
  id: "level-message"
}
const _hoisted_10 = { class: "down-pop" }

import store from "@/store";
import router from "@/router";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {onMounted, onUnmounted, computed, nextTick} from "vue";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelUp',
  setup(__props) {

const { t } = useI18n();

const userData = (window as any).Telegram.WebApp.initDataUnsafe;

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1);
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true });
  on('back_button_pressed', listener);

  const oldLevel = store.getters.USER_OLD_LEVEL;

  nextTick(() => {

    const levelElem = document.getElementById('level');
    const levelMessageElem = document.getElementById('level-message');

    if (levelElem && levelMessageElem) {
      let level = 2;
      const vipStatus = store.getters.USER.vip_status;
      if (vipStatus === 'first') {
        level = 1;
      } else {
        switch (vipStatus) {
          case 'silver':
            level = 3;
            break;
          case 'gold':
            level = 4;
            break;
          default:
            level = 2;
        }
      }

      levelElem.innerText = level.toString();

      levelMessageElem.innerHTML = `${ t(`level_up.congr_1`) } ${level}${ t(`level_up.new_diz`) }`;
    } 

    const userLogoWrapper3 = document.querySelector('.user-logo-wrapper3') as HTMLElement;
    const userLogoWrapper4 = document.querySelector('.user-logo-wrapper4') as HTMLElement;

    if (userLogoWrapper3 && userLogoWrapper4) {
      const rect = userLogoWrapper3.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;

      userLogoWrapper4.style.position = 'absolute'; 
      userLogoWrapper4.style.top = `${topPosition}px`;
      userLogoWrapper4.style.left = `${leftPosition}px`;
    }
  });
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false });
  off('back_button_pressed', listener);
});

const backgroundClass = computed(() => {
  switch (store.getters.USER.vip_status) {
    case 'gold':
      return 'gradient-gold';
    case 'silver':
      return 'gradient-silver';
    default:
      return 'background-white';
  }
});

const tg = (window as any).Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        class: "up-pop-img",
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => {triggerHapticFeedback('soft'); _unref(router).push('/')})
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_unref(store).getters.USER.vip_status != 'first')
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "user-status no-image-animation",
                src: require(`@/assets/player/status/status-${_unref(store).getters.USER.vip_status}.svg`)
              }, null, 8, _hoisted_6))
            : _createCommentVNode("", true),
          _createElementVNode("div", {
            class: _normalizeClass(['user-logo-wrapper2', backgroundClass.value])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(['user-logo-wrapper3', backgroundClass.value])
            }, null, 2)
          ], 2),
          _createElementVNode("div", {
            class: _normalizeClass(['user-logo-wrapper4', backgroundClass.value])
          }, [
            _createElementVNode("img", {
              class: "user-logo",
              src: _unref(store).getters.USER.photo_url ? _ctx.ApiService.url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
            }, null, 8, _hoisted_7)
          ], 2)
        ]),
        _createElementVNode("div", _hoisted_8, [
          (_unref(store).getters.USER.vip_status != 'first')
            ? (_openBlock(), _createElementBlock("p", _hoisted_9, [
                _createTextVNode(_toDisplayString(_unref(t)(`level_up.congr_1`)) + " ", 1),
                _cache[2] || (_cache[2] = _createElementVNode("span", { id: "level" }, "2", -1)),
                _createTextVNode(_toDisplayString(_unref(t)(`level_up.new_diz`)), 1)
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _createElementVNode("div", {
        class: "down-pop-btn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => {triggerHapticFeedback('soft'); _unref(router).push('/levels')})
      }, _toDisplayString(_unref(t)(`level_up.view_all_levels`)), 1)
    ])
  ]))
}
}

})