<script setup lang="ts">

import store from "@/store";
import router from "@/router";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {onMounted, onUnmounted} from "vue";

const closeModal = () => {
  store.commit('SET_FOCUSED', false);
  triggerHapticFeedback('light');
  store.commit('SET_OPEN_MODAL', "");
}

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1)
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


</script>

<template>
  <div class="user-agreement-wrapper" v-if="store.getters.OPEN_MODAL === 'privacy-policy'" :style="{top: tg.isFullscreen ? 0 : -45}">
    <div class="user-agreement-background" @click="closeModal"></div>
    <div class="user-agreement-modal" :style="{top: tg.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + 20px)': 106}">
        <div class="header">
            <div class="header-text">
                Privacy Policy
            </div>
            <div class="close-modal-wrapper">
              <img src="@/assets/tools/close-modal.svg" @click="closeModal">
            </div>
        </div>
        <div class="text">
        <br><br>
        <small>last upd: 2024-12-28</small>
        <br><br>
        <h3>1. Introduction</h3>
        <br><br>
        1.1 OkGo (“we,” “us,” or “our”) is committed to protecting your privacy while providing a seamless and secure poker bot experience on Telegram.
        <br><br>
        1.2 This Privacy Policy explains how we collect, use, and share your information when you interact with our services, ensuring transparency and trust.
        <br><br>
        1.3 By using OkGo, you agree to the terms outlined in this Privacy Policy, which governs all aspects of data handling.
        <br><br>
        1.4 This policy applies to all users globally, regardless of location or jurisdiction, emphasizing our commitment to a consistent and fair approach.
        <br><br>
        1.5 If you have any questions, concerns, or need further clarification, please contact us at the details provided in the Contact Information section.
        <br><br>
        1.5 If you have any questions, concerns, or need further clarification, please contact us at the details provided in the Contact Information section.
        <br><br>
        1.6 This document is regularly reviewed to align with evolving legal and technological standards.
        <br><br>
        1.7 Your continued use of our services indicates your acceptance of this Privacy Policy in its entirety.
        <br><br>
        <h3>2. Information We Collect</h3>
        <br><br>
        2.1 Information You Provide: We may collect your Telegram username, crypto wallet address, in-game preferences, and other information voluntarily shared during support interactions.
        <br><br>
        2.2 Automatically Collected Information:** Our bot may record session activity, including timestamps, transaction logs, gameplay statistics, IP addresses, and device-related metadata.
        <br><br>
        2.3 Third-Party Data:** We may collect public wallet transaction details via blockchain explorers and integrate information from other services linked to gameplay.
        <br><br>
        2.4 We do not collect sensitive personal information such as your real name, address, or payment card details to ensure your privacy is respected.
        <br><br>
        2.5 All data collected is minimal, directly relevant to the functionality of the bot, and processed for legitimate purposes only.
        <br><br>
        2.6 Data collected helps us optimize gameplay experiences and address technical challenges.
        <br><br>
        2.7 We regularly assess and minimize the scope of collected data.
        <br><br>
        <h3>3. How We Use Your Information</h3>
        <br><br>
        3.1 To authenticate your account and link it to your Telegram profile, ensuring a secure and personalized experience.
        <br><br>
        3.2 To facilitate poker gameplay, including crypto transactions, real-time game session management, and leaderboard updates.
        <br><br>
        3.3 To analyze user activity for service improvement, bug fixes, and enhancement of gameplay mechanics.
        <br><br>
        3.4 To communicate essential updates, policy changes, or respond to user inquiries efficiently.
        <br><br>
        3.5 We do not sell, rent, or share your personal data with advertisers or unrelated third parties under any circumstances.
        <br><br>
        3.6 Data may also be used for research and development to introduce new features and improve service reliability.
        <br><br>
        3.7 Transparency remains a cornerstone; you will always be informed about how your data is used.
        <br><br>
        <h3>4. How We Share Your Information</h3>
        <br><br>
        4.1 We share your data with third-party service providers solely for operational purposes, such as transaction processing and blockchain verification.
        <br><br>
        4.2 If required by law, regulation, or court order, we may disclose information to legal authorities in compliance with applicable laws.
        <br><br>
        4.3 In the event of a business transfer, such as a merger or acquisition, user data may be included as part of the transaction, subject to this Privacy Policy.
        <br><br>
        4.4 All shared data is anonymized and aggregated wherever feasible to protect your privacy and reduce risks.
        <br><br>
        4.5 We ensure all third parties comply with strict data security protocols and enter agreements that limit the use of shared data.
        <br><br>
        4.6 We never share your data with organizations that do not align with our privacy and security values.
        <br><br>
        4.7 Detailed logs of any data-sharing activities are maintained for accountability.
        <br><br>
        <h3>5. Data Security and Protection</h3>
        <br><br>
        5.1 We use encryption, firewalls, and other advanced security measures to protect your information from unauthorized access.
        <br><br>
        5.2 Access to user data is limited to authorized personnel who have undergone rigorous training in data security.
        <br><br>
        5.3 We encourage users to safeguard their Telegram credentials and crypto wallet private keys to ensure end-to-end security.
        <br><br>
        5.4 While we strive for robust security, no system can guarantee complete safety against all potential threats.
        <br><br>
        5.5 Report any suspected data breaches or vulnerabilities to us immediately so we can take corrective action.
        <br><br>
        5.6 Regular security audits and updates are conducted to adapt to emerging threats.
        <br><br>
        5.7 Your role in maintaining your account security is equally vital, and we provide resources to help you do so.
        <br><br>
        <h3>Cryptocurrency Transactions</h3>
        <br><br>
        6.1 All transactions are conducted using blockchain technology, ensuring transparency, traceability, and enhanced security.
        <br><br>
        6.2 We do not store private keys, passwords, or control your wallet funds, placing full control in your hands.
        <br><br>
        6.3 Transaction history may be logged for gameplay analytics, dispute resolution, and regulatory compliance when required.
        <br><br>
        6.4 Blockchain data is inherently public and immutable, and OkGo cannot alter or erase this data.
        <br><br>
        6.5 Use your crypto wallet responsibly and verify transactions carefully before confirming.
        <br><br>
        6.6 We recommend adopting additional security practices like two-factor authentication (2FA) for your crypto wallet.
        <br><br>
        6.7 For more information on crypto safety, consult our guidelines and resources.
        <br><br>
        <h3>7. User Rights</h3>
        <br><br>
        7.1 You have the right to access and review the data we collect about you at any time.
        <br><br>
        7.2 You may request corrections to inaccuracies in your information to ensure it remains up-to-date and accurate.
        <br><br>
        7.3 You can ask us to delete your data, subject to certain legal and operational limitations, without affecting your access to non-personalized services.
        <br><br>
        7.4 You may object to specific data processing activities, including automated decision-making, by contacting us directly.
        <br><br>
        7.5 We will respond to user requests within a reasonable timeframe, usually within 30 days.
        <br><br>
        7.6 Your rights extend to all data, regardless of how or when it was collected.
        <br><br>
        7.7 Additional rights may apply depending on your jurisdiction.
        <br><br>
        <h3>8. Data Retention Policy</h3>
        <br><br>
        8.1 Data is retained only as long as necessary to fulfill the purposes outlined in this policy and to comply with legal obligations.
        <br><br>
        8.2 Transaction logs and gameplay data may be retained for up to one year for legal, operational, and analytical purposes.
        <br><br>
        8.3 After the retention period, data is securely deleted, anonymized, or archived based on its relevance.
        <br><br>
        8.4 Users may request data deletion at any time, subject to legal requirements and technical constraints.
        <br><br>
        8.5 Retention policies are reviewed periodically to ensure compliance with evolving best practices and regulations.
        <br><br>
        8.6 Where required, we provide detailed explanations of retention practices upon request.
        <br><br>
        8.7 Our aim is to maintain transparency in all data retention activities.
        <br><br>
        <h3>9. Cookies and Tracking Technologies</h3>
        <br><br>
        9.1 OkGo does not use cookies or other tracking technologies beyond those inherent to Telegram and blockchain networks to respect user privacy.
        <br><br>
        9.2 Any third-party tools integrated with OkGo adhere to their own privacy policies, and we encourage users to review these policies.
        <br><br>
        9.3 Users are encouraged to review Telegram’s privacy settings and blockchain explorer policies for additional transparency.
        <br><br>
        9.4 We do not track user behavior outside of the OkGo bot or perform invasive monitoring of activities.
        <br><br>
        9.5 No additional user tracking is conducted without explicit consent and a clear explanation of the purpose.
        <br><br>
        9.6 Integration of third-party technologies is limited to improving functionality while minimizing privacy risks.
        <br><br>
        9.7 Feedback regarding tracking practices is welcomed and considered for future improvements.
        <br><br>
        <h3>10. Third-Party Links and Integrations</h3>
        <br><br>
        10.1 OkGo may include links to external sites or third-party services for crypto-related resources or gaming enhancements.
        <br><br>
        10.2 We are not responsible for the privacy practices, content, or reliability of external platforms linked through our bot.
        <br><br>
        10.3 Users should review the privacy policies of third-party services before engagement to understand their practices.
        <br><br>
        10.4 Third-party integrations are carefully vetted to ensure they align with our security and privacy standards.
        <br><br>
        10.5 Contact us if you encounter issues with third-party services linked to OkGo or need assistance understanding their policies.
        <br><br>
        10.6 Where feasible, we aim to provide disclaimers or warnings about third-party links.
        <br><br>
        10.7 Enhancing user awareness of third-party integrations remains a priority for us.
        <br><br>
        <h3>11. Children’s Privacy</h3>
        <br><br>
        11.1 OkGo is not intended for users under the age of 18, and our services are explicitly designed for adult use.
        <br><br>
        11.2 We do not knowingly collect data from minors, and any data inadvertently collected will be promptly deleted.
        <br><br>
        11.3 If a user is found to be underage, their account and data will be permanently disabled and removed from our systems.
        <br><br>
        11.4 Parents or guardians should actively monitor their children’s Telegram activities to prevent unauthorized access.
        <br><br>
        11.5 Report any concerns about minors using OkGo to our support team for immediate action.
        <br><br>
        11.6 Additional safeguards are in place to identify and prevent underage usage.
        <br><br>
        11.7 Compliance with global children’s privacy standards remains an integral part of our policy.
        <br><br>
        <h3>12. Changes to the Privacy Policy</h3>
        <br><br>
        12.1 We reserve the right to update this Privacy Policy as needed, based on regulatory requirements, service changes, or feedback.
        <br><br>
        12.2 Users will be notified of significant changes through Telegram announcements, ensuring adequate time for review.
        <br><br>
        12.3 Continued use of OkGo signifies acceptance of updated policies, but we encourage users to revisit this document periodically.
        <br><br>
        12.4 Changes are effective immediately upon posting unless otherwise stated, but you will be informed of any changes impacting your rights.
        <br><br>
        12.5 We recommend periodically reviewing this Privacy Policy to stay informed about how your data is handled.
        <br><br>
        12.6 An archive of past versions is maintained for transparency and user reference.
        <br><br>
        12.7 User feedback is considered when updating the policy to reflect practical concerns.
        <br><br>
        <h3>13. Contact Information</h3>
        <br><br>
        13.1 For support and privacy-related inquiries, please reach out to us via our Telegram handle: @okgopokerhelpbot.
        <br><br>
        13.2 Our Data Protection Officer (DPO) can be contacted for compliance issues, ensuring regulatory adherence.
        <br><br>
        13.3 We aim to respond to all inquiries within 48 hours, prioritizing urgent or sensitive matters.
        <br><br>
        13.4 Feedback, suggestions, and concerns are always welcome and reviewed with diligence.
        <br><br>
        13.5 Inquiries regarding specific aspects of this policy are addressed in detail upon request.
        <br><br>
        13.6 Contact details are regularly updated to ensure seamless communication with our team.
        <br><br>
        </div>
    </div>
  </div>
</template>

<style scoped>

.user-agreement-wrapper {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  left: 0;
  right: 0;
  top: -45px;
  z-index: 101;
}

.user-agreement-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  animation: fadeIn 0.2s ease-out;
}

.text {
  max-width: 430px;
  position: relative;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  font-size: 14px;
  background-color: #30313E;
  margin-top: 62px;
  text-align: start;
  height: calc(100vh - 160px);
  overflow-y: scroll;
}

.header {
  position: fixed;
  max-width: 430px;
  width: 100%;
  background-color: #292936;
  border-top-left-radius: 4.65vw;
  border-top-right-radius: 4.65vw;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  z-index: 1;
  font-size: 18px;
}

.header-text {
    margin-left: 20px;
}

h3 {
    font-size: 18px;
}

.user-agreement-modal {
  background-color: #292936;
  border-top-left-radius: var(--border-mixed);
  border-top-right-radius: var(--border-mixed);
  border-top: #5C4E73 4px solid;
  top: 106px;
  position: relative;
  animation: translateUp 0.4s ease-out;
}

.close-modal-wrapper {
  display: flex;
  justify-content: right;
  margin-right: 20px;
}
</style>