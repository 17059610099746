<script setup lang="ts">
import {UserService} from "@/api/userService";
import {onMounted, ref} from "vue";
import AnimatedPlaceholder from "@/components/tools/AnimatedPlaceholder.vue";
import TopPlayer from "@/models/topPlayer";
import TopPlayerListItem from "@/components/lists/top_players/TopPlayerListItem.vue";
import { useI18n } from 'vue-i18n';
import store from "@/store";

const { t } = useI18n();

const topPlayers = ref<TopPlayer[]>([]);
const isLoaded = ref(false);
const filter = ref<'hour' | 'day'>('hour');

function getTopPlayers(interval: "hour" | "day") {
  isLoaded.value = false;
  const userService = new UserService()
  filter.value = interval;
  userService.topPlayers(interval)
      .then(response => response.json())
      .then(data => {
        topPlayers.value = data;
        isLoaded.value = true;
      });
}

onMounted(() => {
  getTopPlayers("hour");
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <section class="third-section">
    <div class="top-player-filter-wrapper">
      <div @click="triggerHapticFeedback('light'); getTopPlayers('hour')" :class="{ 'top-player-filter': true, 'top-player-filter-active': filter == 'hour' }">
        {{ t(`main.top_of_hour`) }}
      </div>
      <div @click="triggerHapticFeedback('light'); getTopPlayers('day')" :class="{ 'top-player-filter': true, 'top-player-filter-active': filter == 'day' }">
        {{ t(`main.top_of_day`) }}
      </div>
    </div>
    <div v-if="isLoaded">
      <top-player-list-item v-for="(item, i) in topPlayers" :key="i" :item="item"/>
    </div>
    <div v-else>
      <div class="top-player-card-mock">
        <AnimatedPlaceholder border-radius="var(--border-mixed)" width="100%" height="70px"/>
      </div>
      <div class="top-player-card-mock">
        <AnimatedPlaceholder border-radius="var(--border-mixed)" width="100%" height="70px"/>
      </div>
      <div class="top-player-card-mock">
        <AnimatedPlaceholder border-radius="var(--border-mixed)" width="100%" height="70px"/>
      </div>
      <div class="top-player-card-mock">
        <AnimatedPlaceholder border-radius="var(--border-mixed)" width="100%" height="70px"/>
      </div>
      <div class="top-player-card-mock">
        <AnimatedPlaceholder border-radius="var(--border-mixed)" width="100%" height="70px"/>
      </div>
    </div>
  </section>
</template>

<style scoped>
.third-section {
  margin-top: 28px;
  margin-bottom: 113px;
  background-color: #4A3E5D;
  border-radius: var(--border-mixed);
  padding: 7px 10px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.4s ease-out 0.4s forwards;
}

.top-player-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.top-player-filter {
  border-radius: var(--border-mixed);
  font-size: 18px;
  font-weight: 500;
  padding: 14px 11px;
  flex: 1;
}

.top-player-filter-active{
  background-color: #5C4E73;
}

.top-player-card-mock {
  margin-top: 14px;
}

.top-player-info-wrapper {
  display: flex;
  justify-content: left;
  flex-direction: column;
}

.players-label {
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
}

.players-count {
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
}

.top-player-user-wrapper {
  display: flex;
  gap: 10px
}

.top-player-image-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>