import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tournament-wrapper" }
const _hoisted_2 = { class: "tournaments-title" }

import TournamentListItem from "@/components/lists/tournaments/TournamentListItem.vue"
import { useI18n } from 'vue-i18n';
import HeaderMini from "@/components/header/HeaderMini.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'TournamentsList',
  setup(__props) {

const { t } = useI18n();


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderMini),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", null, _toDisplayString(_unref(t)(`tournaments.title_partner`)), 1)
      ]),
      _createVNode(TournamentListItem)
    ])
  ], 64))
}
}

})