<script setup lang="ts">

import {ApiService} from "@/api/apiService";
import {formatNumber, shortenNickname} from "@/tools/tools";
import Player from "@/models/player";
import Game from "@/models/game";
import CountTimer from "@/components/game/CountTimer.vue";
import {defineProps, watch, ref, inject, onMounted} from "vue";
import store from "@/store";
import EmojiPlayerItem from "./EmojiPlayerItem.vue";
import soundNewAudio from '@/assets/sounds/new_audio.mp3';
import Room from "@/models/room";
import { useI18n } from 'vue-i18n';
import { postEvent } from "@telegram-apps/sdk";

const { t } = useI18n();

const playSound = inject<() => void>("playSound");

type Seat = {
  player: Player | null,
  seat: number,
}

const props = defineProps<{
  seat: Seat,
  game: Game,
  dealerSeat: number | null,
  isSecondLine: boolean,
  playerCards: any,
  currentCase: string | null;
  selectedEmoji: any,
  selectedEmojiPlayerID: number | undefined
  winners: any[],
  room: Room,
  itsmyplayer: boolean,
  players: Player[],
  cerruntPlayer: Player,
}>()

const transformStyle = ref('');
const transformStyleCard = ref('');
const transformBalance = ref('');
const userBetBalance = ref(formatNumber(props.seat?.player?.game_deposit ?? 0));
const userBetText = ref<number | undefined | string>(0);
const isCurrentPlayerWinner = ref();

const transformBet = ref('');

onMounted(() => {
  userBetText.value = props.seat?.player?.current_round_bet;
});

watch(
  () => props.currentCase,
  (newCase) => {
    isCurrentPlayerWinner.value = Array.isArray(props.winners) && props.winners.some((winner: any) => winner.user_id === props.seat.player?.user_id);
    
    if (newCase === 'show_down' && isCurrentPlayerWinner.value) {
      // const findWin = props.winners.find((winner: any) => winner.user_id === props.seat?.player?.user_id).sum;

      const winner = props.winners.find((winner: any) => winner.user_id === props.seat?.player?.user_id);
      const sum = winner?.sum || 0; 

      transformStyle.value = `
        transform: scale(1.1) translateY(${props.isSecondLine ? '10px' : '5vw'});
        z-index: 4;
        filter: drop-shadow(0px 0px 2px rgba(240, 190, 121)) drop-shadow(0px 0px 5px rgba(240, 190, 121)) drop-shadow(0px 0px 10px rgba(240, 190, 121));`;

      transformStyleCard.value = '';
      userBetBalance.value = '+'+sum;
      transformBalance.value = 'transform: scale(1.12); color: #31B545; font-weight: 600;';
      userBetText.value = 'WIN'
    } else if(newCase == 'show_down') {
      transformStyle.value = `transform: scale(1.1) translateY(${props.isSecondLine ? '10px' : '5vw'});`;
    } else if (newCase !== 'show_down') {
      transformStyle.value = '';
      userBetBalance.value = formatNumber(props.seat.player?.game_deposit ?? 0);
    }
  }
);

watch(
  () => props.seat?.player?.current_round_bet, 
  (newBet, oldBet) => {
    if (newBet && oldBet! > 0) {
      if (newBet != oldBet) {
        if (newBet! > oldBet!) {
          setTimeout(() => {
            transformBet.value = 'transform: scale(2.5); color: #31B545; font-weight: 600; transition: transform 0.3s ease, color 0.3s ease;';
            setTimeout(() => {
              transformBet.value = 'transform: scale(1); color: inherit; font-weight: inherit; transition: transform 0.3s ease, color 0.3s ease;';
            }, 1000);
          },500)
        }
      }
    }
  },
  { deep: true, immediate: true }
);

watch(() => props.seat?.player?.current_round_bet, () => {
  userBetText.value = props.seat.player?.current_round_bet;
});

const preloadImage = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.src = url;
  });
};

watch(() => props.currentCase, (newCase) => {
  if (newCase === 'show_down') {
    updatePlayerCards();
  }
});

const userCard1Ref = ref<HTMLImageElement | null>(null);
const userCard2Ref = ref<HTMLImageElement | null>(null);
const userCardRef = ref<HTMLImageElement | null>(null);

function updatePlayerCards(playerCards = props.playerCards) {

  const countOfActivePlayers = props.players.filter(player => player.status === "active").length;

  if (props.seat?.player && props.seat?.player!.status === 'active' && countOfActivePlayers >= 2){
    if (!playerCards || playerCards.length < 2) {
      return;
    }

    playerCards.forEach(async playerCard => {
      const playerId = Object.keys(playerCard)[0]; 

      if (playerId == props.seat.player?.id.toString()) {
        const cards = Object.values(playerCard)[0] as { rank: string, suit: string }[];

        // console.log("playerId:", playerId);
        // console.log("cards this player:"); 
        // console.log(cards);

        if (cards.length == 2) {
          const card1 = cards[0];
          const card2 = cards[1];

          try {

            const image1El = document.getElementById('user-card-11');
            const image2El = document.getElementById('user-card-22');

            const image1 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${props.seat.player.user_vip_status}/${card1.rank.toLowerCase()}_${props.seat.player.user_vip_status != 'first' ? card1.suit.toLowerCase(): card1.suit.toLowerCase()}_${props.seat.player.user_vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${props.seat.player.user_vip_status}.svg`);
            const image2 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${props.seat.player.user_vip_status}/${card2.rank.toLowerCase()}_${props.seat.player.user_vip_status != 'first' ? card2.suit.toLowerCase(): card2.suit.toLowerCase()}_${props.seat.player.user_vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${props.seat.player.user_vip_status}.svg`);

            await preloadImage(image1);
            await preloadImage(image2);

            if (userCard1Ref.value && userCard2Ref.value && userCardRef.value) {
              let showdownSound = new Audio(soundNewAudio);
              showdownSound.play();
              
              userCard1Ref.value.src = image1;
              userCard2Ref.value.src = image2;
              
              userCardRef.value.classList.add('showdown-div');
              userCard1Ref.value.classList.add('showdown-card');
              userCard2Ref.value.classList.add('showdown-card', 'showdown-card-delay');
              if (playSound) {
                playSound(); 
              }  
              
            } 
          } catch (error) {
            console.error('Ошибка загрузки изображений карт:', error);
          }
        }
      }
    });
  }
  
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

function openShare() {
  triggerHapticFeedback('soft');
  
  const shareUrl = `https://t.me/okgo_poker_bot/open_app?startapp=${props.room.id}`;
  const inviteText = `${t('settings_room.invite_friends')}\n${t('settings_room.invite_friends2')}\n\n${shareUrl}`;

  postEvent('web_app_open_tg_link', {
    path_full: `/share/url?text=${encodeURI(t('settings_room.invite_friends3'))}&url=${encodeURI(inviteText)}`
  });
}

</script>



<template>
  <div v-if="itsmyplayer">
    <img class="user-game-logo no-image-animation" :src="require('@/assets/mock_user/no-user-game.png')">
    <div class="user-bet-username no-image-animation">
      <div style="height: 3.5vh"></div>
      <p class="user-bet no-image-animation">---</p>
      <p class="user-username no-image-animation">---</p>
    </div>
  </div>
  <div v-else-if="seat.player" :class="{ 'player': true, 'active-player': seat.player.id == game?.current_player_id, 'not-active-player': seat.player.id != game?.current_player_id, 'not-watcher': seat.player.status == 'active', 'proflop': seat.player.status == 'active', 'not-move-player': seat.player.status != 'active'}" :style="transformStyle" >
    <div>
      <div class="user-image-count">
        <div class="dealer-button" v-if="seat.player.seat === dealerSeat">
          <img class="dealer-button-button" :src="require(`@/assets/dealer-buttons/btn-dealer-${store.getters.USER.vip_status}.svg`)">
        </div>
        <div class="emoji-item-player">
          <emoji-player-item 
          :selected-emoji="selectedEmoji"
          :selectedEmojiPlayerID="selectedEmojiPlayerID!"
          :seat="seat"
          />
        </div>
        <div class="user-game-logo no-image-animation">
          <count-timer :game="game" :player="seat.player"/>
          <img class="user-game-logo no-image-animation" :src="seat.player.user_photo_url ? ApiService.url + seat.player.user_photo_url : require('@/assets/mock_user/user.svg')">
        </div>
      </div>
      <div class="user-bet-username no-image-animation">
        <img :class="{'user-card-1': true, 'no-image-animation': true, 'user-card-opacity': seat.player.status != 'active', 'user-card-opacity2': props.currentCase == 'show_down', 'card-animate-move1': seat.player.id == game?.current_player_id}" :src="require(`@/assets/cards/hidden/hidden-${seat.player.user_vip_status}.svg`)" >
        <img :class="{'user-card-2': true, 'no-image-animation': true, 'user-card-opacity': seat.player.status != 'active', 'user-card-opacity2': props.currentCase == 'show_down', 'card-animate-move2': seat.player.id == game?.current_player_id}" :src="require(`@/assets/cards/hidden/hidden-${seat.player.user_vip_status}.svg`)" >
        <div class="user-bet no-image-animation">
          <!-- <p v-if="!currentPLayerWin" class="user-bet-text" :style="transformBet">{{ userBetText }}</p> -->
          <p class="user-balance-text" :style="transformBalance">{{ userBetBalance }}</p> 
        </div>
        <p class="user-username no-image-animation">{{ shortenNickname(seat.player.user_username) ?? '---' }}</p>
      </div>
      <div class="showdown" ref="userCardRef">
        <img class="user-card-11" id="user-card-11" ref="userCard1Ref" :src="require(`@/assets/cards/hidden/hidden-${seat.player.user_vip_status}.svg`)" :style="transformStyleCard">
        <img class="user-card-22" id="user-card-22" ref="userCard2Ref" :src="require(`@/assets/cards/hidden/hidden-${seat.player.user_vip_status}.svg`)" :style="transformStyleCard">
      </div>
      <!-- <p v-if="seat.player?.status == 'active'" :class="{'user-bet-text': true, 'user-bet-text-lft-rght': (props.cerruntPlayer?.seat + 1) % 8 == 1 || (props.cerruntPlayer?.seat + 4) % 8 == 4, 'user-down': isSecondLine, 'user-down-rght': (props.cerruntPlayer?.seat + 5) % 8 == 5, 'user-down-winner': (props.currentCase == 'show_down' && isCurrentPlayerWinner)}" :style="transformBet">{{ userBetText }}</p> -->
      <p v-if="seat.player?.status == 'active'" :class="{'user-bet-text': true, 'user-down': isSecondLine, 'user-down-winner': (props.currentCase == 'show_down' && isCurrentPlayerWinner)}" :style="transformBet">{{ userBetText }}</p>
    </div>
  </div>
  <div v-else-if="props.room.is_private == 1 && props.room.created_by == store.getters.USER.id">
    <img class="user-game-logo no-image-animation" :src="require('@/assets/mock_user/user-plus-game.svg')" @click="openShare()">
    <div class="user-bet-username no-image-animation" @click="openShare()">
      <div style="height: 3.5vh"></div>
      <p class="user-bet no-image-animation">---</p>
      <p class="user-username no-image-animation">---</p>
    </div>
  </div>
  <div v-else>
    <img class="user-game-logo no-image-animation" :src="require('@/assets/mock_user/no-user-game.png')">
    <div class="user-bet-username no-image-animation">
      <div style="height: 3.5vh"></div>
      <p class="user-bet no-image-animation">---</p>
      <p class="user-username no-image-animation">---</p>
    </div>
  </div>
</template>

<style scoped>

.user-bet-text-lft-rght {
  margin-top: 3.72vw; 
}

.opacity-null {
  opacity: 0;
}

.user-down-rght {
  top: -8.9vw;
}

.user-down-winner {
  animation: anim_win_down 0.2s ease forwards;
}

@keyframes anim_win_down {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 2.3vw;
  }
}

.user-down {
  top: -6.9vw;
}

.user-bet-text {
  position: absolute;
  left: 50%;
  translate: -50%;
  font-weight: 500;
  font-size: 1.5vh;
  background-color: #5c4e73;
  border-radius: var(--border-mixed);
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 10px;
  padding-right: 10px;
  filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 27%));
  z-index: 10000;
}

.user-game-logo {
  z-index: 100;
  position: relative;
}

.user-card-1.card-animate-move1 {
  position: relative;
  height: 2.7vh;
  margin-left: -113%;
  top: -13px;
  rotate: -22.55deg;
  z-index: 5;
  transform: scale(1.2);
}

.user-card-2.card-animate-move2 {
  position: relative;
  height: 2.7vh;
  top: -15px;
  left: 5px;
  rotate: 3.58deg;
  margin-left: -10px;
  z-index: 4;
  transform: scale(1.2);
}

.not-move-player {
  filter: brightness(69%);
}

.user-card-opacity, .user-card-opacity2 {
opacity: 0;
}

.winner {
  position: absolute;
  font-weight: 500;
  font-size: 1.3vh;
}

.showdown {
  position: absolute;
  left: 27%;
  bottom: 10px;
  transform: scale(2);
  opacity: 0;
  z-index: 900;
}

.emoji-item-player{
  position: absolute;
  top: 36%;
  left: 85%;
  z-index: 1001;
}

.user-card-11 {
  position: absolute;
  height: 30px;
  top: -13px;
  rotate: 19deg;
  z-index: 9;
  right: -25px;
  opacity: 0;
}

.user-card-22 { 
  position: absolute;
  height: 30px;
  top: -13px;
  rotate: 353deg;
  margin-left: -10px;
  opacity: 0;
  z-index: 8;
}

.dealer-button {
  position: absolute;
  max-width: 20px;
  max-height: 20px;
  width: 20px;
  height: 20px;
  z-index: 101;
  top: -7px;
  right: 0;
}


.dealer-button-button {
  max-width: 20px;
  max-height: 20px;
  width: 20px;
  height: 20px;
} 

.user-game-logo {
  /* height: 6vh; */
  /* width: 9.142vw; */
  width: 10.142vw;
  border-radius: 50%;
}
.user-bet-username {
  margin-top: -10px;
  position: relative;
  z-index: 1000;
}

.user-username {
  font-weight: 500;
  font-size: 1.2vh;
}

.user-bet {
  margin-top: -25px;
  font-weight: 500;
  font-size: 1.5vh;
  background-color: #5C4E73;
  position: relative;
  border-radius: var(--border-mixed);
  z-index: 10;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.user-card-1 {
  position: relative;
  height: 2.7vh;
  margin-left: -35%;
  top: -13px;
  rotate: -48.55deg;
  z-index: 5;
  transition: 0.3s;
}

.user-card-2 {
  position: relative;
  height: 2.7vh;
  top: -16px;
  left: 5px;
  rotate: -22.42deg;
  margin-left: -10px;
  z-index: 4;
  transition: 0.3s;
}

.player {
  position: relative;
  transition: 0.3s;
  z-index: 3;
}

.active-player {
  transform: scale(1.1, 1.1) translateY(-0.9vw);
  position: relative;
}

.not-active-player {
  transform: scale(1);
}
.user-image-count {
  position: relative;
  display: flex;
  justify-content: center;
}

@keyframes fadeIn {
  0% {
    transform: scale(1.2, 1.2) translateY(-6px);
  }
  100% {
    transform: scale(1);
  }
}

.showdown-card {
  position: absolute;
  animation: showdowncard 0.45s ease forwards;
}

.showdown-div {
  position: absolute;
  animation: showdown 0.45s ease forwards;
}

.showdown-card-delay {
  animation-delay: 0.1s;
}

@keyframes showdowncard {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showdown {
  from {
    opacity: 0;
  }
  to {
    bottom: 55px;
    opacity: 1;
  }
}

</style>