import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/tools/close-modal.svg'


const _hoisted_1 = {
  key: 0,
  class: "delete-modal-wrapper"
}
const _hoisted_2 = { class: "delete-modal" }
const _hoisted_3 = { class: "warning-main" }
const _hoisted_4 = { class: "warning-full" }

import store from "@/store";
import router from "@/router";
import {onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'TopUpModal',
  setup(__props) {


const { t } = useI18n();

const closeModal = () => {
  store.commit('SET_FOCUSED', false);
  store.commit('SET_OPEN_MODAL', "");
}

// const topUp = () => {
//   router.push('/profile/top-up');
//   triggerHapticFeedback('light');
//   closeModal();
// }

const inVite = () => {
  router.push('/friends');
  triggerHapticFeedback('light');
  closeModal();
}

onUnmounted(() => {
  triggerHapticFeedback('light');
  closeModal()
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

return (_ctx: any,_cache: any) => {
  return (_unref(store).getters.OPEN_MODAL === 'balance-top-up')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", { class: "close-modal-wrapper" }, [
            _createElementVNode("img", {
              src: _imports_0,
              onClick: closeModal
            })
          ]),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`top_up_modal.warning_main`)), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`top_up_modal.warning_full`)), 1),
          _createElementVNode("div", {
            class: "cancel-button",
            onClick: inVite
          }, _toDisplayString(_unref(t)(`top_up_modal.cancel_button`)), 1)
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})