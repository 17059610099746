<script setup lang="ts">

import {defineProps, defineEmits, ref, onMounted} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import Player from "@/models/player";

const { t } = useI18n();

const props = defineProps<{
  player: Player,
  room: Room,
}>()

const emit = defineEmits(['close']); 

const tg = window.Telegram?.WebApp;

const timer = ref<number>(15);

onMounted (()=> {
  timer.value = 3;
  countTimer();
})

function countTimer(){
  const interval = setInterval(() => {
    if (timer.value > 1) {
      timer.value--;  
    } else {
      emit('close');
      clearInterval(interval);
    }
  }, 1000);
}

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

function Close () {
  triggerHapticFeedback('light');
  emit('close')
}

</script>


<template>

  <div class="notfound-room-background" @click="Close()"></div>
  <div class="notfound-room">
    <div class="notfound-wrapper">
      <div class="notfound-wrapper-card">
        <div class="notfound-up">
          <div class="notfound-up-up">
            <div class="notfound-text1">
              <p>{{ t(`main.text_not_found1`)}} </p>
            </div>
            <div class="notfound-close" @click="Close()">
              <img src="@/assets/tools/close-modal.svg" class="close">
            </div>
          </div>
          <div class="notfound-text2">
            <p>{{ t(`main.text_not_found2`) }}</p>
          </div>
        </div>        
        <img src="@/assets/logo/logo-background.svg" class="background-logo no-image-animation">
      </div>
    </div>
  </div>

</template>

<style scoped>

.notfound-timer {
  margin-top: 10px;
  font-size: 15px;
  color: #B9B9B9;
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.notfound-room {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 1001;
  scale: 0.9;
  opacity: 0;
  animation: fadeInScale 0.2s ease-out 0.2s forwards;
}

.notfound-room-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #2A2B3799;
  animation: fadeIn 0.2s ease-out;
}


.notfound-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.notfound-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
  padding-bottom: 30px;
}

.notfound-background-logo {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.notfound-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
  z-index: 3;
  width: 77.67vw;
}

.notfound-up-up {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.notfound-text1 {
  font-size: 18px;
  font-weight: 600;
}

.notfound-close {
  width: 2.8vw;
}

.notfound-text2 {
  font-size: 15px;
  margin-top: 30px;
  text-align: center;
}

</style>