<script setup lang="ts">

import WalletComponent from "@/components/wallet/WalletComponent.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/profile')
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

</script>

<template>
  <div>
    <img class="background-vector" src="@/assets/wallet/wallet-background-vector.svg">
    <p class="connect-wallet-label">{{ t(`deposit_and_withdraw.connect_wallet_label`) }}</p>
    <div class="wallet-component-wrapper">
      <wallet-component/>
    </div>
  </div>
</template>

<style scoped>
.connect-wallet-label {
  font-weight: 500;
  font-size: 18px;
}

.wallet-component-wrapper {
  margin-top: 52px;
}
</style>