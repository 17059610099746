<script setup lang="ts">
import HeaderMini from "@/components/header/HeaderMini.vue";
import settings from "@/views/settings";
import SettingsItem from "@/components/settings/SettingsItem.vue";
import DeleteAccountModal from "@/components/modals/DeleteAccountModal.vue";
import UserAgreementModal from "@/components/modals/UserAgreementModal.vue";
import PrivacyPolicy from "@/components/modals/PrivacyPolicy.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, nextTick, ref} from "vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const tg = window.Telegram?.WebApp;

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/');
};

const settingsContainer = ref<HTMLElement | null>(null);

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  scrollToTop()
  on('back_button_pressed', listener);
})

function scrollToTop() {
    if (settingsContainer.value) {
        nextTick(() =>{
          settingsContainer.value!.scrollTop = settingsContainer.value!.scrollHeight;
        })
    }
}

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});
</script>

<template>
  <div ref="settingsContainer" class="settings" :style="{'margin-top': tg.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + 20px)': 0}">
    <header-mini/>
    <div class="settings-wrapper">
      <p class="settings-label springy-animation">{{ t('settings.settings') }}</p>
      <div class="setting-wrapper" v-for="(item, i) in settings" :key="i">
        <div v-if="item.type == 'switch'">
          <settings-item 
          @click="settings[i].defaultState = !item.defaultState" 
          :on="item.on" 
          :off="item.off" 
          :type="item.type" 
          :label="item.label" 
          :default-state="item.defaultState"/>
        </div>
        <div v-else>
          <settings-item :click="item.click" :type="item.type" :label="item.label"/>
        </div>
      </div>
    </div>
  </div>
  <delete-account-modal/>
  <user-agreement-modal/>
  <privacy-policy/>
  
</template>

<style scoped>
.settings-wrapper {
  margin-top: 100px;
  margin-bottom: 80px;
}

.settings-label {
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.setting-wrapper {
  margin-top: 14px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.2s ease-out 0.1s forwards;
}

.settings {
  margin-bottom: 130px;
}
</style>