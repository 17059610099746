<script setup lang="ts">
import Player from "@/models/player";
import Game from "@/models/game";
import { defineProps, ref, watch, inject, onMounted } from "vue";

const playTimeEndSoundBuffer = inject<() => void>("playTimeEndSoundBuffer");
const playYourMoveSound = inject<() => void>("playYourMoveSound");

const props = defineProps<{
  player: Player,
  game: Game,
  showPlayer: boolean,
  myplayer: boolean
}>()

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (tg?.HapticFeedback) {
    tg.HapticFeedback.notificationOccurred(style);
  }
}

const strokeWidth = 3;
const dashOffset = ref(0);
const circumference = ref(0);
const radius = ref(0);

const timer = ref(20);
let timerInterval: ReturnType<typeof setInterval> | null = null;
let countdown: ReturnType<typeof setInterval> | null = null;
let startTime: number;
const duration = 20000;

let tenSecondTriggered = false;

function calculateCircle() {
  const size = 100;
  radius.value = (size / 2) - (strokeWidth / 2);
  circumference.value = 2 * Math.PI * radius.value;
  dashOffset.value = circumference.value;
}

function startTimer() {
  if (timerInterval) clearInterval(timerInterval as unknown as number);
  if (countdown) clearInterval(countdown as unknown as number);

  timer.value = 20;
  dashOffset.value = circumference.value;
  startTime = Date.now();
  tenSecondTriggered = false;

  if (props.myplayer && playYourMoveSound) {
    triggerHapticFeedback('warning');
    playYourMoveSound(); 
  }

  timerInterval = setInterval(() => {
    if (timer.value > 0) {
      timer.value--;

      if (props.player.id !== props.game?.current_player_id) {
        clearInterval(timerInterval as unknown as number);
        clearInterval(countdown as unknown as number);
        timerInterval = null;
        countdown = null;
        timer.value = 20;
        dashOffset.value = circumference.value;
        return;
      }

      if (timer.value === 10 && !tenSecondTriggered) {
        tenSecondTriggered = true;
        animateMyUserCount();
        if (playTimeEndSoundBuffer && props.myplayer) {
          triggerHapticFeedback('error');
          playTimeEndSoundBuffer();
        }
      }
    } else {
      clearInterval(timerInterval as unknown as number);
      timerInterval = null;
    }
  }, 1000);

  countdown = setInterval(() => {
    const elapsed = Date.now() - startTime;
    const progress = elapsed / duration;

    dashOffset.value = circumference.value * (1 - progress);

    if (progress >= 1) {
      clearInterval(countdown as unknown as number);
      dashOffset.value = 0;
    }
  }, 20);
}

function animateMyUserCount() {
  const myUserCount = document.querySelector('.myuser-count');
  const UserCount = document.querySelector('.user-count');
  const countCirc = document.querySelector('.count-circle')
  if (myUserCount && countCirc) {
    myUserCount.classList.add('animate-haptic');
    myUserCount.classList.remove('myuser-count');
    myUserCount.classList.add('myuser-count-animate-haptic2');
    countCirc.classList.add('count-circle-red');
    setTimeout(() => {
      myUserCount?.classList.remove('animate-haptic');
    }, 500);
  }
  if (UserCount && countCirc) {
    UserCount.classList.remove('user-count');
    UserCount.classList.add('animate-haptic2');
    countCirc.classList.add('count-circle-red');
  }
}

function stopCountdown() {
  if (countdown) {
    clearInterval(countdown as unknown as number);
    countdown = null;
  }
}

watch(() => props.game?.current_player_id, (newVal) => {
  if (newVal === props.player.id) {
    startTimer();
  } else {
    if (timerInterval) {
      clearInterval(timerInterval as unknown as number);
      timerInterval = null;
    }
    stopCountdown();
    timer.value = 20;
    dashOffset.value = circumference.value;
  }
});

onMounted(() => {
  if (props.game && props.player.id === props.game.current_player_id) {
    startTimer();
  }
});

window.addEventListener('resize', calculateCircle);
calculateCircle();
</script>

<template>
  <div :class="{'user-count-wrapper': !props.myplayer, 'myuser-count-wrapper': props.myplayer}"  v-if="props.player?.id == game?.current_player_id">
    <svg class="count-border" width="100%" height="100%" viewBox="0 0 100 100">
      <circle class="count-circle" cx="50" cy="50" 
        :r="radius"
        :style="{ 
          strokeDasharray: circumference, 
          strokeDashoffset: dashOffset 
        }">
      </circle>
    </svg>
    <div :class="{'user-count': !props.myplayer, 'myuser-count': props.myplayer}">{{ timer }}</div>
  </div>
</template>

<style scoped>

.myuser-count-animate-haptic2 {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  font-size: 10vw;
  color: #D14A4A;
}

.animate-haptic2 {
  color: #D14A4A;
  transition: color 0.2s;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
}

.animate-haptic {
  transform: scale(1.2);
  color: #D14A4A;
  transition: transform 0.2s, color 0.2s;
}

.count-border {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
}

.count-circle {
  fill: none;
  stroke: #31B545;
  stroke-width: 3;
  transition: stroke-dashoffset 0.1s linear;
}

.count-circle-red {
  stroke: #D14A4A;
}

.myuser-count-wrapper {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background-color: #15151E99;
  border-radius: 50%;
  height: 11.3vh;
  width: 11.3vh;
  padding: 3px;
}

.user-count-wrapper {
  /* position: absolute;
  top: -7px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  padding: 5px;
  background-color: #7861A5;
  border-radius: 30px;
  height: 10px;
  width: 10px; */
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  background-color: #15151E99;
  border-radius: 50%;
  height: 10.142vw;
  width: 10.142vw;
}

.user-count {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  color: #31B545;
}

.myuser-count {
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  font-size: 10vw;
  color: #31B545;
}
</style>