<script setup lang="ts">
import store from "@/store";
import GameCard from "@/models/gameCard";
import Game from "@/models/game";
import Player from "@/models/player";
import { onMounted, watch, defineEmits, inject, KeepAlive, ref, nextTick } from "vue";
import { useI18n } from 'vue-i18n';
import NotificationModal from "@/components/game/NotificationModal.vue";

// const playSound = inject<() => void>("playSound");

const playFlopSound = inject<() => void>("playFlopSound");

const playNewFlop = inject<() => void>("playNewFlop");
const playNewTern = inject<() => void>("playNewTern");



const { t } = useI18n();

type MoveUser = {
  user_id: string;
  username: string | null;
  time: string;
}

type Winner = {
  user_id: string;
  username: string | null;
  sum: number,
  created_at: string
}

type Action = {
  user_id: string,
  username: string | null,
  action_name: string,
  action_sum?: number
}

// eslint-disable-next-line no-undef
const props = defineProps<{
  firstFlopCard: GameCard,
  secondFlopCard: GameCard,
  thirdFlopCard: GameCard,
  ternCard: GameCard,
  riverCard: GameCard,
  game: Game,
  players: Player[],
  playersCount: number,
  hand: { combination: string, cards: { card_rank: string, card_suit: string }[] },
  notificationType: "winner" | "move" | "new_msg",
  moveUser?: MoveUser,
  winners?: Winner[],
  action?: Action,
  messageData?: any,
  firstFlopCardHanled: boolean,
  nahdNotif: string,
}>();

watch(() => props.notificationType, () => {
  console.log("notificationType:", props.notificationType);
  console.log("winners");
  console.log(props.winners)
});

const preloadImage = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.src = url;
  });
};

const flipCard = async (elementId: string, card: GameCard) => {
  const element = document.getElementById(elementId);
  if (!element) return;
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const imageUrl = require(`@/assets/cards/open/${store.getters.USER.vip_status}/${card.card_rank.toLowerCase()}_${card.card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`);

  await preloadImage(imageUrl);

  element.style.animation = 'moveCard 0.7s ease forwards, flipCard 0.5s ease forwards';
  if (card == props.secondFlopCard) {
    element.style.animationDelay = '0.2s, 1.2s';
    setTimeout(() => {
      element.setAttribute('src', imageUrl);
    }, 1450);
  } else if (card == props.thirdFlopCard) {
    element.style.animationDelay = '0.4s, 1.4s';
    setTimeout(() => {
      element.setAttribute('src', imageUrl);
    }, 1650);
  } else {
    element.style.animationDelay = '0s, 1s';
    setTimeout(() => {
      element.setAttribute('src', imageUrl);
    }, 1250);
  }
};

function checkHandCards() {
  const cardsToCheck = [
    props.firstFlopCard,
    props.secondFlopCard,
    props.thirdFlopCard,
    props.ternCard,
    props.riverCard,
  ];

  cardsToCheck.forEach((card, index) => {
    if (!card) return;

    const isCardInCombination = props.hand.cards.some(
      (comboCard) =>
        comboCard.card_rank === card.card_rank &&
        comboCard.card_suit === card.card_suit
    );

    if (isCardInCombination) {
      const elementId = getCardElementId(index);
      const element = document.getElementById(elementId);

      if (element) {
        setTimeout(() => {
          element.style.animation = "highlight-card-table .4s ease forwards";
          setTimeout(() => {
            element.style.animation = "outhighlight-card-table .4s ease forwards";
          }, 2000);
        }, 1800);
      }
    }
  });
}

function getCardElementId(index: number) {
  switch (index) {
    case 0:
      return "flop-1";
    case 1:
      return "flop-2";
    case 2:
      return "flop-3";
    case 3:
      return "tern";
    case 4:
      return "river";
    default:
      return "";
  }
}

watch(
  () => props.hand,
  (newHand, oldHand) => {
    if (JSON.stringify(newHand) !== JSON.stringify(oldHand)) {
      checkHandCards(); 
    }
  },
  { deep: true, immediate: true }
);

const emit = defineEmits(['firstFlopCardHanled']);

// function setPositionPreflopCard() {
//   const tableCardsElement = document.getElementById('tablecards');
  
//   if (tableCardsElement) {
//     const rect = tableCardsElement.getBoundingClientRect();
//     const centerX = rect.width / 2;
//     const centerY = rect.height / 2;

//     const preflopCards = document.getElementsByClassName('preflop-card');

//     for (let i = 0; i < preflopCards.length; i++) {
//       const element = preflopCards[i] as HTMLElement; 
//       element.style.left = `${centerX - element.offsetWidth / 2}px`;
//       element.style.top = `${centerY - element.offsetHeight / 2}px`;
//     }
//   }
// }


onMounted(() => {
  if (props.firstFlopCard.is_opened) flipCard('flop-1', props.firstFlopCard);
  if (props.secondFlopCard.is_opened) flipCard('flop-2', props.secondFlopCard);
  if (props.thirdFlopCard.is_opened) flipCard('flop-3', props.thirdFlopCard);
  if (props.ternCard.is_opened) flipCard('tern', props.ternCard);
  if (props.riverCard.is_opened) flipCard('river', props.riverCard);
});


watch(() => props.firstFlopCard.is_opened, (newValue) => {
  if (newValue && newValue != undefined) {
      flipCard('flop-1', props.firstFlopCard);
      if (playNewFlop) {
        playNewFlop(); 
      }    
  } 
  else {
    console.log('undefined у карты firstFlopCard')
  }
}, { deep: true });

watch(() => props.secondFlopCard.is_opened, (newValue) => {
  if (newValue && newValue != undefined) {
    flipCard('flop-2', props.secondFlopCard);
  // if (playSound) {
  //   playSound(); 
  // }
  // setTimeout(() => {
  //   if (playFlopSound) {
  //   playFlopSound(); 
  // }  
  // },800)
  }
  else {
    console.log('undefined у карты secondFlopCard')
  }
}, { deep: true });

watch(() => props.thirdFlopCard.is_opened, (newValue) => {
  if (newValue && newValue != undefined) {
    flipCard('flop-3', props.thirdFlopCard);
  //   if (playSound) {
  //   playSound(); 
  // }  
  } 
  else {
    console.log('undefined у карты thirdFlopCard')
  }
}, { deep: true });

watch(() => props.ternCard.is_opened, (newValue) => {
  if (newValue && newValue != undefined) {
    nextTick(() => {
      flipCard('tern', props.ternCard);
        if (playNewTern) {
          playNewTern(); 
      } 
    });
  // setTimeout(() => {
  //   if (playFlopSound) {
  //   playFlopSound(); 
  // }  
  // },800)
  }
  else {
    console.log('undefined у карты ternCard')
  }
}, { deep: true });

watch(() => props.riverCard.is_opened, (newValue) => {
  if (newValue && newValue != undefined) {
    nextTick(() => {
      flipCard('river', props.riverCard);
      if (playNewTern) {
        playNewTern(); 
      }  
      setTimeout(() => {
        if (playFlopSound) {
        playFlopSound(); 
      }  
      },800)
    });
  }
  else {
    console.log('undefined у карты riverCard')
  }
}, { deep: true });


</script>

<template>
  <div class="table-cards" id="tablecards">
    <div class="logo-background-wrapper">
      <img src="@/assets/logo/background_logo_partner.svg" class="logo-background">
        <!-- <img src="@/assets/logo/logo-background-table.svg" class="logo-background">  -->
    </div>
    <div class="absolut-cards">
      <img :class="['preflop-card']" id="preflop-1" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-2" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-3" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-4" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-5" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-6" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-7" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-8" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-9" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-10" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-11" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-12" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-13" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-14" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-15" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-16" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
      <img :class="['preflop-card']" id="preflop-base" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
    </div>
    <div class="bank-bet">
      <div class="bank">
        <p class="bank-label">{{ t(`game.bank`) }}</p>
        <p class="bank-sum">{{ game?.bank ?? 0 }}</p>
      </div>
      <div class="bet">
        <p class="bet-label">{{ t(`game.bet`) }}</p>
        <p class="bet-sum">{{ props.players.length!=0 ? Math.max(...players.map(x => x.current_round_bet)) : 0 }}</p>
      </div>
    </div>
    <div class="cards">
      <div class="cartas">
        <img :class="['card']" id="flop-1"
             :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)"
        >
        <img :class="['card']" id="flop-2"
             :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)"
        >
        <img :class="['card']" id="flop-3"
             :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)"
        >
        <img v-if="props.ternCard.is_opened" :class="['card']" id="tern"
            :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)"
        >
        <img v-if="props.riverCard.is_opened" :class="['card']" id="river"
            :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)"
        >
      </div>
    </div>
    <notification-modal v-if="notificationType == 'move' && moveUser" 
      :notification-type="'move'" 
      :move-user="moveUser" 
      :action="action" 
      :card_open="props.firstFlopCard.is_opened"
      :key="moveUser ? moveUser.time : ''"
      /> 
      <notification-modal v-if="notificationType && winners !== undefined" 
      :notification-type="'winner'" 
      :winners="winners" 
      :hand="nahdNotif"
      :card_open="props.firstFlopCard.is_opened"
      :key="winners.map(element => element.created_at).join(', ')" 
      />
  </div>
</template>

<style scoped>

.cartas {
  display: flex;
  z-index: 100;
}

.bet {
  display: flex;
  align-items: center;
  gap: 6px;
}

.bank-bet {
  display: flex;
  justify-content: space-around;
  background-color: #09080C60;
  gap: 44px;
  border-radius: 50px;
  padding: 2px 12px;
}

.absolut-cards {
  top: 50%;
  position: absolute;
}

.bank-label, .bet-label {
  color: #C6BDD4;
  font-size: 2.6vw;
  font-weight: 700;
  letter-spacing: 8%;
}

.bank-sum, .bet-sum  {
  font-size: 4.6vw;
  font-weight: 700;
}

.bank{
  display: flex;
  align-items: center;
  gap: 6px;
}

.table-cards {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: -100px;
  z-index: 2;
  justify-content: space-around;
  width: 133%;
  box-sizing: border-box;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0)), #423757;
  border: 3px solid #5c4e73;
  box-shadow: 4px 7px 40px rgba(0, 0, 0, .6);
  border-radius: 500px;
  /* padding: 31px 13px; */
  align-items: center;
  overflow: hidden;
  padding-top: 31px;
  padding-right: 31px;
  padding-left: 31px;
  padding-bottom: 67px;
}

.cards {
  z-index: 100;
  text-align: right;
  position: relative;
}

.card {
  opacity: 0;
  margin: 3px;
  width: 12.3vw;
  position: relative;
  animation: none;
  visibility: visible;
}

.logo-background-wrapper {
  position: absolute;
  z-index: -1;
  height: 100%;
  display: flex;
  border-radius: 100px;
  overflow: hidden;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-background {
  z-index: -1;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: blur(0.7px);
}


.stack {
  z-index: 1000;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: .2s ease all;
}

.stack-card {
  height: 10vh;
  width: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.preflop-card{
  opacity: 0;
  position: absolute;
  height: 25vw;
  transition: left .3s ease, top .3s ease; 
  top: 50%;
  left: 50%;
  translate: (-50%, -50%);
  transform: translate(-50%, -50%);
}

#preflop-1, #preflop-2, #preflop-3, #preflop-4, #preflop-5, #preflop-6, #preflop-7, #preflop-8,
#preflop-9, #preflop-10, #preflop-11, #preflop-12, #preflop-13, #preflop-14, #preflop-15, #preflop-16 {
  z-index: 100;
} 

#preflop-base {
  z-index: 90;
}

</style>