import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/logo/logo.svg'
import _imports_1 from '@/assets/wallet/ton-transactions.svg'


const _hoisted_1 = { class: "transaction-wrapper" }
const _hoisted_2 = { class: "transaction-labels-wrapper" }
const _hoisted_3 = { class: "transaction-label" }
const _hoisted_4 = {
  key: 0,
  class: "transaction"
}
const _hoisted_5 = { class: "transaction-type-wrapper" }
const _hoisted_6 = { class: "transaction-type" }
const _hoisted_7 = { class: "transaction-sum-wrapper" }
const _hoisted_8 = { class: "transaction-sum" }
const _hoisted_9 = { class: "transaction-date" }
const _hoisted_10 = { class: "transaction-again-label" }
const _hoisted_11 = { class: "operation-details-label" }
const _hoisted_12 = { class: "operation-details" }
const _hoisted_13 = { class: "status-label" }
const _hoisted_14 = { class: "status" }
const _hoisted_15 = { class: "commission-label" }

import {onMounted, onUnmounted, ref} from "vue";
import Transaction from "@/models/transaction";
import router from "@/router";
import {useRoute} from "vue-router";
import {UserService} from "@/api/userService";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {formatLocalDate} from "@/tools/tools";
import store from "@/store";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionView',
  setup(__props) {

const { t } = useI18n();

const transaction = ref<Transaction | null>(null);

let id = 0;

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/profile/transactions')
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
  const route = useRoute();
  if (route.query.id) {
    id = parseInt(route.query.id);
  }
  const userService = new UserService();
  userService.transaction(id)
      .then(response => response.json())
      .then((data: Transaction) => {
        transaction.value = data;
      })
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

const repeatTransaction = (transaction: Transaction) => {
  window.scrollTo(0, 0);
  router.push(`/profile/${transaction.type}`)
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`profile.history`)) + " " + _toDisplayString(_unref(t)(`profile.transaction`)), 1),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "transaction-hr" }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("img", {
        src: _imports_0,
        class: "no-image-animation"
      }, null, -1))
    ]),
    (transaction.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _cache[3] || (_cache[3] = _createElementVNode("img", {
              src: _imports_1,
              class: "transaction-ton"
            }, null, -1)),
            _createElementVNode("p", _hoisted_6, _toDisplayString(transaction.value.type === 'top-up' ? _unref(t)('profile.add') : _unref(t)('profile.withdraw2')), 1)
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_8, _toDisplayString(transaction.value.type === 'top-up' ? '+' : '-') + _toDisplayString(transaction.value.ton_sum), 1)
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", null, [
              _createElementVNode("p", { class: "transaction-currency" }, "TON")
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_unref(formatLocalDate)(transaction.value.updated_at, true).split(' ')[0]) + " в " + _toDisplayString(_unref(formatLocalDate)(transaction.value.updated_at, true).split(' ')[1]), 1),
          _createElementVNode("div", {
            class: "transaction-again",
            onClick: _cache[0] || (_cache[0] = ($event: any) => {triggerHapticFeedback('light'); repeatTransaction(transaction.value)})
          }, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(t)(`profile.transaction_again_label`)), 1)
          ]),
          _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(t)(`profile.operation_details_label`)), 1),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)(`profile.status`)), 1),
            _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(t)(`profile.status_success`)), 1),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "operation-details-hr" }, null, -1)),
            _createElementVNode("p", _hoisted_15, _toDisplayString(_unref(t)(`profile.withdraw_fee`)), 1),
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "commission" }, "0.06 TON", -1))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})