
<script setup lang="ts">
import {onMounted, onUnmounted, ref, defineProps, nextTick, watch} from "vue";
import WalletComponent from "@/components/wallet/WalletComponent.vue";
import { useStore } from "vuex";
import router from "@/router";
import {UserService} from "@/api/userService";
import {MiniAppsEventListener, number, off, on, postEvent} from "@telegram-apps/sdk";
import { useI18n } from 'vue-i18n';

let intervalId: ReturnType<typeof setInterval> | null = null;

const { t } = useI18n();

const store = useStore();

const currentLevel = getUserLevel(store.getters.USER.vip_status);

function getUserLevel(vipStatus: string): number {
  switch (vipStatus) {
    case 'usual':
      return 2; 
    case 'silver':
      return 3; 
    case 'gold':
      return 4; 
    default:
      return 1; 
  }
}

store.commit('SET_OLD_LEVEL', currentLevel);

const tg = (window as any).Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const props = defineProps<{
  action: "top-up" | "withdraw"
}>();


let inputData = ref("1");
if (props.action==="top-up"){
  inputData = ref("1");
}
else inputData = ref("1000");

let currentRate = ref();

let comission = ref();

let result = ref();

let commisionOnWithdraw = 5;

let currentResult = ref(0);

const inputRef = ref<HTMLInputElement | null>(null);

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/profile');
};

const mainButtonPressed: MiniAppsEventListener<'main_button_pressed'> = payload => {
  if (props.action==="top-up"){
    if(isSwapped.value){
      if (inputData.value < '5') {
        return;
      }
    }
    else {
      if (inputData.value === '0') {
        return;
      }
    }      
  }
  else if (parseInt(inputData.value) < 1000){
    return;
  }
  
  postEvent('web_app_setup_main_button', {
    is_progress_visible: true,
  })
  doBalanceAction();
}


const isSwapped = ref(false);
//false - TON, true - USDT

function toggleSwap() {
  isSwapped.value = !isSwapped.value;
}

watch(isSwapped, (newVal: boolean) => {
  if (isSwapped.value) {
    currentResult.value = (Number(inputData.value)*200) - (Number(inputData.value)*200) * 0.02;
  }
  else {
    currentResult.value = Math.ceil(((Number(currentRate.value) * Number(inputData.value)) * 200) - ((Number(currentRate.value) * Number(inputData.value)) * 200 * 0.02));
  }
});

const fetchTonToUsdtRate = async () => {
  try {
    const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=TONUSDT');
    const data = await response.json();
    const rate = parseFloat(data.price);
    currentRate.value = rate;
    return rate;
  } catch (error) {
    console.error('Erorr TON to USDT:', error);
    return null;
  }
}

function onInput(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value.replace(/\D/g, '');
  if (value === '') {
    value = '0';
  }
  else if (value.charAt(0) ===  '0') {
    value = value.substring(1)
  }

  inputData.value = value;
  if (props.action === 'top-up'){
    if (isSwapped.value) {
      comission.value = Number(inputData.value)*2/100;
      currentResult.value = (Number(inputData.value)*200) - (Number(inputData.value)*200) * 0.02;
    }
    else {
      comission.value = Number(inputData.value)*2/100;
      currentResult.value = Math.ceil(((Number(currentRate.value) * Number(inputData.value)) * 200) - ((Number(currentRate.value) * Number(inputData.value)) * 200 * 0.02));
    }
  } else {
    comission.value = Number(inputData.value)/1000 * commisionOnWithdraw / 100;
    result.value = Number(inputData.value) / 1000 - comission.value;
  }
}

function setInputData(data: string) {
  inputData.value = data;
  comission.value = Number(inputData.value)*2/100;
  if (isSwapped.value) {
    currentResult.value = (Number(inputData.value)*200) - (Number(inputData.value)*200) * 0.02;
  }
  else {
    currentResult.value = Math.ceil(((Number(currentRate.value) * Number(inputData.value)) * 200) - ((Number(currentRate.value) * Number(inputData.value)) * 200 * 0.02));
  }
  result.value = Number(inputData.value)- comission.value;
}

onMounted(async () => {

  if (!store.getters.USER.address) {
    router.push('/profile/connect-wallet')
  }
  else {
    await fetchTonToUsdtRate();
    if (inputRef.value) {
    if (props.action==="top-up"){
      inputRef.value.value = "1";
      comission.value = Number(inputData.value)*2/100;
      result.value = Number(inputData.value) - comission.value;
      currentResult.value = Math.ceil(((Number(currentRate.value) * Number(inputData.value)) * 200) - ((Number(currentRate.value) * Number(inputData.value)) * 200 * 0.02));
    }
    else {
      inputRef.value.value = "1000";
      comission.value = Number(inputData.value)/1000 * commisionOnWithdraw / 100;
      result.value = Number(inputData.value) / 1000 - comission.value;
    }
    inputRef.value.focus();
    }

    intervalId = setInterval(() => {
      fetchTonToUsdtRate();
    }, 30000);

    const levelElem = document.getElementById('level');
  
    if (levelElem){
        if (store.getters.USER.vip_status == 'first'){
        levelElem.innerText = '1'
        commisionOnWithdraw = 5;
      }
      else {
        switch (store.getters.USER.vip_status){
          case 'silver':{
            levelElem.innerText = '3'
            commisionOnWithdraw = 3;
            break;
          }
          case 'gold':{
            levelElem.innerText = '4'
            commisionOnWithdraw = 2;
            break;
          }
          default:{
            levelElem.innerText = '2'
            commisionOnWithdraw = 4;
            break;
          }
        }
      }
    }

    store.commit('SET_FOCUSED', true)
    postEvent('web_app_setup_back_button', { is_visible: true});
    postEvent('web_app_setup_main_button', {
      is_visible: true,
      is_active: true,
      is_progress_visible: false,
      text: props.action == 'top-up' ? 'Пополнить' : 'Вывести',
      color: '#32A1E6',
      text_color: '#FFFFFF'
    })
    on('back_button_pressed', listener);
    on('main_button_pressed', mainButtonPressed);

    nextTick(() => {
      const observeElement = (parent: HTMLElement | null, scale: HTMLElement | null) => {
        if (scale && parent) {
          const observer = new ResizeObserver(() => {
            scaleContent(parent, scale);
          });
          observer.observe(scale);
          resizeObservers.push(observer);
        }
      };
      observeElement(wrapperChild.value, wrapperScale.value);
      observeElement(containerRef.value, textScale.value);
      observeElement(balanceWrapperRef.value, balanceScale.value);

      scaleContent(wrapperChild.value, wrapperScale.value);
      scaleContent(containerRef.value, textScale.value);
      scaleContent(balanceWrapperRef.value, balanceScale.value);

      window.addEventListener('resize', () => {
        scaleContent(wrapperChild.value, wrapperScale.value);
        scaleContent(containerRef.value, textScale.value);
        scaleContent(balanceWrapperRef.value, balanceScale.value);
      });
    });
  }
});

onUnmounted(() => {
  store.commit('SET_FOCUSED', false)
  postEvent('web_app_setup_back_button', { is_visible: false});
  postEvent('web_app_setup_main_button', {
    is_visible: false,
    is_active: false
  })
  off('back_button_pressed', listener);
  off('main_button_pressed', mainButtonPressed);
  resizeObservers.forEach((observer) => observer.disconnect());
  resizeObservers = [];
  window.removeEventListener('resize', scaleContent);
  if (intervalId) {
    clearInterval(intervalId);
  }
})


const doBalanceAction = () => {
  const userService = new UserService();
  if (props.action === 'top-up') {
    userService.topUp(Number(inputData.value)
    // isSwapped.value ? 'usdt': 'ton'
  )
        .then(response => response.json())
        .then(data => {
          store.commit('SET_USER', data);
        });
  } else {
    userService.withdraw(parseInt(inputData.value)/1000)
        .then(response => response.json())
        .then(data => {
          if (data.balance !== undefined) {
            store.commit('SET_USER', data);
          }
        });
  }
  router.push('/profile');
}

const wrapperChild = ref<HTMLElement | null>(null);
const containerRef = ref<HTMLElement | null>(null);
const balanceWrapperRef = ref<HTMLElement | null>(null);

const wrapperScale = ref<HTMLElement | null>(null);
const textScale = ref<HTMLElement | null>(null);
const balanceScale = ref<HTMLElement | null>(null);


let resizeObservers: ResizeObserver[] = [];

const scaleContent = (parent: HTMLElement | null, scale: HTMLElement | null) => {
  if (parent && scale) {
    const parentWidth = parent.offsetWidth;
    const scaleWidth = scale.offsetWidth;

    if (scaleWidth > parentWidth) {
      const scaleFactor = parentWidth / scaleWidth;
      scale.style.transform = `scale(${scaleFactor})`;
    } else {
      scale.style.transform = 'scale(1)';
    }
  }
};

</script>

<template>
  <div class="balance-action-input">
    <div class="balance-action-wrapper-start">
      <div class="balance-action-wrapper-start-up">
        <p class="balance-action-label">{{ t(`deposit_and_withdraw.you_are`) }} {{ action === 'top-up' ? t('deposit_and_withdraw.depositing') : t('deposit_and_withdraw.withdraw')}}:</p>
        <p>{{ t(`deposit_and_withdraw.сurrency`) }}</p>
      </div>
      <div class="balance-action-wrapper-start-center">
        <div class="balance-action-wrapper-start-center-left">
          <div class="input-wrapper">
            <div class="input-wrapper-child" ref="wrapperChild">
              <div class="input-wrapper-cild-scale" ref="wrapperScale">
                <div class="input-and-top">
                  <div class="input">
                    <input v-if="action === 'top-up'" id="input"  inputmode="decimal" @input="onInput" maxlength="8" class="balance-input" v-model="inputData" ref="inputRef" value="">
                    <input v-else id="input" inputmode="decimal" @input="onInput" class="balance-input" v-model="inputData" ref="inputRef" value="">
                    <div class="result" :style="{ color: inputData.toString() == '0' ? 'red' : 'white' }">{{ inputData }}</div>
                  </div>
                  <div class="crypto" v-if="action === 'top-up'">
                    <p v-if="isSwapped" class="balance-input-ton">USDT</p>
                    <p v-else class="balance-input-ton">TON</p>
                  </div>
                  <div class="crypto" v-else>
                    <img class="balance-game-logo-big" src="@/assets/logo/money_logo.svg">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="action==='top-up'">
            <div class="container" ref="containerRef">
              <div class="text" ref="textScale">
                <div class="balance-wrapper-comission">
                  <p class="balance-level">{{ t(`deposit_and_withdraw.fee`) }}:&nbsp;</p>
                  <p v-if="isSwapped" class="balance-game-label" id="comission">
                    {{ comission + ' USDT' }}
                  </p>
                  <p v-else class="balance-game-label" id="comission">
                    {{ comission + ' TON' }}
                  </p>
                </div>
              </div>
            </div>  
          </div>
          <div v-else>
            <div class="container" ref="containerRef">
              <div class="text" ref="textScale">
                <div class="balance-wrapper">
                  <p class="balance-level">{{ t(`deposit_and_withdraw.your`) }}&nbsp;{{ t(`deposit_and_withdraw.level`) }}&nbsp;</p>
                  <p class="balance-game-label" id="level">
                    1
                  </p>
                </div>
                <div class="balance-wrapper-comission">
                  <p class="balance-level">{{ t(`deposit_and_withdraw.fee`) }}:&nbsp;</p>
                  <p class="balance-game-label" id="comission">
                    {{ comission + ' TON' }}
                  </p>
                </div>
                <div class="balance-wrapper-comission">
                  <p class="balance-level">{{t(`deposit_and_withdraw.result`)}}&nbsp;</p>
                  <p class="balance-game-label" id="comission">
                    {{result + ' TON'}}
                  </p>
                </div>
              </div>
            </div>
            <div class="reduce-commission" @click="triggerHapticFeedback('light'); router.push('/levels')">
                <p>{{ t(`deposit_and_withdraw.reduce`) }}&nbsp;{{ t(`deposit_and_withdraw.reduce_fee`) }}</p>
              </div>
          </div>
          <div v-if="action === 'top-up'" class="balance-game-wrapper" ref="balanceWrapperRef">
            <div class="balance-game-wrapper-scale" ref="balanceScale">
              <!-- <p class="balance-game-label">{{ t(`deposit_and_withdraw.result`) }}{{ (parseInt(inputData) * 1000) - (parseInt(inputData) * 1000 * 0.02) }}</p> -->
              <p class="balance-game-label">{{ t(`deposit_and_withdraw.result`) }}{{ currentResult }}</p>
              <img class="balance-game-logo" src="@/assets/logo/money_logo_gc.svg">
            </div>
          </div>
        </div>
        <div class="balance-action-wrapper-start-center-right" @click="toggleSwap">
          <svg 
            :class="['svg-animated', isSwapped ? 'to-bottom-right' : 'to-top-left']"
            viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg"
          >
          <path 
            :class="['path-animated']"
            :fill="isSwapped ? '#606060' : '#0098EA'" d="M76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76C58.9868 76 76 58.9868 76 38Z"/>
            <path d="M50.9807 20.8999H25.0305C20.2577 20.8999 17.2348 26.047 19.6345 30.208L35.6515 57.967C35.8898 58.3806 36.2329 58.724 36.6461 58.9629C37.0594 59.2017 37.5283 59.3274 38.0056 59.3274C38.4829 59.3274 38.9518 59.2017 39.3651 58.9629C39.7783 58.724 40.1214 58.3806 40.3597 57.967L56.3786 30.208C58.7764 26.0527 55.7516 20.8999 50.9826 20.8999H50.9807ZM35.6382 49.6431L32.1498 42.8924L23.7328 27.8406C23.6048 27.6159 23.5384 27.3614 23.5401 27.1028C23.5419 26.8442 23.6118 26.5906 23.7429 26.3677C23.8739 26.1447 24.0614 25.9603 24.2865 25.8329C24.5115 25.7056 24.7662 25.6398 25.0248 25.6423H35.6344V49.6469L35.6382 49.6431ZM52.2708 27.8368L43.8576 42.8962L40.3692 49.6431V25.6385H50.9769C52.1397 25.6385 52.8275 26.8735 52.2708 27.8368Z" fill="white"/>
          </svg>

          <svg 
            :class="['svg-animated', isSwapped ? 'to-top-left' : 'to-bottom-right']"
            viewBox="0 0 76 76" fill="none" xmlns="http://www.w3.org/2000/svg"
          >
          <path 
            :class="['path-animated']"
            :fill="isSwapped ? '#28C281' : '#606060'" d="M76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76C58.9868 76 76 58.9868 76 38Z"/>
            <path d="M38.0228 60.8944C36.6187 60.8944 35.7105 60.0128 35.7105 58.5327V55.9088C30.2024 55.259 26.3853 52.428 25.2681 48.8161C25.0986 48.3587 25.0105 47.8751 25.0078 47.3873C25.0078 45.7533 26.125 44.6608 27.9167 44.6608C29.4234 44.6608 30.2822 45.5443 30.8275 46.7641C31.8649 49.4146 34.3083 50.8681 38.2565 50.8681C42.4365 50.8681 45.1155 49.2056 45.1155 46.1162C45.1155 43.4923 42.7234 42.1414 38.7505 41.2066L35.2165 40.3744C29.2942 39.0254 25.3726 35.6472 25.3726 30.7376C25.3726 24.8666 29.8908 21.3326 35.7086 20.6068V17.9544C35.7086 16.4724 36.6206 15.5908 38.0228 15.5908C39.425 15.5908 40.3351 16.4743 40.3351 17.9544V20.6049C45.4271 21.2281 49.1131 23.9812 50.2569 27.8002C50.388 28.2942 50.4906 28.7616 50.4906 29.2537C50.4906 30.7604 49.3487 31.6705 47.6862 31.6705C46.1795 31.6705 45.3739 30.9428 44.7241 29.6945C43.5556 26.968 41.5283 25.6437 38.0475 25.6437C34.0746 25.6437 31.6825 27.4107 31.6825 30.1904C31.6825 32.5787 34.0214 34.0607 37.4775 34.8397L40.8538 35.6187C47.5304 37.152 51.3228 40.3991 51.3228 45.4892C51.3228 51.8808 46.2308 55.3084 40.3351 55.9582V58.5308C40.3351 60.0109 39.425 60.8944 38.0228 60.8944Z" fill="white"/>
          </svg>
        </div>
      </div>
    </div>
    <div v-if="action === 'top-up'" class="chips-wrapper" :style="{ 'max-width': '100vw' }">
      <div class="chips" @click="triggerHapticFeedback('soft'); setInputData('10')">
        <p v-if="isSwapped" class="chips-data">10 USDT</p>
        <p v-else class="chips-data">10 TON</p>
      </div>
      <div class="chips" @click="triggerHapticFeedback('soft'); setInputData('20')">
        <p v-if="isSwapped" class="chips-data">20 USDT</p>
        <p v-else class="chips-data">20 TON</p>
      </div>
      <div class="chips" @click="triggerHapticFeedback('soft');setInputData('30')">
        <p v-if="isSwapped" class="chips-data">30 USDT</p>
        <p v-else class="chips-data">30 TON</p>
      </div>
      <div class="chips" @click="triggerHapticFeedback('soft'); setInputData('40')">
        <p v-if="isSwapped" class="chips-data">40 USDT</p>
        <p v-else class="chips-data">40 TON</p>
      </div>
      <div class="chips" @click="triggerHapticFeedback('soft'); setInputData('50')">
        <p v-if="isSwapped" class="chips-data">50 USDT</p>
        <p v-else class="chips-data">50 TON</p>
      </div>
      <div class="chips" @click="triggerHapticFeedback('soft'); setInputData('100')">
        <p v-if="isSwapped" class="chips-data">100 USDT</p>
        <p v-else class="chips-data">100 TON</p>
      </div>
    </div>
    <div class="wallet-wrapper">
      <p class="wallet-label">{{ t(`deposit_and_withdraw.your_ton_wallet`) }}</p>
      <wallet-component/>
    </div>
  </div>
</template>



<style scoped>

.input-wrapper-cild-scale, .text, .balance-game-wrapper-scale {
  transform-origin: left;
}

.balance-game-wrapper-scale {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.input-wrapper {
  width: 100%;
  max-width: 207px;
}

.input-wrapper-child {
  display: flex;
  justify-content: start;
  transition: transform 0.2s ease;
  transform-origin: center;
}

.input-wrapper-cild-scale {
  display: flex;
}

.input-and-top {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.input {
  display: flex;
  position: relative;
}

#input {
  height: 100%;
  width: 100%;
  opacity: 0;
}

.result {
  display: block;
  pointer-events: none;
  white-space: nowrap;
  font-size: 60px;
}

.crypto {
  display: flex;
}

.svg-animated {
  position: absolute;
  transition: width 0.3s ease, height 0.3s ease, z-index 0.3s ease;
}

@keyframes toBottomRight {
  0% {
    top: 0;
    left: 0;
    width: 76px;
    height: 76px;
    z-index: 1;
    opacity: 1;
  }
  50% {
    top: 50%;
    left: 50%;
    width: 56px;
    height: 56px;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  100% {
    bottom: 0;
    right: 0;
    width: 36px;
    height: 36px;
    z-index: 0;
    opacity: 60%;
  }
}

@keyframes toTopLeft {
  0% {
    bottom: 0;
    right: 0;
    width: 36px;
    height: 36px;
    z-index: 0;
    opacity: 60%;
  }
  50% {
    top: 50%;
    left: 50%;
    width: 56px;
    z-index: 0;
    height: 56px;
    transform: translate(-50%, -50%);
  }
  100% {
    top: 0;
    left: 0;
    width: 76px;
    height: 76px;
    z-index: 1;
    opacity: 1;
  }
}

.to-bottom-right {
  animation: toBottomRight 0.3s forwards;
}

.to-top-left {
  animation: toTopLeft 0.3s forwards;
}

.path-animated {
  transition: fill 0.3s ease-in-out;
}

.svg-animated {
  position: absolute;
  transition: width 0.3s ease, height 0.3s ease-in-out, z-index 0.3s ease;
}

/* .ton-currency {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

.usdt-currency {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
} */

.balance-action-wrapper-start-center-right {
  display: flex;  
  position: relative;
  width: 93px;
  height: 88px;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  justify-content: center;
}

.balance-action-wrapper-start-up {
  display: flex;
  justify-content: space-between;
  font-weight: 500;
  font-size: 18px;
}

.balance-action-wrapper-start-center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.test_crypto {
  width: 21.6vw;
  max-width: 93px;
}

.balance-action-wrapper-start {
  margin-top: 39px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.second-wallet {
  margin-top: 15px;
}

.balance-action-input {
  text-align: left;
}


.balance-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  color: transparent;
  inset-inline-start: 0;
  margin: 0;
  outline: none;
  padding: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.balance-input-wrapper {
  display: flex;
  text-align: left;
  align-items: end;
}

.balance-input-ton {
  margin-left: 12px;
  margin-bottom: 5px;
  font-size: 40px;
  text-align: left;
  color: #A4A4A4;
}
.balance-input-ton-min {
  margin-left: 6px;
  font-size: 18px;
  text-align: left;
  color: #A4A4A4;
}

.balance-game-logo {
  width: 20px;
  height: 20px;
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  margin-left: 5px;
}

.balance-game-logo-big {
  width: 34px;
  height: 34px;
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  margin-bottom: 15px;
  margin-left: 10px;
}

.balance-game-wrapper {
  margin-top: 6px;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 207px;
}

.balance-game-label {
  font-weight: 500;
  font-size: 18px;
  white-space: nowrap;
}

.wallet-wrapper {
  margin-top: 20px;
}

.wallet-label {
  margin-bottom: 14px;
  text-align: left;
}

.enter {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 10px 12px;
  background-color: white;
}

.enter-button {
  border-radius: 9px;
  padding-top: 16px;
  padding-bottom: 16px;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
  background-color: #007AFF;
}

.chips-wrapper {
  white-space: nowrap;
  overflow-x: auto;
  display: flex;
  gap: 18px;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.chips-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.chips {
  background: #5C4E73;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 14px;
}

.chips-data {
  font-size: 16px;
  font-weight: 500;
  margin: 11px 13px;
}

.reduce-commission {
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 20px;
  font-weight: 500;
  font-size: 14px;
  background: #5C4E73;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  margin-top: 24px;
}

.container{
  /* margin-top: 20px; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 207px;
}

.text{
  display: flex;
  flex-direction: column;
}

.balance-wrapper{
  display: flex;
  align-items: center;
}

.balance-level{
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  text-align: left;
  color: #A4A4A4;
}
.balance-wrapper-comission{
  display: flex;
  align-items: center;
  margin-top: 6px;
}


</style>
