<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import {onMounted, onUnmounted, defineEmits, ref} from "vue";
import { TournamentServise } from "@/api/tournamentService";
import { MiniAppsEventListener, off, on, postEvent } from "@telegram-apps/sdk";
import { useStore } from "vuex";
import TopUpModalClose from "@/components/modals/TopUpModalClose.vue";

const store = useStore();

const TourServise = new TournamentServise;

const tg = window.Telegram?.WebApp;

const { t } = useI18n();

const emit = defineEmits(['close']); 

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
    emit('close');
};

const exists = ref();

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  TourServise.getTourPlayer('partner')
  .then(response => response.json())
    .then(data => {
        exists.value = data.exists;
  });
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})


function registrTour() {
    if (store.getters.USER.bonus_balance >= 150000) {
        TourServise.register('partner')
        .then(response => {
            if (response.status == 201) {
                emit('close');
                return;
            }
        });
    }
    else {
        showPopAddBalance.value = true;
    }
}

function unRegistrTour() {
    // console.log('i in unRegistrTour')
    TourServise.unRegister('partner')
    .then(response => {
        if (response.status == 200) {
            emit('close');
            return;
        }
    });
}

const showPopAddBalance = ref(false);

const closeChangeRoomPop = () => {
    showPopAddBalance.value = false;
}



</script>

<template>
<top-up-modal-close @close="closeChangeRoomPop" v-if="showPopAddBalance"/>
    <div class="detail-component">
        <div class="detail-background" @click="emit('close')"></div>
        <div class="detail-wrapper" :style="{top: tg.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + 20px)': 60}">
            <div class="header">
                <div class="header-text">
                    {{ t(`tournaments.detail_tour`) }}
                </div>
                <div class="close-modal-wrapper">
                    <img src="@/assets/tools/close-modal.svg" @click="emit('close')">
                </div>
            </div>
            <div class="detail-body-wrapper">
                <div class="details-some">
                    <div class="some-fee">
                        <p class="title-some">{{ t(`tournaments.fee`) }}</p>
                        <div class="some-sum">
                            <svg class="fee-coin-type no-image-animation" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
                            </svg>
                            <p class="sum">150K</p>
                        </div>
                    </div>
                    <div class="some-prize-pool">
                        <p class="title-some">{{ t(`tournaments.prize`) }}</p>
                        <p class="sum">100.000 ₽</p>
                    </div>
                    <div class="some-players-count">
                        <p class="title-some">{{ t(`tournaments.players`) }}</p>
                        <p class="sum">10000</p>
                    </div>
                </div>
                <div class="details-all">
                    <div class="general-information-title">
                        <p class="general-information-title-text">{{ t(`tournaments.general_information`) }}</p>
                    </div>
                    <div class="general-information">
                        <p class="daily-tour">{{ t(`tournaments.daily_tour`) }}</p>
                        <p class="registr">{{ t(`tournaments.registr`) }}</p>
                        <p class="minimum">{{ t(`tournaments.minimum`) }}</p>
                    </div>
                    <div class="general-information-templ">
                        <div class="status">
                            <p class="title-inform">{{ t(`tournaments.status`) }}</p>
                            <div class="point-status">
                                <svg width="9" height="10" viewBox="0 0 9 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="4.5" cy="5.2207" r="4.5" fill="#B698EF"/>
                                </svg>
                                <p class="count-title-inform">{{ t(`tournaments.open_registr`) }}</p>
                            </div>
                        </div>
                        <div class="start">
                            <p class="title-inform">{{ t(`tournaments.start`) }}</p>
                            <p class="count-title-inform">Янв 31, 2025 21:00</p>
                        </div>
                        <div class="count-play">
                            <p class="title-inform">{{ t(`tournaments.player_count`) }}</p>
                            <p class="count-title-inform">10000</p>
                        </div>
                        <div class="stack">
                            <p class="title-inform">{{ t(`tournaments.stack`) }}</p>
                            <div class="stack-sum">
                                <svg class="fee-coin-type no-image-animation" width="14" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#E5D229"/>
                                </svg>
                                <p class="count-title-inform">10 000</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="details-prize-pool">
                    <div class="prize-pool-title">
                        <p>{{ t(`tournaments.prize_pool_title`) }}</p>
                    </div>
                    <div class="prize-pool-body">
                        <div class="prize-place">
                            <p class="prize-pool-head">{{ t(`tournaments.prize_pool_place`) }}</p>
                            <p class="prize-sum">1</p>
                        </div>
                        <div class="prize-pool-percent">
                            <p class="prize-pool-head">%</p>
                            <p class="prize-sum">100%</p>
                        </div>
                        <div class="prize-pool-sum">
                            <p class="prize-pool-head">{{ t(`tournaments.prize_pool_sum`) }}</p>
                            <p class="prize-sum">100.000 ₽</p>
                        </div>
                    </div>
                </div>
                <div class="details-blind-structure">
                    <div class="prize-pool-title">
                        <p>{{ t(`tournaments.structure_blind`) }}</p>
                    </div>
                    <div class="prize-pool-body">
                        <div class="prize-place">
                            <p class="prize-pool-head">{{ t(`tournaments.level`) }}</p>
                            <p class="prize-sum">1</p>
                            <p class="prize-sum">2</p>
                            <p class="prize-sum">3</p>
                            <p class="prize-sum">4</p>
                            <p class="prize-sum">5</p>
                            <p class="prize-sum">6</p>
                            <p class="prize-sum">7</p>
                            <p class="prize-sum">8</p>
                            <p class="prize-sum">9</p>
                            <p class="prize-sum">10</p>
                            <p class="prize-sum">11</p>
                            <p class="prize-sum">12</p>
                            <p class="prize-sum">13</p>
                            <p class="prize-sum">14</p>
                            <p class="prize-sum">15</p>
                            <p class="prize-sum">16</p>
                        </div>
                        <div class="prize-pool-percent">
                            <p class="prize-pool-head">{{ t(`tournaments.bb_mb`) }}</p>
                            <p class="prize-sum">25/50</p>
                            <p class="prize-sum">50/100</p>
                            <p class="prize-sum">75/150</p>
                            <p class="prize-sum">125/250</p>
                            <p class="prize-sum">200/400</p>
                            <p class="prize-sum">300/600</p>
                            <p class="prize-sum">600/1200</p>
                            <p class="prize-sum">1000/2000</p>
                            <p class="prize-sum">1500/3000</p>
                            <p class="prize-sum">3000/6000</p>
                            <p class="prize-sum">5000/10000</p>
                            <p class="prize-sum">8000/16000</p>
                            <p class="prize-sum">15000/30000</p>
                            <p class="prize-sum">20000/40000</p>
                            <p class="prize-sum">40000/80000</p>
                            <p class="prize-sum">70000/140000</p>
                        </div>
                        <div class="prize-pool-sum">
                            <p class="prize-pool-head">{{ t(`tournaments.start_raund`) }}</p>
                            <p class="prize-sum">+00:00:00</p>
                            <p class="prize-sum">+00:10:00</p>
                            <p class="prize-sum">+00:20:00</p>
                            <p class="prize-sum">+00:30:00</p>
                            <p class="prize-sum">+00:40:00</p>
                            <p class="prize-sum">+00:50:00</p>
                            <p class="prize-sum">+01:00:00</p>
                            <p class="prize-sum">+01:10:00</p>
                            <p class="prize-sum">+01:20:00</p>
                            <p class="prize-sum">+01:30:00</p>
                            <p class="prize-sum">+01:40:00</p>
                            <p class="prize-sum">+01:50:00</p>
                            <p class="prize-sum">+02:00:00</p>
                            <p class="prize-sum">+02:10:00</p>
                            <p class="prize-sum">+02:20:00</p>
                            <p class="prize-sum">+02:30:00</p>
                        </div>
                    </div>
                </div>
                <div v-if="!exists" class="btn" @click="registrTour()">
                    <div class="participating-btn">
                        <p class="participating-text">{{ t(`tournaments.participating`) }}</p>
                    </div>
                </div>
                <div v-else class="btn" @click="unRegistrTour()">
                    <div class="participating-btn">
                        <p class="participating-text">{{ t(`tournaments.un_participating`) }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>

.detail-component {
    position: fixed;
    display: flex;
    justify-content: flex-end;
    bottom: 0;
    left: 0;
    right: 0;
    top: -45px;
    z-index: 100;
}

.detail-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    animation: fadeIn 0.2s ease-out;
}

.header {
  position: fixed;
  max-width: 430px;
  width: 100%;
  background-color: #292936;
  border-top-left-radius: 4.65vw;
  border-top-right-radius: 4.65vw;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
  z-index: 1;
  font-size: 18px;
}

.header-text {
    margin-left: 20px;
}

.detail-wrapper {
  background-color: #292936;
  border-top-left-radius: var(--border-mixed);
  border-top-right-radius: var(--border-mixed);
  border-top: #5C4E73 4px solid;
  top: 60px;
  position: fixed;
  width: 100%;
  animation: translateUp 0.4s ease-out;
}

.close-modal-wrapper {
  display: flex;
  justify-content: right;
  margin-right: 20px;
}

.detail-body-wrapper {
    max-width: 430px;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 140px;
    font-size: 14px;
    background-color: #30313E;
    margin-top: 62px;
    text-align: start;
    height: calc(100vh - 160px);
    overflow-y: scroll;
}

.details-some {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: var(--border-mixed);
    padding: 15px 43px;
    background-color: #15151E;
}

.some-fee, .some-prize-pool, .some-players-count {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2px;
}

.some-sum {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 3px;
}

.sum {
    font-size: 16px;
    font-weight: 500;
}

.title-some {
    font-size: 13px;
    color: #B9B9B9;
}

.details-all, .details-prize-pool, .details-blind-structure {
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    background-color: #15151E;
    border-radius: var(--border-mixed);
    padding: 20px;
}

.details-blind-structure {
    margin-bottom: 80px;
}

.general-information-title, .prize-pool-title {
    font-size: 18px;
    font-weight: 500;
}

.general-information {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-top: 28px;
    gap: 15px;
    font-size: 12px;
    font-weight: 500;
    color: #B9B9B9;
}

.general-information-templ {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
}

.status, .start, .count-play, .stack {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 16px;
    font-weight: 500;
}

.title-inform {
    font-size: 16px;
    font-weight: 500;
}

.point-status {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 5px;
}

.count-title-inform {
    font-size: 14px;
    font-weight: 500;
}

.stack-sum {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.prize-pool-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    width: 100%;
}

.prize-place {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 10px;
}

.prize-pool-percent, .prize-pool-sum {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
    gap: 10px;
}

.prize-pool-head {
    font-size: 12px;
    font-weight: 500;
    color: #B9B9B9;
}

.prize-sum {
    font-size: 14px;
    font-weight: 500;
}

.btn {
    position: fixed;
    bottom: 38px;
    z-index: 99;
    left: 5%;
    right: 5%;
}

.participating-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.participating-text {
    width: 100%;
    padding-top: 23px;
    padding-bottom: 23px;
    background-color: #5C4E73;
    border-radius: var(--border-mixed);
    font-size: 18px;
    font-weight: 500;
    display: flex;
    justify-content: center;
}

</style>