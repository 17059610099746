import { useCssVars as _useCssVars, unref as _unref, defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "bet-slider-wrapper" }
const _hoisted_2 = { class: "bet-slider" }
const _hoisted_3 = { class: "bet-sum-draft" }
const _hoisted_4 = ["min", "max", "disabled"]
const _hoisted_5 = ["min", "max", "disabled"]
const _hoisted_6 = { class: "bet-sum-draft" }

import store from "@/store";
import Player from "@/models/player";
import Game from "@/models/game";
import Room from "@/models/room";
import { computed, ref, watch, onMounted } from "vue";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'BetSlider',
  props: {
    player: {},
    players: {},
    game: {},
    room: {},
    value: {},
    min: {}
  },
  emits: ['updateSliderValue'],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "c206d9ba": (getOpacityBetSlider.value),
  "f23539e0": (getColorByStatus.value)
}))

const { t } = useI18n();


const props = __props;

const emit = __emit;


const sliderValue = ref(props.min);


watch(() => props.min, (newMin) => {
  if (sliderValue.value < newMin && sliderValue.value > props.player.game_deposit) {
    sliderValue.value = newMin/2;  
  } else sliderValue.value = newMin;
});



watch(() => props.value, (newVal) => {
  sliderValue.value = Math.max(newVal, props.min);  
});


const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  triggerHapticFeedback('light');
  const newValue = Math.max(Number(target.value), props.min);
  console.log("Новое значение в слайдере = ", newValue);
  if (newValue >= props.player.game_deposit - props.player.current_round_bet) {
    sliderValue.value = props.player.game_deposit + props.player.current_round_bet;
    emit('updateSliderValue', sliderValue.value)
  }
  else {
    sliderValue.value = newValue;  
    emit('updateSliderValue', newValue); 
  }  
}


watch(sliderValue, (newVal) => {
  emit('updateSliderValue', Number(newVal)); 
});

onMounted(() => {
  emit('updateSliderValue', sliderValue.value); 
})

const getColorByStatus = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "#CBC0DF";
    case "silver":
      return "var(--gradient-silver-thumb)";
    case "gold":
      return "var(--gradient-gold-thumb)";
    default:
      return "#CBC0DF";
  }
});


const getOpacityBetSlider = computed(() => {
  return props.game && props.game.current_player_id === props.player.id ? '1' : '.4';
});


const isSliderDisabled = computed(() => {
  return getOpacityBetSlider.value === '.4';
});


const getMaxBet = computed(() => {
  return props.player.game_deposit ?? props.room.big_blind_bet;
});


const getMaxRaise = computed(() => {
  return props.player.game_deposit ?? props.room.big_blind_bet;
});


const getSliderValue = computed(() => {
  if (sliderValue.value == props.player.game_deposit + props.player.current_round_bet){
    return t('game.all_in');
  }
  if (sliderValue.value == getMaxBet.value) {
    return t('game.all_in');
  }
  else {
    if (sliderValue.value == getMaxRaise.value) {
      return t('game.all_in');
    }
  }
  return sliderValue.value;
});


watch(getMaxBet, () => {
  if (sliderValue.value > getMaxBet.value) {
    sliderValue.value = getMaxBet.value;
  }
});


watch(getMaxRaise, () => {
  if (sliderValue.value > getMaxRaise.value) {
    sliderValue.value = getMaxRaise.value;
  }
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(getSliderValue.value), 1),
        (_ctx.player.current_round_bet >= Math.max(..._ctx.players.map(x => x.current_round_bet)))
          ? _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 0,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((sliderValue).value = $event)),
              class: "bet-slider-input",
              min: _ctx.min,
              max: getMaxBet.value,
              type: "range",
              disabled: isSliderDisabled.value,
              onInput: handleInput
            }, null, 40, _hoisted_4)), [
              [_vModelText, sliderValue.value]
            ])
          : _withDirectives((_openBlock(), _createElementBlock("input", {
              key: 1,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((sliderValue).value = $event)),
              class: "bet-slider-input",
              min: _ctx.min,
              max: getMaxRaise.value,
              type: "range",
              disabled: isSliderDisabled.value,
              onInput: handleInput
            }, null, 40, _hoisted_5)), [
              [_vModelText, sliderValue.value]
            ]),
        _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.min), 1)
      ])
    ])
  ]))
}
}

})