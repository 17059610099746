import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/tools/close-modal.svg'


const _hoisted_1 = { class: "detail-component" }
const _hoisted_2 = { class: "detail-wrapper" }
const _hoisted_3 = { class: "header" }
const _hoisted_4 = { class: "header-text" }
const _hoisted_5 = { class: "close-modal-wrapper" }
const _hoisted_6 = { class: "detail-body-wrapper" }
const _hoisted_7 = { class: "details-some" }
const _hoisted_8 = { class: "some-fee" }
const _hoisted_9 = { class: "title-some" }
const _hoisted_10 = { class: "some-prize-pool" }
const _hoisted_11 = { class: "title-some" }
const _hoisted_12 = { class: "some-players-count" }
const _hoisted_13 = { class: "title-some" }
const _hoisted_14 = { class: "details-all" }
const _hoisted_15 = { class: "general-information-title" }
const _hoisted_16 = { class: "general-information-title-text" }
const _hoisted_17 = { class: "general-information" }
const _hoisted_18 = { class: "daily-tour" }
const _hoisted_19 = { class: "registr" }
const _hoisted_20 = { class: "minimum" }
const _hoisted_21 = { class: "general-information-templ" }
const _hoisted_22 = { class: "status" }
const _hoisted_23 = { class: "title-inform" }
const _hoisted_24 = { class: "point-status" }
const _hoisted_25 = { class: "count-title-inform" }
const _hoisted_26 = { class: "start" }
const _hoisted_27 = { class: "title-inform" }
const _hoisted_28 = { class: "count-play" }
const _hoisted_29 = { class: "title-inform" }
const _hoisted_30 = { class: "stack" }
const _hoisted_31 = { class: "title-inform" }
const _hoisted_32 = { class: "details-prize-pool" }
const _hoisted_33 = { class: "prize-pool-title" }
const _hoisted_34 = { class: "prize-pool-body" }
const _hoisted_35 = { class: "prize-place" }
const _hoisted_36 = { class: "prize-pool-head" }
const _hoisted_37 = { class: "prize-pool-sum" }
const _hoisted_38 = { class: "prize-pool-head" }
const _hoisted_39 = { class: "details-blind-structure" }
const _hoisted_40 = { class: "prize-pool-title" }
const _hoisted_41 = { class: "prize-pool-body" }
const _hoisted_42 = { class: "prize-place" }
const _hoisted_43 = { class: "prize-pool-head" }
const _hoisted_44 = { class: "prize-pool-percent" }
const _hoisted_45 = { class: "prize-pool-head" }
const _hoisted_46 = { class: "prize-pool-sum" }
const _hoisted_47 = { class: "prize-pool-head" }
const _hoisted_48 = { class: "participating-btn" }
const _hoisted_49 = { class: "participating-text" }
const _hoisted_50 = { class: "participating-btn" }
const _hoisted_51 = { class: "participating-text" }

import { useI18n } from 'vue-i18n';
import {onMounted, onUnmounted, ref} from "vue";
import { TournamentServise } from "@/api/tournamentService";
import { MiniAppsEventListener, off, on, postEvent } from "@telegram-apps/sdk";
import { useStore } from "vuex";
import TopUpModalClose from "@/components/modals/TopUpModalClose.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'DetailTournament',
  emits: ['close'],
  setup(__props, { emit: __emit }) {


const store = useStore();

const TourServise = new TournamentServise;

const { t } = useI18n();

const emit = __emit; 

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
    emit('close');
};

const exists = ref();

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  TourServise.getTourPlayer('partner')
  .then(response => response.json())
    .then(data => {
        exists.value = data.exists;
  });
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})


function registrTour() {
    if (store.getters.USER.bonus_balance >= 150000) {
        TourServise.register('partner')
        .then(response => {
            if (response.status == 201) {
                emit('close');
                return;
            }
        });
    }
    else {
        showPopAddBalance.value = true;
    }
}

function unRegistrTour() {
    // console.log('i in unRegistrTour')
    TourServise.unRegister('partner')
    .then(response => {
        if (response.status == 200) {
            emit('close');
            return;
        }
    });
}

const showPopAddBalance = ref(false);

const closeChangeRoomPop = () => {
    showPopAddBalance.value = false;
}




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showPopAddBalance.value)
      ? (_openBlock(), _createBlock(TopUpModalClose, {
          key: 0,
          onClose: closeChangeRoomPop
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "detail-background",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
      }),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, _toDisplayString(_unref(t)(`tournaments.detail_tour`)), 1),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _imports_0,
              onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('close')))
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(t)(`tournaments.fee`)), 1),
              _cache[4] || (_cache[4] = _createElementVNode("div", { class: "some-sum" }, [
                _createElementVNode("svg", {
                  class: "fee-coin-type no-image-animation",
                  width: "14",
                  height: "14",
                  viewBox: "0 0 24 24",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("path", {
                    d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                    fill: "#D14A4A"
                  })
                ]),
                _createElementVNode("p", { class: "sum" }, "150K")
              ], -1))
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("p", _hoisted_11, _toDisplayString(_unref(t)(`tournaments.prize`)), 1),
              _cache[5] || (_cache[5] = _createElementVNode("p", { class: "sum" }, "100.000 ₽", -1))
            ]),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)(`tournaments.players`)), 1),
              _cache[6] || (_cache[6] = _createElementVNode("p", { class: "sum" }, "10000", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(t)(`tournaments.general_information`)), 1)
            ]),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("p", _hoisted_18, _toDisplayString(_unref(t)(`tournaments.daily_tour`)), 1),
              _createElementVNode("p", _hoisted_19, _toDisplayString(_unref(t)(`tournaments.registr`)), 1),
              _createElementVNode("p", _hoisted_20, _toDisplayString(_unref(t)(`tournaments.minimum`)), 1)
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                _createElementVNode("p", _hoisted_23, _toDisplayString(_unref(t)(`tournaments.status`)), 1),
                _createElementVNode("div", _hoisted_24, [
                  _cache[7] || (_cache[7] = _createElementVNode("svg", {
                    width: "9",
                    height: "10",
                    viewBox: "0 0 9 10",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }, [
                    _createElementVNode("circle", {
                      cx: "4.5",
                      cy: "5.2207",
                      r: "4.5",
                      fill: "#B698EF"
                    })
                  ], -1)),
                  _createElementVNode("p", _hoisted_25, _toDisplayString(_unref(t)(`tournaments.open_registr`)), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("p", _hoisted_27, _toDisplayString(_unref(t)(`tournaments.start`)), 1),
                _cache[8] || (_cache[8] = _createElementVNode("p", { class: "count-title-inform" }, "Янв 31, 2025 21:00", -1))
              ]),
              _createElementVNode("div", _hoisted_28, [
                _createElementVNode("p", _hoisted_29, _toDisplayString(_unref(t)(`tournaments.player_count`)), 1),
                _cache[9] || (_cache[9] = _createElementVNode("p", { class: "count-title-inform" }, "10000", -1))
              ]),
              _createElementVNode("div", _hoisted_30, [
                _createElementVNode("p", _hoisted_31, _toDisplayString(_unref(t)(`tournaments.stack`)), 1),
                _cache[10] || (_cache[10] = _createElementVNode("div", { class: "stack-sum" }, [
                  _createElementVNode("svg", {
                    class: "fee-coin-type no-image-animation",
                    width: "14",
                    height: "14",
                    viewBox: "0 0 24 24",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg"
                  }, [
                    _createElementVNode("path", {
                      d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                      fill: "#E5D229"
                    })
                  ]),
                  _createElementVNode("p", { class: "count-title-inform" }, "10 000")
                ], -1))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("div", _hoisted_33, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)(`tournaments.prize_pool_title`)), 1)
            ]),
            _createElementVNode("div", _hoisted_34, [
              _createElementVNode("div", _hoisted_35, [
                _createElementVNode("p", _hoisted_36, _toDisplayString(_unref(t)(`tournaments.prize_pool_place`)), 1),
                _cache[11] || (_cache[11] = _createElementVNode("p", { class: "prize-sum" }, "1", -1))
              ]),
              _cache[13] || (_cache[13] = _createElementVNode("div", { class: "prize-pool-percent" }, [
                _createElementVNode("p", { class: "prize-pool-head" }, "%"),
                _createElementVNode("p", { class: "prize-sum" }, "100%")
              ], -1)),
              _createElementVNode("div", _hoisted_37, [
                _createElementVNode("p", _hoisted_38, _toDisplayString(_unref(t)(`tournaments.prize_pool_sum`)), 1),
                _cache[12] || (_cache[12] = _createElementVNode("p", { class: "prize-sum" }, "100.000 ₽", -1))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_39, [
            _createElementVNode("div", _hoisted_40, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)(`tournaments.structure_blind`)), 1)
            ]),
            _createElementVNode("div", _hoisted_41, [
              _createElementVNode("div", _hoisted_42, [
                _createElementVNode("p", _hoisted_43, _toDisplayString(_unref(t)(`tournaments.level`)), 1),
                _cache[14] || (_cache[14] = _createStaticVNode("<p class=\"prize-sum\" data-v-109cb656>1</p><p class=\"prize-sum\" data-v-109cb656>2</p><p class=\"prize-sum\" data-v-109cb656>3</p><p class=\"prize-sum\" data-v-109cb656>4</p><p class=\"prize-sum\" data-v-109cb656>5</p><p class=\"prize-sum\" data-v-109cb656>6</p><p class=\"prize-sum\" data-v-109cb656>7</p><p class=\"prize-sum\" data-v-109cb656>8</p><p class=\"prize-sum\" data-v-109cb656>9</p><p class=\"prize-sum\" data-v-109cb656>10</p><p class=\"prize-sum\" data-v-109cb656>11</p><p class=\"prize-sum\" data-v-109cb656>12</p><p class=\"prize-sum\" data-v-109cb656>13</p><p class=\"prize-sum\" data-v-109cb656>14</p><p class=\"prize-sum\" data-v-109cb656>15</p><p class=\"prize-sum\" data-v-109cb656>16</p>", 16))
              ]),
              _createElementVNode("div", _hoisted_44, [
                _createElementVNode("p", _hoisted_45, _toDisplayString(_unref(t)(`tournaments.bb_mb`)), 1),
                _cache[15] || (_cache[15] = _createStaticVNode("<p class=\"prize-sum\" data-v-109cb656>25/50</p><p class=\"prize-sum\" data-v-109cb656>50/100</p><p class=\"prize-sum\" data-v-109cb656>75/150</p><p class=\"prize-sum\" data-v-109cb656>125/250</p><p class=\"prize-sum\" data-v-109cb656>200/400</p><p class=\"prize-sum\" data-v-109cb656>300/600</p><p class=\"prize-sum\" data-v-109cb656>600/1200</p><p class=\"prize-sum\" data-v-109cb656>1000/2000</p><p class=\"prize-sum\" data-v-109cb656>1500/3000</p><p class=\"prize-sum\" data-v-109cb656>3000/6000</p><p class=\"prize-sum\" data-v-109cb656>5000/10000</p><p class=\"prize-sum\" data-v-109cb656>8000/16000</p><p class=\"prize-sum\" data-v-109cb656>15000/30000</p><p class=\"prize-sum\" data-v-109cb656>20000/40000</p><p class=\"prize-sum\" data-v-109cb656>40000/80000</p><p class=\"prize-sum\" data-v-109cb656>70000/140000</p>", 16))
              ]),
              _createElementVNode("div", _hoisted_46, [
                _createElementVNode("p", _hoisted_47, _toDisplayString(_unref(t)(`tournaments.start_raund`)), 1),
                _cache[16] || (_cache[16] = _createStaticVNode("<p class=\"prize-sum\" data-v-109cb656>+00:00:00</p><p class=\"prize-sum\" data-v-109cb656>+00:10:00</p><p class=\"prize-sum\" data-v-109cb656>+00:20:00</p><p class=\"prize-sum\" data-v-109cb656>+00:30:00</p><p class=\"prize-sum\" data-v-109cb656>+00:40:00</p><p class=\"prize-sum\" data-v-109cb656>+00:50:00</p><p class=\"prize-sum\" data-v-109cb656>+01:00:00</p><p class=\"prize-sum\" data-v-109cb656>+01:10:00</p><p class=\"prize-sum\" data-v-109cb656>+01:20:00</p><p class=\"prize-sum\" data-v-109cb656>+01:30:00</p><p class=\"prize-sum\" data-v-109cb656>+01:40:00</p><p class=\"prize-sum\" data-v-109cb656>+01:50:00</p><p class=\"prize-sum\" data-v-109cb656>+02:00:00</p><p class=\"prize-sum\" data-v-109cb656>+02:10:00</p><p class=\"prize-sum\" data-v-109cb656>+02:20:00</p><p class=\"prize-sum\" data-v-109cb656>+02:30:00</p>", 16))
              ])
            ])
          ]),
          (!exists.value)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "btn",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (registrTour()))
              }, [
                _createElementVNode("div", _hoisted_48, [
                  _createElementVNode("p", _hoisted_49, _toDisplayString(_unref(t)(`tournaments.participating`)), 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "btn",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (unRegistrTour()))
              }, [
                _createElementVNode("div", _hoisted_50, [
                  _createElementVNode("p", _hoisted_51, _toDisplayString(_unref(t)(`tournaments.un_participating`)), 1)
                ])
              ]))
        ])
      ])
    ])
  ], 64))
}
}

})