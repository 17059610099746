import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo/partner_logo_rell.svg'
import _imports_1 from '@/assets/logo/bcakground_logo_rell.svg'
import _imports_2 from '@/assets/friends/Profile_arrow.svg'
import _imports_3 from '@/assets/background/back_logo_tournaments.svg'


const _hoisted_1 = { class: "tournaments-wrapper" }
const _hoisted_2 = { class: "tournaments-partner-wpapper" }
const _hoisted_3 = { class: "title-wrapper" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "tournament-left" }
const _hoisted_6 = { class: "tournament-left-upcoming" }
const _hoisted_7 = { class: "upcoming-text" }
const _hoisted_8 = { class: "tournament-left-priz-pool" }
const _hoisted_9 = { class: "prize-pool-text" }
const _hoisted_10 = { class: "tournament-right" }
const _hoisted_11 = { class: "tournament-right-partner-wrapper" }
const _hoisted_12 = { class: "tournament-right-partner" }
const _hoisted_13 = { class: "tournament-see-all" }
const _hoisted_14 = { class: "see-all" }
const _hoisted_15 = { class: "tournaments-fast-wpapper" }
const _hoisted_16 = { class: "title-wrapper" }
const _hoisted_17 = { class: "title" }
const _hoisted_18 = { class: "tournament-fast" }
const _hoisted_19 = { class: "soon" }
const _hoisted_20 = { class: "tournaments-long-wpapper" }
const _hoisted_21 = { class: "title-wrapper" }
const _hoisted_22 = { class: "title" }
const _hoisted_23 = { class: "tournament-long" }
const _hoisted_24 = { class: "soon" }

import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';
import store from "../store";
import HeaderMini from "@/components/header/HeaderMini.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'TournamentsView',
  setup(__props) {


const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1);
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderMini),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`tournaments.title_partner`)), 1)
        ]),
        _createElementVNode("div", {
          class: "tournament-wrapper-up",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push('/tournaments/list')))
        }, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(t)(`tournaments.upcoming`)), 1),
              _cache[2] || (_cache[2] = _createElementVNode("p", { class: "upcoming-date" }, "31.01.25", -1))
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(t)(`tournaments.prize_pool`)), 1),
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "prize-pool-sum" }, "100.000 ₽", -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_unref(t)(`tournaments.partner_name_partner`)), 1)
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", { class: "tournament-right-partner-logo" }, [
              _createElementVNode("img", { src: _imports_0 })
            ], -1))
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            src: _imports_1,
            class: "background-partner-logo"
          }, null, -1))
        ]),
        _createElementVNode("div", {
          class: "tournament-wrapper-down",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_unref(router).push('/tournaments/list')))
        }, [
          _createElementVNode("div", _hoisted_13, [
            _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(t)(`tournaments.see_all`)), 1)
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "tournament-arrow" }, [
            _createElementVNode("img", { src: _imports_2 })
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("p", _hoisted_17, _toDisplayString(_unref(t)(`tournaments.title_fast`)), 1)
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("p", _hoisted_19, _toDisplayString(_unref(t)(`tournaments.soon`)), 1),
          _cache[7] || (_cache[7] = _createElementVNode("img", {
            src: _imports_3,
            class: "background-logo-mini no-image-animation"
          }, null, -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("p", _hoisted_22, _toDisplayString(_unref(t)(`tournaments.title_long`)), 1)
        ]),
        _createElementVNode("div", _hoisted_23, [
          _createElementVNode("p", _hoisted_24, _toDisplayString(_unref(t)(`tournaments.soon`)), 1),
          _cache[8] || (_cache[8] = _createElementVNode("img", {
            src: _imports_3,
            class: "background-logo-mini no-image-animation"
          }, null, -1))
        ])
      ])
    ])
  ], 64))
}
}

})