<script setup lang="ts">
import store from "@/store";
import Player from "@/models/player";
import Game from "@/models/game";
import {ApiService} from "@/api/apiService";
import {computed, defineProps, watch, ref, onMounted} from "vue";
import CountTimer from "@/components/game/CountTimer.vue";
import {formatNumber} from "../../tools/tools";
import EmojiPlayerItem from "@/components/game/EmojiPlayerItem.vue";

const props = defineProps<{
  selectedEmoji: any,
  game: Game,
  player: Player,
  showPlayer: boolean
  selectedEmojiPlayerID: number,
  winners: any[],
  currentCase: string | null;
  waitForJoin: boolean,
}>();

const seat = {
  player: props.player,
  seat: props.player?.seat
}

const userBetText = ref(formatNumber(store.getters.USER.bonus_balance));
const transformStyle= ref('');
const transformBetText = ref('')
const userBet = ref<number | undefined>(0);

const transformBet = ref('');

watch(
  () => props.player!.current_round_bet, 
  (newBet, oldBet) => {
    if (newBet && oldBet! > 0) {
      if (newBet != oldBet) {
        if (newBet! > oldBet!) {
          transformBet.value = 'transform: scale(1.3); color: #31B545; font-weight: 600; transition: transform 0.3s ease, color 0.3s ease;';
          setTimeout(() => {
            transformBet.value = 'transform: scale(1); color: inherit; font-weight: inherit; transition: transform 0.3s ease, color 0.3s ease;';
          }, 1000);
        }
      }
    }
  },
  { deep: true, immediate: true }
);

watch(() => props.player!.current_round_bet, () => {
  userBet.value = props.player!.current_round_bet;
});

watch(
  () => props.currentCase,
  (newCase) => {
    const isCurrentPlayerWinner = Array.isArray(props.winners) && props.winners.some((winner: any) => winner.user_id === props.player.user_id);

    if (newCase === 'show_down' && isCurrentPlayerWinner) {
      const winner = props.winners.find((winner: any) => winner.user_id === props.player?.user_id);
      const sum = winner?.sum || 0;
      userBetText.value = '+'+sum;
      transformBetText.value = 'transform: scale(1.1); color: #31B545; font-weight: 600;';
      transformStyle.value = 'transform: scale(1.1); z-index: 100; filter: drop-shadow(0px 0px 3px rgba(240, 190, 121)) drop-shadow(0px 0px 5px rgba(240, 190, 121)) drop-shadow(0px 0px 7px rgba(240, 190, 121));';
    }
  }
);

const userData = window.Telegram.WebApp.initDataUnsafe;

const borderColor = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return 'white';
    case "silver":
      return "var(--gradient-sliver)";
    case "gold":
      return "var(--gradient-gold)";
    default:
      return 'white';
  }
});

const avatarUrl = ref('');

const getCachedAvatarUrl = () => {
  const cachedAvatar = localStorage.getItem('userAvatarUrl');
  if (cachedAvatar && avatarUrl.value !== cachedAvatar) {
    avatarUrl.value = cachedAvatar;
  } else {
    updateAvatarUrl();
  }
};

const updateAvatarUrl = () => {
  const photoUrl = store.getters.USER.photo_url;
  if (photoUrl && avatarUrl.value !== ApiService.url + photoUrl) {
    avatarUrl.value = ApiService.url + photoUrl;
    localStorage.setItem('userAvatarUrl', avatarUrl.value);
  } else if (!photoUrl) {
    avatarUrl.value = require('@/assets/mock_user/user.svg');
  }
};

watch(() => store.getters.USER.photo_url, (newPhotoUrl) => {
  if (newPhotoUrl && avatarUrl.value !== ApiService.url + newPhotoUrl) {
    updateAvatarUrl();
  }
});

onMounted(() => {
  userBet.value = props.player!.current_round_bet;
  getCachedAvatarUrl();
});

const isCurrentPlayerWinner = computed(() => {
  return Array.isArray(props.winners) && props.winners.some((winner: any) => winner.user_id === props.player.user_id);
});

</script>

<template>
  <div v-if="props.game?.current_player_id">
    <div :class="['game-user-player', {'not-watcher': props.showPlayer }]" :style="transformStyle" >
      <div class="game-user-image-wrapper">
        <div class="dealer-button" v-if="props.player?.id === props.game.dealer_id">
          <img class="dealer-button-button" :src="require(`@/assets/dealer-buttons/btn-dealer-${store.getters.USER.vip_status}.svg`)">
        </div>
        <div class="emoji-item-user">
          <emoji-player-item
            :selected-emoji="selectedEmoji"
            :selectedEmojiPlayerID="selectedEmojiPlayerID!"
            :seat="seat"
            :showPlayer="showPlayer"
          />
        </div>
        <div v-if="props.player.status != 'inactive'" class="user-game-deposit" :style="transformBet">
          {{ userBet }}
        </div>
        <div class="game-user-image-border no-image-animation">
          <div class="user-corona">
            <img v-if="store.getters.USER.vip_status != 'first'"
                 class="user-corona no-image-animation"
                 :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" />
          </div>
          <div class="game-user-image-1">
            <count-timer :game="game" :player="player" :showPlayer="showPlayer" :myplayer="true"/>
            <img class="game-user-image no-image-animation"
              :style="{'background': (props.player?.id !== game?.current_player_id) ? borderColor : 'none'}"
              :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')" />
          </div>
          <div class="user-username-bet-wrapper">
            <div v-if="isCurrentPlayerWinner"
                 class="user-balance no-image-animation"
                 :style="{ justifyContent: 'center' }">
              <div class="game-user-balance"
                :style="[transformBetText, { textAlign: 'center', marginRight: '0' }]" >
                {{ userBetText }}
              </div>
            </div>
            <div v-else-if="!isCurrentPlayerWinner && !props.showPlayer"
                 class="user-balance no-image-animation"
                 :style="{ justifyContent: 'center' }">
              <div class="game-user-balance"
                   :style="{ textAlign: 'center'}">
                {{ formatNumber(store.getters.USER.bonus_balance) }}
              </div>
            </div>
            <div v-else class="user-balance no-image-animation">
              <!-- <div class="user-game-deposit" :style="transformBet">
                {{ userBet }}
              </div> -->
              <div class="game-user-balance">
                {{ formatNumber(props.player!.game_deposit) }}
              </div>
            </div>
            <div class="user-username no-image-animation">
              {{ userData.user.username ?? 'noname' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div :class="['game-user-player', {'not-watcher': props.showPlayer }]"
         :style="transformStyle">
      <div class="game-user-image-wrapper">
        <div class="emoji-item-user">
          <emoji-player-item
            :selected-emoji="selectedEmoji"
            :selectedEmojiPlayerID="selectedEmojiPlayerID!"
            :seat="seat"
            :showPlayer="showPlayer" />
        </div>
        <div v-if="props.player.status != 'inactive'" class="user-game-deposit" :style="transformBet">
          {{ userBet }}
        </div>
        <div class="game-user-image-border no-image-animation">
          <div class="user-corona">
            <img v-if="store.getters.USER.vip_status != 'first'"
                 class="user-corona no-image-animation"
                 :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" />
          </div>
          <div class="game-user-image-1">
            <img class="game-user-image no-image-animation"
              :style="{'background': (props.player?.id !== game?.current_player_id) ? borderColor : 'none'}"
              :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')" />
          </div>
          <div class="user-username-bet-wrapper">
            <div v-if="isCurrentPlayerWinner"
                 class="user-balance no-image-animation"
                 :style="{ justifyContent: 'center' }">
              <div class="game-user-balance"
                  :style="[transformBetText, { textAlign: 'center', marginRight: '0' }]">
                {{ userBetText }}
              </div>
            </div>
            <div v-else-if="!isCurrentPlayerWinner && !props.showPlayer"
                 class="user-balance no-image-animation"
                 :style="{ justifyContent: 'center' }">
              <div class="game-user-balance"
                   :style="{ textAlign: 'center'}">
                {{ formatNumber(store.getters.USER.bonus_balance) }}
              </div>
            </div>
            <div v-else class="user-balance no-image-animation">
              <!-- <div class="user-game-deposit" :style="transformBet">
                {{ userBet }}
              </div> -->
              <div class="game-user-balance">
                {{ formatNumber(props.player.game_deposit) }}
              </div>
            </div>
            <div class="user-username no-image-animation">
              {{ userData.user.username ?? 'noname' }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

/* .user-game-deposit {
  text-align: start;
  margin-left: 1vw;
} */

.user-game-deposit {
  position: absolute;
  left: 50%;
  translate: -50%;
  top: -3vw;
  font-weight: 500;
  font-size: 2vh;
  background-color: #5c4e73;
  border-radius: var(--border-mixed);
  padding-top: 1px;
  padding-bottom: 1px;
  padding-left: 20px;
  padding-right: 20px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 27%));
}

.game-user-balance {
  font-weight: 600;
}

.dealer-button {
  position: absolute;
  z-index: 101;
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
  right: 0;
  top: 12px;
}

.emoji-item-user{
  position: absolute;
  top: 65%;
  left: 90%;
  z-index: 2;
}


.dealer-button-button {
  max-width: 24px;
  max-height: 24px;
  width: 24px;
  height: 24px;
} 

.timer-player {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 4px;
  top: 22px;
  transform: scale(1.2);
  z-index: 2;
}

.game-user-image-1 {
  width: 100%;
}

.game-user-image {
  padding: 3px;
  border-radius: 50%;
  width: 11.3vh;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.4));
  z-index: 1;
  /* background: borderColor; */
}

.user-hand-wrapper {
  margin-top: 7%;
  margin-right: 8%;
  text-align: right;
  width: 45%;
}

.player-cards-wrapper {
  position: relative;
  z-index: 2;
}
.game-user-image-wrapper {
  position: relative;
  z-index: 3;
  padding: 2px;
}

.game-user-image-border {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
}

.user-username-bet-wrapper {
  z-index: 2;
  margin-top: -20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.user-username {
  font-size: 1.4vh;
  font-weight: 500;
  z-index: 2;
}

.user-balance {
  font-size: 1.5vh;
  padding: .5vh;
  background: #5c4e73;
  box-shadow: 0 5px 62px rgba(21, 21, 30, .5);
  border-radius: var(--border-mixed);
  display: flex;
  justify-content: center;
  width: 14vw;
}

.user-corona {
  height: 3vh;
  width: 100%;
  margin-bottom: 6px;
}

</style>