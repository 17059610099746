import {createStore} from "vuex";
import {retrieveLaunchParams} from "@telegram-apps/sdk";
import User from "@/models/user";
import {TonConnectUI} from "@tonconnect/ui";

export default createStore({
  state: {
    focused: false,
    openModal: "",
    selectedLanguage: localStorage.getItem('selectedLanguage') || 'ru',
    user: {
      id: 0,
      telegram_id: "",
      balance: 0,
      bonus_balance: 0,
      vip_status: "first",
      week_earn: 0,
      photo_url: '',
      oldLevel: 0,
      last_bonus_at: null,
    },
    userReferrals: [],
    tonConnectUI: new TonConnectUI({
      manifestUrl: 'https://keep-coin.ru/static-files/manifest.json',
      buttonRootId: 'ton-connect'
    }),
    isVibrationOn: localStorage.getItem('isVibrationOn') === 'false' ? false : true,
    isMusicOn: localStorage.getItem('isMusicOn') === 'false' ? false : true,
    isSoundOn: localStorage.getItem('isSoundOn') === 'false' ? false : true,
    withPlayers: true,
  },
  getters: {
    FOCUSED: state => {
      return state.focused;
    },
    OPEN_MODAL: state => {
      return state.openModal;
    },
    SELECTED_LANGUAGE: state => {
      return state.selectedLanguage
    },
    TELEGRAM_USER: state => {
      return retrieveLaunchParams().initData!;
    },
    USER: state => {
      const cachedAvatarUrl = localStorage.getItem('userAvatarUrl');
      if (cachedAvatarUrl && state.user.photo_url !== cachedAvatarUrl) {
        state.user.photo_url = cachedAvatarUrl;
      }
      return state.user;
    },
    USER_OLD_LEVEL: state => {
      return state.user.oldLevel;
    },
    TON_CONNECT_UI: state => {
      return state.tonConnectUI;
    },
    USER_REFERRALS: state => {
      return state.userReferrals;
    },
    isVibrationOn: (state) => {
      return state.isVibrationOn;
    },
    isMusicOn: (state) => {  
      return state.isMusicOn;
    },
    isSoundOn: (state) => {
      return state.isSoundOn;
    },
    WITH_PLAYERS: (state) => {
      return state.withPlayers
    },
  },
  mutations: {
    // SET_LAST_BONUS_AT(state, lastBonusAt) {
    //   state.user.last_bonus_at = lastBonusAt;
    // },
    SET_FOCUSED: (state, payload) => {
      state.focused = payload;
    },
    SET_OPEN_MODAL: (state, payload) => {
      state.openModal = payload;
    },
    SET_SELECTED_LANGUAGE: (state, payload) => {
      state.selectedLanguage = payload;
    },
    SET_USER: (state, payload: User) => {
      state.user = payload;
      if (payload.photo_url) {
        localStorage.setItem('userAvatarUrl', payload.photo_url);
      }
    },
    SET_OLD_LEVEL: (state, payload: number) => {
      state.user.oldLevel = payload;
    },
    SET_USER_REFERRALS: (state, payload) => {
      state.userReferrals = payload;
    },
    ENABLE_VIBRATION: (state) => {
      state.isVibrationOn = true;
      localStorage.setItem('isVibrationOn', 'true');
    },
    DISABLE_VIBRATION: (state) => {
      state.isVibrationOn = false;
      localStorage.setItem('isVibrationOn', 'false');
    },
    ENABLE_MUSIC: (state) => {
      state.isMusicOn = true;
      localStorage.setItem('isMusicOn', 'true');
    },
    DISABLE_MUSIC: (state) => {
      state.isMusicOn = false;
      localStorage.setItem('isMusicOn', 'false');
    },
    ENABLE_SOUND: (state) => {
      state.isSoundOn = true;
      localStorage.setItem('isSoundOn', 'true');
    },
    DISABLE_SOUND: (state) => {
      state.isSoundOn = false;
      localStorage.setItem('isSoundOn', 'false');
    },
    SET_WITH_PLAYERS(state, newValue: boolean) {
      state.withPlayers = newValue;
    },
  },
  modules: {
  },
})
