import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/tools/close-modal.svg'
import _imports_1 from '@/assets/logo/money_logo.svg'
import _imports_2 from '@/assets/logo/logo-background.svg'


const _hoisted_1 = { class: "daily-bonus" }
const _hoisted_2 = { class: "daily-bonus-wrapper" }
const _hoisted_3 = { class: "daily-bonus-wrapper-up" }
const _hoisted_4 = { class: "daily-bonus-wrapper-center" }
const _hoisted_5 = { class: "daily-bonus-wrapper-cenetr-up" }

import store from "@/store";
import {onUnmounted, ref, nextTick} from "vue";
import { useI18n } from 'vue-i18n';
import { UserService } from "@/api/userService";


export default /*@__PURE__*/_defineComponent({
  __name: 'DailyBonus',
  emits: ['close'],
  setup(__props, { emit: __emit }) {


const { t } = useI18n();

const userService = new UserService;

const last_bonus_at = ref(store.getters.USER.last_bonus_at);

const closeModal = () => {
  // store.commit('SET_FOCUSED', false);
  // store.commit('SET_OPEN_MODAL', "");
  emit('close');
}

onUnmounted(() => {
  triggerHapticFeedback('light');
})

const emit = __emit;

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

function updateUser() {
  userService.get()
      .then((response: any) => response.json())
      .then((data: any) => {
        store.commit('SET_USER', data);
      });
  last_bonus_at.value = store.getters.USER.last_bonus_at;
}

function getBonus(){
  if (isMoreThan24HoursAgo(last_bonus_at.value)){
    triggerHapticFeedback('soft');
    userService.getBonus(10000);
    nextTick(()=>{
      updateUser();
    })
    nextTick(()=>{
      emit('close');
    })
  }
}

// function getRemainingTime(lastBonusAt: string) {
//   const targetDate = new Date(lastBonusAt);
//   const currentDate = new Date();
//   const diffInMilliseconds = 24 * 60 * 60 * 1000 - (currentDate.getTime() - targetDate.getTime());

//   const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
//   const minutes = Math.floor((diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));

//   return { hours, minutes };
// }

function isMoreThan24HoursAgo(dateString: string): boolean {
  const targetDate = new Date(dateString);
  const currentDate = new Date();
  const diffInMilliseconds = currentDate.getTime() - targetDate.getTime();
  const hours24InMilliseconds = 24 * 60 * 60 * 1000;

  return diffInMilliseconds > hours24InMilliseconds;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: _imports_0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (closeModal()))
        })
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", null, _toDisplayString(_unref(t)(`daily_bonus_pop.claim`)), 1)
        ]),
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "daily-bonus-wrapper-center-dwn" }, [
          _createElementVNode("p", null, "+10 000"),
          _createElementVNode("img", {
            src: _imports_1,
            class: "red-coin"
          }),
          _createElementVNode("p", { class: "red-coin-ten-k" }, "RedCoin")
        ], -1))
      ]),
      _createElementVNode("div", {
        class: "daily-bonus-wrapper-dwn",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (getBonus()))
      }, _toDisplayString(_unref(t)(`daily_bonus_pop.claim_btn`)), 1),
      _cache[3] || (_cache[3] = _createElementVNode("img", {
        src: _imports_2,
        class: "background-logo no-image-animation"
      }, null, -1))
    ])
  ]))
}
}

})