type MenuItems = {
    label: string,
    image: string,
    path: string[],
    hide_small_devices?: boolean
}

const menu: MenuItems[] = [
    {
        label: "play",  
        image: "play.svg",
        path: ['/'],
    },
    {
        label: "rooms", 
        image: "rooms.svg",
        path: ['/rooms']
    },
    {
        label: "friends", 
        image: "friends.svg",
        path: ["/friends"]
    },
    {
        label: "profile", 
        image: "profile.svg",
        path: ['/profile', '/profile/change', '/levels', '/profile/top-up', '/profile/connect-wallet', '/withrdaw_soon', '/profile/transactions', '/settings', '/settings/language', ],
    },
    {
        label: "tournaments", 
        image: "tournaments.svg",
        path: ['/tournaments', '/tournaments/list', ]
    }
];

export default menu;
