<script setup lang="ts">

import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';
import store from "../store";
import HeaderMini from "@/components/header/HeaderMini.vue";

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1);
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


</script>

<template>
<header-mini/>
<div class="tournaments-wrapper" :style="{'margin-top': tg.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + 20px)': 72}">
    <div class="tournaments-partner-wpapper">
        <div class="title-wrapper">
            <p class="title">{{ t(`tournaments.title_partner`) }}</p>
        </div>
        <div class="tournament-wrapper-up" @click="router.push('/tournaments/list')">
            <div class="tournament-left">
                <div class="tournament-left-upcoming">
                        <p class="upcoming-text">{{ t(`tournaments.upcoming`) }}</p>
                        <p class="upcoming-date">31.01.25</p>
                </div>
                <div class="tournament-left-priz-pool">
                        <p class="prize-pool-text">{{ t(`tournaments.prize_pool`) }}</p>
                        <p class="prize-pool-sum">100.000 ₽</p>
                </div>
            </div>
            <div class="tournament-right">
                <div class="tournament-right-partner-wrapper">
                    <p class="tournament-right-partner">{{ t(`tournaments.partner_name_partner`) }}</p>
                </div>
                <div class="tournament-right-partner-logo">
                    <img src="@/assets/logo/partner_logo_rell.svg">
                </div>
            </div>
            <img src="@/assets/logo/bcakground_logo_rell.svg" class="background-partner-logo">
        </div>
        <div class="tournament-wrapper-down" @click="router.push('/tournaments/list')">
            <div class="tournament-see-all">
                <p class="see-all">{{ t(`tournaments.see_all`) }}</p>
            </div>
            <div class="tournament-arrow">
                <img src="@/assets/friends/Profile_arrow.svg">
            </div>
        </div>
    </div>
    <div class="tournaments-fast-wpapper">
        <div class="title-wrapper">
            <p class="title">{{ t(`tournaments.title_fast`) }}</p>
        </div>
        <div class="tournament-fast">
            <p class="soon">{{ t(`tournaments.soon`) }}</p>
            <img src="@/assets/background/back_logo_tournaments.svg" class="background-logo-mini no-image-animation">
        </div>
    </div>
    <div class="tournaments-long-wpapper">
        <div class="title-wrapper">
            <p class="title">{{ t(`tournaments.title_long`) }}</p>
        </div>
        <div class="tournament-long">
            <p class="soon">{{ t(`tournaments.soon`) }}</p>
            <img src="@/assets/background/back_logo_tournaments.svg" class="background-logo-mini no-image-animation">
        </div>
    </div>
</div>

</template>

<style scoped>

.background-partner-logo {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    border-radius: var(--border-mixed);
}

.tournaments-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 150px;
    margin-top: 72px;
}

.tournaments-partner-wpapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.title-wrapper {
    display: flex;
    align-items: start;
    font-weight: 500;
    font-size: 28px;
}

.tournament-wrapper-up {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #15151E;
    padding: 20px 20px;
    border-top-left-radius: var(--border-mixed);
    border-top-right-radius: var(--border-mixed);
    margin-top: 20px;
}

.tournament-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    align-items: start;
}

.tournament-left-upcoming, .tournament-left-priz-pool {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    gap: 6px;
}

.upcoming-text, .prize-pool-text {
    font-weight: 500;
    font-size: 18px;
    color: #B9B9B9;
}

.upcoming-date, .prize-pool-sum { 
    font-weight: 500;
    font-size: 20px;
}

.tournament-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.background-logo {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    height: 100%;
    border-radius: var(--border-mixed);
}

.background-logo-mini {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    border-radius: var(--border-mixed);
}

.tournament-right-partner-wrapper {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 18px;
}

.tournament-right-partner-logo {
    display: flex;
    flex-direction: column;
    width: 80px;
    max-width: 80px;
}

.tournament-wrapper-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;
    background-color: #15151E;
    border-bottom-left-radius: var(--border-mixed);
    border-bottom-right-radius: var(--border-mixed);
    padding: 20px 18px;
}

.tournament-see-all {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 18px;
}

.tournament-arrow {
    display: flex;
    flex-direction: column;
    width: 5px;
    max-width: 5px;
}

.tournaments-fast-wpapper, .tournaments-long-wpapper {
    display: flex;
    flex-direction: column;
    justify-content: start;
}

.tournament-fast, .tournament-long {
    display: flex;
    position: relative;
    background-color: #15151E;
    padding: 26px 155px;
    font-weight: 500;
    font-size: 18px;
    margin-top: 20px;
    border-radius: var(--border-mixed);
}

.soon {
    width: 100%;
}
</style>