import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, unref as _unref, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/wallet/ton-transactions.svg'
import _imports_1 from '@/assets/tools/arrow.svg'


const _hoisted_1 = { class: "transaction-sum-wrapper" }
const _hoisted_2 = { class: "transaction-date-wrapper" }
const _hoisted_3 = { class: "transaction-type-label" }
const _hoisted_4 = { class: "transaction-date" }

import Transaction from "@/models/transaction";
import router from "@/router";
import {formatLocalDate} from "@/tools/tools";
import { useI18n } from 'vue-i18n';
import store from "@/store";



export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionsItem',
  props: {
    transaction: {}
  },
  setup(__props: any) {


const { t } = useI18n();

const props = __props;

const pushToTransaction = (id: number) => {
  window.scrollTo(0, 0);
  router.push(`/profile/transaction?id=${id}`)
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "transaction",
    onClick: _cache[0] || (_cache[0] = ($event: any) => {triggerHapticFeedback('light'); pushToTransaction(_ctx.transaction.id)})
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        class: "ton-transaction-logo",
        src: _imports_0
      }, null, -1)),
      _createElementVNode("p", {
        class: _normalizeClass({ 'transaction-sum': true, 'transaction-top-up': _ctx.transaction.type == 'top-up', 'transaction-withdraw': _ctx.transaction.type == 'withdraw' })
      }, _toDisplayString(_ctx.transaction.type == "top-up" ? "+" : "-") + _toDisplayString(_ctx.transaction.ton_sum), 3)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.transaction.type == 'top-up' ? _unref(t)('profile.add') : _unref(t)('profile.withdraw2')), 1),
      _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(formatLocalDate)(_ctx.transaction.updated_at)), 1)
    ]),
    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "transaction-arrow-wrapper" }, [
      _createElementVNode("img", { src: _imports_1 })
    ], -1))
  ]))
}
}

})