import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo/logo.svg'
import _imports_1 from '@/assets/player/status/status-usual.svg'
import _imports_2 from '@/assets/cards/hidden/hidden-first.svg'
import _imports_3 from '@/assets/cards/hidden/hidden-usual.svg'
import _imports_4 from '../assets/player/status/status-silver.svg'
import _imports_5 from '@/assets/cards/hidden/hidden-silver.svg'
import _imports_6 from '@/assets/player/status/status-gold.svg'
import _imports_7 from '@/assets/cards/hidden/hidden-gold.svg'


const _hoisted_1 = { class: "levels-wrapper" }
const _hoisted_2 = { class: "levels-list" }
const _hoisted_3 = { class: "levels-list-item" }
const _hoisted_4 = { class: "level-img-wrapper" }
const _hoisted_5 = { class: "level-number" }
const _hoisted_6 = { class: "level-decor" }
const _hoisted_7 = { class: "level-user" }
const _hoisted_8 = {
  class: "level-user-image-wrapper",
  style: {"background":"white"}
}
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "level-pros-wrapper" }
const _hoisted_11 = { class: "level-pros-list" }
const _hoisted_12 = { class: "level-pros-item" }
const _hoisted_13 = { class: "level-pros-info" }
const _hoisted_14 = { class: "level-pros-info-up" }
const _hoisted_15 = { class: "level-pros-item" }
const _hoisted_16 = { class: "level-pros-info" }
const _hoisted_17 = { class: "levels-list-item" }
const _hoisted_18 = { class: "level-img-wrapper" }
const _hoisted_19 = { class: "level-number" }
const _hoisted_20 = { class: "level-decor" }
const _hoisted_21 = { class: "level-user" }
const _hoisted_22 = {
  class: "level-user-image-wrapper",
  style: {"background":"white"}
}
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "level-pros-wrapper" }
const _hoisted_25 = { class: "level-pros-list" }
const _hoisted_26 = { class: "level-pros-item" }
const _hoisted_27 = { class: "level-pros-info" }
const _hoisted_28 = { class: "level-pros-info-up" }
const _hoisted_29 = { class: "level-pros-item" }
const _hoisted_30 = { class: "level-pros-info" }
const _hoisted_31 = { class: "level-pros-sum" }
const _hoisted_32 = { class: "level-pros-item" }
const _hoisted_33 = { class: "level-pros-info" }
const _hoisted_34 = { class: "levels-list-item" }
const _hoisted_35 = { class: "level-img-wrapper" }
const _hoisted_36 = { class: "level-number" }
const _hoisted_37 = { class: "level-decor" }
const _hoisted_38 = { class: "level-user" }
const _hoisted_39 = {
  class: "level-user-image-wrapper",
  style: {"background":"var(--gradient-sliver)"}
}
const _hoisted_40 = ["src"]
const _hoisted_41 = { class: "level-pros-wrapper" }
const _hoisted_42 = { class: "level-pros-list" }
const _hoisted_43 = { class: "level-pros-item" }
const _hoisted_44 = { class: "level-pros-info" }
const _hoisted_45 = { class: "level-pros-info-up" }
const _hoisted_46 = { class: "level-pros-item" }
const _hoisted_47 = { class: "level-pros-info" }
const _hoisted_48 = { class: "levels-list-item" }
const _hoisted_49 = { class: "level-img-wrapper" }
const _hoisted_50 = { class: "level-number" }
const _hoisted_51 = { class: "level-decor" }
const _hoisted_52 = { class: "level-user" }
const _hoisted_53 = {
  class: "level-user-image-wrapper",
  style: {"background":"var(--gradient-gold)"}
}
const _hoisted_54 = ["src"]
const _hoisted_55 = { class: "level-pros-wrapper" }
const _hoisted_56 = { class: "level-pros-list" }
const _hoisted_57 = { class: "level-pros-item" }
const _hoisted_58 = { class: "level-pros-info" }
const _hoisted_59 = { class: "level-pros-info-up" }
const _hoisted_60 = { class: "level-pros-item" }
const _hoisted_61 = { class: "level-pros-info" }

import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import {ApiService} from "@/api/apiService";
import store from "@/store";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'LevelsView',
  setup(__props) {

const { t } = useI18n();

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.go(-1);
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  triggerHapticFeedback('light');
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  triggerHapticFeedback('light');
  off('back_button_pressed', listener);
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[17] || (_cache[17] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(`levels.level_1`)), 1),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _cache[0] || (_cache[0] = _createElementVNode("img", {
                  src: _imports_1,
                  style: {"visibility":"hidden"}
                }, null, -1)),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("img", {
                    class: "level-user-image",
                    src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
                  }, null, 8, _hoisted_9)
                ])
              ]),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "level-cards-wrapper" }, [
                _createElementVNode("img", {
                  class: "player-card-1 no-image-animation",
                  src: _imports_2
                }),
                _createElementVNode("img", {
                  class: "player-card-2 no-image-animation",
                  src: _imports_2
                })
              ], -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)(`levels.to_achive`)), 1),
              _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(t)(`levels.top_up_by`)), 1),
              _cache[2] || (_cache[2] = _createElementVNode("br", null, null, -1)),
              _cache[3] || (_cache[3] = _createElementVNode("p", { class: "level-pros-sum" }, "1 TON", -1))
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(t)(`levels.withdraw_fee`)), 1),
              _cache[4] || (_cache[4] = _createElementVNode("p", { class: "level-pros-sum" }, "5%", -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_17, [
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("p", _hoisted_19, _toDisplayString(_unref(t)(`levels.level_2`)), 1),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", null, [
                _cache[5] || (_cache[5] = _createElementVNode("img", { src: _imports_1 }, null, -1)),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("img", {
                    class: "level-user-image",
                    src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
                  }, null, 8, _hoisted_23)
                ])
              ]),
              _cache[6] || (_cache[6] = _createElementVNode("div", { class: "level-cards-wrapper" }, [
                _createElementVNode("img", {
                  class: "player-card-1 no-image-animation",
                  src: _imports_3
                }),
                _createElementVNode("img", {
                  class: "player-card-2 no-image-animation",
                  src: _imports_3
                })
              ], -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_24, [
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", null, [
              _createElementVNode("div", _hoisted_26, [
                _createElementVNode("p", _hoisted_27, _toDisplayString(_unref(t)(`levels.to_achive`)), 1),
                _createElementVNode("p", _hoisted_28, _toDisplayString(_unref(t)(`levels.top_up_by`)), 1),
                _cache[7] || (_cache[7] = _createElementVNode("p", { class: "level-pros-sum" }, "10 TON", -1))
              ]),
              _createElementVNode("div", _hoisted_29, [
                _createElementVNode("p", _hoisted_30, _toDisplayString(_unref(t)(`levels.or_win`)), 1),
                _createElementVNode("p", _hoisted_31, _toDisplayString(_unref(t)(`levels.game_10`)), 1)
              ])
            ]),
            _createElementVNode("div", _hoisted_32, [
              _createElementVNode("p", _hoisted_33, _toDisplayString(_unref(t)(`levels.withdraw_fee`)), 1),
              _cache[8] || (_cache[8] = _createElementVNode("p", { class: "level-pros-sum" }, "4%", -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("p", _hoisted_36, _toDisplayString(_unref(t)(`levels.level_3`)), 1),
          _createElementVNode("div", _hoisted_37, [
            _createElementVNode("div", _hoisted_38, [
              _createElementVNode("div", null, [
                _cache[9] || (_cache[9] = _createElementVNode("img", { src: _imports_4 }, null, -1)),
                _createElementVNode("div", _hoisted_39, [
                  _createElementVNode("img", {
                    class: "level-user-image",
                    src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
                  }, null, 8, _hoisted_40)
                ])
              ]),
              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "level-cards-wrapper" }, [
                _createElementVNode("img", {
                  class: "player-card-1 no-image-animation",
                  src: _imports_5
                }),
                _createElementVNode("img", {
                  class: "player-card-2 no-image-animation",
                  src: _imports_5
                })
              ], -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_41, [
          _createElementVNode("div", _hoisted_42, [
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("p", _hoisted_44, _toDisplayString(_unref(t)(`levels.to_achive`)), 1),
              _createElementVNode("p", _hoisted_45, _toDisplayString(_unref(t)(`levels.top_up_by`)), 1),
              _cache[11] || (_cache[11] = _createElementVNode("p", { class: "level-pros-sum" }, "50 TON", -1))
            ]),
            _createElementVNode("div", _hoisted_46, [
              _createElementVNode("p", _hoisted_47, _toDisplayString(_unref(t)(`levels.withdraw_fee`)), 1),
              _cache[12] || (_cache[12] = _createElementVNode("p", { class: "level-pros-sum" }, "3%", -1))
            ])
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_48, [
        _createElementVNode("div", _hoisted_49, [
          _createElementVNode("p", _hoisted_50, _toDisplayString(_unref(t)(`levels.level_4`)), 1),
          _createElementVNode("div", _hoisted_51, [
            _createElementVNode("div", _hoisted_52, [
              _createElementVNode("div", null, [
                _cache[13] || (_cache[13] = _createElementVNode("img", { src: _imports_6 }, null, -1)),
                _createElementVNode("div", _hoisted_53, [
                  _createElementVNode("img", {
                    class: "level-user-image",
                    src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
                  }, null, 8, _hoisted_54)
                ])
              ]),
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "level-cards-wrapper" }, [
                _createElementVNode("img", {
                  class: "player-card-1 no-image-animation",
                  src: _imports_7
                }),
                _createElementVNode("img", {
                  class: "player-card-2 no-image-animation",
                  src: _imports_7
                })
              ], -1))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_55, [
          _createElementVNode("div", _hoisted_56, [
            _createElementVNode("div", _hoisted_57, [
              _createElementVNode("p", _hoisted_58, _toDisplayString(_unref(t)(`levels.to_achive`)), 1),
              _createElementVNode("p", _hoisted_59, _toDisplayString(_unref(t)(`levels.top_up_by`)), 1),
              _cache[15] || (_cache[15] = _createElementVNode("p", { class: "level-pros-sum" }, "200 TON", -1))
            ]),
            _createElementVNode("div", _hoisted_60, [
              _createElementVNode("p", _hoisted_61, _toDisplayString(_unref(t)(`levels.withdraw_fee`)), 1),
              _cache[16] || (_cache[16] = _createElementVNode("p", { class: "level-pros-sum" }, "2%", -1))
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})