
<script setup lang="ts">
import NavBar from "@/components/navigation/NavBar.vue";
import { onMounted, ref, provide } from "vue";
import router from "@/router";
import { UserService } from "@/api/userService";
import store from "@/store";
import { postEvent } from "@telegram-apps/sdk";
import TopUpModal from "@/components/modals/TopUpModal.vue";
import soundUrl from '@/assets/sounds/new_audio.mp3';
import checkSoundUrl from '@/assets/sounds/sound_check.mp3';
import flopSoundUrl from '@/assets/sounds/flop2.mp3';
import backMusic from '@/assets/sounds/flop2.mp3';
import YourMove from '@/assets/sounds/your_move.mp3';
import TimeEnd from '@/assets/sounds/time_end.mp3';
import { nextTick } from "vue";
import {RoomService} from "@/api/roomService";
import RoomsItem from "@/models/roomsListItem";
import NotfoundRoom from "@/views/PopNotFoundRoom.vue"
import TutorialView from '@/views/TutorialView.vue';
import TechnicalMaintenance from '@/views/TechnicalMaintenance.vue';

const audioContext = new (window.AudioContext || window.webkitAudioContext)();

declare global {
  interface Window {
    webkitAudioContext?: typeof AudioContext;
  }
}

async function loadSound(url: string): Promise<AudioBuffer | undefined> {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`Network response was not ok for ${url}`);
    }
    const arrayBuffer = await response.arrayBuffer();
    const decodedData = await audioContext.decodeAudioData(arrayBuffer);
    return decodedData;
  } catch (error) {
    console.error(`Error loading sound from ${url}:`, error);
  }
}

function playSound(buffer: AudioBuffer | undefined) {
  if (!buffer) {
    return;
  }

  try {
    const source = audioContext.createBufferSource();
    source.buffer = buffer;
    source.connect(audioContext.destination);
    source.start(0);
  } catch (error) {
    console.error("Error playing sound:", error);
  }
}

let soundBuffer: AudioBuffer | undefined;
let checkSoundBuffer: AudioBuffer | undefined;
let flopSoundBuffer: AudioBuffer | undefined;
let backMusicBuffer: AudioBuffer | undefined;
let yourMoveSoundBuffer: AudioBuffer | undefined;
let timeEndSoundBuffer: AudioBuffer | undefined;


function playbackMusic() {
  if (store.getters.isMusicOn) {
    if (backMusicBuffer) {
      playSound(backMusicBuffer);
    } else {
      console.warn("backMusic buffer is not loaded");
    }
  }
}

function playYourMoveSound() {
  if (store.getters.isSoundOn) {
    if (yourMoveSoundBuffer) {
      playSound(yourMoveSoundBuffer);
    } else {
      console.warn("Global sound buffer is not loaded");
    }
  }
}

function playTimeEndSoundBuffer() {
  if (store.getters.isSoundOn) {
    if (timeEndSoundBuffer) {
      playSound(timeEndSoundBuffer);
    } else {
      console.warn("Global sound buffer is not loaded");
    }
  }
}

function playGlobalSound() {
  if (store.getters.isSoundOn) {
    if (soundBuffer) {
      playSound(soundBuffer);
    } else {
      console.warn("Global sound buffer is not loaded");
    }
  }
}

function playCheckSound() {
  if (store.getters.isSoundOn) {
    if (checkSoundBuffer) {
      playSound(checkSoundBuffer);
    } else {
      console.warn("Check sound buffer is not loaded");
    }
  }
}

function playFlopSound() {
  if (store.getters.isSoundOn) {
    if (flopSoundBuffer) {
      playSound(flopSoundBuffer);
    } else {
      console.warn("Flop sound buffer is not loaded");
    }
  }
}

const isLoaded = ref(false);

provide("playCheckSound", playCheckSound);
provide("playSound", playGlobalSound);
provide("playFlopSound", playFlopSound);
provide("playYourMoveSound", playYourMoveSound);
provide("playTimeEndSoundBuffer", playTimeEndSoundBuffer);

function loadImage(imageUrl: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => resolve();
    img.onerror = (error) => reject(error);
  });
}

onMounted(async () => {

  soundBuffer = await loadSound(soundUrl);
  checkSoundBuffer = await loadSound(checkSoundUrl);
  flopSoundBuffer = await loadSound(flopSoundUrl);
  backMusicBuffer = await loadSound(backMusic);

  yourMoveSoundBuffer = await loadSound(YourMove);
  timeEndSoundBuffer = await loadSound(TimeEnd);

  postEvent('web_app_expand');
  postEvent('web_app_set_header_color', { color: '#2A2B37' });
  postEvent('web_app_set_background_color', { color: '#2A2B37' });

  const initData = window.Telegram.WebApp.initData;

  const Params = new URLSearchParams(initData);

  const userService = new UserService();
  const kentId = Params.get('start_param');
  await userService.get(kentId)
    .then(response => response.json())
    .then(data => {
      store.commit('SET_USER', data);
      loadImage(store.getters.USER.photo_url ?? require('@/assets/mock_user/user.svg'));
      isLoaded.value = true;
    });

  await nextTick();

  const validIds = ["699569447", "213469070", "7169925521", "5011732483", 
  "37366869", "873519389", "1805197862", "291910222", 
  "667400736", "268884676", "796740108", "929936833", 
  "486844877", "7657954932", "691203016", "7220658533", 
  "860971755", "461109190", "1671494658", "201154308", 
  "921709428", "257789713", "801538930", "291948355",
  "845639484", "1916184114", "1142211121", "1352538125",
  "1965108679", "285193174", "627343856", "922279796", 
  "455931081", "7347153516", "511769027", "5434279613",
  "232308120", "64189549", "1055686003", "775273418", 
  "578230598", "96708214","1328743643", "820562207", 
  "523451463", "284279", "552827050", "5161747869", 
  "906992764", "7193120372", "874109224", "1306160001",
  "956710836", "847505349", "974194907", "821587301", 
  "7444256830", "72304094", "786993757", "711166491",
  "1685080098", "1578760060", "5180926190", "635049706", 
  "106662042", "789741280", "1280742049", "7655890491", 
  "1063641098", "520723903", "1082253064", "968889516", 
  "89475480", "154252740", "938633461", "325642615", 
  "6675035026", "7855273344", "5405267205", "833642734", 
  "916023508", "916023508", "596276116", "636764593", 
  "409286889", "1076523766"];

  // const validIds = ["699569447", "213469070", "7220658533", "7169925521", "860971755", "821587301", "5011732483", "7657954932", "486844877", "929936833", "7444256830", "873519389", "6671566249", "291910222", "268884676"];

  if (validIds.includes(store.getters.USER.telegram_id)) {
    openYwch.value = false;
  }
  else {
    openYwch.value = true;

    window.Telegram.WebApp.disableVerticalSwipes();


    const idPrivateRoom = Number(Params.get('start_param')); 

    const roomService = new RoomService();
    let privateRoomId = ref<RoomsItem | null>(null);

    const Params2 = new URLSearchParams(initData);

    const testStart = Params2.get('start_param');

    // if(testStart) {
    //   console.log("test start param if")
    //   console.log(testStart)

    //   if (window.telemetreeBuilder) {
    //     console.log(' window.telemetreeBuilder')
    //     window.telemetreeBuilder.track('tematg', {
    //       page: testStart,
    //       timestamp: new Date().toISOString(),
    //       userId: store.getters.USER.id
    //     });
    //     console.log('Profile page visit event tracked.');
    //   }
    // }
    if (!isNaN(idPrivateRoom) && idPrivateRoom.toString().length === 5) {
      await roomService.getPrivateRoom(idPrivateRoom)
        .then(response => response.json())
        .then(data => {
          privateRoomId.value = data;
          if (privateRoomId.value && privateRoomId.value.id) {
            const room = privateRoomId.value;
            isLoaded.value = true;
            router.push(`/game?small_blind_bet=${room.small_blind_bet}&coin_type=${room.coin_type}&id=${room.id}`);
          } else {
            console.warn("The room was not found. Go to the main screen.");
            openNotFoundPop.value = true;
            router.push('/');
          }
        })
        .catch(error => {
          console.error("Error fetching private room data:", error);
        });
    } else {
      router.push('/');
    }

    await nextTick();

    if(store.getters.USER.bonus_balance === 0) {
      showTutorial.value = true;
    } 
  }
});

const openNotFoundPop = ref(false);

const openYwch = ref(true)

const closeNotFoundPop = () => {
  openNotFoundPop.value = false;
}

const showTutorial = ref(false)

const closeTutorial = () => {
  showTutorial.value = false; 
}


</script>


<template>
  <TechnicalMaintenance v-if="openYwch"/>
  <div v-else>
    <div v-if="isLoaded">
      <router-view :key="$route.fullPath"/>
      <nav-bar/>
    </div>
    <div v-else class="download-wrapper">
      <div class="download-image-wrapper">
        <img src="@/assets/tools/rolling.svg" class="rolling">
      </div>
    </div>
    <top-up-modal/>
    <notfound-room v-if="openNotFoundPop" 
    @close="closeNotFoundPop()"/>
    <tutorial-view v-if="showTutorial"
    @close="closeTutorial()"/>
  </div>
</template>


<style>

@keyframes scaleUp {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes translateUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes translateDown {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes translateLeft {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@keyframes translateClose {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInScale {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --backround-color: #2A2B37;
  --border-mixed: 24px;
  --gradient-sliver: linear-gradient(#989898 0%, #D3D3D3 10%, #878787 17%, #D3D3D3 24%, #A5A5A5 40%, #767676 57%, #606060 72%, #D3D3D3 85%, #606060 99%);
  --gradient-gold: linear-gradient(#E3A355 10%, #F6DBA6 24%, #FFEBC3 40%, #F0BE79 57%, #BA7F3B 72%, #EEBC70 85%, #89571D 99%);
  --font-family: 'Montserrat', sans-serif;
  --gradient-gold-thumb: linear-gradient(240.24deg, #E3A355 5.73%, #FFEBC4 39.14%, #F0BE79 55.44%, #BA7F3B 74.76%, #EEBC70 92.49%, #89571D 133.37%);
  --gradient-silver-thumb: linear-gradient(240.24deg, #989898 5.73%, #DBDBDB 39.14%, #C0C0C0 55.44%, #878787 74.76%, #D8D7D7 92.49%, #606060 133.37%), linear-gradient(240.24deg, #E3A355 5.73%, #FFEBC4 39.14%, #F0BE79 55.44%, #BA7F3B 74.76%, #EEBC70 92.49%, #89571D 133.37%), #CBC0DF;
  --transform-scale-active: scale(0.91);
}

* {
  margin: 0;
  padding: 0;
  user-select: none;
}

html {
  background-color: #2A2B37;
}

body {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: 'Montserrat', sans-serif;
  color: #FFFFFF;
  min-height: 100vh;
  background: url("@/assets/background/background.svg") repeat-y center top;
  background-size: 100% auto;
}

.no-image-animation {
  animation: none;
}

.springy-animation {
  animation-name: spring;
  animation-duration: .1s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

@keyframes spring {
  from {
    transform: translateX(-10px);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes moveCard {
  from {
    opacity: 0;
    top: 20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes flipCard {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(90deg);
  }
  100% {
    transform: rotateY(0deg)
  }
}

body{
  overflow: hidden;
  height: 100vh;
}

#app::-webkit-scrollbar {
  display: none;
}

#app {
  overflow-y:scroll;
  overflow-x: hidden;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  text-align: center;
  flex-direction: column;
}

#ton-connect {
  display: none;
}

.download-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.rolling {
  width: 25%;
}

.download-image-wrapper {
  display: flex;
  justify-content: center;
}

@keyframes highlight-card1 {
  0% {
    transform: rotateZ(-8deg) rotateY(0) scale(1);
    filter: none;
    z-index: 1;
  }
  100% {
    transform: scale(1.2) rotate(0deg);
    filter: drop-shadow(0px 0px 12px rgba(240, 190, 121));
    z-index: 10;
  }
}

@keyframes outhighlight-card1 {
  0% {
    transform: scale(1.2) rotate(0deg);
    filter: drop-shadow(0px 0px 12px rgba(240, 190, 121));
    z-index: 10;
  }
  100% {
    transform: rotateZ(-8deg) rotateY(0);
    z-index: '';
  }
}

@keyframes highlight-card2 {
  0% {
    transform: rotateZ(17deg) rotateY(0) scale(1);
    filter: none;
    z-index: 1;
  }
  100% {
    transform: scale(1.2) rotate(0deg);
    filter: drop-shadow(0px 0px 12px rgba(240, 190, 121));
    z-index: 10;
  }
}


@keyframes outhighlight-card2 {
  0% {
    transform: scale(1.2) rotate(0deg);
    filter: drop-shadow(0px 0px 12px rgba(240, 190, 121));
    z-index: 10;
  }
  100% {
    transform: rotateZ(17deg) rotateY(0);
    z-index: '';
  }
}

@keyframes highlight-card-table {
  0% {
    transform: scale(1);
    filter: none;
    z-index: 1;
    opacity: 1;
  }
  100% {
    transform: scale(1.5);
    filter: drop-shadow(0px 0px 12px rgba(240, 190, 121));
    z-index: 10;
    opacity: 1;
  }
}

@keyframes outhighlight-card-table {
  0% {
    transform: scale(1.5);
    filter: drop-shadow(0px 0px 12px rgba(240, 190, 121));
    z-index: 10;
    opacity: 1;
  }
  100% {
    transform: scale(1);
    filter: none;
    z-index: 1;
    opacity: 1;
  }
}

@keyframes half-flip-1 {
  0% {
    transform: rotateZ(-8deg) rotateY(0);
  }
  50%{
    transform: rotateZ(-8deg) rotateY(-90deg);
  }
  100% {
    transform: rotateZ(-8deg) rotateY(0);
  }
}

@keyframes half-flip-2 {
  0% {
    transform: rotateZ(17deg) rotateY(0);
  }
  50%{
    transform: rotateZ(17deg) rotateY(-90deg);
  }
  100% {
    transform: rotateZ(17deg) rotateY(0);
  }
}

</style>
