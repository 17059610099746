import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, isRef as _isRef, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/logo/money_logo.svg'
import _imports_1 from '@/assets/logo/money_logo_gc.svg'


const _hoisted_1 = { class: "balance-action-input" }
const _hoisted_2 = { class: "balance-action-wrapper-start" }
const _hoisted_3 = { class: "balance-action-wrapper-start-up" }
const _hoisted_4 = { class: "balance-action-label" }
const _hoisted_5 = { class: "balance-action-wrapper-start-center" }
const _hoisted_6 = { class: "balance-action-wrapper-start-center-left" }
const _hoisted_7 = { class: "input-wrapper" }
const _hoisted_8 = { class: "input-and-top" }
const _hoisted_9 = { class: "input" }
const _hoisted_10 = {
  key: 0,
  class: "crypto"
}
const _hoisted_11 = {
  key: 0,
  class: "balance-input-ton"
}
const _hoisted_12 = {
  key: 1,
  class: "balance-input-ton"
}
const _hoisted_13 = {
  key: 1,
  class: "crypto"
}
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { class: "balance-wrapper-comission" }
const _hoisted_16 = { class: "balance-level" }
const _hoisted_17 = {
  key: 0,
  class: "balance-game-label",
  id: "comission"
}
const _hoisted_18 = {
  key: 1,
  class: "balance-game-label",
  id: "comission"
}
const _hoisted_19 = { key: 1 }
const _hoisted_20 = { class: "balance-wrapper" }
const _hoisted_21 = { class: "balance-level" }
const _hoisted_22 = { class: "balance-wrapper-comission" }
const _hoisted_23 = { class: "balance-level" }
const _hoisted_24 = {
  class: "balance-game-label",
  id: "comission"
}
const _hoisted_25 = { class: "balance-wrapper-comission" }
const _hoisted_26 = { class: "balance-level" }
const _hoisted_27 = {
  class: "balance-game-label",
  id: "comission"
}
const _hoisted_28 = { class: "balance-game-label" }
const _hoisted_29 = ["fill"]
const _hoisted_30 = ["fill"]
const _hoisted_31 = {
  key: 0,
  class: "chips-wrapper",
  style: { 'max-width': '100vw' }
}
const _hoisted_32 = {
  key: 0,
  class: "chips-data"
}
const _hoisted_33 = {
  key: 1,
  class: "chips-data"
}
const _hoisted_34 = {
  key: 0,
  class: "chips-data"
}
const _hoisted_35 = {
  key: 1,
  class: "chips-data"
}
const _hoisted_36 = {
  key: 0,
  class: "chips-data"
}
const _hoisted_37 = {
  key: 1,
  class: "chips-data"
}
const _hoisted_38 = {
  key: 0,
  class: "chips-data"
}
const _hoisted_39 = {
  key: 1,
  class: "chips-data"
}
const _hoisted_40 = {
  key: 0,
  class: "chips-data"
}
const _hoisted_41 = {
  key: 1,
  class: "chips-data"
}
const _hoisted_42 = {
  key: 0,
  class: "chips-data"
}
const _hoisted_43 = {
  key: 1,
  class: "chips-data"
}
const _hoisted_44 = { class: "wallet-wrapper" }
const _hoisted_45 = { class: "wallet-label" }

import {onMounted, onUnmounted, ref, nextTick, watch} from "vue";
import WalletComponent from "@/components/wallet/WalletComponent.vue";
import { useStore } from "vuex";
import router from "@/router";
import {UserService} from "@/api/userService";
import {MiniAppsEventListener, number, off, on, postEvent} from "@telegram-apps/sdk";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'BalanceActionsView',
  props: {
    action: {}
  },
  setup(__props: any) {

let intervalId: ReturnType<typeof setInterval> | null = null;

const { t } = useI18n();

const store = useStore();

const currentLevel = getUserLevel(store.getters.USER.vip_status);

function getUserLevel(vipStatus: string): number {
  switch (vipStatus) {
    case 'usual':
      return 2; 
    case 'silver':
      return 3; 
    case 'gold':
      return 4; 
    default:
      return 1; 
  }
}

store.commit('SET_OLD_LEVEL', currentLevel);

const tg = (window as any).Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const props = __props;


let inputData = ref("1");
if (props.action==="top-up"){
  inputData = ref("1");
}
else inputData = ref("1000");

let currentRate = ref();

let comission = ref();

let result = ref();

let commisionOnWithdraw = 5;

let currentResult = ref(0);

const inputRef = ref<HTMLInputElement | null>(null);

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/profile');
};

const mainButtonPressed: MiniAppsEventListener<'main_button_pressed'> = payload => {
  if (props.action==="top-up"){
    if(isSwapped.value){
      if (inputData.value < '5') {
        return;
      }
    }
    else {
      if (inputData.value === '0') {
        return;
      }
    }      
  }
  else if (parseInt(inputData.value) < 1000){
    return;
  }
  
  postEvent('web_app_setup_main_button', {
    is_progress_visible: true,
  })
  doBalanceAction();
}


const isSwapped = ref(false);
//false - TON, true - USDT

function toggleSwap() {
  isSwapped.value = !isSwapped.value;
}

watch(isSwapped, (newVal: boolean) => {
  if (isSwapped.value) {
    currentResult.value = (Number(inputData.value)*200) - (Number(inputData.value)*200) * 0.02;
  }
  else {
    currentResult.value = Math.ceil(((Number(currentRate.value) * Number(inputData.value)) * 200) - ((Number(currentRate.value) * Number(inputData.value)) * 200 * 0.02));
  }
});

const fetchTonToUsdtRate = async () => {
  try {
    const response = await fetch('https://api.binance.com/api/v3/ticker/price?symbol=TONUSDT');
    const data = await response.json();
    const rate = parseFloat(data.price);
    currentRate.value = rate;
    return rate;
  } catch (error) {
    console.error('Erorr TON to USDT:', error);
    return null;
  }
}

function onInput(event: Event) {
  const input = event.target as HTMLInputElement;
  let value = input.value.replace(/\D/g, '');
  if (value === '') {
    value = '0';
  }
  else if (value.charAt(0) ===  '0') {
    value = value.substring(1)
  }

  inputData.value = value;
  if (props.action === 'top-up'){
    if (isSwapped.value) {
      comission.value = Number(inputData.value)*2/100;
      currentResult.value = (Number(inputData.value)*200) - (Number(inputData.value)*200) * 0.02;
    }
    else {
      comission.value = Number(inputData.value)*2/100;
      currentResult.value = Math.ceil(((Number(currentRate.value) * Number(inputData.value)) * 200) - ((Number(currentRate.value) * Number(inputData.value)) * 200 * 0.02));
    }
  } else {
    comission.value = Number(inputData.value)/1000 * commisionOnWithdraw / 100;
    result.value = Number(inputData.value) / 1000 - comission.value;
  }
}

function setInputData(data: string) {
  inputData.value = data;
  comission.value = Number(inputData.value)*2/100;
  if (isSwapped.value) {
    currentResult.value = (Number(inputData.value)*200) - (Number(inputData.value)*200) * 0.02;
  }
  else {
    currentResult.value = Math.ceil(((Number(currentRate.value) * Number(inputData.value)) * 200) - ((Number(currentRate.value) * Number(inputData.value)) * 200 * 0.02));
  }
  result.value = Number(inputData.value)- comission.value;
}

onMounted(async () => {

  if (!store.getters.USER.address) {
    router.push('/profile/connect-wallet')
  }
  else {
    await fetchTonToUsdtRate();
    if (inputRef.value) {
    if (props.action==="top-up"){
      inputRef.value.value = "1";
      comission.value = Number(inputData.value)*2/100;
      result.value = Number(inputData.value) - comission.value;
      currentResult.value = Math.ceil(((Number(currentRate.value) * Number(inputData.value)) * 200) - ((Number(currentRate.value) * Number(inputData.value)) * 200 * 0.02));
    }
    else {
      inputRef.value.value = "1000";
      comission.value = Number(inputData.value)/1000 * commisionOnWithdraw / 100;
      result.value = Number(inputData.value) / 1000 - comission.value;
    }
    inputRef.value.focus();
    }

    intervalId = setInterval(() => {
      fetchTonToUsdtRate();
    }, 30000);

    const levelElem = document.getElementById('level');
  
    if (levelElem){
        if (store.getters.USER.vip_status == 'first'){
        levelElem.innerText = '1'
        commisionOnWithdraw = 5;
      }
      else {
        switch (store.getters.USER.vip_status){
          case 'silver':{
            levelElem.innerText = '3'
            commisionOnWithdraw = 3;
            break;
          }
          case 'gold':{
            levelElem.innerText = '4'
            commisionOnWithdraw = 2;
            break;
          }
          default:{
            levelElem.innerText = '2'
            commisionOnWithdraw = 4;
            break;
          }
        }
      }
    }

    store.commit('SET_FOCUSED', true)
    postEvent('web_app_setup_back_button', { is_visible: true});
    postEvent('web_app_setup_main_button', {
      is_visible: true,
      is_active: true,
      is_progress_visible: false,
      text: props.action == 'top-up' ? 'Пополнить' : 'Вывести',
      color: '#32A1E6',
      text_color: '#FFFFFF'
    })
    on('back_button_pressed', listener);
    on('main_button_pressed', mainButtonPressed);

    nextTick(() => {
      const observeElement = (parent: HTMLElement | null, scale: HTMLElement | null) => {
        if (scale && parent) {
          const observer = new ResizeObserver(() => {
            scaleContent(parent, scale);
          });
          observer.observe(scale);
          resizeObservers.push(observer);
        }
      };
      observeElement(wrapperChild.value, wrapperScale.value);
      observeElement(containerRef.value, textScale.value);
      observeElement(balanceWrapperRef.value, balanceScale.value);

      scaleContent(wrapperChild.value, wrapperScale.value);
      scaleContent(containerRef.value, textScale.value);
      scaleContent(balanceWrapperRef.value, balanceScale.value);

      window.addEventListener('resize', () => {
        scaleContent(wrapperChild.value, wrapperScale.value);
        scaleContent(containerRef.value, textScale.value);
        scaleContent(balanceWrapperRef.value, balanceScale.value);
      });
    });
  }
});

onUnmounted(() => {
  store.commit('SET_FOCUSED', false)
  postEvent('web_app_setup_back_button', { is_visible: false});
  postEvent('web_app_setup_main_button', {
    is_visible: false,
    is_active: false
  })
  off('back_button_pressed', listener);
  off('main_button_pressed', mainButtonPressed);
  resizeObservers.forEach((observer) => observer.disconnect());
  resizeObservers = [];
  window.removeEventListener('resize', scaleContent);
  if (intervalId) {
    clearInterval(intervalId);
  }
})


const doBalanceAction = () => {
  const userService = new UserService();
  if (props.action === 'top-up') {
    userService.topUp(Number(inputData.value)
    // isSwapped.value ? 'usdt': 'ton'
  )
        .then(response => response.json())
        .then(data => {
          store.commit('SET_USER', data);
        });
  } else {
    userService.withdraw(parseInt(inputData.value)/1000)
        .then(response => response.json())
        .then(data => {
          if (data.balance !== undefined) {
            store.commit('SET_USER', data);
          }
        });
  }
  router.push('/profile');
}

const wrapperChild = ref<HTMLElement | null>(null);
const containerRef = ref<HTMLElement | null>(null);
const balanceWrapperRef = ref<HTMLElement | null>(null);

const wrapperScale = ref<HTMLElement | null>(null);
const textScale = ref<HTMLElement | null>(null);
const balanceScale = ref<HTMLElement | null>(null);


let resizeObservers: ResizeObserver[] = [];

const scaleContent = (parent: HTMLElement | null, scale: HTMLElement | null) => {
  if (parent && scale) {
    const parentWidth = parent.offsetWidth;
    const scaleWidth = scale.offsetWidth;

    if (scaleWidth > parentWidth) {
      const scaleFactor = parentWidth / scaleWidth;
      scale.style.transform = `scale(${scaleFactor})`;
    } else {
      scale.style.transform = 'scale(1)';
    }
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`deposit_and_withdraw.you_are`)) + " " + _toDisplayString(_ctx.action === 'top-up' ? _unref(t)('deposit_and_withdraw.depositing') : _unref(t)('deposit_and_withdraw.withdraw')) + ":", 1),
        _createElementVNode("p", null, _toDisplayString(_unref(t)(`deposit_and_withdraw.сurrency`)), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", {
              class: "input-wrapper-child",
              ref_key: "wrapperChild",
              ref: wrapperChild
            }, [
              _createElementVNode("div", {
                class: "input-wrapper-cild-scale",
                ref_key: "wrapperScale",
                ref: wrapperScale
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    (_ctx.action === 'top-up')
                      ? _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 0,
                          id: "input",
                          inputmode: "decimal",
                          onInput: onInput,
                          maxlength: "8",
                          class: "balance-input",
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(inputData) ? (inputData).value = $event : inputData = $event)),
                          ref_key: "inputRef",
                          ref: inputRef,
                          value: ""
                        }, null, 544)), [
                          [_vModelText, _unref(inputData)]
                        ])
                      : _withDirectives((_openBlock(), _createElementBlock("input", {
                          key: 1,
                          id: "input",
                          inputmode: "decimal",
                          onInput: onInput,
                          class: "balance-input",
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(inputData) ? (inputData).value = $event : inputData = $event)),
                          ref_key: "inputRef",
                          ref: inputRef,
                          value: ""
                        }, null, 544)), [
                          [_vModelText, _unref(inputData)]
                        ]),
                    _createElementVNode("div", {
                      class: "result",
                      style: _normalizeStyle({ color: _unref(inputData).toString() == '0' ? 'red' : 'white' })
                    }, _toDisplayString(_unref(inputData)), 5)
                  ]),
                  (_ctx.action === 'top-up')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                        (isSwapped.value)
                          ? (_openBlock(), _createElementBlock("p", _hoisted_11, "USDT"))
                          : (_openBlock(), _createElementBlock("p", _hoisted_12, "TON"))
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_13, _cache[9] || (_cache[9] = [
                        _createElementVNode("img", {
                          class: "balance-game-logo-big",
                          src: _imports_0
                        }, null, -1)
                      ])))
                ])
              ], 512)
            ], 512)
          ]),
          (_ctx.action==='top-up')
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", {
                  class: "container",
                  ref_key: "containerRef",
                  ref: containerRef
                }, [
                  _createElementVNode("div", {
                    class: "text",
                    ref_key: "textScale",
                    ref: textScale
                  }, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("p", _hoisted_16, _toDisplayString(_unref(t)(`deposit_and_withdraw.fee`)) + ": ", 1),
                      (isSwapped.value)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_17, _toDisplayString(_unref(comission) + ' USDT'), 1))
                        : (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_unref(comission) + ' TON'), 1))
                    ])
                  ], 512)
                ], 512)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("div", {
                  class: "container",
                  ref_key: "containerRef",
                  ref: containerRef
                }, [
                  _createElementVNode("div", {
                    class: "text",
                    ref_key: "textScale",
                    ref: textScale
                  }, [
                    _createElementVNode("div", _hoisted_20, [
                      _createElementVNode("p", _hoisted_21, _toDisplayString(_unref(t)(`deposit_and_withdraw.your`)) + " " + _toDisplayString(_unref(t)(`deposit_and_withdraw.level`)) + " ", 1),
                      _cache[10] || (_cache[10] = _createElementVNode("p", {
                        class: "balance-game-label",
                        id: "level"
                      }, " 1 ", -1))
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                      _createElementVNode("p", _hoisted_23, _toDisplayString(_unref(t)(`deposit_and_withdraw.fee`)) + ": ", 1),
                      _createElementVNode("p", _hoisted_24, _toDisplayString(_unref(comission) + ' TON'), 1)
                    ]),
                    _createElementVNode("div", _hoisted_25, [
                      _createElementVNode("p", _hoisted_26, _toDisplayString(_unref(t)(`deposit_and_withdraw.result`)) + " ", 1),
                      _createElementVNode("p", _hoisted_27, _toDisplayString(_unref(result) + ' TON'), 1)
                    ])
                  ], 512)
                ], 512),
                _createElementVNode("div", {
                  class: "reduce-commission",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/levels')})
                }, [
                  _createElementVNode("p", null, _toDisplayString(_unref(t)(`deposit_and_withdraw.reduce`)) + " " + _toDisplayString(_unref(t)(`deposit_and_withdraw.reduce_fee`)), 1)
                ])
              ])),
          (_ctx.action === 'top-up')
            ? (_openBlock(), _createElementBlock("div", {
                key: 2,
                class: "balance-game-wrapper",
                ref_key: "balanceWrapperRef",
                ref: balanceWrapperRef
              }, [
                _createElementVNode("div", {
                  class: "balance-game-wrapper-scale",
                  ref_key: "balanceScale",
                  ref: balanceScale
                }, [
                  _createElementVNode("p", _hoisted_28, _toDisplayString(_unref(t)(`deposit_and_withdraw.result`)) + _toDisplayString(_unref(currentResult)), 1),
                  _cache[11] || (_cache[11] = _createElementVNode("img", {
                    class: "balance-game-logo",
                    src: _imports_1
                  }, null, -1))
                ], 512)
              ], 512))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", {
          class: "balance-action-wrapper-start-center-right",
          onClick: toggleSwap
        }, [
          (_openBlock(), _createElementBlock("svg", {
            class: _normalizeClass(['svg-animated', isSwapped.value ? 'to-bottom-right' : 'to-top-left']),
            viewBox: "0 0 76 76",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              class: _normalizeClass(['path-animated']),
              fill: isSwapped.value ? '#606060' : '#0098EA',
              d: "M76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76C58.9868 76 76 58.9868 76 38Z"
            }, null, 8, _hoisted_29),
            _cache[12] || (_cache[12] = _createElementVNode("path", {
              d: "M50.9807 20.8999H25.0305C20.2577 20.8999 17.2348 26.047 19.6345 30.208L35.6515 57.967C35.8898 58.3806 36.2329 58.724 36.6461 58.9629C37.0594 59.2017 37.5283 59.3274 38.0056 59.3274C38.4829 59.3274 38.9518 59.2017 39.3651 58.9629C39.7783 58.724 40.1214 58.3806 40.3597 57.967L56.3786 30.208C58.7764 26.0527 55.7516 20.8999 50.9826 20.8999H50.9807ZM35.6382 49.6431L32.1498 42.8924L23.7328 27.8406C23.6048 27.6159 23.5384 27.3614 23.5401 27.1028C23.5419 26.8442 23.6118 26.5906 23.7429 26.3677C23.8739 26.1447 24.0614 25.9603 24.2865 25.8329C24.5115 25.7056 24.7662 25.6398 25.0248 25.6423H35.6344V49.6469L35.6382 49.6431ZM52.2708 27.8368L43.8576 42.8962L40.3692 49.6431V25.6385H50.9769C52.1397 25.6385 52.8275 26.8735 52.2708 27.8368Z",
              fill: "white"
            }, null, -1))
          ], 2)),
          (_openBlock(), _createElementBlock("svg", {
            class: _normalizeClass(['svg-animated', isSwapped.value ? 'to-top-left' : 'to-bottom-right']),
            viewBox: "0 0 76 76",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("path", {
              class: _normalizeClass(['path-animated']),
              fill: isSwapped.value ? '#28C281' : '#606060',
              d: "M76 38C76 17.0132 58.9868 0 38 0C17.0132 0 0 17.0132 0 38C0 58.9868 17.0132 76 38 76C58.9868 76 76 58.9868 76 38Z"
            }, null, 8, _hoisted_30),
            _cache[13] || (_cache[13] = _createElementVNode("path", {
              d: "M38.0228 60.8944C36.6187 60.8944 35.7105 60.0128 35.7105 58.5327V55.9088C30.2024 55.259 26.3853 52.428 25.2681 48.8161C25.0986 48.3587 25.0105 47.8751 25.0078 47.3873C25.0078 45.7533 26.125 44.6608 27.9167 44.6608C29.4234 44.6608 30.2822 45.5443 30.8275 46.7641C31.8649 49.4146 34.3083 50.8681 38.2565 50.8681C42.4365 50.8681 45.1155 49.2056 45.1155 46.1162C45.1155 43.4923 42.7234 42.1414 38.7505 41.2066L35.2165 40.3744C29.2942 39.0254 25.3726 35.6472 25.3726 30.7376C25.3726 24.8666 29.8908 21.3326 35.7086 20.6068V17.9544C35.7086 16.4724 36.6206 15.5908 38.0228 15.5908C39.425 15.5908 40.3351 16.4743 40.3351 17.9544V20.6049C45.4271 21.2281 49.1131 23.9812 50.2569 27.8002C50.388 28.2942 50.4906 28.7616 50.4906 29.2537C50.4906 30.7604 49.3487 31.6705 47.6862 31.6705C46.1795 31.6705 45.3739 30.9428 44.7241 29.6945C43.5556 26.968 41.5283 25.6437 38.0475 25.6437C34.0746 25.6437 31.6825 27.4107 31.6825 30.1904C31.6825 32.5787 34.0214 34.0607 37.4775 34.8397L40.8538 35.6187C47.5304 37.152 51.3228 40.3991 51.3228 45.4892C51.3228 51.8808 46.2308 55.3084 40.3351 55.9582V58.5308C40.3351 60.0109 39.425 60.8944 38.0228 60.8944Z",
              fill: "white"
            }, null, -1))
          ], 2))
        ])
      ])
    ]),
    (_ctx.action === 'top-up')
      ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
          _createElementVNode("div", {
            class: "chips",
            onClick: _cache[3] || (_cache[3] = ($event: any) => {triggerHapticFeedback('soft'); setInputData('10')})
          }, [
            (isSwapped.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_32, "10 USDT"))
              : (_openBlock(), _createElementBlock("p", _hoisted_33, "10 TON"))
          ]),
          _createElementVNode("div", {
            class: "chips",
            onClick: _cache[4] || (_cache[4] = ($event: any) => {triggerHapticFeedback('soft'); setInputData('20')})
          }, [
            (isSwapped.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_34, "20 USDT"))
              : (_openBlock(), _createElementBlock("p", _hoisted_35, "20 TON"))
          ]),
          _createElementVNode("div", {
            class: "chips",
            onClick: _cache[5] || (_cache[5] = ($event: any) => {triggerHapticFeedback('soft');setInputData('30')})
          }, [
            (isSwapped.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_36, "30 USDT"))
              : (_openBlock(), _createElementBlock("p", _hoisted_37, "30 TON"))
          ]),
          _createElementVNode("div", {
            class: "chips",
            onClick: _cache[6] || (_cache[6] = ($event: any) => {triggerHapticFeedback('soft'); setInputData('40')})
          }, [
            (isSwapped.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_38, "40 USDT"))
              : (_openBlock(), _createElementBlock("p", _hoisted_39, "40 TON"))
          ]),
          _createElementVNode("div", {
            class: "chips",
            onClick: _cache[7] || (_cache[7] = ($event: any) => {triggerHapticFeedback('soft'); setInputData('50')})
          }, [
            (isSwapped.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_40, "50 USDT"))
              : (_openBlock(), _createElementBlock("p", _hoisted_41, "50 TON"))
          ]),
          _createElementVNode("div", {
            class: "chips",
            onClick: _cache[8] || (_cache[8] = ($event: any) => {triggerHapticFeedback('soft'); setInputData('100')})
          }, [
            (isSwapped.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_42, "100 USDT"))
              : (_openBlock(), _createElementBlock("p", _hoisted_43, "100 TON"))
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_44, [
      _createElementVNode("p", _hoisted_45, _toDisplayString(_unref(t)(`deposit_and_withdraw.your_ton_wallet`)), 1),
      _createVNode(WalletComponent)
    ])
  ]))
}
}

})