<script setup lang="ts">

import {defineProps, defineEmits, onMounted, ref} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";

const { t } = useI18n();

const props = defineProps<{
  player: Player,
  room: Room,
  currentCase: string | null;
}>()

const emit = defineEmits(['close', 'exithandle_showDown']); 

const tg = window.Telegram?.WebApp;

const roomService = new RoomService();

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

async function ExitRoom() {
  if(props.currentCase == 'show_down'){
    emit('exithandle_showDown'); 
  }
  else {
    try {
    roomService.leaveRoom(props.room.id);
    router.push('/rooms');
    }
    catch (error) {
      console.error("Ошибка при выходе из комнаты:", error)
    }
  }
}

</script>



<template>
  
  <div class="settings-room-background"></div>
  <div class="delete-room" @click="emit('close')">
    <div class="chips-wrapper">
      <div class="chips-wrapper-card">
        <div class="chips-up">
          <div class="chips-up-up">
            <div class="text1">
              <p>{{ t(`room_exit.text1`) }}</p>
            </div>
            <div class="close" @click="emit('close')">
              <img src="@/assets/tools/close-modal.svg" class="close">
            </div>
          </div>
          <div class="text2">
            <p>{{ t(`room_exit.text2`) }}</p>
          </div>
          <div class="text3">
            <p>{{ t(`room_exit.text3`) }}</p>
          </div>
          <div class="text4">
            <p>{{ t(`room_exit.text4`) }}</p>
          </div>
          <div class="text5">
            <p>{{ t(`room_exit.text5`) }}</p>
          </div>
        </div>
        <img src="@/assets/logo/logo-background.svg" class="background-logo no-image-animation">
      </div>
      <div class="chips-wrapper-btns">
        <div class="chips-bt1" @click="emit('close')">
          {{ t(`room_exit.bt1`) }}
        </div>
        <div class="chips-bt2" @click="ExitRoom()">
          {{ t(`room_exit.bt2`) }}
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

.chips-wrapper-card_show_down {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
  padding-bottom: 30px;
  padding-top: 30px;
}

.text6 {
  font-size: 3.7vw;
  font-weight: 600;
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.delete-room {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 1001;
  transform: scale(0.9);
  opacity: 0;
  animation: fadeInScale 0.2s ease-out forwards;
}

.settings-room-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #2A2B3799;
  animation: fadeIn 0.2s ease-out;
}

.chips-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 10;
}

.chips-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
  padding-bottom: 30px;
}

.background-logo {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.chips-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
  z-index: 3;
  width: 77.67vw;
}

.chips-up-up {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text1 {
  font-size: 18px;
  font-weight: 600;
}

.text2 {
  font-size: 13px;
  margin-top: 30px;
  text-align: start;
}

.text3, .text4, .text5 {
  font-size: 13px;
  margin-top: 10px;
  text-align: start;
}

.chips-wrapper-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.chips-bt1, .chips-bt2, .chips-bt3 {
  background-color: #62547F;
  padding: 14px 17px;
  font-size: 15px;
  border-radius: 24px;
  display: flex;
}

</style>