import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "game-user-image-wrapper" }
const _hoisted_3 = {
  key: 0,
  class: "dealer-button"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "emoji-item-user" }
const _hoisted_6 = { class: "game-user-image-border no-image-animation" }
const _hoisted_7 = { class: "user-corona" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "game-user-image-1" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "user-username-bet-wrapper" }
const _hoisted_12 = {
  key: 0,
  class: "user-balance no-image-animation",
  style: { justifyContent: 'center' }
}
const _hoisted_13 = {
  key: 1,
  class: "user-balance no-image-animation",
  style: { justifyContent: 'center' }
}
const _hoisted_14 = {
  class: "game-user-balance",
  style: { textAlign: 'center'}
}
const _hoisted_15 = {
  key: 2,
  class: "user-balance no-image-animation"
}
const _hoisted_16 = { class: "game-user-balance" }
const _hoisted_17 = { class: "user-username no-image-animation" }
const _hoisted_18 = { key: 1 }
const _hoisted_19 = { class: "game-user-image-wrapper" }
const _hoisted_20 = { class: "emoji-item-user" }
const _hoisted_21 = { class: "game-user-image-border no-image-animation" }
const _hoisted_22 = { class: "user-corona" }
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "game-user-image-1" }
const _hoisted_25 = ["src"]
const _hoisted_26 = { class: "user-username-bet-wrapper" }
const _hoisted_27 = {
  key: 0,
  class: "user-balance no-image-animation",
  style: { justifyContent: 'center' }
}
const _hoisted_28 = {
  key: 1,
  class: "user-balance no-image-animation",
  style: { justifyContent: 'center' }
}
const _hoisted_29 = {
  class: "game-user-balance",
  style: { textAlign: 'center'}
}
const _hoisted_30 = {
  key: 2,
  class: "user-balance no-image-animation"
}
const _hoisted_31 = { class: "game-user-balance" }
const _hoisted_32 = { class: "user-username no-image-animation" }

import store from "@/store";
import Player from "@/models/player";
import Game from "@/models/game";
import {ApiService} from "@/api/apiService";
import {computed, watch, ref, onMounted} from "vue";
import CountTimer from "@/components/game/CountTimer.vue";
import {formatNumber} from "../../tools/tools";
import EmojiPlayerItem from "@/components/game/EmojiPlayerItem.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GameUserAction',
  props: {
    selectedEmoji: {},
    game: {},
    player: {},
    showPlayer: { type: Boolean },
    selectedEmojiPlayerID: {},
    winners: {},
    currentCase: {},
    waitForJoin: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const seat = {
  player: props.player,
  seat: props.player?.seat
}

const userBetText = ref(formatNumber(store.getters.USER.bonus_balance));
const transformStyle= ref('');
const transformBetText = ref('')
const userBet = ref<number | undefined>(0);

const transformBet = ref('');

watch(
  () => props.player!.current_round_bet, 
  (newBet, oldBet) => {
    if (newBet && oldBet! > 0) {
      if (newBet != oldBet) {
        if (newBet! > oldBet!) {
          transformBet.value = 'transform: scale(1.3); color: #31B545; font-weight: 600; transition: transform 0.3s ease, color 0.3s ease;';
          setTimeout(() => {
            transformBet.value = 'transform: scale(1); color: inherit; font-weight: inherit; transition: transform 0.3s ease, color 0.3s ease;';
          }, 1000);
        }
      }
    }
  },
  { deep: true, immediate: true }
);

watch(() => props.player!.current_round_bet, () => {
  userBet.value = props.player!.current_round_bet;
});

watch(
  () => props.currentCase,
  (newCase) => {
    const isCurrentPlayerWinner = Array.isArray(props.winners) && props.winners.some((winner: any) => winner.user_id === props.player.user_id);

    if (newCase === 'show_down' && isCurrentPlayerWinner) {
      const winner = props.winners.find((winner: any) => winner.user_id === props.player?.user_id);
      const sum = winner?.sum || 0;
      userBetText.value = '+'+sum;
      transformBetText.value = 'transform: scale(1.1); color: #31B545; font-weight: 600;';
      transformStyle.value = 'transform: scale(1.1); z-index: 100; filter: drop-shadow(0px 0px 3px rgba(240, 190, 121)) drop-shadow(0px 0px 5px rgba(240, 190, 121)) drop-shadow(0px 0px 7px rgba(240, 190, 121));';
    }
  }
);

const userData = window.Telegram.WebApp.initDataUnsafe;

const borderColor = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return 'white';
    case "silver":
      return "var(--gradient-sliver)";
    case "gold":
      return "var(--gradient-gold)";
    default:
      return 'white';
  }
});

const avatarUrl = ref('');

const getCachedAvatarUrl = () => {
  const cachedAvatar = localStorage.getItem('userAvatarUrl');
  if (cachedAvatar && avatarUrl.value !== cachedAvatar) {
    avatarUrl.value = cachedAvatar;
  } else {
    updateAvatarUrl();
  }
};

const updateAvatarUrl = () => {
  const photoUrl = store.getters.USER.photo_url;
  if (photoUrl && avatarUrl.value !== ApiService.url + photoUrl) {
    avatarUrl.value = ApiService.url + photoUrl;
    localStorage.setItem('userAvatarUrl', avatarUrl.value);
  } else if (!photoUrl) {
    avatarUrl.value = require('@/assets/mock_user/user.svg');
  }
};

watch(() => store.getters.USER.photo_url, (newPhotoUrl) => {
  if (newPhotoUrl && avatarUrl.value !== ApiService.url + newPhotoUrl) {
    updateAvatarUrl();
  }
});

onMounted(() => {
  userBet.value = props.player!.current_round_bet;
  getCachedAvatarUrl();
});

const isCurrentPlayerWinner = computed(() => {
  return Array.isArray(props.winners) && props.winners.some((winner: any) => winner.user_id === props.player.user_id);
});


return (_ctx: any,_cache: any) => {
  return (props.game?.current_player_id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(['game-user-player', {'not-watcher': props.showPlayer }]),
          style: _normalizeStyle(transformStyle.value)
        }, [
          _createElementVNode("div", _hoisted_2, [
            (props.player?.id === props.game.dealer_id)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createElementVNode("img", {
                    class: "dealer-button-button",
                    src: require(`@/assets/dealer-buttons/btn-dealer-${_unref(store).getters.USER.vip_status}.svg`)
                  }, null, 8, _hoisted_4)
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(EmojiPlayerItem, {
                "selected-emoji": _ctx.selectedEmoji,
                selectedEmojiPlayerID: _ctx.selectedEmojiPlayerID!,
                seat: seat,
                showPlayer: _ctx.showPlayer
              }, null, 8, ["selected-emoji", "selectedEmojiPlayerID", "showPlayer"])
            ]),
            (props.player.status != 'inactive')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 1,
                  class: "user-game-deposit",
                  style: _normalizeStyle(transformBet.value)
                }, _toDisplayString(userBet.value), 5))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                (_unref(store).getters.USER.vip_status != 'first')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "user-corona no-image-animation",
                      src: require(`@/assets/player/status/status-${_unref(store).getters.USER.vip_status}.svg`)
                    }, null, 8, _hoisted_8))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createVNode(CountTimer, {
                  game: _ctx.game,
                  player: _ctx.player,
                  showPlayer: _ctx.showPlayer,
                  myplayer: true
                }, null, 8, ["game", "player", "showPlayer"]),
                _createElementVNode("img", {
                  class: "game-user-image no-image-animation",
                  style: _normalizeStyle({'background': (props.player?.id !== _ctx.game?.current_player_id) ? borderColor.value : 'none'}),
                  src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
                }, null, 12, _hoisted_10)
              ]),
              _createElementVNode("div", _hoisted_11, [
                (isCurrentPlayerWinner.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                      _createElementVNode("div", {
                        class: "game-user-balance",
                        style: _normalizeStyle([transformBetText.value, { textAlign: 'center', marginRight: '0' }])
                      }, _toDisplayString(userBetText.value), 5)
                    ]))
                  : (!isCurrentPlayerWinner.value && !props.showPlayer)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("div", _hoisted_14, _toDisplayString(_unref(formatNumber)(_unref(store).getters.USER.bonus_balance)), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        _createElementVNode("div", _hoisted_16, _toDisplayString(_unref(formatNumber)(props.player!.game_deposit)), 1)
                      ])),
                _createElementVNode("div", _hoisted_17, _toDisplayString(_unref(userData).user.username ?? 'noname'), 1)
              ])
            ])
          ])
        ], 6)
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_18, [
        _createElementVNode("div", {
          class: _normalizeClass(['game-user-player', {'not-watcher': props.showPlayer }]),
          style: _normalizeStyle(transformStyle.value)
        }, [
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", _hoisted_20, [
              _createVNode(EmojiPlayerItem, {
                "selected-emoji": _ctx.selectedEmoji,
                selectedEmojiPlayerID: _ctx.selectedEmojiPlayerID!,
                seat: seat,
                showPlayer: _ctx.showPlayer
              }, null, 8, ["selected-emoji", "selectedEmojiPlayerID", "showPlayer"])
            ]),
            (props.player.status != 'inactive')
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: "user-game-deposit",
                  style: _normalizeStyle(transformBet.value)
                }, _toDisplayString(userBet.value), 5))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", _hoisted_22, [
                (_unref(store).getters.USER.vip_status != 'first')
                  ? (_openBlock(), _createElementBlock("img", {
                      key: 0,
                      class: "user-corona no-image-animation",
                      src: require(`@/assets/player/status/status-${_unref(store).getters.USER.vip_status}.svg`)
                    }, null, 8, _hoisted_23))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("img", {
                  class: "game-user-image no-image-animation",
                  style: _normalizeStyle({'background': (props.player?.id !== _ctx.game?.current_player_id) ? borderColor.value : 'none'}),
                  src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
                }, null, 12, _hoisted_25)
              ]),
              _createElementVNode("div", _hoisted_26, [
                (isCurrentPlayerWinner.value)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                      _createElementVNode("div", {
                        class: "game-user-balance",
                        style: _normalizeStyle([transformBetText.value, { textAlign: 'center', marginRight: '0' }])
                      }, _toDisplayString(userBetText.value), 5)
                    ]))
                  : (!isCurrentPlayerWinner.value && !props.showPlayer)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, _toDisplayString(_unref(formatNumber)(_unref(store).getters.USER.bonus_balance)), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_30, [
                        _createElementVNode("div", _hoisted_31, _toDisplayString(_unref(formatNumber)(props.player.game_deposit)), 1)
                      ])),
                _createElementVNode("div", _hoisted_32, _toDisplayString(_unref(userData).user.username ?? 'noname'), 1)
              ])
            ])
          ])
        ], 6)
      ]))
}
}

})