<script setup lang="ts">

import PlayerHistory from "@/models/playerHistory";
import {formatLocalDate} from "@/tools/tools";
import { useI18n } from 'vue-i18n';
import {defineProps} from "vue"

const { t } = useI18n();

defineProps<{
  playerHistory: PlayerHistory
}>();

</script>

<template>
  <div class="history-item">
    <div>
      <p class="history-item-room-number">{{ playerHistory.big_blind_bet }}</p>
      <p class="history-item-room-label">{{ t(`profile.history_item_room_label`) }}</p>
    </div>
    <div>
      <p class="history-item-date">{{ formatLocalDate(playerHistory.updated_at) }}</p>
    </div>
    <div class="history-item-money-wrapper">
      <p :class="{ 'history-item-money': true, 'history-item-money-plus': playerHistory.won_amount > 0, 'history-item-money-minus': playerHistory.won_amount <= 0 }">{{ playerHistory.won_amount > 0 ? '+' + playerHistory.won_amount : playerHistory.won_amount}}</p>
      <img src="@/assets/logo/money_logo.svg" class="history-item-money-logo">
    </div>
  </div>
</template>

<style scoped>
.history-item {
  background-color: #5C4E73;
  border-radius: var(--border-mixed);
  margin-top: 14px;
  padding: 17px 33px 17px 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.history-item-room-number {
  font-size: 28px;
  font-weight: 500;
}

.history-item-room-label {
  font-size: 10px;
  font-weight: 500;
}

.history-item-date {
  font-size: 14px;
  font-weight: 500;
}

.history-item-money-wrapper {
  display: flex;
  align-items: center;
}

.history-item-money {
  font-size: 18px;
  font-weight: 500;
  margin-right: 10px;
}

.history-item-money-plus {
  color: #31B545;
}

.history-item-money-minus {
  color: #FF0C17;
}

.history-item-money-logo {
  width: 20px;
  height: 20px;
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
}
</style>