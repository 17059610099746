import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "nav-bar-item__image-wrapper" }
const _hoisted_2 = ["src", "alt"]
const _hoisted_3 = { class: "nav-bar-item__label-wrapper" }
const _hoisted_4 = { class: "nav-bar-item__label" }

import { useI18n } from 'vue-i18n';



export default /*@__PURE__*/_defineComponent({
  __name: 'NavBarItem',
  props: {
  label: String,
  image: String,
  path: Array
},
  setup(__props) {

const { t } = useI18n();

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'nav-bar-item': true, 'nav-bar-item__active': __props.path?.includes(_ctx.$route.fullPath) === true })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        src: require(`@/assets/navigation/${__props.image}`),
        alt: __props.label,
        class: "nav-bar-item__image"
      }, null, 8, _hoisted_2)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`menu.${__props.label}`)), 1)
    ])
  ], 2))
}
}

})