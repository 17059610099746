<script setup lang="ts">
import TournamentListItem from "@/components/lists/tournaments/TournamentListItem.vue"
import { useI18n } from 'vue-i18n';
import HeaderMini from "@/components/header/HeaderMini.vue";


const { t } = useI18n();

</script>

<template>
<header-mini/>
    <div class="tournament-wrapper">
        <div class="tournaments-title">
            <p>{{ t(`tournaments.title_partner`) }}</p>
        </div>
        <tournament-list-item/>
    </div>

</template>

<style scoped>

.tournament-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 150px;
    margin-top: 72px;
}

.tournaments-title {
    display: flex;
    align-items: start;
    font-weight: 500;
    font-size: 28px;
}

</style>