import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/logo/money_logo.svg'
import _imports_1 from '@/assets/game/icon_settings.svg'


const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "header-mini-username" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "header-balance-sum" }
const _hoisted_5 = { class: "header-balance-sum" }

import router from "@/router";
import store from "@/store";
import {formatNumber} from "@/tools/tools";
import {ApiService} from "@/api/apiService";
import {watch} from "vue"
import Player from "@/models/player";
import Room from "@/models/room";
import Game from "@/models/game";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderMini',
  props: {
    animation: { type: Boolean },
    currentPlayer: {},
    room: {},
    player: {},
    game: {}
  },
  emits: ['openSettings', 'openBalance'],
  setup(__props: any, { emit: __emit }) {

const userData = window.Telegram.WebApp.initDataUnsafe;

const props = __props;

// watch(() => props.currentPlayer, () => {

// });

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const emit = __emit

function openSettings(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
  emit('openSettings');
  
}

function openBalance (style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
  emit('openBalance');
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'header-wrapper': true, 'header-animation': _ctx.animation})
  }, [
    _createElementVNode("div", {
      class: "header-wrapper__user-info",
      onClick: _cache[0] || (_cache[0] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/profile')})
    }, [
      _createElementVNode("img", {
        class: "header-wrapper__user-image no-image-animation",
        src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
      }, null, 8, _hoisted_1),
      _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(userData).user.username ?? "noname"), 1),
      (_unref(store).getters.USER.vip_status != 'first')
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "header-wrapper__user-status no-image-animation",
            src: require(`@/assets/player/status/status-${_unref(store).getters.USER.vip_status}.svg`)
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true)
    ]),
    (props.player && props.player?.action_status != 'watcher')
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(['header-wrapper__balance', {'header-wrapper__balance_margin': _ctx.currentPlayer}])
        }, [
          _createElementVNode("div", {
            class: "header-balance",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (openBalance('light')))
          }, [
            _cache[4] || (_cache[4] = _createElementVNode("img", {
              class: "header-balance-image no-image-animation",
              src: _imports_0
            }, null, -1)),
            _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(formatNumber)(props.player.game_deposit)), 1)
          ])
        ], 2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(['header-wrapper__balance', {'header-wrapper__balance_margin': _ctx.currentPlayer}])
        }, [
          _createElementVNode("div", {
            class: "header-balance",
            onClick: _cache[2] || (_cache[2] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/profile')})
          }, [
            _cache[5] || (_cache[5] = _createElementVNode("img", {
              class: "header-balance-image no-image-animation",
              src: _imports_0
            }, null, -1)),
            _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(formatNumber)(_unref(store).getters.USER.bonus_balance)), 1)
          ])
        ], 2)),
    (_ctx.room?.is_private == 1 && _ctx.room?.created_by == _unref(store).getters.USER.id)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "header-wrapper-settings",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (openSettings('light')))
        }, _cache[6] || (_cache[6] = [
          _createElementVNode("img", {
            class: "icon-settings",
            src: _imports_1
          }, null, -1)
        ])))
      : _createCommentVNode("", true)
  ], 2))
}
}

})