<script setup lang="ts">
import HeaderMini from "@/components/header/HeaderMini.vue";
import languages from "@/views/languages";
import store from "@/store";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';

const { t, locale } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/settings')
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

const setLanguage = (language: string) => {
  store.commit('SET_SELECTED_LANGUAGE', language); 
  locale.value = language;
  localStorage.setItem('selectedLanguage', language);
};

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}



</script>

<template>
  <header-mini/>
  <div class="language-setting-wrapper">
    <p class="language-label">{{ t('settings.language') }}</p>
    <div class="language-selection-wrapper">
      <div class="language-selection" v-for="(language, i) in languages" :key="i">
        <div>
          <p class="language-name">{{ language.name }} ({{ language.symbol }})</p>
        </div>
        <div @click="triggerHapticFeedback('light'); setLanguage(language.symbol)">
          <img v-if="store.getters.SELECTED_LANGUAGE === language.symbol" class="language-checker" src="@/assets/checkers/check.svg" />
          <img v-else class="language-checker" src="@/assets/checkers/uncheck.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.language-setting-wrapper {
  margin-top: 100px;
}

.language-selection-wrapper {
  margin-top: 35px;
}

.language-selection {
  padding: 20px 30px 20px 19px;
  background: #5C4E73;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.language-name {
  font-size: 18px;
  font-weight: 500;
}

.language-checker {
  width: 31px;
  height: 31px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  -webkit-tap-highlight-color: transparent;
  outline: none;
  &:active, &:focus {
      background-color: transparent;
  }
}

.language-label {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}
</style>