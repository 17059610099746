import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/logo/money_logo.svg'


const _hoisted_1 = { class: "top-player-card" }
const _hoisted_2 = { class: "top-player-card-info" }
const _hoisted_3 = { class: "top-player-image-wrapper" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "top-player-info-wrapper" }
const _hoisted_6 = { class: "top-player-user-wrapper" }
const _hoisted_7 = { class: "top-player-card-username" }
const _hoisted_8 = ["src"]
const _hoisted_9 = { class: "top-players-wrapper" }
const _hoisted_10 = { class: "players-label" }
const _hoisted_11 = { class: "players-count" }
const _hoisted_12 = { class: "player-win-sum" }
const _hoisted_13 = { class: "player-win-sum-label" }

import {ApiService} from "@/api/apiService";
import {formatNumber} from "@/tools/tools";
import TopPlayer from "@/models/topPlayer";
import { useI18n } from 'vue-i18n';



export default /*@__PURE__*/_defineComponent({
  __name: 'TopPlayerListItem',
  props: {
    item: {}
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          class: "top-player-image",
          src: _ctx.item.user_photo_url ? _unref(ApiService).url + _ctx.item.user_photo_url : require('@/assets/mock_user/user.svg')
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("p", _hoisted_7, _toDisplayString((_ctx.item.user_username ?? 'noname').charAt(0)) + "•••", 1),
          (_ctx.item.vip_status !== 'first' && _ctx.item.vip_status != null)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                src: require(`@/assets/player/status/status-${_ctx.item.vip_status}`)
              }, null, 8, _hoisted_8))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(t)(`main.wins_player_top`)) + ": ", 1),
          _createElementVNode("p", _hoisted_11, _toDisplayString(_ctx.item.won_games) + "/" + _toDisplayString(_ctx.item.total_games), 1)
        ])
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_12, [
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(formatNumber)(_ctx.item.total_won)), 1)
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_0,
          class: "top-player-balance-sum-logo"
        }, null, -1))
      ])
    ])
  ]))
}
}

})