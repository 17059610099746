import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/tools/close-modal.svg'
import _imports_1 from '@/assets/logo/logo-background.svg'


const _hoisted_1 = { class: "chips-wrapper" }
const _hoisted_2 = { class: "chips-wrapper-card" }
const _hoisted_3 = { class: "chips-up" }
const _hoisted_4 = { class: "chips-up-up" }
const _hoisted_5 = { class: "text1" }
const _hoisted_6 = { class: "text2" }
const _hoisted_7 = { class: "text3" }
const _hoisted_8 = { class: "text4" }
const _hoisted_9 = { class: "text5" }
const _hoisted_10 = { class: "chips-wrapper-btns" }

import {onMounted, ref} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";


export default /*@__PURE__*/_defineComponent({
  __name: 'ExitRoomPop',
  props: {
    player: {},
    room: {},
    currentCase: {}
  },
  emits: ['close', 'exithandle_showDown'],
  setup(__props: any, { emit: __emit }) {


const { t } = useI18n();

const props = __props

const emit = __emit; 

const tg = window.Telegram?.WebApp;

const roomService = new RoomService();

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

async function ExitRoom() {
  // if(props.currentCase == 'show_down'){
  //   emit('exithandle_showDown'); 
  // }
  // else {
    try {
    roomService.leaveRoom(props.room.id);
    router.push('/rooms');
    }
    catch (error) {
      console.error("Ошибка при выходе из комнаты:", error)
    }
  // }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[6] || (_cache[6] = _createElementVNode("div", { class: "settings-room-background" }, null, -1)),
    _createElementVNode("div", {
      class: "delete-room",
      onClick: _cache[3] || (_cache[3] = ($event: any) => (emit('close')))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)(`room_exit.text1`)), 1)
              ]),
              _createElementVNode("div", {
                class: "close",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "close"
                }, null, -1)
              ]))
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)(`room_exit.text2`)), 1)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)(`room_exit.text3`)), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)(`room_exit.text4`)), 1)
            ]),
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)(`room_exit.text5`)), 1)
            ])
          ]),
          _cache[5] || (_cache[5] = _createElementVNode("img", {
            src: _imports_1,
            class: "background-logo no-image-animation"
          }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", {
            class: "chips-bt1",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('close')))
          }, _toDisplayString(_unref(t)(`room_exit.bt1`)), 1),
          _createElementVNode("div", {
            class: "chips-bt2",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (ExitRoom()))
          }, _toDisplayString(_unref(t)(`room_exit.bt2`)), 1)
        ])
      ])
    ])
  ], 64))
}
}

})