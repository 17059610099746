<script setup lang="ts">
import store from "@/store";
import PlayerCard from "@/models/playerCard";
import Player from "@/models/player";
import Game from "@/models/game";
import {computed, onMounted, defineProps, defineEmits, watch, ref } from "vue";
import {formatNumber} from "../../tools/tools";
import Room from "@/models/room";
import GameUserPlayer from "@/components/game/GameUserAction.vue"
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const props = defineProps<{
  selectedEmoji: any,
  showPlayer: boolean
  selectedEmojiPlayerID: number
  playerCards: PlayerCard[],
  players: Player[],
  player: Player,
  hand: { combination: string, cards: { card_rank: string, card_suit: string }[] },
  game: Game,
  room: Room,
  sliderValue: number,
  waitForJoin: boolean,
  dealerSeat: number | null
  joinRoom: () => void,
  min: number,
  foldCards: boolean,
  autoFoldCheck: boolean,
  autoCall: boolean,
  showBtnRaise: boolean
  currentCase: string | null;
}>();

const seat = {
  player: props.player,
  seat: props.player?.seat
}

const preloadImage = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.src = url;
  });
};

// const emit = defineEmits(['call', 'raise', 'check', 'bet', 'fold', 'updateSliderValue', 'autoFoldCheck_handle', 'autoCall_handle', 'showHandleBtnRaise', 'hideHandleBtnRaise']);

const emit = defineEmits<{
  (e: 'call'): void;
  (e: 'raise'): void;
  (e: 'check'): void;
  (e: 'bet'): void; 
  (e: 'bet_btn', bet: number): void; 
  (e: 'raise_btn', bet: number): void; 
  (e: 'fold'): void;
  (e: 'autoFoldCheck_handle'): void;
  (e: 'autoCall_handle'): void;
  (e: 'showHandleBtnRaise'): void;
  (e: 'hideHandleBtnRaise'): void;
}>();


// const borderColor = computed(() => {
//   switch (store.getters.USER.vip_status) {
//     case "first":
//     case "usual":
//       return 'white'
//     case "silver":
//       return "var(--gradient-sliver)"
//     case "gold":
//       return "var(--gradient-gold)"
//     default:
//       return 'white';
//   }
// });


onMounted(async () => {
  const image1 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${store.getters.USER.vip_status}/${props.playerCards[0].card_rank.toLowerCase()}_${store.getters.USER.vip_status != 'first' ? props.playerCards[0].card_suit.toLowerCase(): props.playerCards[0].card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`);
  const image2 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${store.getters.USER.vip_status}/${props.playerCards[1].card_rank.toLowerCase()}_${store.getters.USER.vip_status != 'first' ? props.playerCards[1].card_suit.toLowerCase(): props.playerCards[1].card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`);
  
  const image1El = document.getElementById('player-card-1')
  const image2El = document.getElementById('player-card-2')

  await preloadImage(image1);
  await preloadImage(image2);

  if (image1El && image2El && (image1 && image2)) {
    image1El.setAttribute('src', image1);
    image2El.setAttribute('src', image2);

  }

  checkHandCards();
})

// function addSliderValue(){
//   if (isRaiseInactive.value) return;
//   triggerHapticFeedback('light');
//   if (props.sliderValue < props.min){
//     emit('updateSliderValue', props.min + props.room.big_blind_bet)
//   } else {
//     emit('updateSliderValue', props.sliderValue + props.room.big_blind_bet)
//   }
// }

// function delSliderValue(){
//   if (isRaiseInactive.value) return;
//   triggerHapticFeedback('light');
//   emit('updateSliderValue', props.sliderValue - props.room.big_blind_bet)
// }

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const checkHandCards = () => {
  const isCardInHand = (card: { card_rank: string; card_suit: string }) =>
    props.hand.cards.some((comboCard) =>
      comboCard.card_rank === card.card_rank &&
      comboCard.card_suit === card.card_suit
  );

  if (props.playerCards.length > 0) {
    const image1El = document.getElementById('player-card-1');
    const image2El = document.getElementById('player-card-2');

      const applyHighlightAnimation = (element: HTMLElement) => {
        setTimeout(() => {

          setTimeout(() => {
            if(element === image1El){
              element.style.animation = 'highlight-card1 .4s ease forwards';
            }
            else {
              element.style.animation = 'highlight-card2 .4s ease forwards';
            }
          }, 50);

          setTimeout(() => {
            if (element === image1El) {
              element.style.animation = 'outhighlight-card1 .4s ease forwards';
            }
            else {
              element.style.animation = 'outhighlight-card2 .4s ease forwards';
            }
          }, 2000);
        }, 1800);
      };
    

    if (isCardInHand(props.playerCards[0]) && image1El) {
      applyHighlightAnimation(image1El);
    }

    if (isCardInHand(props.playerCards[1]) && image2El) {
      applyHighlightAnimation(image2El);
    }
  }
};

watch(
  () => props.hand, 
  (newHand, oldHand) => {
    if (JSON.stringify(newHand) !== JSON.stringify(oldHand)) {
      checkHandCards();
    }
  },
  { deep: true, immediate: true }
);

const isRaiseInactive = computed(() => {
  const myPlayer = props.player;
  const currentMaxBet = Math.max(...props.players.map(x => x.current_round_bet));
  return myPlayer.game_deposit <= currentMaxBet;
});

const showGameUserPlayer = computed(() => {
  return (props.player?.action_status === 'watcher') || props.waitForJoin;
});

const imAtTheTable = computed(() => {
  return props.game?.current_player_id;
});

const maxBetTable = computed (() => {
  return Math.max(...props.players.map(x => x.current_round_bet));
});

const minRaiseTable = computed(() => {
  return Math.max(...props.players.map(x => x.current_round_bet))*2;
});

const myDeposit = computed(() => {
  if ("game_deposit" in props.player) return props.player.game_deposit;
  else return 0;
});

const myCurrentBet = computed (() => {
  return props.player.current_round_bet;
});

const canImove = computed(() => {
  return props.player.id == props.game?.current_player_id;
});

const imInArrayPlayers = computed(() => {
  return props.players.some(item => item.id === props.player.id);
});

const myBetEqualBetTable = computed (() => {
  return props.player.current_round_bet >= maxBetTable.value;
});

const canISeat = computed(() => {
  return props.room.players_count != props.room.max_players;
});

const canIRaise = computed(() => {
  return myDeposit.value >= maxBetTable.value;
});

const canIAllin = computed (() => {
 return myDeposit.value >= maxBetTable.value && myDeposit.value <= minRaiseTable.value;
});

const bigBlindBet = computed(() =>{
  return props.room.big_blind_bet;
});

const callSum = computed(() => {
  if (imInArrayPlayers.value && canImove.value) {
    let maxBet = Math.max(Math.max(...props.players.map(x => x.current_round_bet)));
    if(props.player.game_deposit >= maxBet) {
      return formatNumber(maxBet)
    }
    else {
      return formatNumber(props.player.game_deposit);
    }
  }
  else {
    return "";
  }
});

const isNewGameWithBlinds = computed(() => {
  return (
    props.currentCase === 'new_game' &&
    (props.room.big_blind_bet + props.room.big_blind_bet / 2) === props.game.bank
  );
});

const potBets = computed(() => {
  const bank = props.game.bank;
  const previousBet = Math.max(...props.players.map(player => player.current_round_bet));
  const minimumRaise = previousBet * 2;
  const percentages = [0.33, 0.5, 0.75, 1.0];
  const bigBlindBet = props.room.big_blind_bet;

  if (isNewGameWithBlinds.value) {
    return [
      { label: '1 BB', percentage: '', bet: bigBlindBet },
      { label: '2 BB', percentage: '', bet: bigBlindBet * 2 },
      { label: '3 BB', percentage: '', bet: bigBlindBet * 3 },
      { label: '4 BB', percentage: '', bet: bigBlindBet * 4 },
    ].reverse();
  } else {
    return percentages
      .map(percentage => {
        const calculatedBet = bank * percentage;
        return {
          label: '',
          percentage: `${(percentage * 100).toFixed(0)}%`,
          bet: Math.max(calculatedBet, minimumRaise),
        };
      })
      .reverse();
  }
});



const handleRaise = (bet: number) => {
  console.log(`Выбрана ставка: ${bet}`);
  if (isNewGameWithBlinds.value) {
    emit('bet_btn', bet);
  }
  else {
    emit('raise_btn', bet);
  }
};

</script>

<template>
  <div class="game-actions">
    <div class="user-hand-wrapper">
        <!-- <p class="user-hand">{{ props.hand.combination }}</p> -->
        <p class="user-hand">Старшая карта</p>
      </div>
    <div class="user-info-wrapper">
      <div class="user-info">
       <div class="elements-player-center">
        <div class="player-cards-wrapper">
          <img :style="{ 'opacity': imInArrayPlayers ? '1' : '0', 'filter': props.foldCards ? 'brightness(69%)' : 'none'  }" id="player-card-1" class="player-card-1 no-image-animation" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
          <img :style="{ 'opacity': imInArrayPlayers ? '1' : '0', 'filter': props.foldCards ? 'brightness(69%)' : 'none'  }" id="player-card-2" class="player-card-2 no-image-animation" :src="require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`)">
        </div>
        <div class="game-button-raise-wrapper-watcher" v-if="imInArrayPlayers || waitForJoin">
          <div v-if="showBtnRaise" class="btn-raise-pot">
            <div 
              v-for="pot in potBets" 
              :key="pot.label || pot.percentage" 
              @click="handleRaise(pot.bet)" 
              :class="{ 'game-button-raise-pot': true }">
              {{ formatNumber(pot.bet) }}
              <p class="bb-percentage">{{ pot.label || pot.percentage }}</p>
            </div>
          </div>
          <div v-if="isNewGameWithBlinds && !props.showBtnRaise" @click="canImove ? emit('showHandleBtnRaise'): null" :class="{ 'game-button-raise-pot': true, 'game-button-inactive1': !canIRaise || !canImove || canIAllin && (myDeposit >= bigBlindBet*2)}">
            <p class="bb-percentage">ББ</p>
            {{ t(`game.btn_bet`) }}
          </div>
          <div v-else-if="!isNewGameWithBlinds && !props.showBtnRaise" @click="canImove ? emit('showHandleBtnRaise'): null" :class="{ 'game-button-raise-pot': true, 'game-button-inactive1': !canIRaise || !canImove || canIAllin && (myDeposit >= bigBlindBet*2)}">
            <p class="bb-percentage">%</p>
            {{ t(`game.btn_raise`) }}
          </div>
        </div>
        <div class="game-button-raise-wrapper-watcher" v-else>
          <div></div>
          <div></div>
        </div>
       </div>
      </div>
    </div>
      <div v-if="imAtTheTable">
        <div class="game-buttons" v-if="imInArrayPlayers || waitForJoin">
          <div :class="{ 'game-button-wrapper': true }">
            <div v-if="!canImove && !autoFoldCheck && !autoCall" :class="{ 'game-button-width': true, 'game-button-auto ': true }" @click="triggerHapticFeedback('light'); emit('autoFoldCheck_handle')">
              <p class="bb-percentage">auto</p>
              {{ t(`game.btn_fold_ckeck`) }}
            </div>
            <div v-else :class="{'game-button-width': true, 'game-button-fold ': true, 'game-button-inactive': !canImove }" @click="triggerHapticFeedback('light'); canImove ? emit('fold') : null; emit('hideHandleBtnRaise')">{{ t(`game.btn_fold`) }}</div>
          </div>
            <div v-if="!canImove && !autoCall && !autoFoldCheck" :class="{'game-button-width': true, 'game-button-auto': true, 'game-button-wrapper': true}" @click="triggerHapticFeedback('light'); emit('autoCall_handle')">
              <p class="bb-percentage">auto</p>
              {{ t(`game.btn_call`) }}
            </div>
          <div v-else-if="myCurrentBet >= maxBetTable" :class="{'game-button-width': true, 'game-button': true, 'game-button-inactive': !canImove }" @click="triggerHapticFeedback('light'); canImove ? emit('check') : null; emit('hideHandleBtnRaise')">{{ t(`game.bnt_check`) }}</div>
          <div v-else-if="myBetEqualBetTable" :class="{'game-button-width': true, 'game-button': true, 'game-button-inactive': !canImove }" @click="triggerHapticFeedback('light'); canImove ? emit('call') : null; emit('hideHandleBtnRaise')">{{ t(`game.all_in`) }}</div>
          <div v-else :class="{'game-button-width': true, 'game-button': true, 'game-button-inactive': !canImove }" @click="triggerHapticFeedback('light'); canImove ? emit('call') : null; emit('hideHandleBtnRaise')">{{ t(`game.btn_call`) }}&nbsp;<span class="call-sum">{{ callSum }}</span></div>
          <div :class="{ 'game-button-raise-wrapper': true }">
            <div v-if="maxBetTable==0" :class="{'game-button-width': true, 'game-button-raise': true, 'game-button-inactive': !canIRaise || !canImove }" @click="triggerHapticFeedback('light'); canImove && canIRaise ? emit('bet') : null; emit('hideHandleBtnRaise')">{{ t(`game.btn_bet`) }}</div>
            <div v-else-if="canIAllin" :class="{'game-button-width': true, 'game-button-raise': true, 'game-button-inactive': !canImove }" @click="triggerHapticFeedback('light'); canImove && canIAllin ? emit('raise') : null; emit('hideHandleBtnRaise')">{{ t(`game.all_in`) }}</div>
            <div v-else :class="{'game-button-width': true, 'game-button-raise': true, 'game-button-inactive': !canIRaise || !canImove }" @click="triggerHapticFeedback('light'); canImove && canIRaise ? emit('raise') : null; emit('hideHandleBtnRaise')">{{ t(`game.btn_raise`) }}</div>
          </div>
        </div>
        <div class="game-buttons" v-else>       
          <div class="join-table" @click=" joinRoom()">
            {{ t(`game.join_room`) }}
          </div>
        </div>
      </div>
      <div v-else>
        <div class="game-buttons" v-if="imInArrayPlayers || waitForJoin">
        <div :class="{'game-button-width': true, 'game-button': true, 'game-button-inactive': true }">{{ t(`game.btn_fold`) }}</div>
        <div :class="{'game-button-width': true, 'game-button': true, 'game-button-inactive': true }">{{ t(`game.btn_call`) }}</div>
          <div :class="{ 'game-button-raise-wrapper': true }">
            <div :class="{ 'game-button-raise': true, 'game-button-inactive': true }">{{ t(`game.btn_bet`) }}</div>
          </div>
        </div>
        <div class="game-buttons" v-else>       
          <div class="join-table" @click="triggerHapticFeedback('light'); joinRoom()" v-if="canISeat">
            {{ t(`game.join_room`) }}
          </div>
          <div :class="{'join-table': true, 'game-button-inactive': !canISeat}" v-else>
            {{ t(`game.join_room`) }}
          </div>
        </div>
      </div>
  </div>
</template>

<style scoped>

.game-button-width {
  width: 25.12vw;
}

.game-button-auto {
  opacity: 1;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.9vh;
  padding-bottom: 1.1vh;
  color: #cbc0df;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
  overflow: hidden;
  position: relative;
}

.bb-percentage {
  padding-right: 5px;
  padding-top: 2px;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.3vh;
  background-color: #15151E;
  padding-left: 13px;
  padding-bottom: 2px;
  border-bottom-right-radius: 10px;
}

.game-button-raise-pot {
  position: relative;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
  overflow: hidden;
}

.btn-raise-pot {
  width: 100%;
  gap: 10px;
  display: flex;
  flex-direction: column;
}

.game-button-wrapper {
  width: 32%;
}

.call-sum {
  transform: scale(1.2);
  color: #31B545;
  display: inline-block;
}

.user-player {
  position: absolute;
  left: 25vw;
}

.game-button-raise1 {
  position: relative;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-button-inactive1 {
  opacity: 0.4;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-actions {
  /* max-width: 293px;
  width: 68.14vw;
  margin-left: 10vw;
  display: flex;
  flex-direction: column;
  align-items: center; */

  /* background-color: #00000030; */
  /* border-radius: var(--border-mixed); */
  max-width: 293px;
  width: 68.14vw;
  /* margin-left: 10vw; */
  display: flex;
  flex-direction: column;
  align-items: start;
  /* box-shadow: 0 4px 4px rgba(0,0,0,.25); */
  /* margin-left: 10px; */
  /* padding-left: 10px; */
  position: relative;
  z-index: 10;
  margin-bottom: 4.7vh;
}

.user-info {
  display: flex;
  width: 77.67vw;
  justify-content: end;
}

.user-info-wrapper {
  display: flex;
  justify-content: space-between;
}

.user-hand {
  font-size: 18px;
  width: 92px;
  text-align: start;
  height: 30px;
  color: #C6BDD4;
  font-weight: 700;
  line-height: 0.95;
}

.game-user-image {
  padding: 3px;
  border-radius: 50%;
  width: 11.3vh;;
  filter: drop-shadow(2px 4px 4px rgba(0, 0, 0, 0.4));
  z-index: 1;
}

.user-hand-wrapper {
  width: 80%;
  display: flex;
  justify-content: start;
  height: 30px;
  margin-top: 28px;
  margin-bottom: -7px;
}

.elements-player-center {
  display: flex;
  align-items: end;
  gap: 20px;
}

.player-card-1 {
  filter: drop-shadow(2px 4px 4px rgba(0,0,0,.4));
  width: 85px;
  transform: rotate(-14deg);
  margin-right: 26px;
  margin-top: 26px;
  margin-bottom: -33px;
}

.player-card-2 {
  filter: drop-shadow(2px 4px 4px rgba(0,0,0,.4));
  width: 85px;
  transform: rotate(13deg);
  margin-left: -33px;
  margin-top: 8px;
  margin-bottom: 24px;
}

.player-cards-wrapper {
  justify-content: start;
  margin-right: 18px;
  z-index: 10;
  display: flex;
  margin-top: -25px;
}

.game-user-image-wrapper {
  position: relative;
  z-index: 3;
  padding: 2px;
}

.game-buttons {
  position: relative;
  display: flex;
  width: 77.67vw;
  align-items: flex-end;
  margin-top: 10px;
  gap: 14px;
  z-index: 10;
}

.game-button-fold {
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-button {
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-button-raise {
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-button-raise-wrapper {
  width: 21.16vw;
}

.game-button-raise-wrapper-watcher {
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;
  gap: 5px;
  opacity: 1;
  width: 21.16vw;
  max-height: 50px;
}

.game-button-inactive {
  opacity: 0.4;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  background: #4D3E66;
  box-shadow: inset 0 0 0 0.5px #7F69A4, 0 0 10px rgba(0,0,0,.4);
  border-radius: var(--border-mixed);
}

.game-user-image-border {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
}

.user-username-bet-wrapper {
  z-index: 2;
  bottom: -17px;
  position: absolute;
  width: 100%;
}

.user-username {
  font-size: 1.4vh;
  font-weight: 500;
  z-index: 2;
}

.user-balance {
  font-size: 1.5vh;
  padding: 0.5vh;
  background: #5C4E73;
  box-shadow: 0 5px 62px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
}

.user-corona {
  height: 3vh;
  position: absolute;
  top: calc(-9px - 2.5vh);
  width: 100%;
}

.join-table, .show-cards, .fold-cards {
  background: #5C4E73;
  font-weight: 700;
  font-size: 1.95vh;
  padding-top: 1.5vh;
  padding-bottom: 1.5vh;
  color: #CBC0DF;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
  border-radius: var(--border-mixed);
  display: flex;
  justify-content: center;
}
</style>