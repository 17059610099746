import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, createStaticVNode as _createStaticVNode, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/logo/logo.svg'
import _imports_1 from '@/assets/logo/go_coin_24.svg'
import _imports_2 from '@/assets/logo/money_logo.svg'
import _imports_3 from '@/assets/player/edit_name.svg'
import _imports_4 from '@/assets/logo/logo-background.svg'
import _imports_5 from '@/assets/tools/all-transaction-arrow.svg'
import _imports_6 from '@/assets/friends/coin_exchange.svg'
import _imports_7 from '@/assets/logo/logo-background-2.svg'
import _imports_8 from '@/assets/friends/friend.svg'
import _imports_9 from '@/assets/friends/open-red-box-dark.svg'
import _imports_10 from '@/assets/friends/Sub_tg_icon.svg'
import _imports_11 from '@/assets/checkers/check.svg'
import _imports_12 from '@/assets/friends/Profile_arrow.svg'
import _imports_13 from '@/assets/friends/Watch_add.svg'
import _imports_14 from '@/assets/friends/Settings_icon.svg'


const _hoisted_1 = {
  key: 2,
  class: "profile-wrapper"
}
const _hoisted_2 = { class: "user-card-container" }
const _hoisted_3 = { class: "user-card-left" }
const _hoisted_4 = { class: "user-card-left-up" }
const _hoisted_5 = { class: "user-balance" }
const _hoisted_6 = { class: "balance-sum-wrapper-gocoin" }
const _hoisted_7 = { class: "balance-sum" }
const _hoisted_8 = { class: "balance-sum-wrapper-redcoin" }
const _hoisted_9 = { class: "balance-sum" }
const _hoisted_10 = { class: "user-card-left-down" }
const _hoisted_11 = { class: "week-earn" }
const _hoisted_12 = {
  key: 0,
  class: "user-info-week-earn"
}
const _hoisted_13 = { class: "week-earn-label" }
const _hoisted_14 = { class: "user-card-right" }
const _hoisted_15 = ["src"]
const _hoisted_16 = ["src"]
const _hoisted_17 = { class: "coin-exchange-wrapper" }
const _hoisted_18 = { class: "balance-action-text" }
const _hoisted_19 = { class: "levels-and-balance-actions" }
const _hoisted_20 = { class: "levels-text" }
const _hoisted_21 = { class: "balance-actions" }
const _hoisted_22 = { class: "balance-action-text" }
const _hoisted_23 = { class: "balance-action-text" }
const _hoisted_24 = { class: "all-transaction-wrapper" }
const _hoisted_25 = { class: "balance-action-text" }
const _hoisted_26 = { class: "bonus-wrapper" }
const _hoisted_27 = { class: "bonus-wrapper-up" }
const _hoisted_28 = { class: "bonus-wrapper" }
const _hoisted_29 = { class: "bonus-wrapper-center-box" }
const _hoisted_30 = { class: "box-text" }
const _hoisted_31 = { class: "sub-tg-wrapper" }
const _hoisted_32 = { class: "sub-tg-text" }
const _hoisted_33 = { class: "sub-tg-text-text" }
const _hoisted_34 = { class: "all-transaction-arrow" }
const _hoisted_35 = {
  key: 0,
  src: _imports_11
}
const _hoisted_36 = {
  key: 1,
  src: _imports_12
}
const _hoisted_37 = { class: "sub-tg-wrapper" }
const _hoisted_38 = { class: "sub-tg-text" }
const _hoisted_39 = { class: "sub-tg-text-text" }
const _hoisted_40 = { class: "settings-wrapper" }
const _hoisted_41 = { class: "settings-action-text-wrapper" }
const _hoisted_42 = { class: "balance-action-text" }
const _hoisted_43 = { class: "game-history" }

import HistoryList from "@/components/lists/history/HistoryList.vue";
import router from "@/router";
import store from "../store";
import { formatNumberByZiro } from "../tools/tools";
import { MiniAppsEventListener, off, on, postEvent } from "@telegram-apps/sdk";
import { computed, onMounted, onUnmounted, ref, inject, watch, nextTick } from "vue";
import { ApiService } from "@/api/apiService";
import levelUp from "@/components/levelup/LevelUp.vue";
import { useI18n } from 'vue-i18n';
import { UserService } from "@/api/userService";
import {formatNumber} from "../tools/tools";
import EditNameWrapper from "@/views/EditNameWrapper.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProfileView',
  setup(__props) {

const playNewTakeBonus = inject<() => void>("playNewTakeBonus");

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const bonusBtn = ref(t(`profile.claim_bonus`));

const isBonusGeted = ref(false);

const intervalId = ref<ReturnType<typeof setInterval> | null>(null);

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/');
};

const userService = new UserService;

const currentLevel = ref('1');
const oldLevel = ref(store.getters.USER.oldLevel);
const showLevelUpPopup = ref(false);
const last_bonus_at = ref(store.getters.USER.last_bonus_at);

// function updateCurrentLevel(vipStatus = store.getters.USER.vip_status) {
//   switch (vipStatus) {
//     case 'gold': currentLevel.value = '4'; break;
//     case 'silver': currentLevel.value = '3'; break;
//     case 'usual': currentLevel.value = '2'; break;
//     default: currentLevel.value = '1';
//   }
//   if (currentLevel.value > oldLevel.value) {
//     showLevelUpPopup.value = true;
//     store.commit('SET_OLD_LEVEL', currentLevel.value);
//   }
// }

async function updateUser() {
  try {
    const response = await userService.get();
    const data = await response.json();
    store.commit('SET_USER', data);
    last_bonus_at.value = store.getters.USER.last_bonus_at;
  } catch (error) {
    console.error('Failed to update user:', error);
  }
}

function isMoreThan24HoursAgo(dateString: string): boolean {
  const targetDate = new Date(dateString);
  const currentDate = new Date();
  return currentDate.getTime() - targetDate.getTime() > 24 * 60 * 60 * 1000;
}

function getRemainingTime(lastBonusAt: string) {
  const targetDate = new Date(lastBonusAt);
  const currentDate = new Date();
  const diff = 24 * 60 * 60 * 1000 - (currentDate.getTime() - targetDate.getTime());
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  return { hours, minutes };
}

const updateBonusButton = () => {
  const { hours, minutes } = getRemainingTime(last_bonus_at.value);
  if (isMoreThan24HoursAgo(last_bonus_at.value)) {
    bonusBtn.value = t(`profile.claim_bonus`);
  } else {
    bonusBtn.value = hours > 0 ? `${hours}h ${minutes}min` : `${minutes}min`;
  }
};

async function getBonus() {
  if (playNewTakeBonus) playNewTakeBonus();
  if (isMoreThan24HoursAgo(last_bonus_at.value)) {
    try {
      await userService.getBonus(10000);
      await updateUser();
      updateBonusButton();
    } catch (error) {
      console.error('Failed to get bonus:', error);
    }
  }
}

const buttonStyle = computed(() => ({
  backgroundColor: isMoreThan24HoursAgo(last_bonus_at.value) ? '#5C4E73' : '#6E6976',
}));

onMounted(() => {
  updateBonusButton();
  postEvent('web_app_setup_back_button', { is_visible: true });
  on('back_button_pressed', listener);
  updateUser();

  if (window.Adsgram) {
    AdController.value = window.Adsgram.init({ blockId: "6749" });
  } else {
    console.error("AdsGram not found");
  }
  
  intervalId.value = setInterval(updateBonusButton, 60000);

  userService.checkSub()
    .then(response =>{
      if (response.status === 400){
        isBonusGeted.value = true;
      }
    })

  userService.checkBonusAds()
  .then(response =>{
      if (response.status === 204){
        goAds.value = true;
        console.log("getBonusAds 200 goAds.value:", goAds.value)
      }
      if (response.status === 400){
        goAds.value = false;
        console.log("getBonusAds 400 goAds.value:", goAds.value)
      }
    })

});

onUnmounted(() => {
  if (intervalId.value !== null) {
    clearInterval(intervalId.value);
  }
  postEvent('web_app_setup_back_button', { is_visible: false });
  off('back_button_pressed', listener);
});

watch(last_bonus_at, updateBonusButton);

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const backgroundClass = computed(() => {
  switch (store.getters.USER.vip_status) {
    case 'gold':
      return 'gradient-gold';
    case 'silver':
      return 'gradient-silver';
    default:
      return 'background-white';
  }
});

const weekEarnLabel = computed(() => {
  return store.getters.USER.week_earn > 0 ? 'week-earn-label-green' : 'week-earn-label-red'
});

function navigateToChannel(){
  if(isBonusGeted.value) return;
  const url = 'https://t.me/okgo_club';
  window.open(url, '_blank');
}

const AdController = ref<any>(null);
const goAds = ref();

async function showAdWithAdsgram() {

  if(goAds.value) {
    if (!AdController.value) {
    console.error("AdsGram AdController не инициализирован.");
    return;
    }

    try {
      const result = await AdController.value.show();
      if (result.done) {
        userService.getBonusAds(`${Math.floor(100000 + Math.random() * 900000)}`)
        .then(response =>{
            if (response.status === 204){
              console.log("getBonusAds 200 goAds.value:", goAds.value)
            }
            if (response.status === 400){
              console.log("getBonusAds 400 goAds.value:", goAds.value)
            }
          })
          console.log('ADS DONE')
      } else {
        // console.log("Реклама была пропущена:", result);
      }
    } catch (error) {
      console.error("Error AdsGram:", error);
    }
  }
  else {
    alert("Вся реклама просмотрена, возвращайтесь завтра")
  }
}

const showEditName = ref(false);

function handleEditName() {
  showEditName.value = true;
}

function handleCloseEditName() {
  showEditName.value = false;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showLevelUpPopup.value)
      ? (_openBlock(), _createBlock(levelUp, { key: 0 }))
      : _createCommentVNode("", true),
    (showEditName.value)
      ? (_openBlock(), _createBlock(EditNameWrapper, {
          key: 1,
          showEditName: showEditName.value,
          weekEarnLabel: weekEarnLabel.value,
          backgroundClass: backgroundClass.value,
          onCloseChat: handleCloseEditName
        }, null, 8, ["showEditName", "weekEarnLabel", "backgroundClass"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _cache[31] || (_cache[31] = _createElementVNode("div", { class: "logo-wrapper" }, [
            _createElementVNode("img", {
              src: _imports_0,
              class: "no-image-animation"
            })
          ], -1)),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(`profile.balance`)), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.balance)), 1),
                  _cache[9] || (_cache[9] = _createElementVNode("div", { class: "balance-sum-logo-wrapper" }, [
                    _createElementVNode("img", {
                      class: "balance-sum-logo",
                      src: _imports_1
                    })
                  ], -1))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("p", _hoisted_9, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.bonus_balance)), 1),
                  _cache[10] || (_cache[10] = _createElementVNode("div", { class: "balance-sum-logo-wrapper" }, [
                    _createElementVNode("img", {
                      class: "balance-sum-logo",
                      src: _imports_2
                    })
                  ], -1))
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("div", {
                    class: "user-name-edit",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (handleEditName()))
                  }, [
                    _createElementVNode("p", {
                      style: _normalizeStyle({'z-index': showEditName.value ? 1001 : 1}),
                      class: "user-info-username"
                    }, _toDisplayString(_unref(userData).user!.username ?? "noname"), 5),
                    _cache[11] || (_cache[11] = _createElementVNode("img", {
                      class: "edit-name-icon",
                      src: _imports_3
                    }, null, -1))
                  ]),
                  (_unref(store).getters.USER.week_earn)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                        _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)(`main.in`)) + " " + _toDisplayString(_unref(t)(`main.a_week`)) + ": ", 1),
                        _createElementVNode("p", {
                          class: _normalizeClass(weekEarnLabel.value)
                        }, _toDisplayString(_unref(store).getters.USER.week_earn > 0 ? '+' : '') + _toDisplayString(_unref(formatNumber)(_unref(store).getters.USER.week_earn)), 3),
                        _cache[12] || (_cache[12] = _createElementVNode("div", { class: "balance-sum-logo-wrapper-mini-big" }, [
                          _createElementVNode("img", {
                            class: "balance-sum-logo-wrapper-mini",
                            src: _imports_2
                          })
                        ], -1))
                      ]))
                    : _createCommentVNode("", true)
                ])
              ])
            ]),
            _createElementVNode("div", _hoisted_14, [
              (_unref(store).getters.USER.vip_status != 'first')
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    class: "user-status no-image-animation",
                    src: require(`@/assets/player/status/status-${_unref(store).getters.USER.vip_status}.svg`)
                  }, null, 8, _hoisted_15))
                : _createCommentVNode("", true),
              _createElementVNode("div", {
                class: _normalizeClass(['user-logo-wrapper', backgroundClass.value])
              }, [
                _createElementVNode("img", {
                  class: "user-logo",
                  src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
                }, null, 8, _hoisted_16)
              ], 2),
              _createElementVNode("p", null, "id: " + _toDisplayString(_unref(store).getters.USER.id), 1)
            ]),
            _cache[13] || (_cache[13] = _createElementVNode("img", {
              src: _imports_4,
              class: "background-logo no-image-animation"
            }, null, -1))
          ]),
          _createElementVNode("div", {
            class: "coin-exchange",
            onClick: _cache[1] || (_cache[1] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/withrdaw_soon')})
          }, [
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_18, _toDisplayString(_unref(t)(`profile.coin_exchange`)), 1)
              ]),
              _cache[14] || (_cache[14] = _createElementVNode("div", { class: "coin-exchange-arrow" }, [
                _createElementVNode("img", { src: _imports_5 })
              ], -1)),
              _cache[15] || (_cache[15] = _createElementVNode("img", {
                src: _imports_6,
                class: "coin_exchange-img"
              }, null, -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_19, [
            _createElementVNode("div", {
              class: "levels-button",
              onClick: _cache[2] || (_cache[2] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/withrdaw_soon')})
            }, [
              _createElementVNode("p", _hoisted_20, _toDisplayString(_unref(t)(`profile.levels`)), 1)
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("div", {
                class: "top-up-button",
                onClick: _cache[3] || (_cache[3] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/withrdaw_soon')})
              }, [
                _createElementVNode("p", _hoisted_22, _toDisplayString(_unref(t)(`profile.deposit`)), 1),
                _cache[16] || (_cache[16] = _createElementVNode("div", { class: "balance-topup-main" }, [
                  _createElementVNode("p", { class: "balance-topup-main-plus" }, "+")
                ], -1))
              ]),
              _createElementVNode("div", {
                class: "withdraw-button",
                onClick: _cache[4] || (_cache[4] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/withrdaw_soon')})
              }, [
                _createElementVNode("p", _hoisted_23, _toDisplayString(_unref(t)(`profile.withdraw`)), 1),
                _cache[17] || (_cache[17] = _createElementVNode("div", { class: "balance-topup-main" }, [
                  _createElementVNode("p", { class: "balance-topup-main-plus" }, "-")
                ], -1))
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "all-transaction",
            onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(router).push('/withrdaw_soon')))
          }, [
            _createElementVNode("div", _hoisted_24, [
              _createElementVNode("div", null, [
                _createElementVNode("p", _hoisted_25, _toDisplayString(_unref(t)(`profile.transaction_history`)), 1)
              ]),
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "all-transaction-arrow" }, [
                _createElementVNode("img", { src: _imports_5 })
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)(`profile.daily_bonus`)), 1)
            ]),
            _cache[19] || (_cache[19] = _createElementVNode("div", { class: "bonus-wrapper-center" }, [
              _createElementVNode("p", { class: "ten-k" }, "+10 000"),
              _createElementVNode("img", {
                src: _imports_2,
                class: "red-coin"
              })
            ], -1)),
            _createElementVNode("div", {
              class: "bonus-wrapper-dwn",
              style: _normalizeStyle(buttonStyle.value),
              onClick: _cache[6] || (_cache[6] = ($event: any) => (getBonus()))
            }, _toDisplayString(bonusBtn.value), 5),
            _cache[20] || (_cache[20] = _createElementVNode("img", {
              src: _imports_7,
              class: "background-logo no-image-animation"
            }, null, -1))
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("p", _hoisted_30, _toDisplayString(_unref(t)(`profile.box`)), 1)
            ]),
            _createElementVNode("div", {
              class: "bonus-wrapper-dwn-box",
              onClick: _cache[7] || (_cache[7] = ($event: any) => {triggerHapticFeedback('soft'); _unref(router).push('/friends')})
            }, [
              _createTextVNode(_toDisplayString(_unref(t)(`profile.friends`)) + " ", 1),
              _cache[21] || (_cache[21] = _createElementVNode("img", { src: _imports_8 }, null, -1))
            ]),
            _cache[22] || (_cache[22] = _createElementVNode("img", {
              src: _imports_9,
              class: "box-img"
            }, null, -1)),
            _cache[23] || (_cache[23] = _createElementVNode("img", {
              src: _imports_7,
              class: "background-logo no-image-animation"
            }, null, -1))
          ]),
          _createElementVNode("div", {
            class: "sub-tg",
            onClick: navigateToChannel
          }, [
            _createElementVNode("div", _hoisted_31, [
              _cache[25] || (_cache[25] = _createElementVNode("div", { class: "sub-tg-icon-wrapper" }, [
                _createElementVNode("img", {
                  class: "sub-tg-icon",
                  src: _imports_10
                })
              ], -1)),
              _createElementVNode("div", _hoisted_32, [
                _createElementVNode("p", _hoisted_33, _toDisplayString(_unref(t)(`profile.sub_tg`)), 1),
                _cache[24] || (_cache[24] = _createStaticVNode("<div class=\"sub-tg-bonus\" data-v-83b7ff1c><div class=\"sub-tg-bonus-sum\" data-v-83b7ff1c><p class=\"sub-tg-bonus-sum-ten\" data-v-83b7ff1c>+10 000</p></div><div class=\"sub-tg-bonus-coin-wrapper\" data-v-83b7ff1c></div><img class=\"sub-tg-bonus-coin\" src=\"" + _imports_2 + "\" data-v-83b7ff1c></div>", 1))
              ]),
              _createElementVNode("div", _hoisted_34, [
                (isBonusGeted.value)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_35))
                  : (_openBlock(), _createElementBlock("img", _hoisted_36))
              ])
            ])
          ]),
          _createElementVNode("div", {
            class: "watch-adv",
            onClick: showAdWithAdsgram
          }, [
            _createElementVNode("div", _hoisted_37, [
              _cache[27] || (_cache[27] = _createElementVNode("div", { class: "sub-tg-icon-wrapper" }, [
                _createElementVNode("img", {
                  class: "sub-tg-icon",
                  src: _imports_13
                })
              ], -1)),
              _createElementVNode("div", _hoisted_38, [
                _createElementVNode("p", _hoisted_39, _toDisplayString(_unref(t)(`profile.watch_add`)), 1),
                _cache[26] || (_cache[26] = _createStaticVNode("<div class=\"sub-tg-bonus\" data-v-83b7ff1c><div class=\"sub-tg-bonus-sum\" data-v-83b7ff1c><p class=\"sub-tg-bonus-sum-ten\" data-v-83b7ff1c>+1 000</p></div><div class=\"sub-tg-bonus-coin-wrapper\" data-v-83b7ff1c></div><img class=\"sub-tg-bonus-coin\" src=\"" + _imports_2 + "\" data-v-83b7ff1c></div>", 1))
              ]),
              _cache[28] || (_cache[28] = _createElementVNode("div", { class: "all-transaction-arrow" }, [
                _createElementVNode("img", { src: _imports_12 })
              ], -1))
            ])
          ]),
          _createElementVNode("div", {
            class: "settings",
            onClick: _cache[8] || (_cache[8] = ($event: any) => (_unref(router).push('/settings')))
          }, [
            _createElementVNode("div", _hoisted_40, [
              _cache[29] || (_cache[29] = _createElementVNode("div", { class: "settings-icon-wrapper" }, [
                _createElementVNode("img", {
                  class: "settings-icon",
                  src: _imports_14
                })
              ], -1)),
              _createElementVNode("div", _hoisted_41, [
                _createElementVNode("p", _hoisted_42, _toDisplayString(_unref(t)(`settings.settings`)), 1)
              ]),
              _cache[30] || (_cache[30] = _createElementVNode("div", { class: "all-transaction-arrow" }, [
                _createElementVNode("img", { src: _imports_12 })
              ], -1))
            ])
          ]),
          _createElementVNode("div", _hoisted_43, [
            _createVNode(HistoryList)
          ])
        ]))
  ], 64))
}
}

})