import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "soon_wrapper" }
const _hoisted_2 = { class: "soon" }

import {onMounted, ref, onUnmounted} from "vue";
import router from "@/router";
import {MiniAppsEventListener, on, off, postEvent} from "@telegram-apps/sdk";
import { useI18n } from 'vue-i18n';
import lottie from 'lottie-web';

import Soon from '@/assets/wallet/soon.json'


export default /*@__PURE__*/_defineComponent({
  __name: 'BalanceActionsViewWithdraw',
  setup(__props) {

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/profile');
};

const image_soon = ref();

let sticker: any = null;

onMounted(() => {
  on('back_button_pressed', listener);
  postEvent('web_app_setup_back_button', { is_visible: true});
  sticker = lottie.loadAnimation({
    container: image_soon.value,
    renderer: 'svg',
    loop: true,
    autoplay: true,
    animationData: Soon,
  });
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)(`tournaments.soon`)), 1),
    _createElementVNode("div", {
      class: "image",
      ref_key: "image_soon",
      ref: image_soon
    }, null, 512)
  ]))
}
}

})