import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/tools/arrow.svg'


const _hoisted_1 = { class: "history-list-container" }
const _hoisted_2 = { class: "history-list-label" }
const _hoisted_3 = { class: "history-list-label-text" }
const _hoisted_4 = {
  key: 0,
  class: "history-items-container-wrapper"
}
const _hoisted_5 = {
  key: 0,
  class: "no-history-games"
}
const _hoisted_6 = { class: "no-history-label" }
const _hoisted_7 = { class: "all-rooms-label" }
const _hoisted_8 = {
  key: 1,
  class: "history-items-container-wrapper"
}
const _hoisted_9 = { class: "history-skeleton-wrapper" }

import HistoryItem from "@/components/lists/history/HistoryItem.vue";
import { onMounted, ref } from "vue";
import PlayerHistory from "@/models/playerHistory";
import { UserService } from "@/api/userService";
import router from "@/router";
import AnimatedPlaceholder from "@/components/tools/AnimatedPlaceholder.vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'HistoryList',
  setup(__props) {


const { t } = useI18n();

window.Telegram.WebApp.disableVerticalSwipes();


let playerHistory = ref<PlayerHistory[]>([]);
const isLoaded = ref<boolean>(false);

onMounted(async () => {
  const userService = new UserService();
  await userService.playerHistory()
      .then(response => response.json())
      .then(data => {
        playerHistory.value = data;
        isLoaded.value = true;
      });
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`profile.game_history`)), 1),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "history-list-label-hr" }, null, -1))
    ]),
    (isLoaded.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(playerHistory), (item, i) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "history-items-container",
              key: i
            }, [
              _createVNode(HistoryItem, { "player-history": item }, null, 8, ["player-history"])
            ]))
          }), 128)),
          (_unref(playerHistory).length == 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(t)(`profile.no_history_label`)), 1),
                _createElementVNode("div", {
                  class: "all-rooms-link",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/rooms')})
                }, [
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_unref(t)(`profile.all_rooms_label`)), 1),
                  _cache[2] || (_cache[2] = _createElementVNode("img", {
                    src: _imports_0,
                    width: ""
                  }, null, -1))
                ])
              ]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_8, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(10, (i) => {
            return _createElementVNode("div", {
              class: "history-items-container",
              key: i
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(AnimatedPlaceholder, {
                  width: "100%",
                  height: "81px",
                  "border-radius": "var(--border-mixed)"
                })
              ])
            ])
          }), 64))
        ]))
  ]))
}
}

})