import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "message-recived"
}
const _hoisted_2 = { class: "user-img" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "content-wrapper" }
const _hoisted_5 = { class: "name" }
const _hoisted_6 = { class: "nickname" }
const _hoisted_7 = { class: "text" }
const _hoisted_8 = { class: "time_stamp" }
const _hoisted_9 = {
  key: 1,
  class: "message-send"
}
const _hoisted_10 = { class: "content-wrapper" }
const _hoisted_11 = { class: "name" }
const _hoisted_12 = { class: "nickname" }
const _hoisted_13 = { class: "text" }
const _hoisted_14 = { class: "time_stamp" }

import Message from "@/models/message";
import Player from "@/models/player";

import {ApiService} from "@/api/apiService";


export default /*@__PURE__*/_defineComponent({
  __name: 'MessageWrapper',
  props: {
    message: {},
    myPlayer: {}
  },
  setup(__props: any) {

const props = __props

function setDate(dateTime:string){
    const date = new Date(dateTime);

    const moscowOffset = 3 * 60 * 60 * 1000; 
    const moscowTime = new Date(date.getTime() + moscowOffset);

    const hours = moscowTime.getUTCHours();
    const minutes = moscowTime.getUTCMinutes();
    
    const time = `${hours}:${minutes.toString().padStart(2, '0')}`;
    return time;
}


return (_ctx: any,_cache: any) => {
  return (_ctx.message.user_id != _ctx.myPlayer.user_id && _ctx.message.type === 'message')
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "img-msg",
            src:  _ctx.message.user_photo_url!? _unref(ApiService).url +_ctx.message.user_photo_url! : require('@/assets/mock_user/user.svg')
          }, null, 8, _hoisted_3)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.message.user_username), 1),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "crown" }, null, -1))
          ]),
          _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.message.body), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("p", null, _toDisplayString(setDate(_ctx.message.created_at)), 1)
          ])
        ])
      ]))
    : (_ctx.message.type === 'message')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.message.user_username), 1),
              _cache[1] || (_cache[1] = _createElementVNode("div", { class: "crown" }, null, -1))
            ]),
            _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.message.body), 1),
            _createElementVNode("div", _hoisted_14, [
              _createElementVNode("p", null, _toDisplayString(setDate(_ctx.message.created_at)), 1)
            ])
          ])
        ]))
      : _createCommentVNode("", true)
}
}

})