<script setup lang="ts">
import menu from "@/components/navigation/menu";
import NavBarItem from "@/components/navigation/NavBarItem.vue";
import router from "@/router";
import store from "@/store";

function push(path: string) {
  router.push(path);
  triggerHapticFeedback('light');
  window.scrollTo(0, 0);
}

function getWidth() {
  return window.screen.width;
}

function isShow(item: any) {
  if (item.hide_small_devices === true) {
    return getWidth() >= 430;
  }
  return true;
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

</script>

<template>
  <nav class="menu" v-if="!store.getters.FOCUSED">
    <div v-for="(item, i) in menu.filter((menuItem) => isShow(menuItem))" :key="i" class="menu-bar-item">
      <nav-bar-item 
      :key="getWidth" 
      @click="push(item.path[0])" 
      :image="item.image" 
      :label="item.label" 
      :path="item.path" />
    </div>
  </nav>
</template>

<style scoped>
.menu-bar-item {
  width: 100%;
}

.menu-bar-item:active {
  transform: var(--transform-scale-active);
}

.menu {
  position: fixed;
  bottom: 38px;
  z-index: 99;
  left: 5%;
  right: 5%;
  padding-right: 2%;
  padding-left: 2%;
  background-color: #15151E;
  border-radius: var(--border-mixed);
  display: flex;
  justify-content: space-around;
  animation: translateUp 0.2s ease-out;
}
</style>