import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo/money_logo.svg'


const _hoisted_1 = { class: "history-item" }
const _hoisted_2 = { class: "history-item-room-number" }
const _hoisted_3 = { class: "history-item-room-label" }
const _hoisted_4 = { class: "history-item-date" }
const _hoisted_5 = { class: "history-item-money-wrapper" }

import PlayerHistory from "@/models/playerHistory";
import {formatLocalDate} from "@/tools/tools";
import { useI18n } from 'vue-i18n';



export default /*@__PURE__*/_defineComponent({
  __name: 'HistoryItem',
  props: {
    playerHistory: {}
  },
  setup(__props: any) {


const { t } = useI18n();




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.playerHistory.big_blind_bet), 1),
      _createElementVNode("p", _hoisted_3, _toDisplayString(_unref(t)(`profile.history_item_room_label`)), 1)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(formatLocalDate)(_ctx.playerHistory.updated_at)), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("p", {
        class: _normalizeClass({ 'history-item-money': true, 'history-item-money-plus': _ctx.playerHistory.won_amount > 0, 'history-item-money-minus': _ctx.playerHistory.won_amount <= 0 })
      }, _toDisplayString(_ctx.playerHistory.won_amount > 0 ? '+' + _ctx.playerHistory.won_amount : _ctx.playerHistory.won_amount), 3),
      _cache[0] || (_cache[0] = _createElementVNode("img", {
        src: _imports_0,
        class: "history-item-money-logo"
      }, null, -1))
    ])
  ]))
}
}

})