import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/tools/close-modal.svg'
import _imports_1 from '@/assets/logo/logo-background.svg'
import _imports_2 from '@/assets/game/delet.svg'


const _hoisted_1 = { class: "chips-wrapper" }
const _hoisted_2 = { class: "chips-wrapper-card" }
const _hoisted_3 = { class: "chips-up" }
const _hoisted_4 = { class: "chips-up-up" }
const _hoisted_5 = { class: "chips-for-game" }
const _hoisted_6 = { class: "chips-count-for-game" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { key: 1 }
const _hoisted_9 = {
  key: 0,
  class: "chips-center"
}
const _hoisted_10 = { class: "chips-center-up" }
const _hoisted_11 = { class: "chips-center-lft" }
const _hoisted_12 = { class: "chips-center-lft-up" }
const _hoisted_13 = { class: "chips-center-lft-dwn" }
const _hoisted_14 = ["width", "height"]
const _hoisted_15 = {
  key: 1,
  class: "chips-down"
}
const _hoisted_16 = { class: "chips-down-bet-buyin" }
const _hoisted_17 = { class: "chips-down-dwn" }
const _hoisted_18 = {
  class: "chips-min",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_19 = ["fill"]
const _hoisted_20 = { class: "chips-down-dwn" }
const _hoisted_21 = {
  class: "chips-min",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_22 = ["fill"]
const _hoisted_23 = { class: "chips-slider" }
const _hoisted_24 = ["value", "max"]
const _hoisted_25 = { class: "chips-wrapper-btns" }

import {ref, computed, onMounted} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import { formatNumber, formatNumberByZiro } from "@/tools/tools";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";
import DeleteRoom from '@/components/lists/rooms/DeleteRoom.vue';
import RoomsItem from "@/models/roomsListItem";


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsPrivateRoom',
  props: {
    player: {},
    room: {},
    joinRoom: { type: Function },
    currentPlayer: {}
  },
  emits: ['close', 'editRoom'],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "3cf54117": (getColorByStatus.value)
}))


const { t } = useI18n();

const props = __props

const emit = __emit; 

const getColorByStatus = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "#CBC0DF";
    case "silver":
      return "var(--gradient-silver-thumb)";
    case "gold":
      return "var(--gradient-gold-thumb)";
    default:
      return "#CBC0DF";
  }
});

const tg = window.Telegram?.WebApp;


const valuesArray = [2, 4, 6, 8, 10, 20, 30, 40, 80, 100, 200, 300, 400, 600, 800, 1000, 3000, 4000, 6000, 8000, 10000, 12000, 14000, 16000];
const sliderPosition = ref(0);
const inputValue = computed(() => valuesArray[sliderPosition.value]);
const feeCoin = computed(() => formatNumber(inputValue.value * 100))

const roomService = new RoomService();


function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  sliderPosition.value = Number(target.value);
  triggerHapticFeedback('light');
};

const isCheckedDown = ref(false);
const oldValueisCheckedDown = ref(false);
const newValueisCheckedDown = ref(false);


onMounted(async () =>{
  // const idPrivateRoom = props.room.id;
  // const myroom = ref<Room>();
  // const myroomBigBlindBet = ref();
  // await roomService.getPrivateRoom(idPrivateRoom)
  // .then(response => response.json())
  // .then(data => {
  //   myroom.value = data;
  //   myroomBigBlindBet.value = myroom.value?.big_blind_bet;
  // })

  // if (myroom.value && myroomBigBlindBet.value) {
  // const initialPosition = valuesArray.indexOf(myroomBigBlindBet.value);
  //   if (initialPosition !== -1) {
  //     sliderPosition.value = initialPosition;
  //   } 
  // } 


  // if(props.room.coin_type == 'go_coin') {
  //   isCheckedDown.value = true;
  // }

  oldValueisCheckedDown.value = isCheckedDown.value;
  newValueisCheckedDown.value = isCheckedDown.value;

  if (props.room) {
    const initialPosition = valuesArray.indexOf(props.room.big_blind_bet);
    if (initialPosition !== -1) {
      sliderPosition.value = initialPosition;
    } 
  }
  const slider = document.getElementById('slider') as HTMLInputElement;
  if (slider) {
    slider.step = "1";
    slider.min = "0";
    slider.max = (valuesArray.length - 1).toString();
    slider.value = sliderPosition.value.toString();
  }
})

// isChecked: True = GoCoins, false = RedCoins
// function handleCheckboxChange(checkboxId: string) {
//     isCheckedDown.value = !isCheckedDown.value;
//     newValueisCheckedDown.value = isCheckedDown.value;
// }

const iconColor = computed(() => {
  return isCheckedDown.value ? "#7861A5" : "#D14A4A";
});

let privateRooms = ref<RoomsItem[]>([]);

let id_room = ref(0)

async function startTheGame() {
  try {
    const small_blind_bet = inputValue.value / 2; 
    const fee = inputValue.value * 100; 
    const coin_type = isCheckedDown.value ? "go_coin" : "red_coin"; 

    await roomService.CreatePrivateRooms(small_blind_bet, fee, coin_type);

    await roomService.getPrivateRooms(10)
    .then(response => response.json())
    .then(data => {
      privateRooms.value = data;
      const lastRoom = privateRooms.value.reduce((maxRoom, room) => 
      room.id > maxRoom.id ? room : maxRoom, privateRooms.value[0]);
      id_room.value = lastRoom?.id || 0;
    });
    router.push(`/game?small_blind_bet=${small_blind_bet}&coin_type=${coin_type}&id=${id_room.value}`);
  } 
  catch (error) {
    console.error("Ошибка при создании комнаты:", error);
  }
}

async function changeRoom() {
  try {
    const Id = props.room.id;
    const small_blind_bet = inputValue.value / 2; 
    const fee = inputValue.value * 100; 
    const coin_type = isCheckedDown.value ? "go_coin" : "red_coin"; 

    await roomService.changePrivateRooms(Id, small_blind_bet, fee, coin_type);
    emit('editRoom');
    emit('close');
  } 
  catch (error) {
    console.error("Ошибка при релактировании комнаты:", error);
  }
}


const isGoCoinSelected = computed(() => isCheckedDown.value);

const openDelConmponent = ref(false);

const showDel = () => {
  openDelConmponent.value = true;
}

const handleCloseDelPopup = () => {
  openDelConmponent.value = false;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "settings-room-background",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
    }),
    _createElementVNode("div", {
      class: "settings-room",
      style: _normalizeStyle({ opacity: openDelConmponent.value ? '0.4' : '1', scale: openDelConmponent.value ? '0.9' : '1' })
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)(`settings_room.settings`)), 1)
              ]),
              _createElementVNode("div", {
                class: "close",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('close')))
              }, _cache[5] || (_cache[5] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "close"
                }, null, -1)
              ]))
            ]),
            _createElementVNode("div", _hoisted_6, [
              (_ctx.room?.is_private == 1)
                ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_unref(t)(`settings_room.del_room`)), 1))
                : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_unref(t)(`settings_room.count_chips`)), 1))
            ])
          ]),
          (_ctx.room?.is_private != 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    _createElementVNode("div", _hoisted_12, [
                      _createElementVNode("p", null, _toDisplayString(_unref(t)(`settings_room.your_balance`)), 1)
                    ]),
                    _createElementVNode("div", _hoisted_13, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["chips-center-lft-dwn-gocoins", { 'selected': isGoCoinSelected.value }]),
                        style: _normalizeStyle({ opacity: isGoCoinSelected.value ? '0.4' : '1' })
                      }, [
                        _createElementVNode("p", {
                          style: _normalizeStyle({ fontSize: isGoCoinSelected.value ? '13px' : '15px', color: isGoCoinSelected.value ? 'white': _unref(store).getters.USER.bonus_balance < (inputValue.value * 100)  ? 'red' : 'white' })
                        }, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.bonus_balance)), 5),
                        (_openBlock(), _createElementBlock("svg", {
                          class: "chips-min2",
                          width: isGoCoinSelected.value ? '19px' : '22px',
                          height: isGoCoinSelected.value ? '19px' : '22px',
                          viewBox: "0 0 24 24",
                          fill: "none",
                          xmlns: "http://www.w3.org/2000/svg"
                        }, _cache[6] || (_cache[6] = [
                          _createElementVNode("path", {
                            d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                            fill: "#D14A4A"
                          }, null, -1)
                        ]), 8, _hoisted_14))
                      ], 6)
                    ])
                  ])
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.room?.is_private != 1)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("div", _hoisted_16, [
                  _createElementVNode("div", _hoisted_17, [
                    _createElementVNode("p", null, _toDisplayString(_unref(t)(`settings_room.blind`)), 1),
                    (isGoCoinSelected.value)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          class: "large-text",
                          style: _normalizeStyle({ color: _unref(store).getters.USER.balance < (inputValue.value * 100)  ? 'red' : 'white' })
                        }, _toDisplayString(_unref(formatNumber)(inputValue.value)), 5))
                      : (_openBlock(), _createElementBlock("p", {
                          key: 1,
                          class: "large-text",
                          style: _normalizeStyle({ color: _unref(store).getters.USER.bonus_balance < (inputValue.value * 100)  ? 'red' : 'white' })
                        }, _toDisplayString(_unref(formatNumber)(inputValue.value)), 5)),
                    (_openBlock(), _createElementBlock("svg", _hoisted_18, [
                      _createElementVNode("path", {
                        d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                        fill: iconColor.value
                      }, null, 8, _hoisted_19)
                    ]))
                  ]),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("p", null, _toDisplayString(_unref(t)(`settings_room.buy_in`)), 1),
                    (isGoCoinSelected.value)
                      ? (_openBlock(), _createElementBlock("p", {
                          key: 0,
                          class: "large-text",
                          style: _normalizeStyle({ color: _unref(store).getters.USER.balance < (inputValue.value * 100) ? 'red' : 'white' })
                        }, _toDisplayString(feeCoin.value), 5))
                      : (_openBlock(), _createElementBlock("p", {
                          key: 1,
                          class: "large-text",
                          style: _normalizeStyle({ color: _unref(store).getters.USER.bonus_balance < (inputValue.value * 100) ? 'red' : 'white' })
                        }, _toDisplayString(feeCoin.value), 5)),
                    (_openBlock(), _createElementBlock("svg", _hoisted_21, [
                      _createElementVNode("path", {
                        d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                        fill: iconColor.value
                      }, null, 8, _hoisted_22)
                    ]))
                  ])
                ]),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("input", {
                    id: "slider",
                    value: sliderPosition.value,
                    min: 0,
                    max: valuesArray.length - 1,
                    step: 1,
                    type: "range",
                    onInput: handleInput
                  }, null, 40, _hoisted_24)
                ])
              ]))
            : _createCommentVNode("", true),
          _cache[7] || (_cache[7] = _createElementVNode("img", {
            src: _imports_1,
            class: "background-logo no-image-animation"
          }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_25, [
          _createElementVNode("div", {
            class: "chips-bt1",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('close')))
          }, _toDisplayString(_unref(t)(`settings_room.exit`)), 1),
          (_ctx.room?.is_private == 1)
            ? (_openBlock(), _createElementBlock("div", {
                key: 0,
                class: "chips-bt3",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (showDel()))
              }, _cache[8] || (_cache[8] = [
                _createElementVNode("img", {
                  class: "delete",
                  src: _imports_2
                }, null, -1)
              ])))
            : _createCommentVNode("", true),
          (!props.room)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "chips-bt2",
                onClick: _cache[4] || (_cache[4] = ($event: any) => {triggerHapticFeedback('light'); startTheGame()})
              }, _toDisplayString(_unref(t)(`settings_room.create_room`)), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ], 4),
    (openDelConmponent.value)
      ? (_openBlock(), _createBlock(DeleteRoom, {
          key: 0,
          onClose: handleCloseDelPopup,
          room: _ctx.room!
        }, null, 8, ["room"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})