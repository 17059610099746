<script setup lang="ts">

import {defineProps, defineEmits, ref, computed, onMounted, watch} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import { formatNumberByZiro } from "@/tools/tools";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";

const { t } = useI18n();

const props = defineProps<{
  player: Player,
  room: Room,
  joinRoom: () => void,
  middletGame: boolean,
  timeToUp: boolean,
  currentCase: string | null;
}>()

const emit = defineEmits(['updateValues', 'close']);

const getColorByStatus = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "#CBC0DF";
    case "silver":
      return "var(--gradient-silver-thumb)";
    case "gold":
      return "var(--gradient-gold-thumb)";
    default:
      return "#CBC0DF";
  }
});

const min = props.room.big_blind_bet * 100;
const max = props.room.big_blind_bet * 100*5;

const tg = window.Telegram?.WebApp;

const inputValue = ref<number>(min);

const timer = ref<number>(15);

const roomService = new RoomService();

const addMyDeposit = ref(false);

const result = ref(0);


function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  triggerHapticFeedback('light');

  const newValue = Math.max(Number(target.value), min);

  inputValue.value = newValue;

  
};

function startTheGame(){
    const value = inputValue.value;
    // emit('close');
    if (value > store.getters.USER.bonus_balance) return;
    if (value <= max) {
      roomService.setDeposit(value, props.player.user_id, props.room.id)
      .then(response => {
          if (response.status == 402) {
            router.push({
              path: '/rooms',
              query: { withBalancePopup: 'true' }
            });
            return;
          }
          props.joinRoom();
      });
    }
    
}

function addBalance(){
  result.value = inputValue.value + props.player?.game_deposit;
    if (result.value > store.getters.USER.bonus_balance) return;
    if (result.value <= max) {
      addMyDeposit.value = true;
      emit('updateValues', { result: result.value, addMyDeposit: addMyDeposit.value });
      emit('close');
    }
}

const iconColor = computed(() => {
  if (props.room.coin_type == 'go_coin') return "#7861A5"; 
  if (props.room.coin_type == 'red_coin') return "#D14A4A";
  return "#D14A4A";
});

onMounted(() =>{
  const slider = document.getElementById('slider') as HTMLInputElement;
  slider.step = (max / 100).toString();
  inputValue.value = min;
  timer.value = 15;
  countTimer();
})

// watch(() => props.currentCase,(newCase) => {
//   if (newCase === 'show_down' && addMyDeposit) {
//     addMyDeposit.value = false;
//     console.log('result.value watch:', result.value)
//       roomService.setDeposit(result.value, props.player.user_id)
//       .then(response => {
//           if (response.status == 402) {
//             emit('close');
//             return;
//           }});
//       result.value = 0;
//       emit('close');
//     }
// });

function countTimer(){
  const interval = setInterval(() => {
    if (timer.value > 1) {
      timer.value--;  
    } else {
      emit('close'); 
      clearInterval(interval); 
    }
  }, 1000);
}

</script>


<template>
  <div class="chips-popup">
    <div class="chips-wrapper">
      <div class="chips-wrapper-card">
        <div class="chips-up">
          <div class="chips-up-up">
            <div v-if="timeToUp" class="chips-for-game">
              <p>{{ t(`chips_popup.top_up_redcoins`) }}</p>
            </div>
            <div v-else class="chips-for-game">
              <p>{{ t(`chips_popup.go_coins`) }}</p>
            </div>
            <div class="close" @click="emit('close')">
              <img src="@/assets/tools/close-modal.svg" class="close">
            </div>
          </div>
          <div v-if="timeToUp || middletGame" class="chips-count-for-game">
            <p>{{ t(`chips_popup.top_up_redcoins2`) }}</p>
          </div>
          <div v-else class="chips-count-for-game">
            <p>{{ t(`chips_popup.chose_go_coins`) }}</p>
          </div>
        </div>
        <div class="chips-center">
          <div class="chips-center-up">
            <div class="chips-center-lft">
              <div class="chips-center-lft-up">
                <p>{{ t(`chips_popup.balance`) }}</p>
              </div>
              <div class="chips-center-lft-dwn">
                <p v-if="props.room.coin_type == 'red_coin'">{{ formatNumberByZiro(store.getters.USER.bonus_balance)  }}</p>
                <p v-else>{{ formatNumberByZiro(store.getters.USER.balance)  }}</p>
                <svg class="chips-min" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
              </svg>
              </div>
          </div>
          <div class="chips-center-rgt">
            <div class="chips-center-rgt-up">
              <p>{{ t(`chips_popup.minimun_bet`) }}</p>
            </div>
            <div class="chips-center-rgt-dwn">
              <p>{{ formatNumberByZiro(min) }}</p>
              <svg class="chips-min" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
              </svg>
            </div>
          </div>
          </div>
          <div class="chips-center-dwn">
            <p v-if="props.room.coin_type == 'red_coin'" :style="{ color: (store.getters.USER.bonus_balance < inputValue || (inputValue + props.player.game_deposit) > max) ? 'red' : 'white' }">{{ formatNumberByZiro(inputValue) }}</p>
            <p v-else :style="{ color: store.getters.USER.balance < inputValue   ? 'red' : 'white' }">{{ formatNumberByZiro(inputValue) }}</p>
            <svg class="chips" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="iconColor"/>
            </svg>
          </div>
        </div>
        <div class="chips-down">
          <div class="chips-slider">
            <input id="slider"
            v-model="inputValue"
            :min=min
            :max=max
            type="range"
            @input="handleInput"
            />
          </div>
          <div class="chips-count-timer">
            <p>{{ t(`chips_popup.waiting`) + timer + t(`chips_popup.waiting_time`) }}</p>
          </div>
        </div>
        <img src="@/assets/logo/logo-background.svg" class="background-logo no-image-animation">
      </div>
      <div class="chips-wrapper-btns">
        <div v-if="props.middletGame || timeToUp" class="chips-bt1" @click="emit('close')">
          {{ t(`chips_popup.exit`) }}
        </div>
        <div v-else class="chips-bt1" @click="router.push('/rooms')">
          {{ t(`chips_popup.exit`) }}
        </div>
        <div v-if="props.middletGame || timeToUp" :class="{'chips-bt2': true, 'not-push': (inputValue + props.player.game_deposit) > max}" @click="triggerHapticFeedback('light'); addBalance()">
          {{ t(`chips_popup.continue`) }}
        </div>
        <div v-else-if="props.room.coin_type == 'red_coin' ? inputValue > store.getters.USER.bonus_balance : inputValue > store.getters.USER.balance" class="chips-bt2" @click="triggerHapticFeedback('light'); router.push('/friends')">
          {{ t(`chips_popup.add_balance`) }}
        </div>
        <div v-else class="chips-bt2" @click="triggerHapticFeedback('light'); startTheGame()">
          {{ t(`chips_popup.start_game`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.chips-bt2.not-push {
  opacity: 0.4;
}

.chips-popup {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 1000;
  background-color: #2A2B3797;
}

.chips-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.chips-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.chips-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
}

.chips-up-up {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chips-for-game {
  font-size: 18px;
  font-weight: 600;
}

.close {
  width: 2.8vw;
}

.chips-count-for-game {
  font-size: 15px;
  margin-top: 10px;
  text-align: start;
}

.chips-center {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 353px;
}

.chips-center-up {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.chips-center-lft {
  display: flex; 
  justify-content: start;
  flex-direction: column;
  gap: 5px;
}

.chips-center-lft-up {
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
}

.chips-center-lft-dwn {
  font-size: 15px;
  text-align: start;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips-center-rgt {
  display: flex; 
  justify-content: end;
  flex-direction: column;
  gap: 5px;
  align-items: end;
}

.chips-center-rgt-up {
  font-size: 15px;
  font-weight: 600;
  color: #B9B9B9;
  text-align: end;
}

.chips-center-rgt-dwn {
  font-size: 15px;
  text-align: end;
  display: flex;
  gap: 5px;
  align-items: center;
}

.chips-center-dwn {
  margin-top: 15px;
  display: flex;
  gap: 5px;
  font-size: 25px;
}

.chips {
  width: 6.98vw;
  max-width: 30px;
}

.chips-min {
  width: 5.12vw;
  max-width: 22px;
}

.chips-down {
  display: flex;
  justify-content: center;
  margin-top: 23px;
  flex-direction: column;
}

.chips-slider {
  width: 77.67vw;
  max-width: 334px;
  position: relative;
  z-index: 1000;
}

input[type="range"] {
  width: 77.67vw;
  max-width: 334px;
  background: transparent;
  appearance: none;
  -webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  -webkit-appearance: none;
  height: 19px;
  width: 60px;
  margin-top: -1.2vh;
  border-radius: 50px;
  background: v-bind('getColorByStatus');
  border: none;
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  border-radius: 10px;
  background: v-bind('getColorByStatus');
}

.chips-count-timer {
  margin-top: 20px;
  font-size: 15px;
}

.chips-wrapper-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.chips-bt1, .chips-bt2 {
  background-color: #62547F;
  padding: 14px 17px;
  font-size: 15px;
  border-radius: 24px;
}

</style>