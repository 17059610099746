import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/checkers/check.svg'
import _imports_1 from '@/assets/checkers/uncheck.svg'


const _hoisted_1 = { class: "language-setting-wrapper" }
const _hoisted_2 = { class: "language-label" }
const _hoisted_3 = { class: "language-selection-wrapper" }
const _hoisted_4 = { class: "language-name" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = {
  key: 0,
  class: "language-checker",
  src: _imports_0
}
const _hoisted_7 = {
  key: 1,
  class: "language-checker",
  src: _imports_1
}

import HeaderMini from "@/components/header/HeaderMini.vue";
import languages from "@/views/languages";
import store from "@/store";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingLanguageView',
  setup(__props) {

const { t, locale } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/settings')
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

const setLanguage = (language: string) => {
  store.commit('SET_SELECTED_LANGUAGE', language); 
  locale.value = language;
  localStorage.setItem('selectedLanguage', language);
};

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}




return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderMini),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)('settings.language')), 1),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(languages), (language, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "language-selection",
            key: i
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("p", _hoisted_4, _toDisplayString(language.name) + " (" + _toDisplayString(language.symbol) + ")", 1)
            ]),
            _createElementVNode("div", {
              onClick: ($event: any) => {triggerHapticFeedback('light'); setLanguage(language.symbol)}
            }, [
              (_unref(store).getters.SELECTED_LANGUAGE === language.symbol)
                ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                : (_openBlock(), _createElementBlock("img", _hoisted_7))
            ], 8, _hoisted_5)
          ]))
        }), 128))
      ])
    ])
  ], 64))
}
}

})