import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "tournaments-title" }

import TournamentListItem from "@/components/lists/tournaments/TournamentListItem.vue"
import { useI18n } from 'vue-i18n';
import HeaderMini from "@/components/header/HeaderMini.vue";



export default /*@__PURE__*/_defineComponent({
  __name: 'TournamentsList',
  setup(__props) {

const { t } = useI18n();
const tg = window.Telegram?.WebApp;


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(HeaderMini),
    _createElementVNode("div", {
      class: "tournament-wrapper",
      style: _normalizeStyle({'margin-top': _unref(tg).isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + 20px)': 72})
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", null, _toDisplayString(_unref(t)(`tournaments.title_partner`)), 1)
      ]),
      _createVNode(TournamentListItem)
    ], 4)
  ], 64))
}
}

})