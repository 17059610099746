<script setup lang="ts">
import router from "@/router";
import {onMounted, ref, computed, defineProps, nextTick} from "vue";
import {RoomService} from "@/api/roomService";
import AnimatedPlaceholder from "@/components/tools/AnimatedPlaceholder.vue";
import RoomsListItem from "@/components/lists/rooms/RoomsListItem.vue";
import store from "@/store";
import RoomsItem from "@/models/roomsListItem";
import { useI18n } from 'vue-i18n';
import SettingsRoom from '@/components/lists/rooms/SettingsPrivateRoom.vue'

const showSettingsPop = ref(false);

const { t } = useI18n();

const props = defineProps<{
  max_len: number,
  all: boolean;
  random?: boolean,
  myroom?: boolean,
  showSettingsPop?: boolean;
}>();

function scrollToTop() {
  nextTick(() => {
    window.scrollTo(0, 0);
  });
  triggerHapticFeedback('light');
  router.push('/rooms')
}

function scrollToTopPrivate() {
  window.scrollTo(0, 0);
  triggerHapticFeedback('light');
  router.push({
    path: '/rooms',
    query: { showSettingsPop: 'true' }
  });
}

const rooms = ref<RoomsItem[]>([]);
let privateRooms = ref<RoomsItem[]>([]);
let isLoaded = ref(false);

const roomService = new RoomService();

const blindLevels = [
  [1, 2],
  [5, 10],
  [10, 20],
  [50, 100],
  [250, 500],
  [500, 1000],
  [1000, 2000],
  [5000, 10000],
  [10000, 20000],
];

const predefinedRooms = blindLevels.map(([smallBlind, bigBlind], index) => ({
  small_blind_bet: smallBlind,
  big_blind_bet: bigBlind,
  game_deposit: bigBlind * 100,
  players_count: 0,
  fee: 0,
  max_players: 8,
  coin_type: "red_coin",
  id: index + 1,
}));

onMounted(async () => {
  rooms.value = predefinedRooms.slice(0, props.max_len);
  isLoaded.value = true;  
  await roomService.getPrivateRooms(props.max_len ?? 10)
  .then(response => response.json())
  .then(data => {
    privateRooms.value = data;
    privateRooms.value = privateRooms.value.slice(0, props.max_len);
    isLoaded.value = true;
  });
  showSettingsPop.value = props.showSettingsPop || false;
});

const tryJoinRoom = (room: RoomsItem) => {
  let coinType = 'red_coin';
  // if (isCheckedDown.value) coinType = 'go_coin';
  // else coinType = 'red_coin'; 
  router.push(`/game?small_blind_bet=${room.small_blind_bet}&coin_type=${coinType}`);
}

const tryJoinPrivateRoom = (room: RoomsItem) => {
  let coinType = 'red_coin';
  // if (isCheckedDown.value) coinType = 'go_coin';
  // else coinType = 'red_coin';
  router.push(`/game?small_blind_bet=${room.small_blind_bet}&coin_type=${coinType}&id=${room.id}`);
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const isSecondSectionBackground = computed(() => !props.all);

const openPop = () => {
if (!showSettingsPop.value) {
  showSettingsPop.value = true;
  } else {
    showSettingsPop.value = false;
  }
}

const isCheckedTop = ref(false);
const isCheckedDown = ref(false);

// isChecked: True = GoCoins, false = RedCoins
// function handleCheckboxChange(checkboxId: string) {
//   if (checkboxId === 'toggle-top') {
//     isCheckedTop.value = !isCheckedTop.value;
//   } else if (checkboxId === 'toggle-down') {
//     isCheckedDown.value = !isCheckedDown.value;
//   }
// }

const filteredPrivateRooms = computed(() => {
  return privateRooms.value.filter((room) =>
    isCheckedTop.value ? room.coin_type === "go_coin" : room.coin_type === "red_coin"
  );
});


const handleCloseSettingsPopup = () => {
  showSettingsPop.value = false;
}
</script>

<template>

<section :class="{ 'second-section': true, 'second-section-background': !all}" v-if="all">
    <div class="start-game">
      <p 
      class="start-game-label springy-animation" 
      :style="isSecondSectionBackground ? { 'margin-left': '7px' } : null"
      >
          {{ t(myroom ? 'main.myrooms' : 'main.start_game') }}
      </p>
      <div class="game-hr"></div>
      <!-- <input type="checkbox" id="toggle-top" :checked="isCheckedTop" @change="handleCheckboxChange('toggle-top')">
      <label for="toggle-top" class="display">
        <div class="circle">
          <svg class="red_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
          </svg>
          <svg class="go_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#7861A5"/>
          </svg>
        </div>
      </label> -->
    </div>
    <div class="available" v-if="isLoaded">
      <rooms-list-item v-for="(room, i) in filteredPrivateRooms" :key="i" @click="triggerHapticFeedback('light'); tryJoinPrivateRoom(room)" 
      :privateRooms="privateRooms" 
      :room="room" 
      :myroom="myroom"
      :isCheckedTop="isCheckedTop"/>
    </div>
    <div class="available-card-plus" v-if="myroom" @click="openPop">
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="-0.75" width="1.5" height="17.5" rx="0.75" transform="matrix(1 0 0 -1 8 17.5)" fill="#A5A5A6" stroke="white" stroke-width="1.5"/>
      <rect x="0.75" y="0.75" width="1.5" height="17.5" rx="0.75" transform="matrix(-4.37114e-08 1 1 4.37114e-08 -3.27835e-08 8)" fill="#A5A5A6" stroke="white" stroke-width="1.5"/>
      </svg>
    </div>
    <div v-else>
      <div class="available-card-skeleton" v-for="i in max_len" :key="i">
        <AnimatedPlaceholder width="100%" height="100%" border-radius="var(--border-mixed)"/>
      </div>
    </div>
    <div class="all-rooms" @click="scrollToTop" v-if="!all">
      <div>
        <p>{{ t(`main.all_rooms`) }}</p>
      </div>
      <div>
        <img src="@/assets/tools/arrow.svg">
      </div>
    </div>
    <settings-room v-if="showSettingsPop"
      @close="handleCloseSettingsPopup"
      :room="room!"
      :join-room="joinRoom"
      :player="getMyPlayer!"
    />  
  </section>

  <section :class="{ 'second-section': true, 'second-section-background': !all}">
    <div class="start-game">
      <p 
      class="start-game-label springy-animation" 
      :style="isSecondSectionBackground ? { 'margin-left': '7px' } : null"
      >
          {{ t(`main.start_game`) }}
      </p>
      <div class="game-hr"></div>
      <!-- <input type="checkbox" id="toggle-down" :checked="isCheckedDown" @change="handleCheckboxChange('toggle-down')">
      <label for="toggle-down" class="display">
        <div class="circle">
          <svg class="red_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
          </svg>
          <svg class="go_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#7861A5"/>
          </svg>
        </div>
      </label> -->
    </div>
    <div class="available" v-if="isLoaded">
      <rooms-list-item v-for="(room, i) in rooms" @click="triggerHapticFeedback('light'); tryJoinRoom(room)" 
        :key="i"
        :room="room"
      />
    </div>
    <div v-else>
      <div class="available-card-skeleton" v-for="i in max_len" :key="i">
        <AnimatedPlaceholder width="100%" height="100%" border-radius="var(--border-mixed)"/>
      </div>
    </div>
    <div class="all-rooms" @click="scrollToTop" v-if="!all">
      <div>
        <p>{{ t(`main.all_rooms`) }}</p>
      </div>
      <div>
        <img src="@/assets/tools/arrow.svg">
      </div>
    </div>
  </section>

  <section :class="{ 'second-section': true, 'second-section-background': !all}" v-if="!all">
    <div class="start-game">
      <p 
      class="start-game-label springy-animation" 
      :style="isSecondSectionBackground ? { 'margin-left': '7px' } : null"
      >
          {{ t(myroom ? 'main.myrooms' : 'main.start_game') }}
      </p>
      <div class="game-hr"></div>
      <!-- <input type="checkbox" id="toggle-top" :checked="isCheckedTop" @change="handleCheckboxChange('toggle-top')">
      <label for="toggle-top" class="display">
        <div class="circle">
          <svg class="red_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#D14A4A"/>
          </svg>
          <svg class="go_coins" width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" fill="#7861A5"/>
          </svg>
        </div>
      </label> -->
    </div>
    <div class="available" v-if="isLoaded">
      <rooms-list-item v-for="(room, i) in filteredPrivateRooms" :key="i" @click="triggerHapticFeedback('light'); tryJoinPrivateRoom(room)" 
      :privateRooms="privateRooms" 
      :room="room" 
      :myroom="myroom"
      :isCheckedTop="isCheckedTop"/>
    </div>
    <div class="available-card-plus" v-if="myroom" @click="scrollToTopPrivate">
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.75" y="-0.75" width="1.5" height="17.5" rx="0.75" transform="matrix(1 0 0 -1 8 17.5)" fill="#A5A5A6" stroke="white" stroke-width="1.5"/>
      <rect x="0.75" y="0.75" width="1.5" height="17.5" rx="0.75" transform="matrix(-4.37114e-08 1 1 4.37114e-08 -3.27835e-08 8)" fill="#A5A5A6" stroke="white" stroke-width="1.5"/>
      </svg>
    </div>
    <div v-else>
      <div class="available-card-skeleton" v-for="i in max_len" :key="i">
        <AnimatedPlaceholder width="100%" height="100%" border-radius="var(--border-mixed)"/>
      </div>
    </div>
    <div class="all-rooms" @click="scrollToTop" v-if="!all">
      <div>
        <p>{{ t(`main.all_rooms`) }}</p>
      </div>
      <div>
        <img src="@/assets/tools/arrow.svg">
      </div>
    </div>
    <settings-room v-if="showSettingsPop"
      @close="handleCloseSettingsPopup"
      :room="room!"
      :join-room="joinRoom"
      :player="getMyPlayer!"
    />  
  </section>
</template>

<style scoped>

.available-card-plus {
  margin-top: 14px;
  display: flex;
  height: 70px;
  align-items: center;
  background-color: #5C4E73;
  border-radius: var(--border-mixed);
  justify-content: center;
  animation: scaleUp 0.3s ease-out;
}

input {
    display: none;
}

label {
  width: 59px;
  height: 34px;
  border-radius: 999px;
  background: #A63C3C;
  cursor: pointer;
  position: relative;
}

.circle {
  width: 30px;
  height: 30px;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.05),
  -10px -10px 30px rgba(0, 0, 0, 0.05) inset;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: translate(10%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: 0.5s;
}

.red_coins {
  position: absolute;
  margin: 0%;
  opacity: 1;
}

.go_coins {
  position: absolute;
  margin: 0%;
  opacity: 0;
}

input[type=checkbox]:checked + .display .circle {
    left: 100%;
    transform: translate(-110%, -50%);
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.25)) 
            drop-shadow(-2px -2px 5px rgba(0, 0, 0, 0.25));
}

input[type=checkbox]:checked + .display {
    background: #5C4E73;
    transition: 0.5s;
}

input[type=checkbox]:checked + .display .circle .red_coins {
    margin-top: 0%;
    opacity: 0;
}

input[type=checkbox]:checked + .display .circle .go_coins {
    margin-top: 0%;
    opacity: 1;
}


.second-section {
  border-radius: var(--border-mixed);
  margin-top: 25px;
  padding: 25px 0 11px;
}

.second-section-background {
  background-color: #15151E;
  padding: 25px 11px 11px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.3s forwards;
}

.game-hr {
  border-top: 1px solid white;
  width: 35%;
}

.start-game-label {
  font-weight: 500;
  font-size: 17px;
  white-space: nowrap;
}

.start-game {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 15px;
}

.available-card-skeleton {
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
  border-radius: var(--border-mixed);
  height: 70px;
  align-items: center;
}

.all-rooms {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 17px;
  border-radius: var(--border-mixed);
  margin-top: 19px;
  background: linear-gradient(270.01deg, #5C4E73 0.01%, #5C4E73 0.02%, rgba(36, 35, 46, 0) 100%), #5C4E73;
}
</style>