import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/wallet/ton_wallet_connector.svg'


const _hoisted_1 = { class: "connect-wallet-info" }
const _hoisted_2 = { class: "connect-wallet-info-label" }
const _hoisted_3 = { class: "connect-wallet-arrow-wrapper" }
const _hoisted_4 = ["src"]

import {onMounted, ref} from "vue";
import store from "@/store";
import {ConnectedWallet} from "@tonconnect/ui";
import TonWeb from "tonweb";
import {UserService} from "@/api/userService";
import router from "@/router";

import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'WalletComponent',
  setup(__props) {

const { t } = useI18n();

const walletConnected = ref(false);
onMounted(() => {
  if (store.getters.USER.address) {
    walletConnected.value = true;
  }
})

async function walletConnect() {
  if (!store.getters.USER.address) {
    if (store.getters.TON_CONNECT_UI.wallet) {
      triggerHapticFeedback('light');
      await store.getters.TON_CONNECT_UI.disconnect();
    }
    await store.getters.TON_CONNECT_UI.connectWallet().then((wallet: ConnectedWallet) => {
      const address = wallet.account.address;
      triggerHapticFeedback('light');
      const userService = new UserService();
      userService.connectAddress(address)
          .then(response => response.json())
          .then(data => {
            store.commit('SET_USER', data)
            router.push('/profile')
          });
    });
  }
  else {
    showDisconnectPop.value = true;
  }
}

const showDisconnectPop = ref(false);

const CloseDesconnectPopup = () => {
  showDisconnectPop.value = false;
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: "connect-wallet",
    onClick: walletConnect
  }, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
      _createElementVNode("p", _hoisted_2, _toDisplayString(walletConnected.value ? _unref(store).getters.USER.address : _unref(t)(`deposit_and_withdraw.link_wwallet`)), 1)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("img", {
        class: "connect-wallet-arrow",
        src: walletConnected.value ? require('@/assets/wallet/wallet_connected_check.svg') : require('@/assets/tools/arrow-wallet.svg')
      }, null, 8, _hoisted_4)
    ])
  ]))
}
}

})