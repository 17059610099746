<script setup lang="ts">

import {defineProps, defineEmits} from "vue";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";

const { t } = useI18n();

const props = defineProps<{
  player: Player,
  room: Room,
  joinRoom: () => void,
  currentPlayer: Player,
}>()

const emit = defineEmits(['close']); 

const tg = window.Telegram?.WebApp;

const roomService = new RoomService();

async function delRoom() {
  try {
    const id = props.room.id;
    await roomService.leaveRoom(id);
    await roomService.deletePrivateRooms(id);
    emit('close');
  } 
  catch (error) {
    console.error("Ошибка при удалении комнаты:", error);
  }
}

</script>


<template>
  <div class="settings-room-background" @click="emit('close')"></div>
  <div class="delete-room">
    <div class="chips-wrapper">
      <div class="chips-wrapper-card">
        <div class="chips-up">
          <div class="chips-up-up">
            <div class="chips-for-game">
              <p>{{ t(`settings_room.del_room_text`) }}</p>
            </div>
          </div>
          <div class="chips-count-for-game">
            <p>{{ t(`settings_room.del_room_text_2`) }}</p>
          </div>
        </div>
      </div>
      <div class="chips-wrapper-btns">
        <div class="chips-bt1" @click="emit('close')">
          {{ t(`settings_room.exit`) }}
        </div>
        <div class="chips-bt2" @click="delRoom()">
          {{ t(`settings_room.confirm`) }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>

.delete-room {
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 1001;
  transform: scale(0.9);
  opacity: 0;
  animation: fadeInScale 0.2s ease-out 0.2s forwards;
}

.settings-room-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #2A2B3799;
  animation: fadeIn 0.2s ease-out;
}


.chips-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.chips-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
  padding-bottom: 30px;
}

.background-logo {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.chips-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
  z-index: 3;
  width: 77.67vw;
}

.chips-up-up {
  z-index: 2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chips-for-game {
  font-size: 18px;
  font-weight: 600;
  text-align: start;
}

.close {
  width: 2.8vw;
}

.chips-count-for-game {
  font-size: 15px;
  margin-top: 10px;
  text-align: start;
}

.chips-wrapper-btns {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.chips-bt1, .chips-bt2, .chips-bt3 {
  background-color: #62547F;
  padding: 14px 17px;
  font-size: 15px;
  border-radius: 24px;
  display: flex;
}

</style>