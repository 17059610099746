<script setup lang="ts">

import {defineProps, defineEmits, ref, onMounted, computed} from "vue";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";

const { t } = useI18n();

const props = defineProps<{
  room: Room,
  myroomBigBlindBet: number,
  showPlayer:boolean,
  deleteRoom: boolean
}>()

const emit = defineEmits(['close']); 

const tg = window.Telegram?.WebApp;

const timer = ref<number>(3);

onMounted (async ()=> {
  timer.value = 3;
  countTimer();
})

function countTimer(){
  const interval = setInterval(() => {
    if (timer.value > 1) {
      timer.value--;  
    } else {
      emit('close');
      clearInterval(interval);
    }
  }, 1000);
}

const colorChips = computed(() => {
  if(props.room.coin_type == 'go_coin') {
    return "#7861A5";
  }
  if(props.room.coin_type == 'red_coin') {
    return "#D14A4A";
  }
  else {
    return "#FFFFFF";
  }
  
});

const computedCoinTypeText = computed(() => {
  if (props.room.coin_type === 'go_coin') {
    return t('notification.go_coins');
  } else if (props.room.coin_type === 'red_coin') {
    return t('notification.red_coins');
  }
  return '';
});


</script>


<template>

  <div class="new-bb-room">
    <div class="new-bb-wrapper">
      <div class="new-bb-wrapper-card">
        <div class="new-bb-up">
          <div class="new-bb-up-up">
            <div class="new-bb-text1">
              <p v-if="deleteRoom">{{ t(`notification.admin_del_room`)}} </p>
              <p v-else>{{ t(`notification.change_settings_room`)}} </p>
            </div>
          </div>
          <div class="new-bb-text2">
            <p v-if="deleteRoom">{{ t(`notification.admin_del_room2`) }}</p>
            <p v-else>{{ t(`notification.game`) }} <span style="font-weight: 600;">{{ computedCoinTypeText }}</span> {{ t(`notification.bb`) }} {{ myroomBigBlindBet }}</p>
            <svg 
                class="new-bb-сhips" 
                width="15px" 
                height="15px"
                viewBox="0 0 24 24" 
                fill="none" 
                xmlns="http://www.w3.org/2000/svg">
                <path d="M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z" :fill="colorChips"/>
              </svg>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<style scoped>

.coins {

}

.new-bb-сhips {
  margin-left: 3px;
}

.new-bb-room {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 1001;
  transform: scale(0.9);
  opacity: 0;
  animation: fadeInScale 0.2s ease-out 0.2s forwards;
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.new-bb-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.new-bb-wrapper-card {
  position: relative;
  max-width: 393px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #15151E;
  border-radius: 24px;
  padding: 20px;
  width: 85.4vw;
}

.new-bb-background-logo {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.new-bb-up {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 353px;
  z-index: 3;
  width: 77.67vw;
}

.new-bb-up-up {
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-bb-text1 {
  font-size: 13px;
  font-weight: 600;
}

.new-bb-close {
  width: 2.8vw;
}

.new-bb-text2 {
  font-size: 13px;
  margin-top: 5px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

</style>