import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, normalizeStyle as _normalizeStyle, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "game-actions" }
const _hoisted_2 = { class: "user-info-wrapper" }
const _hoisted_3 = { class: "user-info" }
const _hoisted_4 = { class: "elements-player-center" }
const _hoisted_5 = { class: "player-cards-wrapper" }
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = {
  key: 0,
  class: "game-button-raise-wrapper-watcher"
}
const _hoisted_9 = {
  key: 0,
  class: "btn-raise-pot"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "bb-percentage" }
const _hoisted_12 = {
  key: 1,
  class: "game-button-raise-wrapper-watcher"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = {
  key: 0,
  class: "game-buttons"
}
const _hoisted_15 = {
  class: /*@__PURE__*/_normalizeClass({ 'game-button-wrapper': true })
}
const _hoisted_16 = { class: "call-sum" }
const _hoisted_17 = {
  class: /*@__PURE__*/_normalizeClass({ 'game-button-raise-wrapper': true })
}
const _hoisted_18 = {
  key: 1,
  class: "game-buttons"
}
const _hoisted_19 = { key: 1 }
const _hoisted_20 = {
  key: 0,
  class: "game-buttons"
}
const _hoisted_21 = {
  class: /*@__PURE__*/_normalizeClass({'game-button-width': true, 'game-button': true, 'game-button-inactive': true })
}
const _hoisted_22 = {
  class: /*@__PURE__*/_normalizeClass({'game-button-width': true, 'game-button': true, 'game-button-inactive': true })
}
const _hoisted_23 = {
  class: /*@__PURE__*/_normalizeClass({ 'game-button-raise-wrapper': true })
}
const _hoisted_24 = {
  class: /*@__PURE__*/_normalizeClass({ 'game-button-raise': true, 'game-button-inactive': true })
}
const _hoisted_25 = {
  key: 1,
  class: "game-buttons"
}

import store from "@/store";
import PlayerCard from "@/models/playerCard";
import Player from "@/models/player";
import Game from "@/models/game";
import {computed, onMounted, watch, ref } from "vue";
import {formatNumber} from "../../tools/tools";
import Room from "@/models/room";
import GameUserPlayer from "@/components/game/GameUserAction.vue"
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'GameActions',
  props: {
    selectedEmoji: {},
    showPlayer: { type: Boolean },
    selectedEmojiPlayerID: {},
    playerCards: {},
    players: {},
    player: {},
    hand: {},
    game: {},
    room: {},
    sliderValue: {},
    waitForJoin: { type: Boolean },
    dealerSeat: {},
    joinRoom: { type: Function },
    min: {},
    foldCards: { type: Boolean },
    autoFoldCheck: { type: Boolean },
    autoCall: { type: Boolean },
    showBtnRaise: { type: Boolean },
    currentCase: {}
  },
  emits: ["call", "raise", "check", "bet", "bet_btn", "raise_btn", "fold", "autoFoldCheck_handle", "autoCall_handle", "showHandleBtnRaise", "hideHandleBtnRaise"],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const props = __props;

const seat = {
  player: props.player,
  seat: props.player?.seat
}

const preloadImage = (url: string) => {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.src = url;
  });
};

// const emit = defineEmits(['call', 'raise', 'check', 'bet', 'fold', 'updateSliderValue', 'autoFoldCheck_handle', 'autoCall_handle', 'showHandleBtnRaise', 'hideHandleBtnRaise']);

const emit = __emit;


// const borderColor = computed(() => {
//   switch (store.getters.USER.vip_status) {
//     case "first":
//     case "usual":
//       return 'white'
//     case "silver":
//       return "var(--gradient-sliver)"
//     case "gold":
//       return "var(--gradient-gold)"
//     default:
//       return 'white';
//   }
// });


onMounted(async () => {
  const image1 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${store.getters.USER.vip_status}/${props.playerCards[0].card_rank.toLowerCase()}_${store.getters.USER.vip_status != 'first' ? props.playerCards[0].card_suit.toLowerCase(): props.playerCards[0].card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`);
  const image2 = props.playerCards.length > 0 ? require(`@/assets/cards/open/${store.getters.USER.vip_status}/${props.playerCards[1].card_rank.toLowerCase()}_${store.getters.USER.vip_status != 'first' ? props.playerCards[1].card_suit.toLowerCase(): props.playerCards[1].card_suit.toLowerCase()}_${store.getters.USER.vip_status}.svg`) : require(`@/assets/cards/hidden/hidden-${store.getters.USER.vip_status}.svg`);
  
  const image1El = document.getElementById('player-card-1')
  const image2El = document.getElementById('player-card-2')

  await preloadImage(image1);
  await preloadImage(image2);

  if (image1El && image2El && (image1 && image2)) {
    image1El.setAttribute('src', image1);
    image2El.setAttribute('src', image2);

  }

  checkHandCards();
})

// function addSliderValue(){
//   if (isRaiseInactive.value) return;
//   triggerHapticFeedback('light');
//   if (props.sliderValue < props.min){
//     emit('updateSliderValue', props.min + props.room.big_blind_bet)
//   } else {
//     emit('updateSliderValue', props.sliderValue + props.room.big_blind_bet)
//   }
// }

// function delSliderValue(){
//   if (isRaiseInactive.value) return;
//   triggerHapticFeedback('light');
//   emit('updateSliderValue', props.sliderValue - props.room.big_blind_bet)
// }

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const checkHandCards = () => {
  const isCardInHand = (card: { card_rank: string; card_suit: string }) =>
    props.hand.cards.some((comboCard) =>
      comboCard.card_rank === card.card_rank &&
      comboCard.card_suit === card.card_suit
  );

  if (props.playerCards.length > 0) {
    const image1El = document.getElementById('player-card-1');
    const image2El = document.getElementById('player-card-2');

      const applyHighlightAnimation = (element: HTMLElement) => {
        setTimeout(() => {

          setTimeout(() => {
            if(element === image1El){
              element.style.animation = 'highlight-card1 .4s ease forwards';
            }
            else {
              element.style.animation = 'highlight-card2 .4s ease forwards';
            }
          }, 50);

          setTimeout(() => {
            if (element === image1El) {
              element.style.animation = 'outhighlight-card1 .4s ease forwards';
            }
            else {
              element.style.animation = 'outhighlight-card2 .4s ease forwards';
            }
          }, 2000);
        }, 1800);
      };
    

    if (isCardInHand(props.playerCards[0]) && image1El) {
      applyHighlightAnimation(image1El);
    }

    if (isCardInHand(props.playerCards[1]) && image2El) {
      applyHighlightAnimation(image2El);
    }
  }
};

watch(
  () => props.hand, 
  (newHand, oldHand) => {
    if (JSON.stringify(newHand) !== JSON.stringify(oldHand)) {
      checkHandCards();
    }
  },
  { deep: true, immediate: true }
);

const isRaiseInactive = computed(() => {
  const myPlayer = props.player;
  const currentMaxBet = Math.max(...props.players.map(x => x.current_round_bet));
  return myPlayer.game_deposit <= currentMaxBet;
});

const showGameUserPlayer = computed(() => {
  return (props.player?.action_status === 'watcher') || props.waitForJoin;
});

const imAtTheTable = computed(() => {
  return props.game?.current_player_id;
});

const maxBetTable = computed (() => {
  return Math.max(...props.players.map(x => x.current_round_bet));
});

const minRaiseTable = computed(() => {
  return Math.max(...props.players.map(x => x.current_round_bet))*2;
});

const myDeposit = computed(() => {
  if ("game_deposit" in props.player) return props.player.game_deposit;
  else return 0;
});

const myCurrentBet = computed (() => {
  return props.player.current_round_bet;
});

const canImove = computed(() => {
  return props.player.id == props.game?.current_player_id;
});

const imInArrayPlayers = computed(() => {
  return props.players.some(item => item.id === props.player.id);
});

const myBetEqualBetTable = computed (() => {
  return props.player.current_round_bet >= maxBetTable.value;
});

const canISeat = computed(() => {
  return props.room.players_count != props.room.max_players;
});

const canIRaise = computed(() => {
  return myDeposit.value >= maxBetTable.value;
});

const canIAllin = computed (() => {
 return myDeposit.value >= maxBetTable.value && myDeposit.value <= minRaiseTable.value;
});

const bigBlindBet = computed(() =>{
  return props.room.big_blind_bet;
});

const callSum = computed(() => {
  if (imInArrayPlayers.value && canImove.value) {
    let maxBet = Math.max(Math.max(...props.players.map(x => x.current_round_bet)));
    if(props.player.game_deposit >= maxBet) {
      return formatNumber(maxBet)
    }
    else {
      return formatNumber(props.player.game_deposit);
    }
  }
  else {
    return "";
  }
});

const isNewGameWithBlinds = computed(() => {
  return (
    props.currentCase === 'new_game' &&
    (props.room.big_blind_bet + props.room.big_blind_bet / 2) === props.game.bank
  );
});

const potBets = computed(() => {
  const bank = props.game.bank;
  const previousBet = Math.max(...props.players.map(player => player.current_round_bet));
  const minimumRaise = previousBet * 2;
  const percentages = [0.33, 0.5, 0.75, 1.0];
  const bigBlindBet = props.room.big_blind_bet;

  if (isNewGameWithBlinds.value) {
    return [
      { label: '1 BB', percentage: '', bet: bigBlindBet },
      { label: '2 BB', percentage: '', bet: bigBlindBet * 2 },
      { label: '3 BB', percentage: '', bet: bigBlindBet * 3 },
      { label: '4 BB', percentage: '', bet: bigBlindBet * 4 },
    ].reverse();
  } else {
    return percentages
      .map(percentage => {
        const calculatedBet = bank * percentage;
        return {
          label: '',
          percentage: `${(percentage * 100).toFixed(0)}%`,
          bet: Math.max(calculatedBet, minimumRaise),
        };
      })
      .reverse();
  }
});



const handleRaise = (bet: number) => {
  console.log(`Выбрана ставка: ${bet}`);
  if (isNewGameWithBlinds.value) {
    emit('bet_btn', bet);
  }
  else {
    emit('raise_btn', bet);
  }
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "user-hand-wrapper" }, [
      _createElementVNode("p", { class: "user-hand" }, "Старшая карта")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              style: _normalizeStyle({ 'opacity': imInArrayPlayers.value ? '1' : '0', 'filter': props.foldCards ? 'brightness(69%)' : 'none'  }),
              id: "player-card-1",
              class: "player-card-1 no-image-animation",
              src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
            }, null, 12, _hoisted_6),
            _createElementVNode("img", {
              style: _normalizeStyle({ 'opacity': imInArrayPlayers.value ? '1' : '0', 'filter': props.foldCards ? 'brightness(69%)' : 'none'  }),
              id: "player-card-2",
              class: "player-card-2 no-image-animation",
              src: require(`@/assets/cards/hidden/hidden-${_unref(store).getters.USER.vip_status}.svg`)
            }, null, 12, _hoisted_7)
          ]),
          (imInArrayPlayers.value || _ctx.waitForJoin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_ctx.showBtnRaise)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(potBets.value, (pot) => {
                        return (_openBlock(), _createElementBlock("div", {
                          key: pot.label || pot.percentage,
                          onClick: ($event: any) => (handleRaise(pot.bet)),
                          class: _normalizeClass({ 'game-button-raise-pot': true })
                        }, [
                          _createTextVNode(_toDisplayString(_unref(formatNumber)(pot.bet)) + " ", 1),
                          _createElementVNode("p", _hoisted_11, _toDisplayString(pot.label || pot.percentage), 1)
                        ], 8, _hoisted_10))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true),
                (isNewGameWithBlinds.value && !props.showBtnRaise)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (canImove.value ? emit('showHandleBtnRaise'): null)),
                      class: _normalizeClass({ 'game-button-raise-pot': true, 'game-button-inactive1': !canIRaise.value || !canImove.value || canIAllin.value && (myDeposit.value >= bigBlindBet.value*2)})
                    }, [
                      _cache[13] || (_cache[13] = _createElementVNode("p", { class: "bb-percentage" }, "ББ", -1)),
                      _createTextVNode(" " + _toDisplayString(_unref(t)(`game.btn_bet`)), 1)
                    ], 2))
                  : (!isNewGameWithBlinds.value && !props.showBtnRaise)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 2,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (canImove.value ? emit('showHandleBtnRaise'): null)),
                        class: _normalizeClass({ 'game-button-raise-pot': true, 'game-button-inactive1': !canIRaise.value || !canImove.value || canIAllin.value && (myDeposit.value >= bigBlindBet.value*2)})
                      }, [
                        _cache[14] || (_cache[14] = _createElementVNode("p", { class: "bb-percentage" }, "%", -1)),
                        _createTextVNode(" " + _toDisplayString(_unref(t)(`game.btn_raise`)), 1)
                      ], 2))
                    : _createCommentVNode("", true)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_12, _cache[15] || (_cache[15] = [
                _createElementVNode("div", null, null, -1),
                _createElementVNode("div", null, null, -1)
              ])))
        ])
      ])
    ]),
    (imAtTheTable.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
          (imInArrayPlayers.value || _ctx.waitForJoin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("div", _hoisted_15, [
                  (!canImove.value && !_ctx.autoFoldCheck && !_ctx.autoCall)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass({ 'game-button-width': true, 'game-button-auto ': true }),
                        onClick: _cache[2] || (_cache[2] = ($event: any) => {triggerHapticFeedback('light'); emit('autoFoldCheck_handle')})
                      }, [
                        _cache[16] || (_cache[16] = _createElementVNode("p", { class: "bb-percentage" }, "auto", -1)),
                        _createTextVNode(" " + _toDisplayString(_unref(t)(`game.btn_fold_ckeck`)), 1)
                      ]))
                    : (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass({'game-button-width': true, 'game-button-fold ': true, 'game-button-inactive': !canImove.value }),
                        onClick: _cache[3] || (_cache[3] = ($event: any) => {triggerHapticFeedback('light'); canImove.value ? emit('fold') : null; emit('hideHandleBtnRaise')})
                      }, _toDisplayString(_unref(t)(`game.btn_fold`)), 3))
                ]),
                (!canImove.value && !_ctx.autoCall && !_ctx.autoFoldCheck)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: _normalizeClass({'game-button-width': true, 'game-button-auto': true, 'game-button-wrapper': true}),
                      onClick: _cache[4] || (_cache[4] = ($event: any) => {triggerHapticFeedback('light'); emit('autoCall_handle')})
                    }, [
                      _cache[17] || (_cache[17] = _createElementVNode("p", { class: "bb-percentage" }, "auto", -1)),
                      _createTextVNode(" " + _toDisplayString(_unref(t)(`game.btn_call`)), 1)
                    ]))
                  : (myCurrentBet.value >= maxBetTable.value)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 1,
                        class: _normalizeClass({'game-button-width': true, 'game-button': true, 'game-button-inactive': !canImove.value }),
                        onClick: _cache[5] || (_cache[5] = ($event: any) => {triggerHapticFeedback('light'); canImove.value ? emit('check') : null; emit('hideHandleBtnRaise')})
                      }, _toDisplayString(_unref(t)(`game.bnt_check`)), 3))
                    : (myBetEqualBetTable.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: _normalizeClass({'game-button-width': true, 'game-button': true, 'game-button-inactive': !canImove.value }),
                          onClick: _cache[6] || (_cache[6] = ($event: any) => {triggerHapticFeedback('light'); canImove.value ? emit('call') : null; emit('hideHandleBtnRaise')})
                        }, _toDisplayString(_unref(t)(`game.all_in`)), 3))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 3,
                          class: _normalizeClass({'game-button-width': true, 'game-button': true, 'game-button-inactive': !canImove.value }),
                          onClick: _cache[7] || (_cache[7] = ($event: any) => {triggerHapticFeedback('light'); canImove.value ? emit('call') : null; emit('hideHandleBtnRaise')})
                        }, [
                          _createTextVNode(_toDisplayString(_unref(t)(`game.btn_call`)) + " ", 1),
                          _createElementVNode("span", _hoisted_16, _toDisplayString(callSum.value), 1)
                        ], 2)),
                _createElementVNode("div", _hoisted_17, [
                  (maxBetTable.value==0)
                    ? (_openBlock(), _createElementBlock("div", {
                        key: 0,
                        class: _normalizeClass({'game-button-width': true, 'game-button-raise': true, 'game-button-inactive': !canIRaise.value || !canImove.value }),
                        onClick: _cache[8] || (_cache[8] = ($event: any) => {triggerHapticFeedback('light'); canImove.value && canIRaise.value ? emit('bet') : null; emit('hideHandleBtnRaise')})
                      }, _toDisplayString(_unref(t)(`game.btn_bet`)), 3))
                    : (canIAllin.value)
                      ? (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: _normalizeClass({'game-button-width': true, 'game-button-raise': true, 'game-button-inactive': !canImove.value }),
                          onClick: _cache[9] || (_cache[9] = ($event: any) => {triggerHapticFeedback('light'); canImove.value && canIAllin.value ? emit('raise') : null; emit('hideHandleBtnRaise')})
                        }, _toDisplayString(_unref(t)(`game.all_in`)), 3))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 2,
                          class: _normalizeClass({'game-button-width': true, 'game-button-raise': true, 'game-button-inactive': !canIRaise.value || !canImove.value }),
                          onClick: _cache[10] || (_cache[10] = ($event: any) => {triggerHapticFeedback('light'); canImove.value && canIRaise.value ? emit('raise') : null; emit('hideHandleBtnRaise')})
                        }, _toDisplayString(_unref(t)(`game.btn_raise`)), 3))
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_18, [
                _createElementVNode("div", {
                  class: "join-table",
                  onClick: _cache[11] || (_cache[11] = ($event: any) => ( _ctx.joinRoom()))
                }, _toDisplayString(_unref(t)(`game.join_room`)), 1)
              ]))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_19, [
          (imInArrayPlayers.value || _ctx.waitForJoin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                _createElementVNode("div", _hoisted_21, _toDisplayString(_unref(t)(`game.btn_fold`)), 1),
                _createElementVNode("div", _hoisted_22, _toDisplayString(_unref(t)(`game.btn_call`)), 1),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("div", _hoisted_24, _toDisplayString(_unref(t)(`game.btn_bet`)), 1)
                ])
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_25, [
                (canISeat.value)
                  ? (_openBlock(), _createElementBlock("div", {
                      key: 0,
                      class: "join-table",
                      onClick: _cache[12] || (_cache[12] = ($event: any) => {triggerHapticFeedback('light'); _ctx.joinRoom()})
                    }, _toDisplayString(_unref(t)(`game.join_room`)), 1))
                  : (_openBlock(), _createElementBlock("div", {
                      key: 1,
                      class: _normalizeClass({'join-table': true, 'game-button-inactive': !canISeat.value})
                    }, _toDisplayString(_unref(t)(`game.join_room`)), 3))
              ]))
        ]))
  ]))
}
}

})