<script setup lang="ts">
import Message from "@/models/message";
import Player from "@/models/player";
import MessageWrapper from "@/components/game/MessageWrapper.vue";
import { RoomService } from "@/api/roomService";
import Room from "@/models/room";
import {defineProps, defineEmits, ref, onMounted, nextTick, watch} from "vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();


const roomService = new RoomService();


const props = defineProps<{
  myPlayer:Player,
  room:Room,
  messages: Message[],
}>()


const messageContainer = ref<HTMLElement | null>(null);
const isChatFullScreen = ref(false); 
const isInputFocused = ref(false); 

const emit = defineEmits(['closeChat']);

onMounted(() => {
    nextTick(() => {
            scrollToBottom(); 
    });
});



function closeChat() {
    const inputElement = document.getElementById('msg') as HTMLInputElement;
    const msg = inputElement.value;
    if (msg) emit('closeChat');

    if (isChatFullScreen.value){
        isChatFullScreen.value = false; 
        scrollToBottom();
        emit('closeChat'); 
    }
    else emit('closeChat'); 
}

function sendMsg(){
    const inputElement = document.getElementById('msg') as HTMLInputElement;
    const msg = inputElement.value;
    if (msg === '') return;

    roomService.sendMessage(props.room.id, msg!, 'message');    
    inputElement.value = "";

    nextTick(() => {
        inputElement.focus();
    });

}


function handleInputFocus() {
    isChatFullScreen.value = true;  
    isInputFocused.value = true; 
    scrollToBottom();
}

function handleBlur() {
    const inputElement = document.getElementById('msg') as HTMLInputElement;
    const msg = inputElement.value;
    if (msg) inputElement.focus(); 
    else {
        isInputFocused.value = false; 
        isChatFullScreen.value = false;
        scrollToBottom();
    }
}

function scrollToBottom() {
    if (messageContainer.value) {
        nextTick(() =>{
            messageContainer.value!.scrollTop = messageContainer.value!.scrollHeight;
        })
    }
}

watch(() => props.messages, () => {
    nextTick(() => {
        scrollToBottom(); 
    });
});

</script>


<template>
    <div :class="['chat-wrapper', { 'chat-fullscreen': isChatFullScreen }]">
        <div :class="{'wrapper': !isChatFullScreen, 'wrapper-full': isChatFullScreen }">
            <div :class="{'top': !isChatFullScreen, 'top-full': isChatFullScreen}">
                <p v-if="!isChatFullScreen" class="header">{{ t(`game.chat`) }}</p>
                <div class="close" @click="closeChat">
                    <img class="close-img" src="@/assets/chat/close.svg">
                </div>
            </div>
            <div
                :class="{'message_container': !isChatFullScreen, 'message-container-full': isChatFullScreen}"
                :style="{ height: isChatFullScreen && isInputFocused ? '100vh' : '630px' }"
                ref="messageContainer"
            >
                <MessageWrapper
                    v-for="(message, index) in messages"
                    :key="index"
                    :message="message"
                    :myPlayer="myPlayer"
                />
            </div>
            <div :class="{'input_container': !isChatFullScreen, 'input-container-full': isChatFullScreen }">
                <input
                    :class="{'text': !isChatFullScreen, 'text-full': isChatFullScreen}"
                    id="msg"
                    type="text"
                    placeholder="Сообщение"
                    @focus="handleInputFocus"
                    @blur="handleBlur"
                />
                <div class="btn-send" @click="sendMsg">
                    <img class="btn-send-img" src="@/assets/chat/send.svg">
                </div>
            </div>
        </div> 
    </div>
</template>

<style scoped>

.wrapper-full {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    position: relative;
    /* margin-top: 35px; */
    margin-bottom: 0;
    margin-right: 0;
    height: 46vh;
}

.message-container-full {
    display: flex;
    flex-direction: column;
    width: 71%;
    overflow-y: scroll;
    gap: 2vw;
    height: 100vh;
}

.input-container-full {
    display: flex;
    width: 91%;
    gap: 2.5vw;
    height: 35px;
    justify-content: space-between;
    margin-bottom: -20px;
}

.btn-send-img {
    width: 8vw;
    height: 35px;
}

.chat-wrapper {
    /* position: absolute;
    height: 405px;
    background-color: #423757;
    z-index: 10000;
    border-top-left-radius: 5vw;
    border-bottom-left-radius: 5vw;
    box-shadow: 4px 7px 40px rgba(0, 0, 0, 0.6);
    transform: scale(0.9);
    opacity: 0;
    animation: fadeInScale 0.2s ease-out forwards; */

    position: absolute;
    height: 405px;
    z-index: 10000;
    border-top-left-radius: 5vw;
    border-bottom-left-radius: 5vw;
    border-radius: 25px;
    box-shadow: inset 0 0 0 0.5px #7F69A4, 4px 7px 40px rgba(0,0,0,.6);
    transform: scale(.9);
    opacity: 0;
    animation: fadeInScale .2s ease-out forwards;
    right: 0;
    bottom: 4.2vh;
    background: linear-gradient(135deg, rgba(188, 0, 0, 0), rgba(188, 0, 0, 0.1)), #423757;
}

.chat-fullscreen {
    position: fixed;
    top: calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + 38px);
    left: 0;
    width: 100vw;
    z-index: 998;
    background-color: #423757;
    display: flex;
    justify-content: flex-end;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 100vh;
    align-items: start;
    border-radius: 25px;
    box-shadow: 4px 7px 40px rgba(0,0,0,.6);
    transform: scale(.9);
    opacity: 0;
    animation: fadeInScale .2s ease-out forwards;
    right: 0;
    bottom: 0;
    background: linear-gradient(135deg,rgba(188,0,0,0),rgba(188,0,0,.1)),#423757;
}

.wrapper {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 12px;
    height: 365px; */

    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 13px;
    margin-right: 31px;
    height: 374px;
    width: 100%;
}

.chat-fullscreen .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    position: relative;
}

.top-full{
    display: flex;
    justify-content: end;
    align-items: center;
    position: absolute;
    top: 20px;
    right: 20px;
}

.top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    right: 20px;
    width: 83%;
}

p.header{
    font-size: 16px;
    color: #62547F;
    font-weight: 600;
}

.close-img{
    width: 14px;
}

.close{
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message_container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 91%;
    max-width: 360px;
    overflow-y: scroll;
    gap: 2vw;
}

.input_container {
    display: flex;
    width: 91%;
    gap: 2.5vw;
    height: 35px;
}

input.text {
    width: 70vw;
    max-width: 300px;
    background-color: #252331;
    color: #B9B9B9;
    font-size: 14px;
    padding: 0px;
    font-family: inherit;
    margin: 0;
    border-radius: 100px;
    padding-left: 14px;
    padding-right: 14px;
    border: none;
    outline: none;
}

input.text-full {
    width: 75vw;
    max-width: 300px;
    background-color: #252331;
    color: #B9B9B9;
    font-size: 14px;
    padding: 0px;
    font-family: inherit;
    margin: 0;
    border-radius: 100px;
    padding-left: 14px;
    padding-right: 14px;
    border: none;
    outline: none;
}

.btn-send {
    width: 8vw;
    height: 35px;
}
</style>