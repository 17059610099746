import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/tools/close-modal.svg'
import _imports_1 from '@/assets/logo/logo-background.svg'


const _hoisted_1 = { class: "notfound-room" }
const _hoisted_2 = { class: "notfound-wrapper" }
const _hoisted_3 = { class: "notfound-wrapper-card" }
const _hoisted_4 = { class: "notfound-up" }
const _hoisted_5 = { class: "notfound-up-up" }
const _hoisted_6 = { class: "notfound-text1" }
const _hoisted_7 = { class: "notfound-text2" }

import {ref, onMounted} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import Player from "@/models/player";


export default /*@__PURE__*/_defineComponent({
  __name: 'PopNotFoundRoom',
  props: {
    player: {},
    room: {}
  },
  emits: ['close'],
  setup(__props: any, { emit: __emit }) {


const { t } = useI18n();

const props = __props

const emit = __emit; 

const tg = window.Telegram?.WebApp;

const timer = ref<number>(15);

onMounted (()=> {
  timer.value = 3;
  countTimer();
})

function countTimer(){
  const interval = setInterval(() => {
    if (timer.value > 1) {
      timer.value--;  
    } else {
      emit('close');
      clearInterval(interval);
    }
  }, 1000);
}

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

function Close () {
  triggerHapticFeedback('light');
  emit('close')
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "notfound-room-background",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (Close()))
    }),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)(`main.text_not_found1`)), 1)
              ]),
              _createElementVNode("div", {
                class: "notfound-close",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (Close()))
              }, _cache[2] || (_cache[2] = [
                _createElementVNode("img", {
                  src: _imports_0,
                  class: "close"
                }, null, -1)
              ]))
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)(`main.text_not_found2`)), 1)
            ])
          ]),
          _cache[3] || (_cache[3] = _createElementVNode("img", {
            src: _imports_1,
            class: "background-logo no-image-animation"
          }, null, -1))
        ])
      ])
    ])
  ], 64))
}
}

})