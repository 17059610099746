import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_daily_bonus = _resolveComponent("daily-bonus")!
  const _component_header_mini = _resolveComponent("header-mini")!
  const _component_header_main = _resolveComponent("header-main")!
  const _component_rooms_list = _resolveComponent("rooms-list")!
  const _component_top_players_list = _resolveComponent("top-players-list")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.showPopup)
      ? (_openBlock(), _createBlock(_component_daily_bonus, {
          key: 0,
          onClose: _ctx.handlePopupClose
        }, null, 8, ["onClose"]))
      : _createCommentVNode("", true),
    (_ctx.isCollapsed)
      ? (_openBlock(), _createBlock(_component_header_mini, {
          key: 1,
          animation: true
        }))
      : _createCommentVNode("", true),
    _createVNode(_component_header_main),
    _createVNode(_component_rooms_list, {
      max_len: 3,
      all: false,
      random: false,
      myroom: true
    }),
    _createVNode(_component_top_players_list)
  ], 64))
}