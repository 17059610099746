import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/wallet/wallet-background-vector.svg'


const _hoisted_1 = { class: "connect-wallet-label" }
const _hoisted_2 = { class: "wallet-component-wrapper" }

import WalletComponent from "@/components/wallet/WalletComponent.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'ConnectWalletView',
  setup(__props) {


const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/profile')
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    _cache[0] || (_cache[0] = _createElementVNode("img", {
      class: "background-vector",
      src: _imports_0
    }, null, -1)),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_unref(t)(`deposit_and_withdraw.connect_wallet_label`)), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(WalletComponent)
    ])
  ]))
}
}

})