<script setup lang="ts">
import {onMounted, ref} from "vue";
import store from "@/store";
import {ConnectedWallet} from "@tonconnect/ui";
import TonWeb from "tonweb";
import {UserService} from "@/api/userService";
import router from "@/router";

import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const walletConnected = ref(false);
onMounted(() => {
  if (store.getters.USER.address) {
    walletConnected.value = true;
  }
})

async function walletConnect() {
  if (!store.getters.USER.address) {
    if (store.getters.TON_CONNECT_UI.wallet) {
      triggerHapticFeedback('light');
      await store.getters.TON_CONNECT_UI.disconnect();
    }
    await store.getters.TON_CONNECT_UI.connectWallet().then((wallet: ConnectedWallet) => {
      const address = wallet.account.address;
      triggerHapticFeedback('light');
      const userService = new UserService();
      userService.connectAddress(address)
          .then(response => response.json())
          .then(data => {
            store.commit('SET_USER', data)
            router.push('/profile')
          });
    });
  }
  else {
    showDisconnectPop.value = true;
  }
}

const showDisconnectPop = ref(false);

const CloseDesconnectPopup = () => {
  showDisconnectPop.value = false;
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <div class="connect-wallet" @click="walletConnect">
    <div class="connect-wallet-info">
      <img src="@/assets/wallet/ton_wallet_connector.svg">
      <p class="connect-wallet-info-label">{{ walletConnected ? store.getters.USER.address : t(`deposit_and_withdraw.link_wwallet`) }}</p>
    </div>
    <div class="connect-wallet-arrow-wrapper">
      <img class="connect-wallet-arrow" :src="walletConnected ? require('@/assets/wallet/wallet_connected_check.svg') : require('@/assets/tools/arrow-wallet.svg')">
    </div>
  </div>
  <!-- <disconnect-wallet v-if="showDisconnectPop"
   @close="CloseDesconnectPopup"
  /> -->
</template>

<style scoped>
.connect-wallet {
  background: #33A1E5;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
  display: flex;
  justify-content: space-between;
  padding: 10px 27px 10px 16px;
  align-items: center;
}

.connect-wallet-info {
  display: flex;
  align-items: center;
  overflow: hidden;
}

.connect-wallet-info-label {
  margin-left: 20px;
  font-weight: 500;
  font-size: 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.connect-wallet-arrow-wrapper {
  margin-left: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
