import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/logo/go_coin_24.svg'
import _imports_1 from '@/assets/logo/money_logo.svg'
import _imports_2 from '@/assets/logo/logo-background.svg'


const _hoisted_1 = { class: "user-card-container-2" }
const _hoisted_2 = { class: "user-card-left" }
const _hoisted_3 = { class: "user-card-left-up" }
const _hoisted_4 = { class: "user-balance" }
const _hoisted_5 = { class: "balance-sum-wrapper-gocoin" }
const _hoisted_6 = { class: "balance-sum" }
const _hoisted_7 = { class: "balance-sum-wrapper-redcoin" }
const _hoisted_8 = { class: "balance-sum" }
const _hoisted_9 = { class: "user-card-left-down" }
const _hoisted_10 = { class: "week-earn" }
const _hoisted_11 = { class: "user-name-edit" }
const _hoisted_12 = ["placeholder"]
const _hoisted_13 = {
  key: 0,
  class: "user-info-week-earn"
}
const _hoisted_14 = { class: "week-earn-label" }
const _hoisted_15 = { class: "user-card-right" }
const _hoisted_16 = ["src"]
const _hoisted_17 = ["src"]

import { RoomService } from "@/api/roomService";
import {ref, onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';
import store from "../store";
import {formatNumber} from "../tools/tools";
import { formatNumberByZiro } from "../tools/tools";
import { ApiService } from "@/api/apiService";
import router from "@/router";
import {MiniAppsEventListener, number, off, on, postEvent} from "@telegram-apps/sdk";


export default /*@__PURE__*/_defineComponent({
  __name: 'EditNameWrapper',
  props: {
    showEditName: { type: Boolean },
    weekEarnLabel: { type: Boolean },
    backgroundClass: {}
  },
  emits: ['closeChat'],
  setup(__props: any, { emit: __emit }) {

const { t } = useI18n();

const inputRef = ref<HTMLInputElement | null>(null);

const props = __props

const userData = window.Telegram.WebApp.initDataUnsafe;

const emit = __emit;

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
    emit('closeChat'); 
};

const mainButtonPressed: MiniAppsEventListener<'main_button_pressed'> = payload => {
    emit('closeChat'); 
    postEvent('web_app_setup_main_button', {
        is_progress_visible: true,
    })
}

onMounted(() => {
    if(inputRef.value) {
        inputRef.value.focus();
    }
    store.commit('SET_FOCUSED', true)
    postEvent('web_app_setup_back_button', { is_visible: true});
    postEvent('web_app_setup_main_button', {
      is_visible: true,
      is_active: true,
      is_progress_visible: false,
      text: 'Изменить имя',
    })
    on('back_button_pressed', listener);
    on('main_button_pressed', mainButtonPressed);
});

onUnmounted(() => {
  store.commit('SET_FOCUSED', false)
  postEvent('web_app_setup_back_button', { is_visible: false});
  postEvent('web_app_setup_main_button', {
    is_visible: false,
    is_active: false
  })
  off('back_button_pressed', listener);
  off('main_button_pressed', mainButtonPressed);
})



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, _toDisplayString(_unref(t)(`profile.balance`)), 1),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("p", _hoisted_6, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.balance)), 1),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "balance-sum-logo-wrapper" }, [
            _createElementVNode("img", {
              class: "balance-sum-logo",
              src: _imports_0
            })
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.bonus_balance)), 1),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "balance-sum-logo-wrapper" }, [
            _createElementVNode("img", {
              class: "balance-sum-logo",
              src: _imports_1
            })
          ], -1))
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("input", {
              ref_key: "inputRef",
              ref: inputRef,
              class: "text",
              id: "msg",
              type: "text",
              placeholder: _unref(userData).user?.username || 'noname'
            }, null, 8, _hoisted_12)
          ]),
          (_unref(store).getters.USER.week_earn)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createElementVNode("p", _hoisted_14, _toDisplayString(_unref(t)(`main.in`)) + " " + _toDisplayString(_unref(t)(`main.a_week`)) + ": ", 1),
                _createElementVNode("p", {
                  class: _normalizeClass(props.weekEarnLabel)
                }, _toDisplayString(_unref(store).getters.USER.week_earn > 0 ? '+' : '') + _toDisplayString(_unref(formatNumber)(_unref(store).getters.USER.week_earn)), 3),
                _cache[2] || (_cache[2] = _createElementVNode("div", { class: "balance-sum-logo-wrapper-mini-big" }, [
                  _createElementVNode("img", {
                    class: "balance-sum-logo-wrapper-mini",
                    src: _imports_1
                  })
                ], -1))
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_15, [
      (_unref(store).getters.USER.vip_status != 'first')
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "user-status no-image-animation",
            src: require(`@/assets/player/status/status-${_unref(store).getters.USER.vip_status}.svg`)
          }, null, 8, _hoisted_16))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(['user-logo-wrapper', _ctx.backgroundClass])
      }, [
        _createElementVNode("img", {
          class: "user-logo",
          src: _unref(store).getters.USER.photo_url ? _unref(ApiService).url + _unref(store).getters.USER.photo_url : require('@/assets/mock_user/user.svg')
        }, null, 8, _hoisted_17)
      ], 2),
      _createElementVNode("p", null, "id: " + _toDisplayString(_unref(store).getters.USER.id), 1)
    ]),
    _cache[3] || (_cache[3] = _createElementVNode("img", {
      src: _imports_2,
      class: "background-logo no-image-animation"
    }, null, -1))
  ]))
}
}

})