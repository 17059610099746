<script setup lang="ts">
import store from "@/store";
import router from "@/router";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import {onMounted, onUnmounted, computed, nextTick} from "vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const userData = (window as any).Telegram.WebApp.initDataUnsafe;

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1);
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true });
  on('back_button_pressed', listener);

  const oldLevel = store.getters.USER_OLD_LEVEL;

  nextTick(() => {

    const levelElem = document.getElementById('level');
    const levelMessageElem = document.getElementById('level-message');

    if (levelElem && levelMessageElem) {
      let level = 2;
      const vipStatus = store.getters.USER.vip_status;
      if (vipStatus === 'first') {
        level = 1;
      } else {
        switch (vipStatus) {
          case 'silver':
            level = 3;
            break;
          case 'gold':
            level = 4;
            break;
          default:
            level = 2;
        }
      }

      levelElem.innerText = level.toString();

      levelMessageElem.innerHTML = `${ t(`level_up.congr_1`) } ${level}${ t(`level_up.new_diz`) }`;
    } 

    const userLogoWrapper3 = document.querySelector('.user-logo-wrapper3') as HTMLElement;
    const userLogoWrapper4 = document.querySelector('.user-logo-wrapper4') as HTMLElement;

    if (userLogoWrapper3 && userLogoWrapper4) {
      const rect = userLogoWrapper3.getBoundingClientRect();
      const topPosition = rect.top;
      const leftPosition = rect.left;

      userLogoWrapper4.style.position = 'absolute'; 
      userLogoWrapper4.style.top = `${topPosition}px`;
      userLogoWrapper4.style.left = `${leftPosition}px`;
    }
  });
});

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false });
  off('back_button_pressed', listener);
});

const backgroundClass = computed(() => {
  switch (store.getters.USER.vip_status) {
    case 'gold':
      return 'gradient-gold';
    case 'silver':
      return 'gradient-silver';
    default:
      return 'background-white';
  }
});

const tg = (window as any).Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}
</script>

<template>
  <div class="level-up">
    <div class="up-pop">
      <img class="up-pop-img" src="@/assets/tools/close-modal.svg" @click="triggerHapticFeedback('soft'); router.push('/')">
    </div>
    <div class="center-pop">
      <div class="user-info">
        <div class="user-container">
          <img class="user-status no-image-animation" :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" v-if="store.getters.USER.vip_status != 'first'">
          <div :class="['user-logo-wrapper2', backgroundClass]">
            <div :class="['user-logo-wrapper3', backgroundClass]">
            </div>
          </div>
            <div :class="['user-logo-wrapper4', backgroundClass]">
              <img class="user-logo" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
            </div>
        </div>
        <div class="text-pop">
          <p class="text-pop-text" id="level-message" v-if="store.getters.USER.vip_status != 'first'">{{ t(`level_up.congr_1`) }} <span id="level">2</span>{{ t(`level_up.new_diz`) }}</p>
        </div>
      </div>
      </div>
      <div class="down-pop">
        <div class="down-pop-btn" @click="triggerHapticFeedback('soft'); router.push('/levels')">
          {{ t(`level_up.view_all_levels`) }}
        </div>
      </div>
  </div>
</template>

<style scoped>
.backblur {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  width: 100%;
}

.back-blur{
  max-width: 278px;
  width: 64.6512vw;
  max-height: 278px;
  height: 64.6512vw;
}

.center-pop {
  display: flex;
  justify-content: center;
  align-items: center;
}

.down-pop {
  display: flex;
  justify-content: center;
  align-items: center;
}

.down-pop-btn {
  background-color: #5C4E73;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--border-mixed);
  padding: 24px 90px;
}

.up-pop-img {
  width: 3.25581vw;
  height: 3.25581vw;
}

.level-up {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  background-color: #000000;
  position: absolute;
  width: 100%;
  max-width: 430px;
  left: 0;
  top: 0;
  z-index: 100;
  height: 100%;
}

.up-pop {
  display: flex;
  max-width: 374px;
  width: 86.97674vw;
  justify-content: end;
  align-items: center;
  left: 0;
  top: 0;
}

.text-pop {
  max-width: 358px;
  width: 83.25581vw;
  text-align: center;
  margin-top: 10px;
}

.gradient-gold {
  background: var(--gradient-gold);
}

.gradient-silver {
  background: var(--gradient-sliver);
}

.background-white {
  background: white;
}

.user-logo {
  border-radius: 50%;
  width: 158px;
  height: 158px;
  text-align: center;
  margin-left: 3px;
  z-index: 10;
}

.user-info {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.user-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-info-main {
  width: 100%;
  text-align: left;
  margin-top: 15px;
}

.user-info__username {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}

.user-status {
  height: 26px;
  z-index: 100;
}

.user-logo-wrapper4 {
  border-radius: 50%;
  width: 164px;
  height: 164px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 10;
  position: absolute;
  left: 50%;
  top: 0;
}

.user-logo-wrapper2 {
  border-radius: 50%;
  margin-top: -30px;
  width: 64.6512vw;
  height: 64.6512vw;
  filter: blur(30px);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  z-index: 10;
}

.user-logo-wrapper3 {
  border-radius: 50%;
  width: 164px;
  height: 164px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  z-index: 10;
  left: 50%;
  top: 0;
}
</style>