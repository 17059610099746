import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "settings-wrapper" }
const _hoisted_2 = { class: "settings-label springy-animation" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import HeaderMini from "@/components/header/HeaderMini.vue";
import settings from "@/views/settings";
import SettingsItem from "@/components/settings/SettingsItem.vue";
import DeleteAccountModal from "@/components/modals/DeleteAccountModal.vue";
import UserAgreementModal from "@/components/modals/UserAgreementModal.vue";
import PrivacyPolicy from "@/components/modals/PrivacyPolicy.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, nextTick, ref} from "vue";
import { useI18n } from 'vue-i18n';


export default /*@__PURE__*/_defineComponent({
  __name: 'SettingsView',
  setup(__props) {

const { t } = useI18n();

const tg = window.Telegram?.WebApp;

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  router.push('/');
};

const settingsContainer = ref<HTMLElement | null>(null);

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  scrollToTop()
  on('back_button_pressed', listener);
})

function scrollToTop() {
    if (settingsContainer.value) {
        nextTick(() =>{
          settingsContainer.value!.scrollTop = settingsContainer.value!.scrollHeight;
        })
    }
}

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      ref_key: "settingsContainer",
      ref: settingsContainer,
      class: "settings",
      style: _normalizeStyle({'margin-top': _unref(tg).isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + 20px)': 0})
    }, [
      _createVNode(HeaderMini),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_unref(t)('settings.settings')), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(settings), (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "setting-wrapper",
            key: i
          }, [
            (item.type == 'switch')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  _createVNode(SettingsItem, {
                    onClick: ($event: any) => (_unref(settings)[i].defaultState = !item.defaultState),
                    on: item.on,
                    off: item.off,
                    type: item.type,
                    label: item.label,
                    "default-state": item.defaultState
                  }, null, 8, ["onClick", "on", "off", "type", "label", "default-state"])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(SettingsItem, {
                    click: item.click,
                    type: item.type,
                    label: item.label
                  }, null, 8, ["click", "type", "label"])
                ]))
          ]))
        }), 128))
      ])
    ], 4),
    _createVNode(DeleteAccountModal),
    _createVNode(UserAgreementModal),
    _createVNode(PrivacyPolicy)
  ], 64))
}
}

})