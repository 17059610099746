<script setup lang="ts">
import router from "@/router";
import store from "@/store";
import {formatNumber} from "@/tools/tools";
import {ApiService} from "@/api/apiService";
import {defineProps, defineEmits, watch} from "vue"
import Player from "@/models/player";
import Room from "@/models/room";
import Game from "@/models/game";

const userData = window.Telegram.WebApp.initDataUnsafe;

const props = defineProps<{
  animation?: boolean,
  currentPlayer: Player,
  room: Room,
  player: Player,
  game: Game,
}>();

const webApp = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && webApp?.HapticFeedback) {
    webApp.HapticFeedback.impactOccurred(style);
  }
}

const emit = defineEmits(['openSettings', 'openBalance'])

function openSettings(style: string) {
  if (store.getters['isVibrationOn'] && webApp?.HapticFeedback) {
    webApp.HapticFeedback.impactOccurred(style);
  }
  emit('openSettings');
  
}

function openBalance (style: string) {
  if (store.getters['isVibrationOn'] && webApp?.HapticFeedback) {
    webApp.HapticFeedback.impactOccurred(style);
  }
  emit('openBalance');
}

</script>

<template>
  <div :class="{ 'header-wrapper': true, 'header-animation': animation}" :style="{top: webApp.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top))': 0}">
    <div class="header-wrapper__user-info" @click="triggerHapticFeedback('light'); router.push('/profile')">
      <img class="header-wrapper__user-image no-image-animation" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
      <div class="header-mini-username">{{ userData.user.username ?? "noname" }}</div>
      <img class="header-wrapper__user-status no-image-animation" :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" v-if="store.getters.USER.vip_status != 'first'">
    </div>
    <div v-if="props.player && props.player?.action_status != 'watcher'" :class="['header-wrapper__balance', {'header-wrapper__balance_margin': currentPlayer}]">
      <div class="header-balance" @click="openBalance('light')">
        <img class="header-balance-image no-image-animation" src="@/assets/logo/money_logo.svg">
        <p class="header-balance-sum">{{ formatNumber(props.player.game_deposit) }}</p>
      </div>
    </div>
    <div v-else :class="['header-wrapper__balance', {'header-wrapper__balance_margin': currentPlayer}]">
      <div class="header-balance" @click="triggerHapticFeedback('light'); router.push('/profile')">
        <p class="header-balance-sum">{{ formatNumber(store.getters.USER.bonus_balance) }}</p>
        <img class="header-balance-image no-image-animation" src="@/assets/logo/money_logo.svg">
      </div>
    </div>
    <div class="header-wrapper-settings" @click="openSettings('light')" v-if="room?.is_private == 1 && room?.created_by == store.getters.USER.id">
      <img class="icon-settings" src="@/assets/game/icon_settings.svg">
    </div>
  </div>
</template>

<style scoped>

.icon-settings {
  width: 27.5px;
  height: auto;
}

.header-wrapper-settings {
  display: flex;
  padding: 7px;
  border-radius: var(--border-mixed);
  background-color: #2A2B37;
  z-index: 101;
  height: 100%;
}

.header-wrapper {
  position: fixed;
  z-index: 100;
  top: 0;
  right: 0;
  left: 50%;
  translate: -50%;
  display: flex;
  justify-content: space-between;
  background-color: #15151d;
  padding: 6px 10px;
  border-radius: 50px;
  opacity: 0;
  animation: translateDown .3s ease-out .1s forwards;
  width: 91%;
}

.header-animation {
  animation: header-animation .2s ease-out;
}

@keyframes header-animation {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0px);
  }
}

.header-wrapper__user-image {
  width: 8.84vw;
  height: 8.84vw;
  margin-right: 20px;
  border: 1px solid #C6BDD4;
  border-radius: 100%;
  filter: drop-shadow(1px 2px 3px rgba(0, 0, 0, 0.4));
  margin-left: -5px;
}

.header-wrapper__user-info {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}

.header-wrapper__balance {
  display: flex;
}

.header-balance-image {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  height: 25px;
  width: 25px;
  margin-right: 5px;
  transition: 0.2s ease color;
}

.header-balance {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 18px;
}

.header-balance-sum {
  font-weight: 700;
  color: #BC0000;
}

.header-wrapper__user-status {
  margin-left: 11px;
}

.header-mini-username {
  max-width: 11ch;
  flex-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #C6BDD4;
  font-size: 18px;
}
</style>