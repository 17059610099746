<script setup lang="ts">
import HistoryList from "@/components/lists/history/HistoryList.vue";
import router from "@/router";
import store from "../store";
import { formatNumberByZiro } from "../tools/tools";
import { MiniAppsEventListener, off, on, postEvent } from "@telegram-apps/sdk";
import { computed, onMounted, onUnmounted, ref, nextTick, watch } from "vue";
import { ApiService } from "@/api/apiService";
import levelUp from "@/components/levelup/LevelUp.vue";
import { useI18n } from 'vue-i18n';
import { UserService } from "@/api/userService";
import {formatNumber} from "../tools/tools";

const { t } = useI18n();

const userData = window.Telegram.WebApp.initDataUnsafe;

const bonusBtn = ref(t(`profile.claim_bonus`));

const isBonusGeted = ref(false);

const intervalId = ref<ReturnType<typeof setInterval> | null>(null);

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.push('/');
};

const userService = new UserService;

const currentLevel = ref('1');
const oldLevel = ref(store.getters.USER.oldLevel);
const showLevelUpPopup = ref(false);
const last_bonus_at = ref(store.getters.USER.last_bonus_at);

// function updateCurrentLevel(vipStatus = store.getters.USER.vip_status) {
//   switch (vipStatus) {
//     case 'gold': currentLevel.value = '4'; break;
//     case 'silver': currentLevel.value = '3'; break;
//     case 'usual': currentLevel.value = '2'; break;
//     default: currentLevel.value = '1';
//   }
//   if (currentLevel.value > oldLevel.value) {
//     showLevelUpPopup.value = true;
//     store.commit('SET_OLD_LEVEL', currentLevel.value);
//   }
// }

async function updateUser() {
  try {
    const response = await userService.get();
    const data = await response.json();
    store.commit('SET_USER', data);
    last_bonus_at.value = store.getters.USER.last_bonus_at;
  } catch (error) {
    console.error('Failed to update user:', error);
  }
}

function isMoreThan24HoursAgo(dateString: string): boolean {
  const targetDate = new Date(dateString);
  const currentDate = new Date();
  return currentDate.getTime() - targetDate.getTime() > 24 * 60 * 60 * 1000;
}

function getRemainingTime(lastBonusAt: string) {
  const targetDate = new Date(lastBonusAt);
  const currentDate = new Date();
  const diff = 24 * 60 * 60 * 1000 - (currentDate.getTime() - targetDate.getTime());
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
  return { hours, minutes };
}

const updateBonusButton = () => {
  const { hours, minutes } = getRemainingTime(last_bonus_at.value);
  if (isMoreThan24HoursAgo(last_bonus_at.value)) {
    bonusBtn.value = t(`profile.claim_bonus`);
  } else {
    bonusBtn.value = hours > 0 ? `${hours}h ${minutes}min` : `${minutes}min`;
  }
};

async function getBonus() {
  if (isMoreThan24HoursAgo(last_bonus_at.value)) {
    try {
      await userService.getBonus(10000);
      await updateUser();
      updateBonusButton();
    } catch (error) {
      console.error('Failed to get bonus:', error);
    }
  }
}

const buttonStyle = computed(() => ({
  backgroundColor: isMoreThan24HoursAgo(last_bonus_at.value) ? '#5C4E73' : '#6E6976',
}));

onMounted(() => {
  updateBonusButton();
  postEvent('web_app_setup_back_button', { is_visible: true });
  on('back_button_pressed', listener);
  updateUser();

  if (window.Adsgram) {
    AdController.value = window.Adsgram.init({ blockId: "6749" });
  } else {
    console.error("AdsGram not found");
  }
  
  intervalId.value = setInterval(updateBonusButton, 60000);

  userService.checkSub()
    .then(response =>{
      if (response.status === 400){
        isBonusGeted.value = true;
      }
    })

  userService.checkBonusAds()
  .then(response =>{
      if (response.status === 204){
        goAds.value = true;
        console.log("getBonusAds 200 goAds.value:", goAds.value)
      }
      if (response.status === 400){
        goAds.value = false;
        console.log("getBonusAds 400 goAds.value:", goAds.value)
      }
    })

});

onUnmounted(() => {
  if (intervalId.value !== null) {
    clearInterval(intervalId.value);
  }
  postEvent('web_app_setup_back_button', { is_visible: false });
  off('back_button_pressed', listener);
});

watch(last_bonus_at, updateBonusButton);

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const backgroundClass = computed(() => {
  switch (store.getters.USER.vip_status) {
    case 'gold':
      return 'gradient-gold';
    case 'silver':
      return 'gradient-silver';
    default:
      return 'background-white';
  }
});

const weekEarnLabel = computed(() => {
  return store.getters.USER.week_earn > 0 ? 'week-earn-label-green' : 'week-earn-label-red'
});

function navigateToChannel(){
  if(isBonusGeted.value) return;
  const url = 'https://t.me/okgo_club';
  window.open(url, '_blank');
}

const AdController = ref<any>(null);
const goAds = ref();

async function showAdWithAdsgram() {

  if(goAds.value) {
    if (!AdController.value) {
    console.error("AdsGram AdController не инициализирован.");
    return;
    }

    try {
      const result = await AdController.value.show();
      if (result.done) {
        userService.getBonusAds(`${Math.floor(100000 + Math.random() * 900000)}`)
        .then(response =>{
            if (response.status === 204){
              console.log("getBonusAds 200 goAds.value:", goAds.value)
            }
            if (response.status === 400){
              console.log("getBonusAds 400 goAds.value:", goAds.value)
            }
          })
          console.log('ADS DONE')
      } else {
        // console.log("Реклама была пропущена:", result);
      }
    } catch (error) {
      console.error("Error AdsGram:", error);
    }
  }
  else {
    alert("Вся реклама просмотрена, возвращайтесь завтра")
  }
}
</script>


<template>
   <level-up v-if="showLevelUpPopup"/>
  <div v-else class="profile-wrapper">
    <div class="logo-wrapper">
      <img src="@/assets/logo/logo.svg" class="no-image-animation">
    </div>
    <div class="user-card-container">
      <div class="user-card-left">
        <div class="user-card-left-up">
          <p class="user-balance">{{ t(`profile.balance`) }}</p>
          <div class="balance-sum-wrapper-gocoin">
            <p class="balance-sum">{{ formatNumberByZiro(store.getters.USER.balance) }}</p>
            <div class="balance-sum-logo-wrapper">
              <img class="balance-sum-logo" src="@/assets/logo/go_coin_24.svg">
            </div>
          </div>
          <div class="balance-sum-wrapper-redcoin">
            <p class="balance-sum">{{ formatNumberByZiro(store.getters.USER.bonus_balance) }}</p>
            <div class="balance-sum-logo-wrapper">
              <img class="balance-sum-logo" src="@/assets/logo/money_logo.svg">
            </div>
          </div>
        </div>
        <div class="user-card-left-down">
          <div class="week-earn">
            <p class="user-info-username">{{ userData.user!.username ?? "noname" }}</p>
            <div class="user-info-week-earn" v-if="store.getters.USER.week_earn">
              <p class="week-earn-label">{{ t(`main.in`) }}&nbsp;{{ t(`main.a_week`) }}:&nbsp;</p>
              <p :class="weekEarnLabel">{{ store.getters.USER.week_earn > 0 ? '+' : '' }}{{ formatNumber(store.getters.USER.week_earn) }}</p>
              <div class="balance-sum-logo-wrapper-mini-big">
                <img class="balance-sum-logo-wrapper-mini" src="@/assets/logo/money_logo.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-card-right">
        <img class="user-status no-image-animation" :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" v-if="store.getters.USER.vip_status != 'first'">
        <div :class="['user-logo-wrapper', backgroundClass]">
          <img class="user-logo" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
        </div> 
      </div>
      <img src="@/assets/logo/logo-background.svg" class="background-logo no-image-animation">
    </div>
    <div class="coin-exchange" @click="triggerHapticFeedback('light'); router.push('/withrdaw_soon')">
      <div class="coin-exchange-wrapper">
        <div>
          <p class="balance-action-text">{{ t(`profile.coin_exchange`) }}</p>
        </div>
        <div class="coin-exchange-arrow">
          <img src="@/assets/tools/all-transaction-arrow.svg">
        </div>
        <img src="@/assets/friends/coin_exchange.svg" class="coin_exchange-img">
      </div>
    </div>
    <div class="levels-and-balance-actions">
      <div class="levels-button" @click="triggerHapticFeedback('light'); router.push('/withrdaw_soon')">
        <p class="levels-text">{{ t(`profile.levels`) }}</p>
      </div>
      <div class="balance-actions">
        <div class="top-up-button" @click="triggerHapticFeedback('light'); router.push('/withrdaw_soon')">
          <p class="balance-action-text">{{ t(`profile.deposit`) }}</p>
          <div class="balance-topup-main">
            <p class="balance-topup-main-plus">+</p>
          </div>
        </div>
        <div class="withdraw-button" @click="triggerHapticFeedback('light'); router.push('/withrdaw_soon')">
          <p class="balance-action-text">{{ t(`profile.withdraw`) }}</p>
          <div class="balance-topup-main">
            <p class="balance-topup-main-plus">-</p>
          </div>
        </div>
      </div>
    </div>
    <div class="all-transaction" @click="router.push('/withrdaw_soon')">
      <div class="all-transaction-wrapper">
        <div>
          <p class="balance-action-text">{{ t(`profile.transaction_history`) }}</p>
        </div>
        <div class="all-transaction-arrow">
          <img src="@/assets/tools/all-transaction-arrow.svg">
        </div>
      </div>
    </div>
    <div class="bonus-wrapper">
      <div class="bonus-wrapper-up">
        <p>{{ t(`profile.daily_bonus`) }}</p>
      </div>
      <div class="bonus-wrapper-center">
        <p class="ten-k">+10 000</p>
        <img src="@/assets/logo/money_logo.svg" class="red-coin">
      </div>
      <div class="bonus-wrapper-dwn" :style="buttonStyle" @click="getBonus()">
        {{ bonusBtn }}
      </div>
      <img src="@/assets/logo/logo-background-2.svg" class="background-logo no-image-animation">
    </div>
    <div class="bonus-wrapper">
      <div class="bonus-wrapper-center-box">
        <p class="box-text">{{ t(`profile.box`) }}</p>
      </div>
      <div class="bonus-wrapper-dwn-box" @click="triggerHapticFeedback('soft'); router.push('/friends')">
        {{ t(`profile.friends`) }}
        <img src="@/assets/friends/friend.svg">
      </div>
      <img src="@/assets/friends/open-red-box-dark.svg" class="box-img ">
      <img src="@/assets/logo/logo-background-2.svg" class="background-logo no-image-animation">
    </div>
    <div class="sub-tg" @click="navigateToChannel">
      <div class="sub-tg-wrapper">
        <div class="sub-tg-icon-wrapper">
          <img class="sub-tg-icon" src="@/assets/friends/Sub_tg_icon.svg">
        </div>
        <div class="sub-tg-text">
          <p class="sub-tg-text-text">{{ t(`profile.sub_tg`) }}</p>
          <div class="sub-tg-bonus">
            <div class="sub-tg-bonus-sum">
              <p class="sub-tg-bonus-sum-ten">+10 000</p>
            </div>
            <div class="sub-tg-bonus-coin-wrapper"></div>
            <img class="sub-tg-bonus-coin" src="@/assets/logo/money_logo.svg">
          </div>
        </div>
        <div class="all-transaction-arrow">
          <img v-if="isBonusGeted" src="@/assets/checkers/check.svg">
          <img v-else src="@/assets/friends/Profile_arrow.svg">
        </div>
      </div>
    </div>
    <div class="watch-adv" @click="showAdWithAdsgram">
      <div class="sub-tg-wrapper">
        <div class="sub-tg-icon-wrapper">
          <img class="sub-tg-icon" src="@/assets/friends/Watch_add.svg">
        </div>
        <div class="sub-tg-text">
          <p class="sub-tg-text-text">{{ t(`profile.watch_add`) }}</p>
          <div class="sub-tg-bonus">
            <div class="sub-tg-bonus-sum">
              <p class="sub-tg-bonus-sum-ten">+1 000</p>
            </div>
            <div class="sub-tg-bonus-coin-wrapper"></div>
            <img class="sub-tg-bonus-coin" src="@/assets/logo/money_logo.svg">
          </div>
        </div>
        <div class="all-transaction-arrow">
          <img src="@/assets/friends/Profile_arrow.svg">
        </div>
      </div>
    </div>
    <div class="settings" @click="router.push('/settings')">
      <div class="settings-wrapper">
        <div class="settings-icon-wrapper">
          <img class="settings-icon" src="@/assets/friends/Settings_icon.svg">
        </div>
        <div class="settings-action-text-wrapper">
          <p class="balance-action-text">{{ t(`settings.settings`) }}</p>
        </div>
        <div class="all-transaction-arrow">
          <img src="@/assets/friends/Profile_arrow.svg">
        </div>
      </div>
    </div>
    <div class="game-history">
      <history-list/>
    </div>
  </div>
</template>

<style scoped>

.profile-wrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: 59px;
}

.user-card-container {
  display: flex;
  margin-top: 10px;
  padding: 21px 20px;
  background-color: #15151E;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: var(--border-mixed);
  position: relative;
  justify-content: space-between;
}

.user-card-left {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.user-card-left-up {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.user-balance {
  font-size: 18px;
  font-weight: 600;
  color: #B9B9B9;
  display: flex;
}

.balance-sum-wrapper-gocoin {
  margin-top: 10px;
}

.balance-sum-wrapper-gocoin, .balance-sum-wrapper-redcoin {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.balance-sum {
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
}

.balance-sum-logo-wrapper {
  display: flex;
  align-items: center;
}

.balance-sum-logo {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  margin-left: 5px;
}

.user-card-left-down {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.week-earn {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-info-username {
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: start;
}

.user-info-week-earn {
  display: flex;
  align-items: center;
}

.week-earn-label {
  font-size: 3vw;
  font-weight: 500;
  color: #B9B9B9;
}

.balance-sum-logo-wrapper-mini-big {
  display: flex;
  justify-content: center;
  margin-left: 2px;
}

.balance-sum-logo-wrapper-mini {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  width: 15px;
  height: 15px;
}

.user-card-right {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: end;
}

.user-status {
  height: 25px;
}

.user-logo-wrapper {
  border-radius: 50%;
  width: 128px;
  height: 128px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-logo {
  border-radius: 50%;
  width: 125px;
  height: 125px;
  filter: drop-shadow(0px 10px 125px rgba(118, 81, 140, 0.4));
}

.coin-exchange {
  margin-top: 15px;
}

.coin-exchange-wrapper {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  background: #15151E;
  border-radius: var(--border-mixed);
  font-size: 14px;
  font-weight: 500;
  padding: 30px 19px;
  box-shadow: 0 4px 48px rgba(21,21,30,.5);
}

.coin_exchange-img {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.levels-and-balance-actions{
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  height: 100%;
  gap: 15px;
}

.levels-button{
  background-size: cover;
  background-image: url(/src/assets/background/background-levels.svg);
  flex-direction: column;
  background-color: #15151E;
  border-radius: var(--border-mixed);
  text-align: left;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
}

.levels-text{
  font-weight: 500;
  font-size: 18px;
  margin-left: 18px;
  margin-top: 17px;
  margin-right: 50px;
}

.balance-actions {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
}

.top-up-button {
  border-radius: var(--border-mixed);
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  background: #15151E;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  padding: 21px 16px;
}

.balance-action-text {
  font-weight: 500;
  font-size: 18px;
}

.balance-topup-main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #A5A5A6;
  width: 40px;
  height: 40px;
  z-index: 5;
}

.balance-topup-main-plus {
  text-align: center;
  color: #A5A5A6;
}

.withdraw-button {
  border-radius: var(--border-mixed);
  display: flex;
  text-align: left;
  justify-content: space-between;
  align-items: center;
  background: #15151E;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  padding: 21px 16px;
}

.all-transaction {
  margin-top: 15px;
}

.all-transaction-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #15151E;
  border-radius: var(--border-mixed);
  font-size: 14px;
  font-weight: 500;
  padding: 30px 19px;
  box-shadow: 0 4px 48px rgba(21,21,30,.5);
}

.bonus-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  max-width: 391px;
  border-radius: var(--border-mixed);
  background-color: #15151E;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 10px;
  padding-right: 20px;
  margin-top: 20px;
  overflow: hidden;
  max-height: 226px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.18s forwards;
}

.bonus-wrapper-up {
  width: 100%;
  text-align: left;
  color: #B9B9B9;
  font-size: 18px;
  font-weight: 500;
}

.bonus-wrapper-center {
  display: flex;
  align-items: center;
  margin-top: 12px;
  font-size: 30px;
  font-weight: 700;
  gap: 3.95vw;
}

.bonus-wrapper-dwn {
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;
  border-radius: var(--border-mixed);
  z-index: 2;
}

.bonus-wrapper-dwn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 48px;
  padding-top: 18px;
  padding-bottom: 18px;
  width: 100%;
  background-color: #977BCD;
  border-radius: var(--border-mixed);
  z-index: 2;
  gap: 21px;
  z-index: 4;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

.bonus-wrapper-center-box {
  width: 100%;
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: end;
  z-index: 5;
}

.box-text {
  max-width: 160px;
  font-size: 17px;
  text-align: start;
}

.box-img {
  position: absolute;
  z-index: 3;
  animation: none;
  transform: rotate(14deg) scale(-1, 1);
  filter: drop-shadow(-2px 19px 18px rgba(0, 0, 0, 0.25));
  width: 168px;
  left: 6px;
  top: 16px;
}

.gradient-gold {
  background: var(--gradient-gold);
}

.gradient-silver {
  background: var(--gradient-sliver);
}

.background-white {
  background: white;
}

.all-transaction-arrow {
  display: flex;
  width: 31px;
  justify-content: end;
}

.logo-wrapper {
  margin-top: 3%;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.user-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.12s forwards;
}

.user-info-main {
  width: 100%;
  text-align: left;
  margin-top: 15px;
}

.user-info__username {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.14s forwards;
}

.user-info__balance {
  display: flex;
  align-items: center;
  margin-top: 15px;
  justify-content: center;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.16s forwards;
}

.user-info__balance-label {
  font-size: 16px;
  font-weight: 500;
  margin-right: 5px;
}

.user-info__balance-money {
  font-size: 16px;
  font-weight: 700;
  margin-right: 5px;
}

.user-info__balance-money-logo {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
}

.game-history {
  margin-top: 15px;
  margin-bottom: 100px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.sub-tg, .watch-adv {
  margin-top: 15px;
}

.inactive {
  opacity: 0.4;
}

.sub-tg-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #15151E;
  border-radius: var(--border-mixed);
  padding: 8px 19px;
  box-shadow: 0 4px 48px rgba(21,21,30,.5);
}

.sub-tg-text {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 5px;
}

.sub-tg-text-text {
  font-weight: 500;
  font-size: 16px;
  color: #B9B9B9;
  max-width: 227px;
  text-align: start;
}

.sub-tg-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sub-tg-icon {
  width: 38px;
  max-width: 38px;
  height: 38px;
  max-height: 38px;
}

.sub-tg-bonus {
  display: flex;
  align-items: center;
}

.sub-tg-bonus-sum {
  font-size: 18px;
  font-weight: 600;
}

.sub-tg-bonus-coin-wrapper {
  display: flex;
  align-items: center;
}

.sub-tg-bonus-coin {
  margin-left: 5px;
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
}

.settings {
  margin-top: 15px;
}

.settings-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #15151E;
  border-radius: var(--border-mixed);
  padding: 22px 19px;
  box-shadow: 0 4px 48px rgba(21,21,30,.5);
}

.settings-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.settings-icon {
  width: 38px;
  max-width: 38px;
  height: 38px;
  max-height: 38px;
}

.settings-action-text-wrapper {
  width: 66%;
  text-align: start;
}

</style>