import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/tools/close-modal.svg'
import _imports_1 from '@/assets/tutorial_video/hello.mp4'
import _imports_2 from '@/assets/tutorial_video/add_red_coin.mp4'
import _imports_3 from '@/assets/tutorial_video/choose_room.mp4'
import _imports_4 from '@/assets/tutorial_video/table.mp4'
import _imports_5 from '@/assets/tutorial_video/tournaments.mp4'


const _hoisted_1 = { class: "tutor-wrapper" }
const _hoisted_2 = { class: "close-absolute" }
const _hoisted_3 = { class: "description-tutor" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 2 }
const _hoisted_7 = { key: 3 }
const _hoisted_8 = { key: 4 }
const _hoisted_9 = { class: "button-tutor" }
const _hoisted_10 = { class: "progress-line" }
const _hoisted_11 = ["width", "height"]
const _hoisted_12 = ["fill"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }

import { ref, computed, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const totalSteps = 5;


export default /*@__PURE__*/_defineComponent({
  __name: 'TutorialView',
  emits: ['close'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();

const emit = __emit; 

const currentStep = ref(0);

const nextStep = () => {
  if (currentStep.value < totalSteps - 1) {
    currentStep.value++;
  }
};

const prevStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
};

const videoTransform = computed(() => {
  return {
    transform: `translateX(${currentStep.value === 0 ? 0 : currentStep.value * -82.02}vw)`,
    transition: 'transform 0.4s ease',
  };
});


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _imports_0,
        onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
      })
    ]),
    _createElementVNode("div", {
      class: "video",
      style: _normalizeStyle(videoTransform.value)
    }, _cache[3] || (_cache[3] = [
      _createElementVNode("video", {
        autoplay: "",
        loop: "",
        muted: "",
        playsinline: "",
        class: "video1"
      }, [
        _createElementVNode("source", { src: _imports_1 })
      ], -1),
      _createElementVNode("video", {
        autoplay: "",
        loop: "",
        muted: "",
        playsinline: "",
        class: "video1"
      }, [
        _createElementVNode("source", { src: _imports_2 })
      ], -1),
      _createElementVNode("video", {
        autoplay: "",
        loop: "",
        muted: "",
        playsinline: "",
        class: "video1"
      }, [
        _createElementVNode("source", { src: _imports_3 })
      ], -1),
      _createElementVNode("video", {
        autoplay: "",
        loop: "",
        muted: "",
        playsinline: "",
        class: "video1"
      }, [
        _createElementVNode("source", { src: _imports_4 })
      ], -1),
      _createElementVNode("video", {
        autoplay: "",
        loop: "",
        muted: "",
        playsinline: "",
        class: "video1"
      }, [
        _createElementVNode("source", { src: _imports_5 })
      ], -1)
    ]), 4),
    _createElementVNode("div", _hoisted_3, [
      (currentStep.value === 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_unref(t)('tutorial.text_hello')), 1))
        : _createCommentVNode("", true),
      (currentStep.value === 1)
        ? (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_unref(t)('tutorial.text_add_redcoin')), 1))
        : _createCommentVNode("", true),
      (currentStep.value === 2)
        ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_unref(t)('tutorial.text_choose_room')), 1))
        : _createCommentVNode("", true),
      (currentStep.value === 3)
        ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_unref(t)('tutorial.text_table')), 1))
        : _createCommentVNode("", true),
      (currentStep.value === 4)
        ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_unref(t)('tutorial.text_tournaments')), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_9, [
      (currentStep.value === 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "button-back",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (emit('close')))
          }, [
            _createElementVNode("p", null, _toDisplayString(_unref(t)('tutorial.button_close')), 1)
          ]))
        : _createCommentVNode("", true),
      (currentStep.value > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "button-back",
            onClick: prevStep
          }, [
            _createElementVNode("p", null, _toDisplayString(_unref(t)('tutorial.button_back')), 1)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_10, [
        (_openBlock(), _createElementBlock(_Fragment, null, _renderList(totalSteps, (step, index) => {
          return _createElementVNode("svg", {
            key: index,
            width: currentStep.value === index ? 9 : 5,
            height: currentStep.value === index ? 9 : 5,
            viewBox: "0 0 5 5",
            xmlns: "http://www.w3.org/2000/svg"
          }, [
            _createElementVNode("circle", {
              cx: "2.5",
              cy: "2.5",
              r: "2.5",
              fill: currentStep.value >= index ? '#AD90E4' : '#B9B9B9'
            }, null, 8, _hoisted_12)
          ], 8, _hoisted_11)
        }), 64))
      ]),
      (currentStep.value <=3)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "button-next",
            onClick: nextStep
          }, [
            (currentStep.value <=3)
              ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_unref(t)('tutorial.button_next')), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_14, _toDisplayString(_unref(t)('tutorial.button_close')), 1))
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 3,
            class: "button-next",
            onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('close')))
          }, [
            _createElementVNode("p", null, _toDisplayString(_unref(t)('tutorial.button_close')), 1)
          ]))
    ])
  ]))
}
}

})