import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "menu"
}

import menu from "@/components/navigation/menu";
import NavBarItem from "@/components/navigation/NavBarItem.vue";
import router from "@/router";
import store from "@/store";


export default /*@__PURE__*/_defineComponent({
  __name: 'NavBar',
  setup(__props) {

function push(path: string) {
  router.push(path);
  triggerHapticFeedback('light');
  window.scrollTo(0, 0);
}

function getWidth() {
  return window.screen.width;
}

function isShow(item: any) {
  if (item.hide_small_devices === true) {
    return getWidth() >= 430;
  }
  return true;
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}


return (_ctx: any,_cache: any) => {
  return (!_unref(store).getters.FOCUSED)
    ? (_openBlock(), _createElementBlock("nav", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(menu).filter((menuItem) => isShow(menuItem)), (item, i) => {
          return (_openBlock(), _createElementBlock("div", {
            key: i,
            class: "menu-bar-item"
          }, [
            (_openBlock(), _createBlock(NavBarItem, {
              key: getWidth,
              onClick: ($event: any) => (push(item.path[0])),
              image: item.image,
              label: item.label,
              path: item.path
            }, null, 8, ["onClick", "image", "label", "path"]))
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}
}

})