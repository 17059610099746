import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, vModelText as _vModelText, withDirectives as _withDirectives, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/tools/close-modal.svg'
import _imports_1 from '@/assets/logo/logo-background.svg'


const _hoisted_1 = { class: "chips-popup" }
const _hoisted_2 = { class: "chips-wrapper" }
const _hoisted_3 = { class: "chips-wrapper-card" }
const _hoisted_4 = { class: "chips-up" }
const _hoisted_5 = { class: "chips-up-up" }
const _hoisted_6 = {
  key: 0,
  class: "chips-for-game"
}
const _hoisted_7 = {
  key: 1,
  class: "chips-for-game"
}
const _hoisted_8 = {
  key: 0,
  class: "chips-count-for-game"
}
const _hoisted_9 = {
  key: 1,
  class: "chips-count-for-game"
}
const _hoisted_10 = { class: "chips-center" }
const _hoisted_11 = { class: "chips-center-up" }
const _hoisted_12 = { class: "chips-center-lft" }
const _hoisted_13 = { class: "chips-center-lft-up" }
const _hoisted_14 = { class: "chips-center-lft-dwn" }
const _hoisted_15 = { key: 0 }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = {
  class: "chips-min",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_18 = ["fill"]
const _hoisted_19 = { class: "chips-center-rgt" }
const _hoisted_20 = { class: "chips-center-rgt-up" }
const _hoisted_21 = { class: "chips-center-rgt-dwn" }
const _hoisted_22 = {
  class: "chips-min",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_23 = ["fill"]
const _hoisted_24 = { class: "chips-center-dwn" }
const _hoisted_25 = {
  class: "chips",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}
const _hoisted_26 = ["fill"]
const _hoisted_27 = { class: "chips-down" }
const _hoisted_28 = { class: "chips-slider" }
const _hoisted_29 = { class: "chips-count-timer" }
const _hoisted_30 = { class: "chips-wrapper-btns" }

import {ref, computed, onMounted, watch} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import Room from "@/models/room";
import router from "@/router";
import { formatNumberByZiro } from "@/tools/tools";
import Player from "@/models/player";
import { RoomService } from "@/api/roomService";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChipsPopup',
  props: {
    player: {},
    room: {},
    joinRoom: { type: Function },
    middletGame: { type: Boolean },
    timeToUp: { type: Boolean },
    currentCase: {}
  },
  emits: ['updateValues', 'close'],
  setup(__props: any, { emit: __emit }) {

_useCssVars(_ctx => ({
  "a6d592f8": (getColorByStatus.value)
}))


const { t } = useI18n();

const props = __props

const emit = __emit;

const getColorByStatus = computed(() => {
  switch (store.getters.USER.vip_status) {
    case "first":
    case "usual":
      return "#CBC0DF";
    case "silver":
      return "var(--gradient-silver-thumb)";
    case "gold":
      return "var(--gradient-gold-thumb)";
    default:
      return "#CBC0DF";
  }
});

const min = props.room.big_blind_bet * 100;
const max = props.room.big_blind_bet * 100*5;

const tg = window.Telegram?.WebApp;

const inputValue = ref<number>(min);

const timer = ref<number>(15);

const roomService = new RoomService();

const addMyDeposit = ref(false);

const result = ref(0);


function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const handleInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  triggerHapticFeedback('light');

  const newValue = Math.max(Number(target.value), min);

  inputValue.value = newValue;

  
};

function startTheGame(){
    const value = inputValue.value;
    // emit('close');
    if (value > store.getters.USER.bonus_balance) return;
    if (value <= max) {
      roomService.setDeposit(value, props.player.user_id, props.room.id)
      .then(response => {
          if (response.status == 402) {
            router.push({
              path: '/rooms',
              query: { withBalancePopup: 'true' }
            });
            return;
          }
          props.joinRoom();
      });
    }
    
}

function addBalance(){
  result.value = inputValue.value + props.player?.game_deposit;
    if (result.value > store.getters.USER.bonus_balance) return;
    if (result.value <= max) {
      addMyDeposit.value = true;
      emit('updateValues', { result: result.value, addMyDeposit: addMyDeposit.value });
      emit('close');
    }
}

const iconColor = computed(() => {
  if (props.room.coin_type == 'go_coin') return "#7861A5"; 
  if (props.room.coin_type == 'red_coin') return "#D14A4A";
  return "#D14A4A";
});

onMounted(() =>{
  const slider = document.getElementById('slider') as HTMLInputElement;
  slider.step = (max / 100).toString();
  inputValue.value = min;
  timer.value = 15;
  countTimer();
})

// watch(() => props.currentCase,(newCase) => {
//   if (newCase === 'show_down' && addMyDeposit) {
//     addMyDeposit.value = false;
//     console.log('result.value watch:', result.value)
//       roomService.setDeposit(result.value, props.player.user_id)
//       .then(response => {
//           if (response.status == 402) {
//             emit('close');
//             return;
//           }});
//       result.value = 0;
//       emit('close');
//     }
// });

function countTimer(){
  const interval = setInterval(() => {
    if (timer.value > 1) {
      timer.value--;  
    } else {
      emit('close'); 
      clearInterval(interval); 
    }
  }, 1000);
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            (_ctx.timeToUp)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("p", null, _toDisplayString(_unref(t)(`chips_popup.top_up_redcoins`)), 1)
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("p", null, _toDisplayString(_unref(t)(`chips_popup.go_coins`)), 1)
                ])),
            _createElementVNode("div", {
              class: "close",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (emit('close')))
            }, _cache[7] || (_cache[7] = [
              _createElementVNode("img", {
                src: _imports_0,
                class: "close"
              }, null, -1)
            ]))
          ]),
          (_ctx.timeToUp || _ctx.middletGame)
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)(`chips_popup.top_up_redcoins2`)), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)(`chips_popup.chose_go_coins`)), 1)
              ]))
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)(`chips_popup.balance`)), 1)
              ]),
              _createElementVNode("div", _hoisted_14, [
                (props.room.coin_type == 'red_coin')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_15, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.bonus_balance)), 1))
                  : (_openBlock(), _createElementBlock("p", _hoisted_16, _toDisplayString(_unref(formatNumberByZiro)(_unref(store).getters.USER.balance)), 1)),
                (_openBlock(), _createElementBlock("svg", _hoisted_17, [
                  _createElementVNode("path", {
                    d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                    fill: iconColor.value
                  }, null, 8, _hoisted_18)
                ]))
              ])
            ]),
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("div", _hoisted_20, [
                _createElementVNode("p", null, _toDisplayString(_unref(t)(`chips_popup.minimun_bet`)), 1)
              ]),
              _createElementVNode("div", _hoisted_21, [
                _createElementVNode("p", null, _toDisplayString(_unref(formatNumberByZiro)(min)), 1),
                (_openBlock(), _createElementBlock("svg", _hoisted_22, [
                  _createElementVNode("path", {
                    d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                    fill: iconColor.value
                  }, null, 8, _hoisted_23)
                ]))
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_24, [
            (props.room.coin_type == 'red_coin')
              ? (_openBlock(), _createElementBlock("p", {
                  key: 0,
                  style: _normalizeStyle({ color: (_unref(store).getters.USER.bonus_balance < inputValue.value || (inputValue.value + props.player.game_deposit) > max) ? 'red' : 'white' })
                }, _toDisplayString(_unref(formatNumberByZiro)(inputValue.value)), 5))
              : (_openBlock(), _createElementBlock("p", {
                  key: 1,
                  style: _normalizeStyle({ color: _unref(store).getters.USER.balance < inputValue.value   ? 'red' : 'white' })
                }, _toDisplayString(_unref(formatNumberByZiro)(inputValue.value)), 5)),
            (_openBlock(), _createElementBlock("svg", _hoisted_25, [
              _createElementVNode("path", {
                d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                fill: iconColor.value
              }, null, 8, _hoisted_26)
            ]))
          ])
        ]),
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, [
            _withDirectives(_createElementVNode("input", {
              id: "slider",
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((inputValue).value = $event)),
              min: min,
              max: max,
              type: "range",
              onInput: handleInput
            }, null, 544), [
              [_vModelText, inputValue.value]
            ])
          ]),
          _createElementVNode("div", _hoisted_29, [
            _createElementVNode("p", null, _toDisplayString(_unref(t)(`chips_popup.waiting`) + timer.value + _unref(t)(`chips_popup.waiting_time`)), 1)
          ])
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("img", {
          src: _imports_1,
          class: "background-logo no-image-animation"
        }, null, -1))
      ]),
      _createElementVNode("div", _hoisted_30, [
        (props.middletGame || _ctx.timeToUp)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "chips-bt1",
              onClick: _cache[2] || (_cache[2] = ($event: any) => (emit('close')))
            }, _toDisplayString(_unref(t)(`chips_popup.exit`)), 1))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "chips-bt1",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(router).push('/rooms')))
            }, _toDisplayString(_unref(t)(`chips_popup.exit`)), 1)),
        (props.middletGame || _ctx.timeToUp)
          ? (_openBlock(), _createElementBlock("div", {
              key: 2,
              class: _normalizeClass({'chips-bt2': true, 'not-push': (inputValue.value + props.player.game_deposit) > max}),
              onClick: _cache[4] || (_cache[4] = ($event: any) => {triggerHapticFeedback('light'); addBalance()})
            }, _toDisplayString(_unref(t)(`chips_popup.continue`)), 3))
          : (props.room.coin_type == 'red_coin' ? inputValue.value > _unref(store).getters.USER.bonus_balance : inputValue.value > _unref(store).getters.USER.balance)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "chips-bt2",
                onClick: _cache[5] || (_cache[5] = ($event: any) => {triggerHapticFeedback('light'); _unref(router).push('/friends')})
              }, _toDisplayString(_unref(t)(`chips_popup.add_balance`)), 1))
            : (_openBlock(), _createElementBlock("div", {
                key: 4,
                class: "chips-bt2",
                onClick: _cache[6] || (_cache[6] = ($event: any) => {triggerHapticFeedback('light'); startTheGame()})
              }, _toDisplayString(_unref(t)(`chips_popup.start_game`)), 1))
      ])
    ])
  ]))
}
}

})