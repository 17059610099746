<script setup lang="ts">
import { RoomService } from "@/api/roomService";
import {defineProps, defineEmits, ref, onMounted, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';
import store from "../store";
import {formatNumber} from "../tools/tools";
import { formatNumberByZiro } from "../tools/tools";
import { ApiService } from "@/api/apiService";
import router from "@/router";
import {MiniAppsEventListener, number, off, on, postEvent} from "@telegram-apps/sdk";

const { t } = useI18n();

const inputRef = ref<HTMLInputElement | null>(null);

const props = defineProps<{
    showEditName: boolean,
    weekEarnLabel: boolean,
    backgroundClass: string
}>()

const userData = window.Telegram.WebApp.initDataUnsafe;

const emit = defineEmits(['closeChat']);

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
    emit('closeChat'); 
};

const mainButtonPressed: MiniAppsEventListener<'main_button_pressed'> = payload => {
    emit('closeChat'); 
    postEvent('web_app_setup_main_button', {
        is_progress_visible: true,
    })
}

onMounted(() => {
    if(inputRef.value) {
        inputRef.value.focus();
    }
    store.commit('SET_FOCUSED', true)
    postEvent('web_app_setup_back_button', { is_visible: true});
    postEvent('web_app_setup_main_button', {
      is_visible: true,
      is_active: true,
      is_progress_visible: false,
      text: 'Изменить имя',
    })
    on('back_button_pressed', listener);
    on('main_button_pressed', mainButtonPressed);
});

onUnmounted(() => {
  store.commit('SET_FOCUSED', false)
  postEvent('web_app_setup_back_button', { is_visible: false});
  postEvent('web_app_setup_main_button', {
    is_visible: false,
    is_active: false
  })
  off('back_button_pressed', listener);
  off('main_button_pressed', mainButtonPressed);
})


</script>

<template>

<div class="user-card-container-2">
      <div class="user-card-left">
        <div class="user-card-left-up">
          <p class="user-balance">{{ t(`profile.balance`) }}</p>
          <div class="balance-sum-wrapper-gocoin">
            <p class="balance-sum">{{ formatNumberByZiro(store.getters.USER.balance) }}</p>
            <div class="balance-sum-logo-wrapper">
              <img class="balance-sum-logo" src="@/assets/logo/go_coin_24.svg">
            </div>
          </div>
          <div class="balance-sum-wrapper-redcoin">
            <p class="balance-sum">{{ formatNumberByZiro(store.getters.USER.bonus_balance) }}</p>
            <div class="balance-sum-logo-wrapper">
              <img class="balance-sum-logo" src="@/assets/logo/money_logo.svg">
            </div>
          </div>
        </div>
        <div class="user-card-left-down">
          <div class="week-earn">
            <div class="user-name-edit">
                    <input
                        ref="inputRef"
                        class="text"
                        id="msg"
                        type="text"
                        :placeholder="userData.user?.username || 'noname'"
                    />
            </div>
            <div class="user-info-week-earn" v-if="store.getters.USER.week_earn">
              <p class="week-earn-label">{{ t(`main.in`) }}&nbsp;{{ t(`main.a_week`) }}:&nbsp;</p>
              <p :class="props.weekEarnLabel">{{ store.getters.USER.week_earn > 0 ? '+' : '' }}{{ formatNumber(store.getters.USER.week_earn) }}</p>
              <div class="balance-sum-logo-wrapper-mini-big">
                <img class="balance-sum-logo-wrapper-mini" src="@/assets/logo/money_logo.svg">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="user-card-right">
        <img class="user-status no-image-animation" :src="require(`@/assets/player/status/status-${store.getters.USER.vip_status}.svg`)" v-if="store.getters.USER.vip_status != 'first'">
        <div :class="['user-logo-wrapper', backgroundClass]">
          <img class="user-logo" :src="store.getters.USER.photo_url ? ApiService.url + store.getters.USER.photo_url : require('@/assets/mock_user/user.svg')">
        </div> 
        <p>id: {{ store.getters.USER.id }}</p>
      </div>
      <img src="@/assets/logo/logo-background.svg" class="background-logo no-image-animation">
    </div>

</template>

<style scoped>
input.text::placeholder {
  color: #B9B9B9;
}

input.text {
  background-color: inherit;
  color: #695a81;
  font-weight: 500;
  font-size: 18px;
  padding: 0px;
  font-family: inherit;
  margin: 0;
  border: none;
  outline: none;
}
/* 
.input_container {
  display: flex;
  width: 85vw;
  max-width: 360px;
  gap: 2.5vw;
  height: 35px;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 0;
  z-index: 1001;
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: start;
} */

.settings-room-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background-color: #2A2B37;
  animation: fadeIn 0.2s ease-out;
  width: 100%;
  height: 100%;
}

.user-card-container-2 {
  display: flex;
  margin-top: 15vh;
  padding: 21px 20px;
  background-color: #15151E;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  border-radius: var(--border-mixed);
  position: relative;
  justify-content: space-between;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.user-card-left {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.user-card-left-up {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.user-balance {
  font-size: 18px;
  font-weight: 600;
  color: #B9B9B9;
  display: flex;
}

.balance-sum-wrapper-gocoin {
  margin-top: 10px;
}

.balance-sum-wrapper-gocoin, .balance-sum-wrapper-redcoin {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.balance-sum {
  font-weight: bold;
  font-size: 18px;
  white-space: nowrap;
}

.balance-sum-logo-wrapper {
  display: flex;
  align-items: center;
}

.balance-sum-logo {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  margin-left: 5px;
}

.user-card-left-down {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.week-earn {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.user-info-username {
  font-weight: 500;
  font-size: 18px;
  display: flex;
  justify-content: start;
}

.user-info-week-earn {
  display: flex;
  align-items: center;
}

.week-earn-label {
  font-size: 3vw;
  font-weight: 500;
  color: #B9B9B9;
}

.balance-sum-logo-wrapper-mini-big {
  display: flex;
  justify-content: center;
  margin-left: 2px;
}

.balance-sum-logo-wrapper-mini {
  filter:drop-shadow(0 0 13px rgba(21, 21, 30, 0.5));
  width: 15px;
  height: 15px;
}

.user-card-right {
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: end;
}

.user-status {
  height: 25px;
}

.user-logo-wrapper {
  border-radius: 50%;
  width: 128px;
  height: 128px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.user-logo {
  border-radius: 50%;
  width: 125px;
  height: 125px;
  filter: drop-shadow(0px 10px 125px rgba(118, 81, 140, 0.4));
}

.background-logo {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  height: 100%;
  border-radius: 24px;
}

</style>