import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Echo from "laravel-echo";
import i18n from './i18n';

(window as any).Pusher = require('pusher-js');


(window as any).Echo = new Echo({
    broadcaster: 'pusher',
    key: 'key', // Совпадает с ключом в Laravel
    wsHost: 'ws-playokgo.ru', // Хост вашего сервера WebSockets
    wsPort: 6001, // Порт WebSocket сервера
    wssPort: 443,
    forceTLS: true, 
    disableStats: true,
    cluster: 'mt1',
    enabledTransports: ['ws', 'wss']
});


createApp(App).use(i18n).use(store).use(router).mount('#app')