<script setup lang="ts">
import { ref, computed, defineEmits, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const emit = defineEmits(['close']); 

const currentStep = ref(0);

const totalSteps = 5;

const nextStep = () => {
  if (currentStep.value < totalSteps - 1) {
    currentStep.value++;
  }
};

const prevStep = () => {
  if (currentStep.value > 0) {
    currentStep.value--;
  }
};

const videoTransform = computed(() => {
  return {
    transform: `translateX(${currentStep.value === 0 ? 0 : currentStep.value * -82.02}vw)`,
    transition: 'transform 0.4s ease',
  };
});

</script>

<template>
  <div class="tutor-wrapper">
    <div class="close-absolute">
      <img src="@/assets/tools/close-modal.svg"  @click="emit('close')"/>
    </div>
    <div class="video" :style="videoTransform">
        <video autoplay loop muted playsinline class="video1">
            <source src="@/assets/tutorial_video/hello.mp4">
        </video>
        <video autoplay loop muted playsinline class="video1">
            <source src="@/assets/tutorial_video/add_red_coin.mp4">
        </video>
        <!-- <video autoplay loop muted playsinline class="video1">
            <source src="@/assets/tutorial_video/gc_to_rc.mp4">
        </video> -->
        <video autoplay loop muted playsinline class="video1">
            <source src="@/assets/tutorial_video/choose_room.mp4">
        </video>
        <video autoplay loop muted playsinline class="video1">
            <source src="@/assets/tutorial_video/table.mp4">
        </video>
        <video autoplay loop muted playsinline class="video1">
            <source src="@/assets/tutorial_video/tournaments.mp4">
        </video>
    </div>
    <div class="description-tutor">
      <p v-if="currentStep === 0">{{ t('tutorial.text_hello') }}</p>
      <p v-if="currentStep === 1">{{ t('tutorial.text_add_redcoin') }}</p>
      <!-- <p v-if="currentStep === 2">{{ t('tutorial.text_gc_to_rc') }}</p> -->
      <p v-if="currentStep === 2">{{ t('tutorial.text_choose_room') }}</p>
      <p v-if="currentStep === 3">{{ t('tutorial.text_table') }}</p>
      <p v-if="currentStep === 4">{{ t('tutorial.text_tournaments') }}</p>
    </div>
    <div class="button-tutor">
      <div class="button-back" @click="emit('close')" v-if="currentStep === 0">
        <p >{{ t('tutorial.button_close') }}</p>
      </div>
      <div class="button-back" @click="prevStep" v-if="currentStep > 0">
        <p >{{ t('tutorial.button_back') }}</p>
      </div>
      <div class="progress-line">
        <svg
          v-for="(step, index) in totalSteps"
          :key="index"
          :width="currentStep === index ? 9 : 5"
          :height="currentStep === index ? 9 : 5"
          viewBox="0 0 5 5"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="2.5"
            cy="2.5"
            r="2.5"
            :fill="currentStep >= index ? '#AD90E4' : '#B9B9B9'"
          />
        </svg>
      </div>
      <div v-if="currentStep <=3" class="button-next" @click="nextStep">
        <p v-if="currentStep <=3">{{ t('tutorial.button_next') }}</p>
        <p v-else>{{ t('tutorial.button_close') }}</p>
      </div>
      <div v-else class="button-next" @click="emit('close')">
        <p>{{ t('tutorial.button_close') }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>

.tutor-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    background-color: #2A2B37;
    height: 100%;
    background: url("@/assets/background/background.svg") repeat-y center top;
}

.close-absolute {
    display: flex;
    position: absolute;
    width: 90%;
    justify-content: end;
}

.video {
    display: flex;
    width: 100%;
    gap: 18.025vw;
    margin-left: 36.05vw;
    margin-top: 15px;
}

.video1{
    width: 63.95vw;
    border-radius: 10.45vw;
}

.description-tutor {
    font-size: 15px;
    font-weight: 500;
    margin-top: 10px;
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-tutor {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 25px;
    width: 90%;
    position: absolute;
    bottom: 40px;   
}

.progress-line {
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 10px;
}

.button-back, .button-next {
    background-color: #62547F;
    border-radius: var(--border-mixed);
    padding: 14px 17px;
    font-size: 2.2vh;
    font-weight: 500;
}

</style>