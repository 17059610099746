import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

import TransactionsItem from "@/components/lists/transactions/TransactionsItem.vue";
import {onMounted, ref} from "vue";
import {UserService} from "@/api/userService";
import Transaction from "@/models/transaction";


export default /*@__PURE__*/_defineComponent({
  __name: 'TransactionsList',
  setup(__props) {


const transactions = ref<Transaction[]>([]);
const isLoaded = ref<boolean>(false);

onMounted(() => {
  const userService = new UserService();
  userService.transactions()
      .then(response => response.json())
      .then(data => {
        transactions.value = data;
        isLoaded.value = true;
      })
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(transactions.value, (transaction, i) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "transactions",
      key: i
    }, [
      _createVNode(TransactionsItem, { transaction: transaction }, null, 8, ["transaction"])
    ]))
  }), 128))
}
}

})