<script setup lang="ts">
import Message from "@/models/message";
import Player from "@/models/player";
import MessageWrapper from "@/components/game/MessageWrapper.vue";
import { RoomService } from "@/api/roomService";
import Room from "@/models/room";
import {defineProps, defineEmits, ref, onMounted, nextTick, watch} from "vue";
import { useI18n } from 'vue-i18n';

const { t } = useI18n();


const roomService = new RoomService();


const props = defineProps<{
  myPlayer:Player,
  room:Room,
  messages: Message[],
}>()


const messageContainer = ref<HTMLElement | null>(null);
const isChatFullScreen = ref(false); 
const isInputFocused = ref(false); 

const emit = defineEmits(['closeChat']);

onMounted(() => {
    nextTick(() => {
            scrollToBottom(); 
    });
});



function closeChat() {
    const inputElement = document.getElementById('msg') as HTMLInputElement;
    const msg = inputElement.value;
    if (msg) emit('closeChat');

    if (isChatFullScreen.value){
        isChatFullScreen.value = false; 
        scrollToBottom();
    }
    else emit('closeChat'); 
}

function sendMsg(){
    const inputElement = document.getElementById('msg') as HTMLInputElement;
    const msg = inputElement.value;
    if (msg === '') return;

    roomService.sendMessage(props.room.id, msg!, 'message');    
    inputElement.value = "";

    nextTick(() => {
        inputElement.focus();
    });

}


function handleInputFocus() {
    isChatFullScreen.value = true;  
    isInputFocused.value = true; 
    scrollToBottom();
}

function handleBlur() {
    const inputElement = document.getElementById('msg') as HTMLInputElement;
    const msg = inputElement.value;
    if (msg) inputElement.focus(); 
    else {
        isInputFocused.value = false; 
        isChatFullScreen.value = false;
        scrollToBottom();
    }
}

function scrollToBottom() {
    if (messageContainer.value) {
        nextTick(() =>{
            messageContainer.value!.scrollTop = messageContainer.value!.scrollHeight;
        })
    }
}

watch(() => props.messages, () => {
    nextTick(() => {
        scrollToBottom(); 
    });
});

</script>

<template>
    <div :class="['chat-wrapper', { 'chat-fullscreen': isChatFullScreen }]">
        <div class="wrapper">
            <div class="top">
                <p class="header">{{ t(`game.chat`) }}</p>
                <div class="close" @click="closeChat">
                    <img class="close-img" src="@/assets/chat/close.svg">
                </div>
            </div>
            <div
                class="message_container"
                :style="{ height: isChatFullScreen && isInputFocused ? '310px' : '630px' }"
                ref="messageContainer"
            >
                <MessageWrapper
                    v-for="(message, index) in messages"
                    :key="index"
                    :message="message"
                    :myPlayer="myPlayer"
                />
            </div>
            <div class="input_container">
                <input
                    class="text"
                    id="msg"
                    type="text"
                    placeholder="Сообщение"
                    @focus="handleInputFocus"
                    @blur="handleBlur"
                />
                <div class="btn-send" @click="sendMsg">
                    <img src="@/assets/chat/send.svg">
                </div>
            </div>
        </div> 
    </div>
</template>

<style scoped>

.chat-wrapper {
    position: absolute;
    height: 405px;
    background-color: #423757;
    z-index: 10000;
    border-top-left-radius: 5vw;
    border-bottom-left-radius: 5vw;
    box-shadow: 4px 7px 40px rgba(0, 0, 0, 0.6);
    transform: scale(0.9);
    opacity: 0;
    animation: fadeInScale 0.2s ease-out forwards;
}

.chat-fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 998;
    background-color: #423757; 
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0; 
}

.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 12px;
    height: 365px;
}

.chat-fullscreen .wrapper {
    width: 100%;
    height: 100%;
    margin: 0;
    margin-top: 20px;
}

.top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80vw;
    max-width: 360px;
}

p.header{
    font-size: 16px;
    color: #62547F;
    font-weight: 600;
}

.close-img{
    width: 14px;
}

.close{
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.message_container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 85vw;
    max-width: 360px;
    overflow-y: scroll;
    gap: 2vw;
}

.input_container {
    display: flex;
    width: 85vw;
    max-width: 360px;
    gap: 2.5vw;
    height: 35px;
}

input.text {
    width: 70vw;
    max-width: 300px;
    background-color: #252331;
    color: #B9B9B9;
    font-size: 14px;
    padding: 0px;
    font-family: inherit;
    margin: 0;
    border-radius: 100px;
    padding-left: 14px;
    padding-right: 14px;
}

.btn-send {
    width: 8vw;
    height: 35px;
}
</style>