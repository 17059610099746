<script setup lang="ts">

import HeaderMini from "@/components/header/HeaderMini.vue";
import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, onUnmounted, ref, watch} from "vue";
import store from "../store";
import {ApiService} from "@/api/apiService";
import { useI18n } from 'vue-i18n';
import { UserService } from "@/api/userService";
import {formatNumber} from "@/tools/tools";

const { t } = useI18n();

const userService = new UserService();

const referals = ref(0);

const balance = ref(store.getters.USER.balance);

const isOpenPop = ref(false);

// watch(() => balance.value, (newValue) => {
//   if (newValue>balance.value) {
//     userService.getReferrals()
//     .then(response => response.json())
//     .then(data => {
//       referals.value = data;
//     });
//   }
// });

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1);
};

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);
  userService.getReferrals()
    .then(response => response.json())
    .then(data => {
      referals.value = data;
    });
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
});

function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text);
  isOpenPop.value = true;
  setTimeout(() => {
    isOpenPop.value = false;
  }, 700);
}

function openShare() {
  triggerHapticFeedback('soft');
  postEvent('web_app_open_tg_link', {
    path_full: `/share/url?text=${encodeURI(t('friends.invite_message'))}&url=https://t.me/okgo_poker_bot/open_app?startapp=${store.getters.TELEGRAM_USER.user.id}`
  });
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

</script>

<template>
  <header-mini/>
  <div class="friends-wrapper" :style="{'margin-top': tg.isFullscreen ? 'calc(var(--tg-content-safe-area-inset-top) + var(--tg-safe-area-inset-top) + 20px)': 72}">
    <div class="popap-copy" :style="{'opacity': isOpenPop ? '1' : '0'}">
      <p>Ссылка скопирована</p>
    </div>
    <p class="invite-friends">{{ t(`friends.invite_friends`) }}</p>
    <p class="invite-friends-additional">{{ t(`friends.invite_friends_additional`) }}</p>
    <div class="invite-friend-gift-wrapper">
      <img class="invite-friends-gift" src="@/assets/friends/gift.png">
      <div class="invite-friend-gift-info">
        <p class="invite-friend-label">{{ t(`friends.invite_friend_label`) }}</p>
        <div class="invite-friend-gift-bonus-label">
          <img class="money-logo-friends" src="@/assets/logo/money_logo.svg">
          <p class="invite-friend-gift-bonus">{{ t(`friends.invite_friend_gift_bonus`) }}</p>
        </div>
      </div>
    </div>
      <div class="invite-friend-link-wrapper">
        <div class="friends-count-wrapper">
          <div class="friends-count">
            <p class="friends-count-label">{{ referals }}</p>
            <p class="friend-get-label">{{ t(`friends.friend_get_label1`) }}<br>{{ t(`friends.friend_get_label2`) }}</p>
          </div>
          <div class="friends-count">
            <p class="friends-count-label">{{ formatNumber(referals * 50000) }}</p>
            <p class="friend-get-label">{{ t(`friends.friend_get_label_bonus1`) }}<br>{{ t(`friends.friend_get_label_bonus2`) }}</p>
          </div>
        </div>
        <div class="referral-link-wrapper"  @click="copyToClipboard(`https://t.me/okgo_poker_bot/open_app?startapp=${store.getters.TELEGRAM_USER.user.id}`)">
          <img src="@/assets/friends/link.svg" class="referral-link-img">
          <p class="referral-link">https://t.me/okgo_poker_bot/open_app?startapp={{ store.getters.TELEGRAM_USER.user.id }}</p>
          <img src="@/assets/friends/copy.svg" class="referral-link-copy no-image-animation">
        </div>
      </div>
      <div class="rules-wrapper">
        <p class="rules-label">{{ t(`friends.rules_label`) }}</p>
        <div class="rules-img-wrapper">
          <p class="rules-img-label">{{ t(`friends.rules_img_label`) }}</p>
          <img src="@/assets/friends/open-red-box.svg" class="rules-img">
        </div>
      </div>
      <p class="rules-list-label">{{ t(`friends.you_are_required`) }}</p>
      <div class="rules-list">
        <div class="rules-item">
          <div class="rules-item-number">
            <p class="rules-item-number-text">1</p>
          </div>
          <p class="rules-item-label">{{ t(`friends.register`) }}</p>
        </div>
        <div class="rules-item">
          <div class="rules-item-number">
            <p class="rules-item-number-text">2</p>
          </div>
          <p class="rules-item-label">{{ t(`friends.win_10_games_and`) }}</p>
        </div>
      </div>
      <p class="rules-list-label">{{ t(`friends.a_friend_id_required_to`) }}</p>
      <div class="rules-list">
        <div class="rules-item">
          <div class="rules-item-number">
            <p class="rules-item-number-text">1</p>
          </div>
          <p class="rules-item-label">{{ t(`friends.follow_your_link`) }}</p>
        </div>
        <div class="rules-item">
          <div class="rules-item-number">
            <p class="rules-item-number-text">2</p>
          </div>
          <p class="rules-item-label">{{ t(`friends.register`) }}</p>
        </div>
        <div class="rules-item">
          <div class="rules-item-number">
            <p class="rules-item-number-text">3</p>
          </div>
          <p class="rules-item-label">{{ t(`friends.win_10_games`) }}</p>
        </div>
      </div>
      <p class="rules-list-label" v-if="store.getters.USER_REFERRALS.length > 0">{{ t(`friends.list_of_your_friends`) }}</p>
      <div class="friends-list" v-if="store.getters.USER_REFERRALS.length > 0">
        <div class="friends-list-item" v-for="(item, index) in store.getters.USER_REFERRALS" :key="index">
          <div class="friend-info">
            <img :src="item.referral_photo_url ? ApiService.url + item.referral_photo_url : require('@/assets/mock_user/user.svg')" class="friend-info-img">
            <p class="friend-username">{{ item.referral_username ?? 'noname' }}</p>
          </div>
          <div class="friend-bonus">
            <img src="@/assets/logo/money_logo.svg" class="friend-bonus-img">
            <p class="friend-bonus-sum">+{{ item.sum }}</p>
          </div>
        </div>
      </div>
      <div class="invite-friend-buttons">
        <div class="invite-friend-invite" @click="openShare()">
          <p>{{ t(`friends.button_invete`) }}</p>
          <img src="@/assets/friends/friend.svg">
        </div>
        <div class="invite-friend-copy" @click="triggerHapticFeedback('soft'); copyToClipboard(`https://t.me/okgo_poker_bot/open_app?startapp=${store.getters.TELEGRAM_USER.user.id}`)">
          <img src="@/assets/friends/copy-white.svg">
        </div>
      </div>
    <!-- <div v-else>
      <div class="rules-img-wrapper-2">
          <p class="rules-img-label">{{ t(`friends.level_2_friends`) }}</p>
          <img src="@/assets/friends/open-box.svg" class="rules-img">
        </div>
        <div class="button" @click="triggerHapticFeedback('light'); router.push('/levels')">
            <p class="how-to-level-up">{{ t(`friends.how_top_up`) }}</p>
        </div>
    </div> -->
  </div>
</template>

<style scoped>

.popap-copy {
  position: absolute;
  top: 61%;
  white-space: nowrap;
  background-color: #977bcd;
  padding: 10px;
  border-radius: var(--border-mixed);
  transition: opacity .3s ease-in-out;
  z-index: 1000;
  left: 50%;
  transform: translate(-50%, -61%);
}

/* for 2 lvl*/
.button{
  background-color: #5C4E73;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-mixed);
}

.how-to-level-up{
  padding-top: 24px;
  padding-bottom: 24px;
  font-weight: 500;
  font-size: 18px;
  border-radius: var(--border-mixed);
}


.rules-img-wrapper-2{
  margin-top: 17px;
  border-radius: var(--border-mixed);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background: linear-gradient(271.98deg, #6F55A1 1.4%, #B698EF 76.73%);
}

.friends-wrapper {
  margin-top: 72px;
  margin-bottom: 220px;
}

.invite-friends {
  font-weight: 600;
  font-size: 30px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.invite-friends-additional {
  font-weight: 500;
  font-size: 18px;
  margin-top: 15px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.15s forwards;
}

.invite-friend-gift-wrapper {
  background-color: #15151E;
  padding: 14px 23px;
  display: flex;
  gap: 21px;
  border-radius: var(--border-mixed);
  margin-top: 20px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.2s forwards;
}

.invite-friends-gift {
  width: 54px;
  height: 53px;
  filter: drop-shadow(0px 0px 20px rgba(182, 152, 239, 0.3));
}

.invite-friend-label {
  font-weight: 600;
  font-size: 18px;
  text-align: left;
}

.invite-friend-gift-bonus-label {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.money-logo-friends {
  margin-right: 7px;
  filter: drop-shadow(0 0 14px rgba(0, 0, 0, 0.5));
}

.invite-friend-gift-bonus {
  font-size: 14px;
  font-weight: 600;
  text-align: left;
}

.invite-friend-link-wrapper {
  padding: 10px 21px 25px;
  border-radius: var(--border-mixed);
  background: #15151E;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  margin-top: 14px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.25s forwards;
}

.friends-count-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.friends-count {
  display: flex;
  align-items: center;
  gap: 10px;
}

.friends-count-label {
  font-weight: 600;
  font-size: 43px;
}

.friend-get-label {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
}

.referral-link-wrapper {
  padding: 14px 23px;
  display: flex;
  border-radius: var(--border-mixed);
  background: #24232E;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  margin-top: 19px;
}

.referral-link-img {
  margin-right: 20px;
}

.referral-link {
  font-size: 3.3vw;
  word-wrap: break-word;
  font-weight: 500;
  width: 45vw;
}

.referral-link-copy {
  transition: .1s transform ease-out;
  margin-left: 20px;
}

.referral-link-copy:active {
  transform: scale(0.8);
}

.rules-wrapper {
  margin-top: 16px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.35s forwards;
}

.rules-label {
  font-size: 30px;
  font-weight: 600;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.3s forwards;
}

.rules-img-wrapper {
  position: relative;
  height: 168px;
  max-height: 168px;
  margin-top: 17px;
  border-radius: var(--border-mixed);
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  background: linear-gradient(271.98deg, #6F55A1 1.4%, #B698EF 76.73%);
}


.rules-img {
  position: absolute;
  z-index: 3;
  animation: none;
  transform: rotate(-14.38deg);
  filter: drop-shadow(-2px 19px 18px rgba(0, 0, 0, 0.25));
  width: 186px;
  right: -19px;
  bottom: -31px;
}

.rules-img-label {
  position: relative;
  z-index: 4;
  margin-left: 14px;
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  margin-right: -50px;
  max-width: 219px;
}

.rules-list {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rules-list-label {
  text-align: left;
  font-weight: 500;
  font-size: 18px;
  color: #B9B9B9;
  margin-bottom: 15px;
  margin-top: 36px;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.4s forwards;
}

.rules-item {
  display: flex;
  align-items: center;
  gap: 14px;
  padding: 6px;
  background: #5C4E73;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.45s forwards;
}

.rules-item-number {
  background-color: #272833;
  height: 39px;
  width: 39px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: var(--border-mixed);
  flex-shrink: 0;
}

.rules-item-number-text {
  font-size: 18px;
  font-weight: 500;
  color: #B9B9B9;
}

.rules-item-label {
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  flex: 1;
}

.friends-list {
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 13px 10px;
  background: #4A3E5D;
  box-shadow: 0 4px 48px rgba(21, 21, 30, 0.5);
  border-radius: var(--border-mixed);
}

.friends-list-item {
  border-radius: var(--border-mixed);
  padding: 14px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #5C4E73;
}

.friend-info {
  display: flex;
  align-items: center;
  gap: 19px;
}

.friend-bonus {
  display: flex;
  align-items: center;
  gap: 19px;
}

.friend-info-img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
  filter: blur(1px) drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

.friend-username {
  font-size: 14px;
  font-weight: 500;
}

.friend-bonus-img {
  width: 20px;
  height: 20px;
}

.friend-bonus-sum {
  font-weight: 500;
  font-size: 18px;
}

.invite-friend-buttons {
  position: fixed;
  left: 20px;
  right: 20px;
  bottom: 120px;
  display: flex;
  align-items: stretch;
  gap: 24px;
  margin-top: 40px;
  z-index: 5;
}

.invite-friend-invite {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 21px;
  background: #977BCD;
  border-radius: var(--border-mixed);
  transition: .1s transform ease-out;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  animation: none;
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.1s forwards;
}

.invite-friend-invite:active {
  transform: scale(0.93);
}

.invite-friend-copy {
  padding: 16px 18px;
  background: #977BCD;
  border-radius: var(--border-mixed);
  transition: .1s transform ease-out;
  animation: none;
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.25));
  transform: scale(0.9);
  opacity: 0;
  animation: scaleUp 0.3s ease-out 0.15s forwards;
}

.invite-friend-copy:active {
  transform: scale(0.93);
}
</style>