import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"
import _imports_0 from '@/assets/logo/money_logo.svg'


const _hoisted_1 = {
  key: 0,
  class: "winners-sum"
}
const _hoisted_2 = { class: "winners-sum-who" }
const _hoisted_3 = { class: "player" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = {
  key: 1,
  src: _imports_0,
  class: "balance-logo"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = {
  key: 0,
  class: "move-label",
  style: {"text-align":"center"}
}
const _hoisted_8 = {
  key: 1,
  class: "move-label",
  style: {"text-align":"center"}
}
const _hoisted_9 = { class: "winners-sum" }
const _hoisted_10 = { class: "winners-sum-who" }
const _hoisted_11 = ["innerHTML"]
const _hoisted_12 = {
  key: 0,
  src: _imports_0,
  class: "balance-logo"
}
const _hoisted_13 = {
  key: 0,
  class: "winners-label"
}
const _hoisted_14 = { class: "container" }
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "wrapper" }
const _hoisted_17 = { class: "user-name" }
const _hoisted_18 = { class: "user-name-lable" }
const _hoisted_19 = { class: "message" }

import {onMounted, ref} from "vue";
import store from "@/store";
import { useI18n } from 'vue-i18n';
import {ApiService} from "@/api/apiService";

type MoveUser = {
  user_id: string;
  username: string | null;
}

type Winner = {
  user_id: string;
  username: string | null;
  sum: number,
  created_at: string
}

type Action = {
  user_id: string,
  username: string | null,
  action_name: string,
  action_sum?: number
}


export default /*@__PURE__*/_defineComponent({
  __name: 'NotificationModal',
  props: {
    notificationType: {},
    moveUser: {},
    winners: {},
    hand: {},
    action: {},
    messageData: {},
    card_open: { type: Boolean }
  },
  setup(__props: any) {

const { t } = useI18n();

const props = __props;
const whoWinnerString = ref("");
const sum = ref(false);

onMounted(() => {
  const currentUserId = store.getters.USER.id;
  if (props.winners) {
    console.log('props.winners notif type:', props.winners)
    if (props.winners.length == 1) {
      if (props.winners[0].user_id == currentUserId) {
        whoWinnerString.value = `${t('notification.youve_won')} ${props.winners[0].sum}`;
      } else {
        whoWinnerString.value = `${t('notification.won')} <span style="color: #B698EF;">${props.winners[0].username ?? 'noname'}</span> ${props.winners[0].sum}`;
      }
      sum.value = true;
    } else {
      const currentUserWinner = props.winners.find((element) => element.user_id == currentUserId);
      if (currentUserWinner) {
        whoWinnerString.value = `${t('notification.youve_won')} ${currentUserWinner.sum}`;
        sum.value = true;
      } else {
        whoWinnerString.value = `${props.winners.length} ${t('notification.slice_bank')}`;
      }
    }
  }
});


return (_ctx: any,_cache: any) => {
  return (_ctx.notificationType != 'new_msg')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "notification_mov_winner",
        style: _normalizeStyle({ 'z-index': _ctx.notificationType == 'move' ? 1 : 1 })
      }, [
        (_ctx.notificationType === 'move')
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: _normalizeClass({'mov_winner': !_ctx.card_open, 'mov_winner_open': _ctx.card_open})
            }, [
              (_ctx.action)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.action.username ?? 'noname'), 1),
                      _createElementVNode("p", null, _toDisplayString(_ctx.action.action_name), 1),
                      (_ctx.action.action_sum)
                        ? (_openBlock(), _createElementBlock("p", _hoisted_4, _toDisplayString(_ctx.action.action_sum), 1))
                        : _createCommentVNode("", true),
                      (_ctx.action.action_sum)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_5))
                        : _createCommentVNode("", true)
                    ])
                  ]))
                : _createCommentVNode("", true),
              (_ctx.moveUser)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    (_ctx.moveUser?.user_id == _unref(store).getters.USER.id)
                      ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_unref(t)(`notification.you_turn`)), 1))
                      : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_unref(t)(`notification.action`)) + " " + _toDisplayString(_ctx.moveUser?.username ?? 'noname'), 1))
                  ]))
                : _createCommentVNode("", true)
            ], 2))
          : (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: _normalizeClass({'mov_winner': !_ctx.card_open, 'mov_winner_open': _ctx.card_open}),
              style: _normalizeStyle({'text-align': 'center', 'z-index': _ctx.notificationType == 'winner' ? 1 : 1000})
            }, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("p", {
                    class: "winners-label",
                    innerHTML: whoWinnerString.value
                  }, null, 8, _hoisted_11),
                  (sum.value)
                    ? (_openBlock(), _createElementBlock("img", _hoisted_12))
                    : _createCommentVNode("", true)
                ]),
                (_ctx.hand != 'нет')
                  ? (_openBlock(), _createElementBlock("p", _hoisted_13, _toDisplayString(_unref(t)(`notification.the_winning_hand`)) + " " + _toDisplayString(_ctx.hand), 1))
                  : _createCommentVNode("", true)
              ])
            ], 6))
      ], 4))
    : (_ctx.notificationType === 'new_msg' && _ctx.messageData.user_id!==_unref(store).getters.USER.id)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "notification-message",
          style: _normalizeStyle({ 'z-index': _ctx.notificationType == 'new_msg' ? 1000 : 1 })
        }, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("img", {
              class: "user-img",
              src: _ctx.messageData.user.photo_url ? _unref(ApiService).url + _ctx.messageData.user.photo_url : require('@/assets/mock_user/user.svg')
            }, null, 8, _hoisted_15),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.messageData.user_username), 1)
              ]),
              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.messageData.body), 1)
            ])
          ])
        ], 4))
      : _createCommentVNode("", true)
}
}

})