import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/logo/partner_logo_rell.svg'
import _imports_1 from '@/assets/logo/bcakground_logo_rell.svg'


const _hoisted_1 = { class: "tournament-list-item" }
const _hoisted_2 = { class: "item-top" }
const _hoisted_3 = { class: "item-partner" }
const _hoisted_4 = { class: "tournament-right-partner-wrapper" }
const _hoisted_5 = { class: "tournament-right-partner" }
const _hoisted_6 = { class: "card-tour-up" }
const _hoisted_7 = { class: "prize-pool-wrapper" }
const _hoisted_8 = { class: "text-prize" }
const _hoisted_9 = { class: "fee-wrapper" }
const _hoisted_10 = { class: "text-fee" }
const _hoisted_11 = { class: "card-tour-down" }
const _hoisted_12 = { class: "count-players" }
const _hoisted_13 = { class: "text-count-players" }
const _hoisted_14 = { class: "sum-count-players" }

import {MiniAppsEventListener, off, on, postEvent} from "@telegram-apps/sdk";
import router from "@/router";
import {onMounted, ref, onUnmounted} from "vue";
import { useI18n } from 'vue-i18n';
import store from "@/store";
import DetailTournament from "@/components/lists/tournaments/DetailTournament.vue";
import { TournamentServise } from "@/api/tournamentService";


export default /*@__PURE__*/_defineComponent({
  __name: 'TournamentListItem',
  setup(__props) {

const TourServise = new TournamentServise;

const { t } = useI18n();

const listener: MiniAppsEventListener<'back_button_pressed'> = payload => {
  triggerHapticFeedback('soft');
  router.go(-1);
};

const countPlayersToour = ref();

onMounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: true});
  on('back_button_pressed', listener);

  TourServise.getTournament('partner')
    .then(response => response.json())
    .then(data => {
        countPlayersToour.value = data;
  });
})

onUnmounted(() => {
  postEvent('web_app_setup_back_button', { is_visible: false});
  off('back_button_pressed', listener);
})

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const openDetail = ref(false);

const openDetailPop = () => {
    openDetail.value = true;
}

const closeDetailPop = () => {
    openDetail.value = false;
}


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("div", { class: "date-wrapper" }, [
          _createElementVNode("p", { class: "date-number" }, "31"),
          _createElementVNode("p", { class: "date-month" }, "Января")
        ], -1)),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "line" }, null, -1)),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_unref(t)(`tournaments.partner_name_partner`)), 1)
          ]),
          _cache[1] || (_cache[1] = _createElementVNode("div", { class: "tournament-right-partner-logo" }, [
            _createElementVNode("img", { src: _imports_0 })
          ], -1))
        ])
      ]),
      _cache[9] || (_cache[9] = _createElementVNode("div", { class: "item-center" }, null, -1)),
      _createElementVNode("div", {
        class: "item-down",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (openDetailPop()))
      }, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(t)(`tournaments.prize_pool`)), 1),
            _cache[4] || (_cache[4] = _createElementVNode("p", { class: "sum-prize" }, "100.000 ₽", -1))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_unref(t)(`tournaments.fee`)), 1),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "fee-sum" }, [
              _createElementVNode("svg", {
                class: "fee-coin-type no-image-animation",
                width: "14",
                height: "14",
                viewBox: "0 0 24 24",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg"
              }, [
                _createElementVNode("path", {
                  d: "M20.3149 10.4882H24C23.3158 5.02563 18.9744 0.684208 13.5118 0V3.68516H10.4882V0C5.02562 0.6842 0.6842 5.02563 0 10.4882H3.68516V13.5118H0C0.6842 18.9744 5.02561 23.3158 10.4882 24V20.3148H13.5118V24C18.9744 23.3158 23.3158 18.9744 24 13.5118H20.3149V10.4882ZM12 15.0236C10.3328 15.0236 8.97643 13.6672 8.97643 12C8.97643 10.3328 10.3328 8.97642 12 8.97642C13.6672 8.97642 15.0236 10.3328 15.0236 12C15.0236 13.6672 13.6672 15.0236 12 15.0236Z",
                  fill: "#D14A4A"
                })
              ]),
              _createElementVNode("p", { class: "fee-sum-sum" }, "150K")
            ], -1))
          ]),
          _cache[6] || (_cache[6] = _createElementVNode("div", { class: "time-wrapper" }, [
            _createElementVNode("p", { class: "time" }, "21:00")
          ], -1))
        ]),
        _createElementVNode("div", _hoisted_11, [
          _cache[7] || (_cache[7] = _createElementVNode("p", { class: "go" }, "GO", -1)),
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("p", _hoisted_13, _toDisplayString(_unref(t)(`tournaments.count_players`)), 1),
            _createElementVNode("p", _hoisted_14, _toDisplayString(countPlayersToour.value), 1)
          ])
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("img", {
          src: _imports_1,
          class: "background-partner-logo"
        }, null, -1))
      ])
    ]),
    (openDetail.value)
      ? (_openBlock(), _createBlock(DetailTournament, {
          key: 0,
          onClose: closeDetailPop
        }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})