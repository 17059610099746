import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/tools/arrow.svg'


const _hoisted_1 = { class: "start-game" }
const _hoisted_2 = {
  key: 0,
  class: "available"
}
const _hoisted_3 = { key: 2 }
const _hoisted_4 = { class: "start-game" }
const _hoisted_5 = {
  key: 0,
  class: "available"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "start-game" }
const _hoisted_8 = {
  key: 0,
  class: "available"
}
const _hoisted_9 = { key: 2 }

import router from "@/router";
import {onMounted, ref, computed, nextTick} from "vue";
import {RoomService} from "@/api/roomService";
import AnimatedPlaceholder from "@/components/tools/AnimatedPlaceholder.vue";
import RoomsListItem from "@/components/lists/rooms/RoomsListItem.vue";
import store from "@/store";
import RoomsItem from "@/models/roomsListItem";
import { useI18n } from 'vue-i18n';
import SettingsRoom from '@/components/lists/rooms/SettingsPrivateRoom.vue'


export default /*@__PURE__*/_defineComponent({
  __name: 'RoomsList',
  props: {
    max_len: {},
    all: { type: Boolean },
    random: { type: Boolean },
    myroom: { type: Boolean },
    showSettingsPop: { type: Boolean }
  },
  setup(__props: any) {

const showSettingsPop = ref(false);

const { t } = useI18n();

const props = __props;

function scrollToTop() {
  nextTick(() => {
    window.scrollTo(0, 0);
  });
  triggerHapticFeedback('light');
  router.push('/rooms')
}

function scrollToTopPrivate() {
  window.scrollTo(0, 0);
  triggerHapticFeedback('light');
  router.push({
    path: '/rooms',
    query: { showSettingsPop: 'true' }
  });
}

const rooms = ref<RoomsItem[]>([]);
let privateRooms = ref<RoomsItem[]>([]);
let isLoaded = ref(false);

const roomService = new RoomService();

const blindLevels = [
  [1, 2],
  [5, 10],
  [10, 20],
  [50, 100],
  [250, 500],
  [500, 1000],
  [1000, 2000],
  [5000, 10000],
  [10000, 20000],
];

const predefinedRooms = blindLevels.map(([smallBlind, bigBlind], index) => ({
  small_blind_bet: smallBlind,
  big_blind_bet: bigBlind,
  game_deposit: bigBlind * 100,
  players_count: 0,
  fee: 0,
  max_players: 8,
  coin_type: "red_coin",
  id: index + 1,
}));

onMounted(async () => {
  rooms.value = predefinedRooms.slice(0, props.max_len);
  isLoaded.value = true;  
  await roomService.getPrivateRooms(props.max_len ?? 10)
  .then(response => response.json())
  .then(data => {
    privateRooms.value = data;
    privateRooms.value = privateRooms.value.slice(0, props.max_len);
    isLoaded.value = true;
  });
  showSettingsPop.value = props.showSettingsPop || false;
});

const tryJoinRoom = (room: RoomsItem) => {
  let coinType = 'red_coin';
  // if (isCheckedDown.value) coinType = 'go_coin';
  // else coinType = 'red_coin'; 
  router.push(`/game?small_blind_bet=${room.small_blind_bet}&coin_type=${coinType}`);
}

const tryJoinPrivateRoom = (room: RoomsItem) => {
  let coinType = 'red_coin';
  // if (isCheckedDown.value) coinType = 'go_coin';
  // else coinType = 'red_coin';
  router.push(`/game?small_blind_bet=${room.small_blind_bet}&coin_type=${coinType}&id=${room.id}`);
}

const tg = window.Telegram?.WebApp;

function triggerHapticFeedback(style: string) {
  if (store.getters['isVibrationOn'] && tg?.HapticFeedback) {
    tg.HapticFeedback.impactOccurred(style);
  }
}

const isSecondSectionBackground = computed(() => !props.all);

const openPop = () => {
if (!showSettingsPop.value) {
  showSettingsPop.value = true;
  } else {
    showSettingsPop.value = false;
  }
}

const isCheckedTop = ref(false);
const isCheckedDown = ref(false);

// isChecked: True = GoCoins, false = RedCoins
// function handleCheckboxChange(checkboxId: string) {
//   if (checkboxId === 'toggle-top') {
//     isCheckedTop.value = !isCheckedTop.value;
//   } else if (checkboxId === 'toggle-down') {
//     isCheckedDown.value = !isCheckedDown.value;
//   }
// }

const filteredPrivateRooms = computed(() => {
  return privateRooms.value.filter((room) =>
    isCheckedTop.value ? room.coin_type === "go_coin" : room.coin_type === "red_coin"
  );
});


const handleCloseSettingsPopup = () => {
  showSettingsPop.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.all)
      ? (_openBlock(), _createElementBlock("section", {
          key: 0,
          class: _normalizeClass({ 'second-section': true, 'second-section-background': !_ctx.all})
        }, [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("p", {
              class: "start-game-label springy-animation",
              style: _normalizeStyle(isSecondSectionBackground.value ? { 'margin-left': '7px' } : null)
            }, _toDisplayString(_unref(t)(_ctx.myroom ? 'main.myrooms' : 'main.start_game')), 5),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "game-hr" }, null, -1))
          ]),
          (_unref(isLoaded))
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredPrivateRooms.value, (room, i) => {
                  return (_openBlock(), _createBlock(RoomsListItem, {
                    key: i,
                    onClick: ($event: any) => {triggerHapticFeedback('light'); tryJoinPrivateRoom(room)},
                    privateRooms: _unref(privateRooms),
                    room: room,
                    myroom: _ctx.myroom,
                    isCheckedTop: isCheckedTop.value
                  }, null, 8, ["onClick", "privateRooms", "room", "myroom", "isCheckedTop"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.myroom)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "available-card-plus",
                onClick: openPop
              }, _cache[1] || (_cache[1] = [
                _createElementVNode("svg", {
                  width: "19",
                  height: "19",
                  viewBox: "0 0 19 19",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("rect", {
                    x: "0.75",
                    y: "-0.75",
                    width: "1.5",
                    height: "17.5",
                    rx: "0.75",
                    transform: "matrix(1 0 0 -1 8 17.5)",
                    fill: "#A5A5A6",
                    stroke: "white",
                    "stroke-width": "1.5"
                  }),
                  _createElementVNode("rect", {
                    x: "0.75",
                    y: "0.75",
                    width: "1.5",
                    height: "17.5",
                    rx: "0.75",
                    transform: "matrix(-4.37114e-08 1 1 4.37114e-08 -3.27835e-08 8)",
                    fill: "#A5A5A6",
                    stroke: "white",
                    "stroke-width": "1.5"
                  })
                ], -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.max_len, (i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "available-card-skeleton",
                    key: i
                  }, [
                    _createVNode(AnimatedPlaceholder, {
                      width: "100%",
                      height: "100%",
                      "border-radius": "var(--border-mixed)"
                    })
                  ]))
                }), 128))
              ])),
          (!_ctx.all)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "all-rooms",
                onClick: scrollToTop
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("p", null, _toDisplayString(_unref(t)(`main.all_rooms`)), 1)
                ]),
                _cache[2] || (_cache[2] = _createElementVNode("div", null, [
                  _createElementVNode("img", { src: _imports_0 })
                ], -1))
              ]))
            : _createCommentVNode("", true),
          (showSettingsPop.value)
            ? (_openBlock(), _createBlock(SettingsRoom, {
                key: 4,
                onClose: handleCloseSettingsPopup,
                room: _ctx.room!,
                "join-room": _ctx.joinRoom,
                player: _ctx.getMyPlayer!
              }, null, 8, ["room", "join-room", "player"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("section", {
      class: _normalizeClass({ 'second-section': true, 'second-section-background': !_ctx.all})
    }, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", {
          class: "start-game-label springy-animation",
          style: _normalizeStyle(isSecondSectionBackground.value ? { 'margin-left': '7px' } : null)
        }, _toDisplayString(_unref(t)(`main.start_game`)), 5),
        _cache[3] || (_cache[3] = _createElementVNode("div", { class: "game-hr" }, null, -1))
      ]),
      (_unref(isLoaded))
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rooms.value, (room, i) => {
              return (_openBlock(), _createBlock(RoomsListItem, {
                onClick: ($event: any) => {triggerHapticFeedback('light'); tryJoinRoom(room)},
                key: i,
                room: room
              }, null, 8, ["onClick", "room"]))
            }), 128))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.max_len, (i) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "available-card-skeleton",
                key: i
              }, [
                _createVNode(AnimatedPlaceholder, {
                  width: "100%",
                  height: "100%",
                  "border-radius": "var(--border-mixed)"
                })
              ]))
            }), 128))
          ])),
      (!_ctx.all)
        ? (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "all-rooms",
            onClick: scrollToTop
          }, [
            _createElementVNode("div", null, [
              _createElementVNode("p", null, _toDisplayString(_unref(t)(`main.all_rooms`)), 1)
            ]),
            _cache[4] || (_cache[4] = _createElementVNode("div", null, [
              _createElementVNode("img", { src: _imports_0 })
            ], -1))
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (!_ctx.all)
      ? (_openBlock(), _createElementBlock("section", {
          key: 1,
          class: _normalizeClass({ 'second-section': true, 'second-section-background': !_ctx.all})
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("p", {
              class: "start-game-label springy-animation",
              style: _normalizeStyle(isSecondSectionBackground.value ? { 'margin-left': '7px' } : null)
            }, _toDisplayString(_unref(t)(_ctx.myroom ? 'main.myrooms' : 'main.start_game')), 5),
            _cache[5] || (_cache[5] = _createElementVNode("div", { class: "game-hr" }, null, -1))
          ]),
          (_unref(isLoaded))
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(filteredPrivateRooms.value, (room, i) => {
                  return (_openBlock(), _createBlock(RoomsListItem, {
                    key: i,
                    onClick: ($event: any) => {triggerHapticFeedback('light'); tryJoinPrivateRoom(room)},
                    privateRooms: _unref(privateRooms),
                    room: room,
                    myroom: _ctx.myroom,
                    isCheckedTop: isCheckedTop.value
                  }, null, 8, ["onClick", "privateRooms", "room", "myroom", "isCheckedTop"]))
                }), 128))
              ]))
            : _createCommentVNode("", true),
          (_ctx.myroom)
            ? (_openBlock(), _createElementBlock("div", {
                key: 1,
                class: "available-card-plus",
                onClick: scrollToTopPrivate
              }, _cache[6] || (_cache[6] = [
                _createElementVNode("svg", {
                  width: "19",
                  height: "19",
                  viewBox: "0 0 19 19",
                  fill: "none",
                  xmlns: "http://www.w3.org/2000/svg"
                }, [
                  _createElementVNode("rect", {
                    x: "0.75",
                    y: "-0.75",
                    width: "1.5",
                    height: "17.5",
                    rx: "0.75",
                    transform: "matrix(1 0 0 -1 8 17.5)",
                    fill: "#A5A5A6",
                    stroke: "white",
                    "stroke-width": "1.5"
                  }),
                  _createElementVNode("rect", {
                    x: "0.75",
                    y: "0.75",
                    width: "1.5",
                    height: "17.5",
                    rx: "0.75",
                    transform: "matrix(-4.37114e-08 1 1 4.37114e-08 -3.27835e-08 8)",
                    fill: "#A5A5A6",
                    stroke: "white",
                    "stroke-width": "1.5"
                  })
                ], -1)
              ])))
            : (_openBlock(), _createElementBlock("div", _hoisted_9, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.max_len, (i) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "available-card-skeleton",
                    key: i
                  }, [
                    _createVNode(AnimatedPlaceholder, {
                      width: "100%",
                      height: "100%",
                      "border-radius": "var(--border-mixed)"
                    })
                  ]))
                }), 128))
              ])),
          (!_ctx.all)
            ? (_openBlock(), _createElementBlock("div", {
                key: 3,
                class: "all-rooms",
                onClick: scrollToTop
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("p", null, _toDisplayString(_unref(t)(`main.all_rooms`)), 1)
                ]),
                _cache[7] || (_cache[7] = _createElementVNode("div", null, [
                  _createElementVNode("img", { src: _imports_0 })
                ], -1))
              ]))
            : _createCommentVNode("", true),
          (showSettingsPop.value)
            ? (_openBlock(), _createBlock(SettingsRoom, {
                key: 4,
                onClose: handleCloseSettingsPopup,
                room: _ctx.room!,
                "join-room": _ctx.joinRoom,
                player: _ctx.getMyPlayer!
              }, null, 8, ["room", "join-room", "player"]))
            : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}
}

})